import React, { useState, useEffect } from 'react';

import { observer } from 'mobx-react';
import {
  TextField,
  Typography,
  Box,
  Button,
  DialogActions,
  FormControlLabel,
  Switch,
} from '@mui/material';

import { convertFormData } from '../../utils/form';
import { PushNotification, Camp } from '../../generated/graphql';
import { FullWidthForm } from '../common/CommonDivs';
import { useStores } from '../../stores';

interface CreatePushNotificationProps {
  onSubmit: (data) => void;
  onCancel: () => void;
  existingItem?: PushNotification;
  camp: Camp;
}
export const CreatePushNotification = observer(function CreatePushNotification({
  onSubmit,
  onCancel,
  existingItem,
  camp,
}: CreatePushNotificationProps) {
  const stores = useStores();
  const modalStore = stores.modalStore;

  const [new_passwords, setPasswords] = useState('');

  const p_str = camp.app_passwords;
  let passwords = existingItem ? existingItem.passwords : '';

  let roles = [];

  if (p_str)
  {
    roles = p_str.split(',');
  }

  console.log('existingItem', existingItem);
  console.log('camp', camp);

  const getRoleTitle = (role) => {
    return role.split('_')[1];
  }

  const getChecked = (existingItem, role) => {
    if (!existingItem) return false;
    if (!existingItem.passwords) return false;
    return existingItem.passwords.includes(role.split('_')[0]);
  };

  const getChecked2 = (pw, role) => {
    return pw.includes(role.split('_')[0]);
  };

  const setPassword = (role, set) => {

    let p = role.split('_')[0];

    if (!existingItem)
      {
        passwords = new_passwords;
      }

    console.log('p', p);
    console.log(passwords);
    console.log('set', set);

    if (set)
      {
        passwords = passwords + p + ',';
      }
    if (!set)
      {
        passwords = passwords.replace(p, '');
      }

      passwords = passwords.replace(',,', ',');

      console.log('passwords', passwords);

      if (existingItem)
        {
          existingItem.passwords = passwords;
        }
        else
        {
          setPasswords(passwords);
        }

  }


  const isEdit = !!existingItem;
  const [restrictedAudience, setRestrictedAudience] = useState(false);

  useEffect(() => {
    if (!existingItem) return;
    setRestrictedAudience(existingItem.restrictedAudience);
  }, [existingItem]);

  const submit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const data = convertFormData(e.target);

    //if creating new push, warn the user.
    if (!isEdit) {
      const confirmed = await modalStore.confirm(
        'Är du säker på att du vill skicka en notis till ' + camp.name,
      );
      if (!confirmed) return;
    }

    if (existingItem) {
      data.passwords = passwords;
    }
    else
    {
      data.passwords = new_passwords;
    }

    data.restrictedAudience = restrictedAudience;
    onSubmit(data);
  };

  return (
    <Box p={4} paddingBottom={1}>
      <Typography component="h2" variant="h5">
        {isEdit ? 'Uppdatera inlägg' : 'Skapa nytt inlägg'}
      </Typography>
      <FullWidthForm noValidate onSubmit={submit}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="title"
          name="title"
          label="Rubrik"
          autoFocus
          defaultValue={existingItem ? existingItem.title : ''}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="description"
          name="description"
          defaultValue={existingItem ? existingItem.description : ''}
          label="Brödtext"
          rows={4}
          multiline
        />
    {

      roles.map((role) => (
        <FormControlLabel
          control={
            <Switch
              checked={existingItem ? getChecked(existingItem, role) : getChecked2(new_passwords, role) }
              onChange={(event) => {
                setPassword(role, event.target.checked);
                console.log('event.target.checked', event.target.checked);
              }}
            />
          }
          label={getRoleTitle(role)}
        />
      ))

    }
        <DialogActions>
          <Button color="secondary" type="button" onClick={onCancel}>
            {'Avbryt'}
          </Button>
          <Button color="primary" type="submit">
            {isEdit ? 'Spara pushnotis' : 'Skapa ny pushnotis'}
          </Button>
        </DialogActions>
      </FullWidthForm>
    </Box>
  );
});
