import { observer } from 'mobx-react';
import { Paper } from '@mui/material';
import { NetworkStatus } from '@apollo/client';
import { useRouteLoaderData } from 'react-router-dom';

import {
  CampContactInput,
  CampContactsDocument,
  CampContactUpdate,
  CampQuery,
  useCampContactsQuery,
  useCreateCampContactMutation,
  useDeleteCampContactMutation,
  useUpdateCampContactMutation,
} from '../../../../../../generated/graphql';
import {
  CacheOperation,
  updateCacheWithCampId,
} from '../../../../../../utils/updateCache';
import { CustomDialog } from '../../../../../../components/common/CustomDialog';
import { ErrorLoading } from '../../../../../../components/common/ErrorLoading';
import { AutoTableModal } from '../../../../../../components/Modals/AutoTableModal';
import {
  TableComponent,
  TableHeader,
} from '../../../../../../components/Table/Table';
import { TableColumn } from '../../../../../../types/tableColumn';
import { useStores } from '../../../../../../stores';
import { InputType } from '../../../../../../types/inputTypes';

const headers: TableColumn[] = [
  {
    title: 'Namn',
    accessor: 'name',
    inputType: InputType.Text,
  },
  {
    title: 'Beskrivning',
    accessor: 'description',
    inputType: InputType.Text,
  },
  {
    title: 'Telefon',
    accessor: 'phone',
    inputType: InputType.Text,
  },
];

export const AppContactPage = observer(function AppContactPage() {
  const stores = useStores();
  const modalStore = stores.modalStore;
  const camp = useRouteLoaderData('camp') as CampQuery['camp'];
  const camp_id = camp.camp_id;
  const { data, error, loading, refetch, networkStatus } = useCampContactsQuery(
    { variables: { camp_id } },
  );
  const [deleteItem] = useDeleteCampContactMutation();

  const refetching = networkStatus === NetworkStatus.refetch;

  const [updateItem] = useUpdateCampContactMutation();

  if (!data) {
    return <ErrorLoading {...{ loading, error }} />;
  }

  return (
    <>
      <Paper>
        <TableHeader
          title={'Kontaktinfo'}
          onAddItem={() => {
            modalStore.addItem();
          }}
        />
        {!!data.campContacts && (
          <TableComponent
            headers={headers}
            rows={data.campContacts}
            showEdit
            onUpdateRow={(id, accessor, newValue) => {
              return updateItem({
                variables: {
                  campContact: {
                    camp_id,
                    id: id as string,
                    [accessor]: newValue,
                  },
                },
              });
            }}
            onEdit={(row) => modalStore.updateItem(row)}
            onDelete={(row) => {
              deleteItem({
                variables: {
                  camp_id,
                  campContactId: String(row.id),
                },
                update: (proxy: any) =>
                  updateCacheWithCampId({
                    query: CampContactsDocument,
                    proxy,
                    method: CacheOperation.DELETE,
                    camp_id: camp_id!,
                    mutationResultObject: null,
                    id: row.id,
                  }),
              });
            }}
          />
        )}

        <AddItem camp_id={camp_id!} headers={headers} />
        <EditItem camp_id={camp_id!} headers={headers} />
      </Paper>
    </>
  );
});

const AddItem = observer(
  ({ camp_id, headers }: { camp_id: number; headers: TableColumn[] }) => {
    const stores = useStores();
    const modalStore = stores.modalStore;

    const [createItem] = useCreateCampContactMutation();

    const close = modalStore.closeAddItemModal;

    return (
      <CustomDialog open={modalStore.showingAddItemModal} onClose={close}>
        <AutoTableModal
          title="kontakt"
          headers={headers}
          onCancel={close}
          onSubmit={async (data) => {
            data.camp_id = camp_id;
            await createItem({
              variables: {
                campContact: data as CampContactInput,
              },
              update: (
                proxy: any,
                mutationResult: { data: { createCampContact: any } },
              ) => {
                updateCacheWithCampId({
                  query: CampContactsDocument,
                  proxy,
                  method: CacheOperation.CREATE,
                  camp_id,
                  mutationResultObject: mutationResult.data.createCampContact,
                });
              },
            });
            close();
          }}
        />
      </CustomDialog>
    );
  },
);

const EditItem = observer(
  ({ camp_id, headers }: { camp_id: number; headers: TableColumn[] }) => {
    const stores = useStores();
    const modalStore = stores.modalStore;
    const [updateItem] = useUpdateCampContactMutation();

    const close = modalStore.closeUpdateItemModal;

    return (
      <CustomDialog open={modalStore.showingUpdateItemModal} onClose={close}>
        <AutoTableModal
          title="kontakt"
          headers={headers}
          existingItem={modalStore.existingItem}
          onCancel={close}
          onSubmit={(data) => {
            data.camp_id = camp_id;
            data.id = modalStore.existingItem.id;
            updateItem({
              variables: {
                campContact: data as CampContactUpdate,
              },
            });
            close();
          }}
        />
      </CustomDialog>
    );
  },
);
