import React from 'react';

import { observer } from 'mobx-react';
import { TextField } from '@mui/material';

import { AppCampCollectionFragment } from '../../../generated/graphql';
import { FlexBetween } from '../../common/CommonDivs';
import { DeleteIcon, SavedIconText, WhiteIconButton } from '../../icons';
import { LabelSwitch } from '../../switch';
import { useUpdateCampCollection } from '../../../hooks/camps';

export const CampCollectionForm = observer(function CampCollectionForm({
  selectedItem,
  setSelectedItem,
  itemSingular,
  descriptionEnabled = false,
}: {
  selectedItem: AppCampCollectionFragment;
  setSelectedItem: (item: any) => void;
  itemSingular: string;
  descriptionEnabled?: boolean;
}) {
  const {
    state,
    hasSaved,
    handleChange,
    handleSwitchChange,
    onKeyDown,
    handleBlur,
    deleteItem,
  } = useUpdateCampCollection({ item: selectedItem });

  const deleteCampCollectionItem = () => {
    deleteItem()
      .then(() => setSelectedItem(null))
      .catch();
  };

  if (!state) {
    return <div>Ingen vald {itemSingular}</div>;
  }
  const { name, description, deleted } = state;

  return (
    <div>
      <TextField
        margin="normal"
        required={true}
        fullWidth
        id={'name'}
        name={'name'}
        label={`Namn på ${itemSingular}`}
        value={name || ''}
        onChange={handleChange}
        onKeyDown={onKeyDown}
        onBlur={handleBlur}
      />
      {descriptionEnabled && (
        <TextField
          margin="normal"
          fullWidth
          id={'description'}
          name={'description'}
          label={`Extra info i anmälningsformuläret`}
          value={description || ''}
          onChange={handleChange}
          onKeyDown={onKeyDown}
          onBlur={handleBlur}
        />
      )}
      <LabelSwitch
        label={'Är aktiv i Anmälningsformuläret'}
        // note this is saved as deleted in the database
        // check useUpdateCollection:58
        name={'enabled'}
        value={!deleted}
        onChange={handleSwitchChange}
      />
      <br />
      <br />
      <FlexBetween>
        <SavedIconText visible={hasSaved} />

        <WhiteIconButton
          icon={<DeleteIcon />}
          title={`Radera ${itemSingular}`}
          onClick={deleteCampCollectionItem}
        />
      </FlexBetween>
    </div>
  );
});
