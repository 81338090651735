import * as React from 'react';

import { useMediaQuery } from '@mui/material';
import {
  createTheme,
  Theme,
  ThemeOptions,
  useTheme,
} from '@mui/material/styles';
import { createStyled } from '@mui/system';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';

import type { LinkProps } from '@mui/material/Link';
import type { MUIStyledCommonProps } from '@mui/system';

import type {} from '@mui/x-date-pickers/themeAugmentation';

const LinkBehavior = React.forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }
>((props, ref) => {
  const { href, ...other } = props;

  // Map href (Material UI) -> to (react-router)
  return <RouterLink ref={ref} to={href} {...other} />;
});

// export const colors = {
//   primary: '#CE4D3E',
//   success: '#50E850',
//   successDark: '#4caf50',
//   dark: '#313131',
//   error: '#CE4D3E',
// };

const bodyFonts = [
  'Roboto',
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
].join(',');

const headerFonts = ['"Open Sans"', 'sans-serif'].join(',');

export const baseTheme: ThemeOptions = {
  typography: {
    fontFamily: bodyFonts,
    button: {
      lineHeight: 1.25,
    },
    h1: { fontFamily: headerFonts },
    h2: { fontFamily: headerFonts },
    h3: { fontFamily: headerFonts },
    h4: { fontFamily: headerFonts },
    h5: { fontFamily: headerFonts },
    h6: { fontFamily: headerFonts },
  },
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
      styleOverrides: {
        root: {
          ['& .MuiSvgIcon-root']: {
            width: '16px',
            height: '16px',
            color: 'inherit',
            marginRight: '4px',
          },
        },
      },
    },
  },
};

let theme = createTheme(baseTheme);

theme = createTheme(theme, {
  // Custom colors created with augmentColor go here
  palette: {
    primary: theme.palette.augmentColor({
      color: {
        main: '#ab3224',
        // dark: '#313131',
      },
      name: 'primary',
    }),
    secondary: theme.palette.augmentColor({
      color: {
        main: '#D5D5D5',
        // dark: '#313131',
      },
      name: 'secondary',
    }),
    success: theme.palette.augmentColor({
      color: {
        main: '#50E850',
        dark: '#4caf50',
      },
      name: 'success',
    }),
    error: theme.palette.augmentColor({
      color: {
        main: '#CE4D3E',
      },
      name: 'error',
    }),
    common: {
      deleted: '#ffecef',
      warning: '#ffeaba',
      approved: '#ecffc3',
    },
  },
});

export { theme };

export const styled = createStyled({ defaultTheme: theme });

export const mediaMobile = (
  p: MUIStyledCommonProps<Theme> &
    React.ClassAttributes<HTMLElement> &
    React.HTMLAttributes<HTMLElement>,
) => p.theme!.breakpoints.down('sm');
export const mediaTablet = (
  p: MUIStyledCommonProps<Theme> &
    React.ClassAttributes<HTMLElement> &
    React.HTMLAttributes<HTMLElement>,
) => p.theme!.breakpoints.only('lg');
export const mediaLarge = (
  p: MUIStyledCommonProps<Theme> &
    React.ClassAttributes<HTMLElement> &
    React.HTMLAttributes<HTMLElement>,
) => p.theme!.breakpoints.only('xl');

export const useMobile = function useMobile() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  return matches;
};
