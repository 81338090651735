import { observer } from 'mobx-react';

import {
  MutationType,
  Person,
  usePersonMutatedSubscription,
} from '../../generated/graphql';
import { useStores } from '../../stores';

/**
 * Drop this anywhere you want notifications about person updates.
 * However requires logged in as admin
 */
export const PersonUpdater = observer(function PersonUpdater() {
  const stores = useStores();
  const store = stores.personStore;
  const selectedCampId = store.activeCamp && store.activeCamp.camp_id;

  usePersonMutatedSubscription({
    variables: {
      camp_id: selectedCampId,
    },
    skip: !selectedCampId,
    fetchPolicy: 'no-cache',
    onSubscriptionData: ({ subscriptionData }) => {
      const { mutation, node: person } = subscriptionData.data.personMutated;

      switch (mutation) {
        case MutationType.Created:
          store.createPerson(person as Person);
          break;
        case MutationType.Updated:
          store.updatePersonFromSocket(person);
          break;
        case MutationType.Deleted:
          store.deletePerson(person as Person);
          break;
        default:
          break;
      }
    },
  });

  return null;
});
