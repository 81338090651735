import React from 'react';

import { observer } from 'mobx-react';
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  Droppable,
  DroppableProvided,
  DropResult,
} from 'react-beautiful-dnd';
import { Switch } from '@mui/material';

import { styled } from '../../../styles/theme';
import { ExtendedCampColumn } from '../../../types/extendedCampColumn';
import { useStores } from '../../../stores';

import { FlexLabel, StyledDraggableColumn } from './StyledDraggableColumn';
// import { Handle } from '../table-cells';

const StyledColumnList = styled('div')`
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  @media screen and (max-width: 800px) {
    height: 60vh;
  }
`;

export const ColumnList = observer(function ColumnList() {
  const reorder = (from: number, to: number) => {};

  const handleDragEnd = (result: DropResult) => {
    // setDragging(false);
    // dropped outside the list or same pos
    if (!result.destination) return;
    const from = result.source.index;
    const to = result.destination.index;
    if (to === from) return;
    reorder(from, to);
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="userColumnList">
        {(droppableProvided: DroppableProvided) => (
          <StyledColumnList
            className="scrollbar"
            {...droppableProvided.droppableProps}
            ref={(ref) => {
              droppableProvided.innerRef(ref);
            }}
          >
            <DraggableList />
            {droppableProvided.placeholder}
          </StyledColumnList>
        )}
      </Droppable>
    </DragDropContext>
  );
});

const DraggableList = observer(function DraggableList({}) {
  const stores = useStores();
  const store = stores.personStore;
  const columns = store.columns;

  //Update the user header information.
  const update = (
    column: ExtendedCampColumn,
    { active }: { active: boolean },
  ) => {
    column.enabled = active;
  };

  return (
    <>
      {columns.map((column, idx) => {
        return (
          <Draggable
            draggableId={String(column.name)}
            index={idx}
            key={column.name}
            isDragDisabled
          >
            {(provided, snapshot) => {
              const { dragHandleProps, draggableProps, innerRef } = provided;
              const { isDragging } = snapshot;

              return (
                <DraggableColumn
                  {...{
                    column,
                    update,
                    dragHandleProps,
                    draggableProps,
                    innerRef,
                    isDragging,
                  }}
                />
              );
            }}
          </Draggable>
        );
      })}
    </>
  );
});

const DraggableColumn = observer(function DraggableColumn({
  column,
  update,
  isDragging,
  ...draggable
}: {
  column: ExtendedCampColumn;
  isDragging?: boolean;
  update: (column: any, updates: { active: boolean }) => void;
} & DraggableProvided) {
  const columnTitle = column.header;

  return (
    <StyledDraggableColumn
      ref={draggable.innerRef}
      isDragging={isDragging}
      {...draggable.draggableProps}
      {...draggable.dragHandleProps}
    >
      <FlexLabel>{columnTitle}</FlexLabel>
      <Switch
        color="primary"
        checked={!!column.enabled}
        onChange={(e) => {
          update(column, { active: e.target.checked });
        }}
      />
    </StyledDraggableColumn>
  );
});
