import {
  useCreateStudygroupMutation,
  StudyGroupCollection,
  StudygroupCollectionDocument,
  useDeleteStudygroupMutation,
  StudyGroup,
} from '../../generated/graphql';
import { updateCache, CacheOperation } from '../../utils/updateCache';

export const useCreateList = function useCreateList(
  groupCollection: StudyGroupCollection,
) {
  const [createList] = useCreateStudygroupMutation({
    variables: { groupCollectionId: groupCollection.id, name: 'Namnlös grupp' },
    update: (proxy, { data }) => {
      updateCache({
        query: StudygroupCollectionDocument,
        variables: {
          id: groupCollection.id,
        },
        proxy,
        method: CacheOperation.CREATE,
        dataPath: 'studygroups',
        mutationResultObject: data.createStudygroup,
      });
    },
  });

  return createList;
};

export const useDeleteList = function useDeleteList(studygroup: StudyGroup) {
  const [del] = useDeleteStudygroupMutation({
    variables: {
      id: studygroup.id,
    },
    update: (proxy) => {
      updateCache({
        query: StudygroupCollectionDocument,
        variables: {
          id: studygroup.group_collection_id,
        },
        dataPath: 'studygroups',
        proxy,
        id: studygroup.id,
      });
    },
  });

  return del;
};
