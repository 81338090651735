import { Link, styled } from '@mui/material';
import { tss } from 'tss-react/mui';

export const StyledLink = styled(Link)`
  text-decoration: none;
`;

// const hoverStyles = tss.withName('hoverStyles').create((p) => ({
//   hover: {
//     borderBottom: '1px solid white',

//     '&:hover, &:focus': {
//       backgroundColor: 'white',
//       color: p.theme.palette.primary.main,
//       borderBottom: `1px solid ${p.theme.palette.primary.main}`,
//     },
//   },
// }));

//   css`
//   border-bottom: 1px solid white;
//   &:hover,
//   &:focus {
//     background-color: white;
//     color: ${colors.primary};
//     border-bottom: 1px solid ${colors.primary};
//   }
// `;
type HeaderProps = {
  link?: boolean;
};

export const StyledDashboardItemHeader = styled('div')<HeaderProps>`
  background-color: ${(p) => p.theme.palette.primary.main};
  color: ${(p) =>
    p.theme.palette.getContrastText(p.theme.palette.primary.main)};

  ${(p) =>
    p.link && {
      borderBottom: '1px solid white',

      '&:hover, &:focus': {
        backgroundColor: 'white',
        color: `${p.theme.palette.primary.main}`,
        borderBottom: `1px solid ${p.theme.palette.primary.main}`,
      },
    }}

  height: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  position: relative;

  .dashboard-header-left {
    left: 12px;
    position: absolute;
    height: 1.5rem;
    position: absolute;
    user-select: none;
  }

  .dashboard-header-middle {
    margin: 0;
    color: inherit;
    text-align: center;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    user-select: none;
  }

  .dashboard-header-right {
    right: 12px;
    position: absolute;
    height: 1.5rem;
    position: absolute;
    user-select: none;
  }
`;
