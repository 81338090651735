import React, { useMemo } from 'react';

import { observer } from 'mobx-react';
import { useRouteLoaderData } from 'react-router-dom';

import { SharedLink, Camp, CampQuery } from '../../generated/graphql';
import { TableColumn } from '../../types/tableColumn';
import { InputType } from '../../types/inputTypes';

import { AutoTableModal } from './AutoTableModal';

interface CreateSharedLinkProps {
  existingItem?: SharedLink;
  onSubmit: (data) => void;
  onCancel: () => void;
  title: string;
}

export const CreateSharedLinkModal = observer(
  (props: CreateSharedLinkProps) => {
    const camp = useRouteLoaderData('camp') as CampQuery['camp'];

    const headers: TableColumn[] = useMemo(() => createHeaders(camp), [camp]);

    return <AutoTableModal headers={headers} {...props} />;
  },
);
function createHeaders(camp: Camp): TableColumn[] {
  return [
    {
      title: 'Namn på listan',
      accessor: 'name',
      inputType: InputType.Text,
    },
    {
      title: 'Startdatum',
      accessor: 'startDate',
      inputType: InputType.Date,
      defaultValue: new Date(),
    },
    {
      title: 'Slutdatum',
      accessor: 'endDate',
      inputType: InputType.Date,
      defaultValue: new Date(camp.end_date),
    },
    {
      title: 'Aktiv',
      accessor: 'active',
      inputType: InputType.Boolean,
    },
  ];
}
