import { useMemo } from 'react';

import { ApolloError, useReactiveVar } from '@apollo/client';

import { useCampsQuery } from '../../generated/graphql';
import { meVar } from '../../stores';
import { isUserAuthForCamp } from '../../helpers/camps';
import { campSorter } from '../../utils/date';

export const useCampsQueryWithAuth = function useCampsQueryWithAuth() {
  const result = useCampsQuery();
  const me = useReactiveVar(meVar);

  const authCamps = useMemo(() => {
    return result?.data?.camps
      .filter((camp) => {
        return isUserAuthForCamp(me, camp.camp_id);
      })
      .sort(campSorter);
  }, [me, result?.data?.camps]);

  if (me) {
    return { ...result, data: { ...result.data, camps: authCamps } };
  }

  return {
    ...result,
    data: null,
    error: {
      name: 'UNAUTHORIZED',
      message: 'Användare ej autentitierad',
    } as ApolloError,
  } as ReturnType<typeof useCampsQuery>;
};
