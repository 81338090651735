export const convertToMap = function convertToMap<
  T extends {
    id?: any;
  },
>(array: T[]) {
  return array.reduce<{
    [id: string]: T;
  }>((acc, curr) => {
    acc[String(curr.id)] = curr;

    return acc;
  }, {});
};
