import { action, makeObservable, observable } from 'mobx';

import { ChurchLink, Person } from '../generated/graphql';

export class MovePersonStore {
  constructor() {
    makeObservable(this, {
      activeChurch: observable,
      setActiveChurch: action,
      activePerson: observable,
      setActivePerson: action,
      clear: action,
    });
  }

  private static _store: MovePersonStore;

  static get store() {
    if (!this._store) {
      this._store = new MovePersonStore();
    }

    return this._store;
  }

  activeChurch?: ChurchLink;

  setActiveChurch = (church?: ChurchLink) => {
    this.activeChurch = church;
  };

  activePerson: Person = null;

  setActivePerson = (person: Person) => {
    this.activePerson = person;
  };

  clear = () => {
    this.activePerson = null;
  };
}
