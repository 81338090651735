import { green, orange, red } from '@mui/material/colors';

import { styled } from '../../styles/theme';
import { mediaTablet } from '../../styles/theme';

export const StyledTableHeader = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 12px;
  /* background-color: #ffe3e0; */
  border-bottom: 2px solid #e0e0e0;

  .header {
    font-weight: 700;
  }
`;

export const StyledTable = styled('div')`
  flex: 1 1 auto;
  min-height: calc(100vh - 210px);
  div[role='grid'] {
    &:focus {
      outline: 0;
    }
  }
  .even {
    background-color: #f5f5f5;
  }

  /* .edit_modal_button {
    display: none;
    ${mediaTablet} {
      display: block;
    }
  } */

  .secureField {
    text-security: disc;
    -webkit-text-security: disc;
    -moz-text-security: disc;
    &:hover {
      text-security: none;
      -webkit-text-security: none;
      -moz-text-security: none;
    }
  }
  .hover {
    background-color: #eee;
  }

  /* First column is a floating column */
  .col-0 {
    border-right: 2px solid #ddd;
    font-weight: 900;
    &.even {
      /* background-color: #dedede; */
    }
    &.hover {
      color: ${(p) => p.theme.palette.primary.main};
    }
  }
`;

/*'.cell.hover': { '&:hover': { background: '#eee' } },
'.cell.row_approved': 
  background: '$approved',
  '&.even': { background: 'lighten($approved, 5)' },
  '&.hover,   &:hover': { background: 'darken($approved, 5)' },
'.cell.row_in_progress': 
  background: '$warning',
  '&.even': { background: 'darken($warning, 3)' },
  '&.hover,   &:hover': { background: 'darken($warning, 5)' },
'.cell.row_deleted':
  background: '$deleted',
  '&.even': { background: 'darken($deleted, 3)' },
  '&.hover,   &:hover': { background: 'darken($deleted, 5)' },
$deleted: #ffecef;
$warning: #ffeaba;
$approved: #ecffc3;*/
export const StyledTableCell = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  overflow: 'auto',
  padding: '6px',
  cursor: 'pointer',

  '&.hover': {
    '&:hover': {
      background: '#eee',
      color: `${theme.palette.getContrastText('#eee')}`,
    },
  },
  '&.row_approved': {
    background: `${green[100]}`,
    color: `${theme.palette.getContrastText(green[100])}`,
    '&.even': {
      background: `${green[50]}`,
      color: `${theme.palette.getContrastText(green[50])}`,
    },
    '&.hover,   &:hover': {
      background: `${green[200]}`,
      color: `${theme.palette.getContrastText(green[200])}`,
    },
  },
  '&.row_in_progress': {
    background: `${orange[100]}`,
    color: `${theme.palette.getContrastText(orange[100])}`,
    '&.even': {
      background: `${orange[200]}`,
      color: `${theme.palette.getContrastText(orange[200])}`,
    },
    '&.hover,   &:hover': {
      background: `${orange[300]}`,
      color: `${theme.palette.getContrastText(orange[300])}`,
    },
  },
  '&.row_deleted': {
    background: `${red[100]}`,
    color: `${theme.palette.getContrastText(red[100])}`,
    '&.even': {
      background: `${red[200]}`,
      color: `${theme.palette.getContrastText(red[200])}`,
    },
    '&.hover,   &:hover': {
      background: `${red[300]}`,
      color: `${theme.palette.getContrastText(red[300])}`,
    },
  },
  '&.disabled': {
    cursor: 'default',
    pointerEvents: 'none',
  },
  '&.empty': {
    cursor: 'default',
  },
  '& span': {
    flex: '1 1 auto',
  },
}));
