import React, { useMemo } from 'react';

import { observer } from 'mobx-react';
import { Typography } from '@mui/material';

import { StyledDashboardItemHeader, StyledLink } from './HeaderStyles';

export const DashboardItemHeader = observer(function DashboardItemHeader({
  primaryIcon,
  name,
  link,
  secondaryIcon,
}: {
  primaryIcon?: JSX.Element;
  secondaryIcon?: JSX.Element;
  name: string;
  link?: string;
}) {
  const innerDiv = useMemo(
    () => (
      <StyledDashboardItemHeader link={!!link}>
        <span className="dashboard-header-left">
          {primaryIcon ? primaryIcon : null}
        </span>
        <Typography
          className="dashboard-header-middle"
          component="span"
          variant="h6"
        >
          {name}
        </Typography>
        <span className="dashboard-header-right">
          {secondaryIcon ? secondaryIcon : null}
        </span>
      </StyledDashboardItemHeader>
    ),
    [link, name, primaryIcon, secondaryIcon],
  );

  return link ? <StyledLink href={link}>{innerDiv}</StyledLink> : innerDiv;
});
