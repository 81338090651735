import * as React from 'react';

import { observer } from 'mobx-react';
import MuiGlobalStyles from '@mui/material/GlobalStyles';
import { Interpolation, Theme } from '@mui/material/styles';

const globalStyles: Interpolation<Theme> = (theme) => ({
  'html, body, #root, .router': { minHeight: '100vh' },
  '.flex-between': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  '.main-container': {
    padding: '8px',
    maxWidth: '1200px',
    margin: '0 auto',
    '@media screen and (min-width: 1600px)': { maxWidth: '1600px' },
  },
  '.page': {
    display: 'flex',
    flexDirection: 'column',
    padding: `${theme.spacing(2)} 0`,
    gap: theme.spacing(2),
    position: 'relative',

    ['& > h1,\
      & > h2,\
      & > h3,\
      & > h4,\
      & > h5,\
      & > h6']: {
      padding: `0 ${theme.spacing(2)}`,
    },
  },
  // Table
  '.no_scrollbar': {
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': { display: 'none' },
  },
  '.maxWidthTextField': { maxWidth: '260px' },

  // Used while dragging an item in dnd
  '.drag-item': {
    padding: '4px 0',
    span: {
      display: 'block',
      padding: '15px',
      fontSize: '16px',
      textAlign: 'center',
      borderRadius: '2px',
      background: '#f4f4f4',
    },
  },

  // Used on users screen for the menu popover
  '.menu-dialog': {
    ul: { margin: '0', padding: '0' },
  },
  '.inner-menu-dialog': {
    maxHeight: '500px',
    overflow: 'auto',
    width: '300px',
  },

  // Utility classes
  '.strike-through': { textDecoration: 'line-through' },
  img: { maxWidth: '100%', height: 'auto' },
  '.relative': { position: 'relative' },
  '.absolute-center': {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
  },
  '.flex': { display: 'flex' },
  '.flex-column': { display: 'flex', flexDirection: 'column' },
  '.h-100': { height: '100%' },
  '.flexCenter': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  '.text-center': { textAlign: 'center' },
  '.text-right': { textAlign: 'right' },
  '.text-caption': { fontSize: '0.75rem' },
  '.text-bold': { fontWeight: 700 },
  '.mt-2': { marginTop: '8px' },
  '.text-decoration-none': { textDecoration: 'none' },
  '.d-inline-block': { display: 'inline-block' },
  '@keyframes fadein': { from: { opacity: 0 }, to: { opacity: 1 } },
  '.fadein-delay': {
    opacity: 0,
    animation: 'fadein 300ms 300ms ease-in-out forwards',
  },
  '.p-1': { padding: '4px' },
  '.p-2': { padding: '8px' },
  '.p-3': { padding: '16px' },
  '.mr-1': { marginRight: '4px' },
  '.bg-gray': { background: '#f5f5f5' },
  '.text-overflow-elipsis': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
});

export const GlobalStyles = observer(() => (
  <MuiGlobalStyles styles={globalStyles} />
));
