import React, { ReactNode } from 'react';

import { observer } from 'mobx-react';
import {
  Button,
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
  IconButton as MuiIconButton,
  IconButtonProps as MuiIconButtonProps,
} from '@mui/material';

import { mediaMobile, styled } from '../../styles/theme';

import { CloseIcon, AddIcon, SaveIcon } from './Icons';

export interface IconButtonProps extends MuiButtonProps {
  icon?: ReactNode;
  title?: string;
  active?: boolean;
}

export const IconButton = observer(function IconButton({
  icon,
  title,
  active = false,
  ...props
}: IconButtonProps) {
  return (
    <StyledIconButton {...props} active={active} startIcon={icon}>
      {title}
    </StyledIconButton>
  );
});

export const WhiteIconButton = observer(function WhiteIconButton({
  icon,
  title,
  ...props
}: IconButtonProps) {
  return (
    <StyledWhiteIconButton {...props} startIcon={icon}>
      {title}
    </StyledWhiteIconButton>
  );
});

export const CloseButton = observer(function CloseButton(
  props: MuiButtonProps,
) {
  return <IconButton title={'Stäng'} icon={<CloseIcon />} {...props} />;
});
export const SaveButton = observer(function SaveButton(props: MuiButtonProps) {
  return <IconButton title={'Spara'} icon={<SaveIcon />} {...props} />;
});
export const AddButton = observer(function AddButton(props: MuiButtonProps) {
  return <IconButton title={'Lägg till'} icon={<AddIcon />} {...props} />;
});

export const SimpleIconButton = styled(MuiButton)<{ active?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 30px;
  height: 30px;
  background: none;
  border: 1px solid white;

  background: ${(p) => (p.active ? 'white' : p.theme.palette.primary.main)};
  color: ${(p) => (p.active ? p.theme.palette.primary.main : 'white')};

  &:hover {
    background: ${(p) => (p.active ? 'white' : p.theme.palette.primary.main)};
  }
`;

export const TransparentIconButton = styled(MuiButton)`
  min-width: 10px;
  border: 1px solid white;

  background: transparent;
  color: white;
  font-weight: 700;
  text-transform: initial;

  &:hover {
    background: transparent;
  }
`;

const StyledIconButton = styled(Button)<{ active?: boolean }>`
  min-width: 10px;
  border: 1px solid white;
  text-transform: initial;
  font-weight: 700;

  color: ${(p) =>
    p.disabled ? 'white' : p.active ? p.theme.palette.primary.main : 'white'};
  background: ${(p) =>
    p.disabled ? 'gray' : p.active ? 'white' : p.theme.palette.primary.main};

  &:hover {
    background: ${(p) =>
      p.disabled ? 'gray' : p.active ? 'white' : p.theme.palette.primary.main};
  }

  ${mediaMobile} {
    min-width: 38px;
    padding: 8px 8px;
    font-size: 0px;

    .MuiButton-startIcon {
      margin-right: 0px;
      margin-left: 0px;
    }
  }
`;

const StyledWhiteIconButton = styled(MuiButton)`
  height: 34px;
  color: ${(p) => p.theme.palette.primary.main};
  border: 1px solid ${(p) => p.theme.palette.primary.main};
  text-transform: initial;
`;

export const StyledEditIcon = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  margin-right: 10px;
`;

export const ButtonGroup = styled('div')`
  display: flex;
  gap: 8px;
`;
