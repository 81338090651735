import React, { useEffect, useState } from 'react';

import { useRouteLoaderData } from 'react-router-dom';

import {
  Camp,
  CampQuery,
  useUpdateCampMutation,
} from '../../../generated/graphql';

export const useUpdateCamp = function useUpdateCamp() {
  const camp = useRouteLoaderData('camp') as CampQuery['camp'];
  const [state, setState] = useState(camp);
  const [hasSaved, setSaved] = useState(false);
  const [update] = useUpdateCampMutation();

  const handleChange = (e: any) => {
    const input = e.target as HTMLInputElement;
    const val = input.value;
    const name = input.name;
    setSaved(false);
    const newState = { ...state, [name]: val };
    setState(newState);

    return newState;
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      (e.target as HTMLInputElement).blur();
    }
  };

  const handleSave = (updates: Partial<Camp>) => {
    // TODO: This saves even if no changes
    console.log('updating:', updates);
    update({
      variables: {
        body: {
          camp_id: camp.camp_id,
          ...updates,
        },
      },
    })
      .then(() => setSaved(true))
      .catch();
  };

  const handleSwitchChange = (e) => {
    const name = e.target.name;
    const value = e.target.value == 'true';
    const newState = { ...state, [name]: value };
    setState(newState)
    handleSave({ [name]: value });
  };

  const handleBlur = (e) => {
    const name = e.target.name;
    const value = state[name];
    handleSave({ [name]: value });
  };

  useEffect(() => {
    setState(camp);
  }, [camp]);

  return {
    state,
    hasSaved,
    handleChange,
    handleSwitchChange,
    handleBlur,
    onKeyDown,
  };
};
