import React, { useCallback, useEffect, useState } from 'react';

import { observer } from 'mobx-react';
import Uppy from '@uppy/core';
import { DragDrop, ProgressBar } from '@uppy/react';
import XHRUpload from '@uppy/xhr-upload';

import '@uppy/drag-drop/dist/style.css';
import '@uppy/core/dist/style.css';
import '@uppy/progress-bar/dist/style.css';

import { SERVER_URL } from '../../config';

const uppy = new Uppy({
  restrictions: {
    maxNumberOfFiles: 300,
    allowedFileTypes: ['image/*', 'application/pdf', 'video/*'],
    maxFileSize: null,
    minNumberOfFiles: 1,
  },
  onBeforeUpload: (files) => {
    console.log('uploads');

    return files;
  },
  allowMultipleUploads: false,
  autoProceed: true,
});

uppy.use(XHRUpload, {
  endpoint: SERVER_URL + '/library/upload',
  withCredentials: true,
});

export const DropzoneComponent = observer(function DropzoneComponent({
  onAdded,
}) {
  useEffect(() => {
    const onCompleted = (result) => {
      console.log(result);
      onAdded();
    };

    uppy.on('complete', onCompleted);

    return () => {
      uppy.off('complete', onCompleted);
    };
  }, [onAdded]);

  return (
    <div
      style={{
        background: '#eee',
      }}
    >
      <DragDrop
        uppy={uppy}
        locale={{
          strings: {
            // Text to show on the droppable area.
            // `%{browse}` is replaced with a link that opens the system file selection dialog.
            dropHereOr: 'Dra en fil hit eller %{browse}',
            // Used as the label for the link that opens the system file selection dialog.
            browse: 'bläddra',
          },
        }}
      />
      <ProgressBar uppy={uppy} hideAfterFinish={false} />
    </div>
  );
});
