import { useEffect, useState, useCallback } from 'react';

import { Camp, useUpdateCampMutation } from '../../generated/graphql';

export const useUpdateCampChanger = function useUpdateCampChanger(
  camp: Camp,
  accessor: keyof Camp,
  validFn?: (value: string) => boolean,
) {
  const initialCode = camp[accessor] || '';
  const [val, setVal] = useState(initialCode);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    setSuccess(false);
    setVal(camp[accessor] || '');
  }, [accessor, camp]);

  const [update, { error, loading }] = useUpdateCampMutation();

  let errorMessage = null;

  if (error) {
    if (error.graphQLErrors) {
      errorMessage = error.graphQLErrors[0].message;
    } else {
      errorMessage = error.message;
    }
  }

  const isValid = validFn ? validFn(String(val)) : true;

  const handleUpdate = useCallback(() => {
    if (!isValid) return;
    setSuccess(false);
    update({
      variables: {
        body: {
          camp_id: camp.camp_id,
          [accessor]: val,
        },
      },
    }).then((result) => {
      setSuccess(!!result);
    });
  }, [accessor, camp.camp_id, isValid, update, val]);

  const onKeyDownHandler: React.KeyboardEventHandler<HTMLDivElement> =
    useCallback(
      (e) => {
        if (e.key === 'Enter') {
          handleUpdate();
        }
      },
      [handleUpdate],
    );

  useEffect(() => {
    setVal(camp[accessor] || '');
  }, [accessor, camp]);

  const onChangeHandler: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = useCallback((e) => {
    setVal(e.target.value);
    setSuccess(false);
  }, []);

  return {
    val,
    setVal,
    onChangeHandler,
    onKeyDownHandler,
    handleUpdate,
    isValid,
    success,
    setSuccess,
    errorMessage,
  };
};
