const VITE_CLIENT_URL = process.env.VITE_CLIENT_URL;
const VITE_HOME_URL = process.env.VITE_HOME_URL;
const VITE_SERVER_IP = process.env.VITE_SERVER_IP;
const VITE_FORCE_HTTP = process.env.VITE_FORCE_HTTP;
const DEV = process.env.NODE_ENV;


const SERVER_IP = process.env.VITE_SERVER_IP;

const isDevEnv = DEV == 'development' || VITE_FORCE_HTTP === 'true';
const s = isDevEnv ? '' : 's';

const ServerIP = VITE_SERVER_IP;
const SERVER_URL = `http${s}://${ServerIP}`;
const WEBSOCKET_URL = `ws${s}://${ServerIP}/graphql`;
const GQL_SERVER_URL = `http${s}://${ServerIP}/graphql`;

const CLIENT_URL = VITE_CLIENT_URL;

const HOME_URL = VITE_HOME_URL ?? 'https://anmalan.hjalmargardenlager.se';

export { SERVER_URL, WEBSOCKET_URL, GQL_SERVER_URL, CLIENT_URL, HOME_URL };

export const LEADER_LINK_PREFIX = 'llink';
export const HEADLEADER_LINK_PREFIX = 'alink';

export const EXTERNAL_SHARED_LINK_PREFIX = 'datalink';

export const getHeadLeaderLink = (link: string) => {
  if (!link) {
    return '';
  }

  return `${CLIENT_URL}/${HEADLEADER_LINK_PREFIX}/${link}`;
};
export const getLeaderLink = (link: string) => {
  if (!link) {
    return '';
  }

  return `${CLIENT_URL}/${LEADER_LINK_PREFIX}/${link}`;
};
export const getExternalSharedLink = (link: string) => {
  console.log('getExternalSharedLink', link);
  if (!link) {
    return '';
  }

  return `${CLIENT_URL}/${EXTERNAL_SHARED_LINK_PREFIX}/${link}`;
};