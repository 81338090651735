import { useEffect } from 'react';

import { runInAction } from 'mobx';
import { useRouteLoaderData } from 'react-router';

import {
  useLeaderChurchLinkQuery,
  useAdminChurchLinkQuery,
  ChurchLink,
  CampColumn,
  Person,
} from '../../generated/graphql';
import { useStores } from '../../stores';
import { HEADLEADER_LINK_PREFIX, LEADER_LINK_PREFIX } from '../../config';

/**
 * Handles the differences between headleader and leader link queries to a common interface
 */
export const useChurchLinkQuery = function useChurchLinkQuery({
  isHeadleader,
}: {
  isHeadleader: boolean;
}) {
  const stores = useStores();
  const personStore = stores.personStore;
  const linkStore = stores.linkStore;
  const churchLink = useRouteLoaderData(
    `${isHeadleader ? HEADLEADER_LINK_PREFIX : LEADER_LINK_PREFIX}/link`,
  ) as ChurchLink | string;
  const error = typeof churchLink === 'string' && churchLink;

  useEffect(() => {
    if (error) {
      linkStore.setError(error);
    } else {
      linkStore.setError('');
    }
  }, [linkStore, error]);

  useEffect(() => {
    if (churchLink && typeof churchLink !== 'string') {
      runInAction(() => {
        // console.log('Replacing churchLink properties');
        personStore.setCamp(churchLink.camp!);
        personStore.setPersons(churchLink.persons as Person[]);
        personStore.setCampColumns(churchLink.campColumns as CampColumn[]);
        personStore.setCampCollections(churchLink.camp!.campCollections);

        linkStore.setActiveLink(churchLink);
        linkStore.linkUrl = window.location.pathname;
        // Used in side bar navigation
        linkStore.setCamp(churchLink.camp!);
        if (churchLink.camp!.panicMode) {
          linkStore.togglePanicModal(true);
        }
      });
    }
  }, [churchLink, linkStore, personStore]);

  return churchLink;
};
