import React, { useEffect } from 'react';

import { observer } from 'mobx-react';
import { Link, ListItemButton, ListItemText } from '@mui/material';
import {
  PathMatch,
  useLocation,
  useMatch,
  useNavigate,
  Location,
  NavigateFunction,
  useRouteLoaderData,
} from 'react-router';

import { MeQuery } from '../../generated/graphql';
import { useStores } from '../../stores';
import { useCampsQueryWithAuth } from '../../hooks/camps';

import { useLayoutStyles } from './styles';

export const ChurchLinkMenuList = observer(function ChurchLinkMenuList() {

  const stores = useStores();
  const linkStore = stores.linkStore;
  const match = useMatch(linkStore.linkUrl);
  // @ts-expect-error
  const { classes, cx } = useLayoutStyles();

  if (!match) {
    return null;
  }

  return (
    <ListItemButton
      className={cx(classes.nested)}
      component={Link}
      href={linkStore.linkUrl}
      selected={!!match.params.url}
    >
      <ListItemText primary={linkStore.camp.name} />
    </ListItemButton>
  );
});

export const ActiveCampList = observer(function ActiveCampList() {
  const stores = useStores();
  const { activeCampStore, linkStore } = stores;
  const me = useRouteLoaderData('root') as MeQuery['me'];

  const { data } = useCampsQueryWithAuth();
  // @ts-expect-error
  const { classes, cx } = useLayoutStyles();

  const activeSharedLinkCamp = linkStore.camp;

  useEffect(() => {
    activeCampStore.setAllCamps(data?.camps);
  }, [activeCampStore, data?.camps]);

  if (activeSharedLinkCamp) {
    return <ChurchLinkMenuList />;
  }

  const currentUser = me;
  const isSiteAdmin = me?.siteAdmin;

  if (!currentUser || !isSiteAdmin) {
    return null;
  }

  // We display all camps if we are not site admins
  const activeCamps = isSiteAdmin
    ? activeCampStore.activeCamps
    : activeCampStore.camps;

  return (
    <>
      {activeCamps.map((camp) => {
        const pathName = `/dashboard/camps/${camp.camp_id}`;

        return (
          <Match path={`${pathName}/*`} key={camp.camp_id}>
            {(props) => (
              <ListItemButton
                className={cx(classes.nested)}
                component={Link}
                href={pathName}
                selected={!!props.match}
              >
                <ListItemText primary={camp.name} />
              </ListItemButton>
            )}
          </Match>
        );
      })}
      <ListItemButton
        className={cx(classes.nested)}
        component={Link}
        href={`/dashboard/camps`}
      >
        <ListItemText primary={'Visa alla läger'} />
      </ListItemButton>
    </>
  );
});

const Match = observer(function Match({
  path,
  children,
}: {
  path: string;
  children: React.FC<{
    match: PathMatch<string> | null;
    location: Location<any>;
    navigate: NavigateFunction;
  }>;
}) {
  const match = useMatch(path);
  const location = useLocation();
  const navigate = useNavigate();

  return children({ match, location, navigate });
});
