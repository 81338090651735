import React, { useEffect, useRef, useState } from 'react';

import { observer } from 'mobx-react';
import { Button, Grid, ModalProps } from '@mui/material';
import clsx from 'clsx';
import { observable } from 'mobx';
import { useRouteLoaderData } from 'react-router-dom';

import { styled } from '../../../styles/theme';
import {
  AppCampColumnFragment,
  CampQuery,
  useUpdateGdprDaysMutation,
} from '../../../generated/graphql';
import { cancelSubmit, convertFormData } from '../../../utils/form';
import {
  BorderedBox,
  FlexColumn,
  PaddedModalView,
} from '../../common/CommonDivs';
import { CustomDialog } from '../../common/CustomDialog';
import {
  AddIcon,
  ButtonGroup,
  CloseButton,
  IconButton,
  ImportIcon,
  RemoveIcon,
  SaveButton,
} from '../../icons';
import { StyledModalHeader } from '../common';
import { useGdprColumns } from '../../../hooks/camps';
import { useStores } from '../../../stores';

import { ImportGdpr } from '.';

const MODAL_TITLE = 'GDPR';

const touched = observable.box(false);

export const GDPRModalModal = observer(function GDPRModalModal({
  onClose,
  ...props
}: Omit<ModalProps, 'children'>) {
  const stores = useStores();
  const modalStore = stores.modalStore;
  const camp = useRouteLoaderData('camp') as CampQuery['camp'];
  const [importActive, setImportActive] = useState(false);
  const toggleImport = () => setImportActive((s) => !s);
  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    touched.set(false);
  }, []);

  const [update] = useUpdateGdprDaysMutation();

  const onSave = () => {
    if (!formRef.current) return;

    const columns = convertFormData(formRef.current);

    // Delete empty values and transform into integers
    Object.keys(columns).forEach((column) => {
      const value = columns[column];

      if (value === '') {
        delete columns[column];
      } else {
        columns[column] = Number(value);
      }
    });
    update({
      variables: {
        camp_id: camp.camp_id,
        columnsObject: columns,
      },
    }).then(() => {
      onClose({}, 'backdropClick');
    });
  };

  const handleCancel = async () => {
    const isTouched = touched.get();

    if (isTouched) {
      const result = await modalStore.confirm(
        'Du har osparade ändringar, är du säker på att du vill stänga?',
      );

      if (result) {
        onClose({}, 'backdropClick');
      }

      return;
    }
    onClose({}, 'backdropClick');
  };

  return (
    <CustomDialog {...props} onClose={handleCancel} maxWidth={'lg'}>
      <StyledModalHeader>
        <IconButton
          title={'Importera'}
          icon={<ImportIcon />}
          active={importActive}
          onClick={toggleImport}
        />
        <span></span>

        <h2>{MODAL_TITLE}</h2>
        <ButtonGroup>
          <SaveButton onClick={onSave} />
          <CloseButton
            onClick={(e) => {
              onClose(e, 'backdropClick');
            }}
            title={'Avbryt'}
          />
        </ButtonGroup>
      </StyledModalHeader>
      <PaddedModalView>
        <Grid container spacing={3}>
          <Grid
            item
            xs={4}
            className={clsx('animate', { active: importActive })}
          >
            <BorderedBox>
              <FlexColumn>
                <ImportGdpr />
              </FlexColumn>
            </BorderedBox>
          </Grid>

          <Grid
            item
            xs={8}
            className={clsx('animate', 'large', { active: importActive })}
          >
            <form ref={formRef} onSubmit={cancelSubmit}>
              <ItemList />
            </form>
          </Grid>
        </Grid>
      </PaddedModalView>
    </CustomDialog>
  );
});

const ItemList = observer(function ItemList() {
  const columns = useGdprColumns();

  return (
    <Grid container wrap={'wrap'} spacing={3}>
      {columns.map((item: any) => (
        <Item key={item.id} item={item} />
      ))}
    </Grid>
  );
});

const INCREMENT_BY = 365;

const Item = observer(function Item({ item }: { item: AppCampColumnFragment }) {
  const [val, setVal] = useState<number | undefined | null>(
    item.gdprDeleteAfter,
  );

  useEffect(() => {
    setVal(item.gdprDeleteAfter);
  }, [item.gdprDeleteAfter]);

  const updateValue = (val: number) => {
    setVal(val);
    touched.set(true);
  };

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    updateValue(Number(e.target.value));
  };

  const handleBlur: React.FocusEventHandler<HTMLInputElement> = (_e) => {
    // const value = Number(e.target.value);
    // console.log('save', value);
  };

  const increment = () => {
    setVal((v) => (!v ? INCREMENT_BY : v + INCREMENT_BY));
    touched.set(true);
  };

  const decrement = () => {
    setVal((v) => (!!v && v >= INCREMENT_BY ? v - INCREMENT_BY : 0));
    touched.set(true);
  };

  const disabled = !!item.gdprDeleted || item.nonDeletable;

  return (
    <Grid item xs={12} sm={4} md={4}>
      <StyledColumnRow>
        <span>{item.header}</span>
        <Button onClick={decrement} disabled={disabled!}>
          <RemoveIcon />
        </Button>
        <input
          placeholder={'Antal dagar'}
          type="number"
          name={item.name!}
          value={val === undefined || val === null ? '' : val}
          disabled={disabled!}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <Button onClick={increment} disabled={disabled!}>
          <AddIcon />
        </Button>
      </StyledColumnRow>
    </Grid>
  );
});

const StyledColumnRow = styled('div')`
  display: flex;
  align-items: center;
  > span {
    flex: 1 1 auto;
  }
  > button {
    border-radius: 0px;
    background: #f2f2f2;
    padding: 6px;

    min-width: 0;
  }
  input {
    height: 36px;
    width: 100px;
    font-size: 14px;
    margin: 0 4px;
    padding: 0 4px;
  }
`;
