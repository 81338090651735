import { action, computed, makeObservable, observable } from 'mobx';

import { Camp, ChurchLink } from '../generated/graphql';

export class LinkStore {
  constructor() {
    makeObservable(this, {
      displayingPanicModeModal: observable,
      togglePanicModal: action,
      error: observable,
      setError: action,
      activeLink: observable,
      setActiveLink: action,
      isHeadleader: observable,
      setIsHeadleader: action,
      permissionLevel: computed,
      camp: observable,
      setCamp: action,
      linkUrl: observable,
    });
  }
  displayingPanicModeModal = false;
  togglePanicModal = (override?: boolean) => {
    if (override) {
      this.displayingPanicModeModal = override;
    } else {
      this.displayingPanicModeModal = !this.displayingPanicModeModal;
    }
  };
  error?: string;
  setError = (e: string) => {
    this.error = e;
  };
  activeLink?: Partial<ChurchLink>;
  setActiveLink = (link: Partial<ChurchLink>) => {
    this.activeLink = link;
  };
  isHeadleader = false;
  setIsHeadleader = (isHeadleader: boolean) => {
    this.isHeadleader = isHeadleader;
  };

  // Lower is better
  get permissionLevel() {
    const activeLink = this.activeLink;

    if (activeLink) {
      return this.isHeadleader ? 20 : 30;
    }

    // We are admins if we dont use a link
    return 10;
  }

  camp?: Partial<Camp>;
  setCamp = (camp: Partial<Camp>) => {
    this.camp = camp;
  };

  // This replaces the links that takes the user to '/'
  linkUrl = '';

  // get linkUrl() {
  //   if (!this.activeLink) {
  //     return '';
  //   }
  //   if (this.isHeadleader) {
  //     return `/${HEADLEADER_LINK_PREFIX}/${this.activeLink.admin_link}`;
  //   }
  //   return `/${LEADER_LINK_PREFIX}/${this.activeLink.leader_link}`;
  // }
}
