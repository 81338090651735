import { createContext, useContext } from 'react';

import { action, computed, makeObservable, observable, set, toJS } from 'mobx';

import {
  Camp,
  CampCollection,
  CampColumn,
  InputType,
  Person,
  UpdatePersonDocument,
  UpdatePersonMutation,
  UpdatePersonMutationVariables,
  CreateSharedLinkDocument,
  CreateSharedLinkMutationVariables,
  CreateSharedLinkMutation,
  SharedLinksDocument,
  SharedLinkInput,
} from '../generated/graphql';
import { arrayMove } from '../utils/arrayutils';
import { exportCSVFile } from '../utils/convertPersonToString';
import { updateCache } from '../utils/updateCache';
import { ExtendedCampColumn } from '../types/extendedCampColumn';
import { ExtendedPerson } from '../types/person';
import {
  createExtendedPerson,
  getPersonValueFromColumn,
} from '../helpers/persons';
import { campColumnsSort } from '../helpers/camps';

import { client } from '.';

export type TableFilter = string | any[];

export const PANIC_MODE_COLUMN = 'panicModeIsHere';
export const ALWAYS_HIDDEN_COLUMNS = ['studygroup_id'];

export class PersonStore {
  constructor() {
    makeObservable(this, {
      activeCamp: observable,
      setCamp: action,
      filterOverlayActive: observable,
      setFilterOverlay: action,
      cardView: observable,
      toggleCardView: action,
      editingEnabled: observable,
      setEditingEnabled: action,
      editingPerson: observable,
      clearEditing: action,
      startEditingPerson: action,
      toggleRemovePerson: action,
      updatePerson: action,
      editModalPerson: observable,
      toggleEditModal: action,
      orderBy: observable,
      setOrderBy: action,
      filters: observable,
      filterMethod: observable,
      getColumnFilterValue: observable,
      getFilterStringValue: observable,
      onChangeFilter: action,
      showingDeleted: observable,
      setShowingDeleted: action,
      toggleShowingDeleted: action,
      persons: observable,
      activePersons: computed,
      setPersons: action,
      createPerson: action,
      deletePerson: action,
      updatePersonFromSocket: action,
      filteredPersons: computed,
      sortedPersons: computed,
      dataLength: computed,
      sortedPersonsByChurch: computed,
      columns: observable,
      setCampColumns: action,
      activeColumns: computed,
      showingAllColumns: computed,
      toggleAllColumns: action,
      displayingSharedLinkModal: observable,
      setDisplayingSharedLinkModal: action,
      createSharedLink: action,
      exportCSVFile: action,
      campCollections: observable,
      setCampCollections: action,
      piechartData: computed,
    });
  }
  static _store: PersonStore;
  static get store() {
    if (!this._store) {
      this._store = new this();
      window['person_store'] = this._store;
    }

    return this._store;
  }
  // Used by the person subscription updater
  activeCamp?: Camp;
  setCamp = (activeCamp: Partial<Camp>) => {
    this.activeCamp = activeCamp as Camp;
  };

  filterOverlayActive = false;
  setFilterOverlay = (active: boolean) => {
    this.filterOverlayActive = active;
  };

  cardView = false;
  toggleCardView = () => {
    this.cardView = !this.cardView;
  };

  // --- Overlays ---
  // if editingEnabled is set to false, we never open editing dialogs (example: sharedlinks)
  editingEnabled = true;
  setEditingEnabled = (bool: boolean) => {
    this.editingEnabled = bool;
  };
  editingPerson?: {
    person: ExtendedPerson;
    anchorEl: HTMLElement;
    column: ExtendedCampColumn;
  };

  // Removes all overlays
  clearEditing = () => {
    this.editingPerson = undefined;
  };
  startEditingPerson = (
    person: ExtendedPerson,
    anchorEl: HTMLElement,
    column: ExtendedCampColumn,
  ) => {
    if (this.editingEnabled) {
      this.editingPerson = { person, anchorEl, column };
    }
  };
  toggleRemovePerson = (person: ExtendedPerson) => {
    this.updatePerson(person, {
      deleted: !person.deleted,
    });
  };
  updatePerson = async (person: ExtendedPerson, values: any) => {
    if (this.editingEnabled) {
      client
        .mutate<UpdatePersonMutation, UpdatePersonMutationVariables>({
          mutation: UpdatePersonDocument,
          fetchPolicy: 'no-cache',
          variables: {
            id: person.id,
            values,
          },
        })
        .then(({ data }) => {
          if (!data) {
            return;
          }
          this.updatePersonFromSocket(data.updatePerson!);
        })
        .catch((e) => {
          console.log('Couldnt not update person:', e);
        });
    }
  };

  editModalPerson?: ExtendedPerson = undefined;
  toggleEditModal = (person?: ExtendedPerson) => {
    if (this.editModalPerson) {
      this.editModalPerson = undefined;
    } else {
      this.editModalPerson = person;
    }
  };

  // --- Sorting ---

  /** where 'id' is the accessor */
  orderBy = { id: 'first_name', direction: true };
  // window['orderBy'] = orderBy;
  setOrderBy = (column: ExtendedCampColumn) => {
    const orderBy = this.orderBy;
    orderBy.direction = orderBy.id === column.name ? !orderBy.direction : true;
    orderBy.id = column.name!;
  };

  // --- Filters ---

  readonly filters = observable.map<string, TableFilter>(new Map());
  filterMethod = (person: ExtendedPerson) => {
    // eslint-disable-next-line prefer-const
    for (let [filterId, filterValue] of this.filters.entries()) {
      // const filterValue = filter;
      if (!filterValue) {
        continue;
      }

      filterValue = String(filterValue);
      const column = this.columns.find((c) => c.name === filterId);
      const { stringValue, idValue } = getPersonValueFromColumn(
        person,
        column!,
      );

      let matches = false;

      switch (column!.inputType) {
        case InputType.Boolean:
          matches = idValue === filterValue;
          break;
        case InputType.Collection:
          // When using array filters were dealing with identifiers (id-numbers, booleans etc)
          matches = filterValue.indexOf(String(idValue)) !== -1;
          break;
        default:
          matches = stringValue
            .toLowerCase()
            .startsWith(filterValue.toLowerCase());
      }

      if (!matches) {
        return false;
      }
    }

    return true;
  };
  getColumnFilterValue = (column: ExtendedCampColumn) => {
    const currentFilter = this.filters.get(column.name!);
    let currentFilterValue: string;

    if (!currentFilter) {
      currentFilterValue = '';
    } else if (typeof currentFilter === 'string') {
      currentFilterValue = currentFilter;
    } else {
      if (currentFilter.length === 0) {
        currentFilterValue = 'Visa alla';
      }
      if (currentFilter.length > 1) {
        currentFilterValue = 'Flera';
      } else {
        const filterItem = currentFilter[0];
        currentFilterValue = this.getFilterStringValue(filterItem, column);
      }
    }

    return { currentFilter, currentFilterValue };
  };

  getFilterStringValue = (filterItem: any, column: ExtendedCampColumn) => {
    let currentFilterValue = '';

    switch (column.inputType) {
      case InputType.Boolean:
        currentFilterValue = filterItem === '1' ? 'Ja' : 'Nej';
        break;
      case InputType.Collection:
        currentFilterValue = this.campCollections.get(
          column.collection as string
        ).find((c) => c.id === filterItem)!.name;
        break;
      default:
        currentFilterValue = filterItem;
    }

    return currentFilterValue;
  };

  onChangeFilter = (column: ExtendedCampColumn, value: TableFilter) => {
    this.filters.set(column.name!, value);
  };

  // --- Persons ---

  showingDeleted = false;
  setShowingDeleted = (bool: boolean) => {
    this.showingDeleted = bool;
  };
  toggleShowingDeleted = () => {
    this.showingDeleted = !this.showingDeleted;
  };

  readonly persons = observable.array<ExtendedPerson>([]);
  get activePersons() {
    return this.showingDeleted
      ? this.persons.filter((p) => p.deleted)
      : this.persons.filter((p) => !p.deleted);
  }

  setPersons = (dataPersons: Person[]) => {
    const persons = dataPersons.map<ExtendedPerson>(createExtendedPerson);

    this.persons.replace(persons);
  };

  createPerson = (person: Person) => {
    console.log('Creating person from websocket', person.id);
    const c = this.persons.find((c) => c.id === person.id);

    if (!c) {
      this.persons.push(createExtendedPerson(person));
    }
  };
  deletePerson = (person: Person) => {
    const c = this.persons.find((c) => c.id === person.id);

    if (c) {
      // TODO: Do we ever delete persons?
      this.persons.remove(c);
    }
  };
  updatePersonFromSocket = (person: Partial<Person>) => {
    const exitingPerson = this.persons.find((c) => c.id === person.id);

    if (!exitingPerson) {
      return;
    }
    console.log('updatePersonFromSocket', person.id);
    set(exitingPerson, person);
    // exitingPerson.modifiedRow = true;
  };

  get filteredPersons() {
    return this.activePersons.filter(this.filterMethod);
  }
  get sortedPersons() {
    const orderById = this.orderBy.id;
    let column = this.columns.find((i) => i.name === orderById);
    // Default to the first column if none found
    if (!column) {
      column = this.columns[0];
    }

    return this.filteredPersons.slice().sort((a, b) => {
      const { stringValue: aVal } = getPersonValueFromColumn(a, column!);
      const { stringValue: bVal } = getPersonValueFromColumn(b, column!);
      const sortResult = aVal.localeCompare(bVal, 'sv', {
        numeric: true,
      });

      return this.orderBy.direction ? sortResult : -sortResult;
    });
  }
  get dataLength() {
    return `${this.filteredPersons.length}/${this.activePersons.length}`;
  }
  get sortedPersonsByChurch(): {
    churchId: number;
    name: string;
    persons: ExtendedPerson[];
  }[] {
    return this.filteredPersons.reduce(
      (acc, currentPerson) => {
        const churchId = currentPerson.church!.id;
        const existingChurch = acc.find((c) => c.churchId === churchId);

        if (existingChurch) {
          existingChurch.persons.push(currentPerson);
        } else {
          const name = currentPerson.church!.name;

          acc.push({
            churchId,
            name,
            persons: [currentPerson],
          });
        }

        return acc;
      },
      [] as {
        churchId: number;
        name: string;
        persons: ExtendedPerson[];
      }[],
    );
  }

  // --- Camp columns ---

  readonly columns = observable.array<ExtendedCampColumn>([]);
  setCampColumns = (campColumns: CampColumn[]) => {
    // TODO: refactor to loop for performance
    let columns = campColumns.map((col) => ({
      ...col,
      // title: c.header,
      // width: Math.random() * 300 + 50,
      enabled: true,
      parsedValues:
        col.values &&
        col.values.split('\n').map((value) => ({ id: value, name: value })),
      // parsedShowIf: col.showIf && JSON.parse(col.showIf),
    }));
    columns = columns.sort(campColumnsSort);

    // Check if we should show the panic_mode column
    if (this.activeCamp?.panicMode) {
      const index = columns.findIndex((c) => c.name === PANIC_MODE_COLUMN);

      if (index !== -1) {
        arrayMove(columns, index, 2);
      }
    } else {
      columns = columns.filter((c) => c.name !== PANIC_MODE_COLUMN);
    }
    // columns = columns.filter(c => !ALWAYS_HIDDEN_COLUMNS.includes(c.name));
    columns = columns.filter((c) => !c.hidden);

    this.columns.replace(columns);
  };

  get activeColumns() {
    return this.columns.filter((c) => c.enabled);
  }
  get showingAllColumns() {
    return this.activeColumns.length === this.columns.length;
  }
  toggleAllColumns = () => {
    if (this.showingAllColumns) {
      this.columns.forEach((c) => {
        if (c.name !== 'first_name') {
          c.enabled = false;
        }
      });
    } else {
      this.columns.forEach((c) => (c.enabled = true));
    }
  };

  displayingSharedLinkModal = false;
  setDisplayingSharedLinkModal = (show: boolean) => {
    this.displayingSharedLinkModal = show;
  };
  createSharedLink = async (input: any) => {
    const filterMap = toJS(this.filters);
    const filters = Object.keys(filterMap).map((columnName) => {
      const column = this.columns.find((c) => c.name === columnName);

      return {
        columnName,
        relationalId: column.relationalId,
        queryValue: filterMap[columnName],
      };
    });

    const body: SharedLinkInput = {
      ...input,
      allowedColumns: this.activeColumns.map((c) => {
        // prioritize relationalId
        if (c.relationalId) {
          return c.relationalId;
        }

        return c.name;
      }),
      filters,
    };
    const camp_id = this.activeCamp.camp_id;

    const result = await client.mutate<
      CreateSharedLinkMutation,
      CreateSharedLinkMutationVariables
    >({
      mutation: CreateSharedLinkDocument,
      variables: {
        camp_id,
        body,
      },
      update: (proxy, result) => {
        const createSharedLink = result.data.createSharedLink;
        console.log(createSharedLink);
        updateCache({
          proxy,
          query: SharedLinksDocument,
          mutationResultObject: createSharedLink,
          variables: {
            camp_id,
          },
        });
      },
    });
    const createSharedLink = result.data.createSharedLink;

    return createSharedLink;
  };

  exportCSVFile = () => {
    exportCSVFile(this.activePersons, this.activeColumns);
  };

  // --- Camp collections ---
  campCollections = observable.map<string, CampCollection[]>();
  setCampCollections = (campCollections: {
    [key: string]: CampCollection[];
  }) => {
    this.campCollections.replace(campCollections);
  };

  // Stats
  get piechartData() {
    return this.activePersons.reduce(
      (acc, curr) => {
        if (!curr.role) {
          return acc;
        }
        const roleSlug = curr.role.role_slug;
        const activeSet = acc.find((a) => a.slug === roleSlug);

        if (activeSet) {
          activeSet.value += 1;
        } else {
          acc.push({
            slug: roleSlug,
            name: curr.role.name,
            value: 1,
          });
        }

        return acc;
      },
      [] as { slug: string; name: string; value: number }[],
    );
  }
}
