import { Fragment } from 'react';

import { observer } from 'mobx-react';
import { useMatches } from 'react-router-dom';

type HandleType = {
  crumb: (param?: Record<string, any>) => React.ReactNode;
};

export const useBreadcrumbItems = () => {
  const matches = useMatches();
  const crumbs = matches
    // first get rid of any matches that don't have handle and crumb
    .filter((match) =>
      Boolean(match.handle && (match.handle as HandleType).crumb),
    )
    // now map them into an array of elements, passing the loader
    // data to each one
    .map((match) =>
      (match.handle as HandleType).crumb({
        ...(match.data as Record<string, any>),
        path: match.pathname,
      }),
    );
  // console.log('crumbs', crumbs);

  return crumbs;
};
