import { compareAsc, parseISO } from 'date-fns';

import { CampTimeTable, CampTimeTableQuery } from '../generated/graphql';

import { formatDate } from './date';

export const getSectionsFromCampTimetable =
  function getSectionsFromCampTimetable(data: CampTimeTableQuery): {
    title: string;
    data: CampTimeTable[];
  }[] {
    if (!data || !data.campTimeTable) {
      return [];
    }

    //Convert all to dates.
    const sectionObject = data.campTimeTable.reduce(
      (acc, curr) => {
        if (!curr) return acc;

        const date = parseISO(curr.date);
        const formattedDay = formatDate(date, 'yyyy-MM-dd');

        if (!acc[formattedDay]) {
          acc[formattedDay] = [];
        }
        acc[formattedDay].push({ ...curr, date });

        return acc;
      },
      {} as Record<string, any>,
    );

    const sectionKeys = Object.keys(sectionObject);

    const sections = sectionKeys
      .map((key) => {
        let items: CampTimeTable[] = sectionObject[key];
        items = items.sort((a, b) =>
          compareAsc(a.date as unknown as Date, b.date as unknown as Date),
        );

        return {
          title: key,
          data: items,
        };
      })
      .sort((a, b) => compareAsc(new Date(a.title), new Date(b.title)));

    return sections;
  };
