import React from 'react';

import { observer } from 'mobx-react';
import {
  Checkbox,
  Divider,
  FormControl,
  Input,
  ListItemText,
  MenuItem,
  MenuProps,
  Select,
  TableSortLabel,
  TextField,
} from '@mui/material';
import { GridCellProps } from 'react-virtualized';

import { InputType } from '../../generated/graphql';
import { FlexRow } from '../common/CommonDivs';
import { ExtendedCampColumn } from '../../types/extendedCampColumn';
import { useStores } from '../../stores';

import { StyledTableHeader } from './VirtualizedTableStyles';

const ITEM_HEIGHT = 42;
const ITEM_PADDING_TOP = 6;
const SHOW_ALL = 'Visa alla';

export const TableHeader = observer(function TableHeader({
  columnIndex,
  style,
}: GridCellProps) {
  // const [dropdown, setDropdown] = useState(null);
  const stores = useStores();
  const personStore = stores.personStore;
  const columns = personStore.activeColumns;
  const column = columns[columnIndex];

  if (!column) {
    return null;
  }

  const isSortingCurrent = personStore.orderBy.id === column.name;
  const sortDirection = isSortingCurrent
    ? personStore.orderBy.direction
      ? 'asc'
      : 'desc'
    : 'asc';

  const handleSort = () => {
    personStore.setOrderBy(column);
  };

  const handleSearchChange = (e) => {
    personStore.onChangeFilter(column, e.target.value);
  };

  const { currentFilter, currentFilterValue } =
    personStore.getColumnFilterValue(column);
  let filterComponent: JSX.Element = null;

  switch (column.inputType) {
    case InputType.Text:
    case InputType.Textarea:
    case InputType.Email:
    case InputType.Phone:
    case InputType.Number: {
      filterComponent = (
        <TextField
          type="text"
          placeholder={'Sök'}
          variant="standard"
          value={currentFilterValue}
          onChange={handleSearchChange}
        />
      );
      break;
    }
    case InputType.Values:
    case InputType.Boolean:
    case InputType.Collection: {
      filterComponent = (
        <>
          <FilterDropdown
            column={column}
            filter={currentFilter as any[]}
            selectedValue={currentFilterValue}
          />
          {/* <button
            onClick={e => {
              setDropdown(s => s? null: e.target);
            }}
          >
            {currentFilterValue}
          </button> */}
        </>
      );
      break;
    }
    default:
      break;
  }

  return (
    <StyledTableHeader style={style} className={'col-' + columnIndex}>
      <FlexRow>
        <TableSortLabel
          className="header"
          active={isSortingCurrent}
          direction={sortDirection}
          onClick={handleSort}
        >
          {column.header}
        </TableSortLabel>
      </FlexRow>
      {filterComponent}
    </StyledTableHeader>
  );
});

const FilterDropdown = observer(function FilterDropdown({
  column,
  filter,
  selectedValue,
}: {
  column: ExtendedCampColumn;
  filter: any[];
  selectedValue: string;
}) {
  const { handleChange, items } = useFilterItems(column);

  if (filter) {
    // Slice to pass isArray() checks
    filter = filter.slice();
  } else {
    filter = [];
  }
  // const isAllChecked = filter.length === 0 || items.length === filter.length;

  // const labelId = `mutiple-checkbox-label-${column.accessor}`;

  return (
    <FormControl>
      {/* <InputLabel id={labelId}>{SHOW_ALL}</InputLabel> */}
      <Select
        // labelId={labelId}
        multiple
        value={filter}
        onChange={handleChange}
        input={<Input placeholder={SHOW_ALL} />}
        renderValue={(selected: string[]) => selectedValue || 'Filtrera'}
        displayEmpty
        MenuProps={SelectMenuProps}
      >
        <MenuItem value={-1} style={styles.menuItem}>
          {/* <Checkbox checked={isAllChecked} /> */}
          <ListItemText primary={SHOW_ALL} style={styles.paddingLeft42} />
        </MenuItem>
        {items &&
          items.map((item) => (
            <MenuItem key={item.id} value={item.id} style={styles.menuItem}>
              <Checkbox checked={filter.indexOf(item.id) > -1} />
              <ListItemText primary={item.name} />
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
});

function useFilterItems(column: ExtendedCampColumn) {
  const stores = useStores();
  const personStore = stores.personStore;
  // const { data } = useCampCollectionQuery({
  //   variables: {
  //     camp_id: column.camp_id,
  //     collection: column.collection as any,
  //   },
  //   skip: column.inputType !== InputType.collection,
  // });

  let items: { id: any; name: string }[];

  if (column.inputType === InputType.Values) {
    items = column.parsedValues;
  } else if (column.inputType === InputType.Boolean) {
    items = [
      {
        id: '1',
        name: 'Ja',
      },
      {
        id: '0',
        name: 'Nej',
      },
    ];
  } else if (column.inputType === InputType.Collection) {
    items = personStore.campCollections.get(column.collection);
    if (items) {
      items = items.map((c) => ({ name: c.name, id: c.id }));
    }
  }

  const handleChange = (e) => {
    const val = e.target.value as any[];

    if (val.indexOf(-1) !== -1 || val.length === 0) {
      console.log('Clear all');
      personStore.onChangeFilter(column, null);
    } else {
      console.log('filter: ', column.name, ': ', val);
      personStore.onChangeFilter(column, val);
    }
  };

  return { items, handleChange };
}

const styles = {
  menuItem: {
    padding: '0 6px',
  },
  paddingLeft42: {
    padding: '6px 42px',
    // height: ITEM_HEIGHT,
  },
};

const SelectMenuProps: Partial<MenuProps> = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  variant: 'menu',
};
