import { observer } from 'mobx-react';
import { Navigate, useLocation, useRouteLoaderData } from 'react-router-dom';

import { CampQuery, MeQuery } from '../../generated/graphql';
import { isUserAuthForCamp } from '../../helpers/camps';

export const RequireAuth = observer(function RequireAuth({
  children,
}: {
  children: JSX.Element;
}) {
  const me = useRouteLoaderData('root') as MeQuery['me'];
  const camp = useRouteLoaderData('camp') as CampQuery['camp'];
  const location = useLocation();
  const splitPathname = location.pathname.split('/');
  console.log('RequireAuth', splitPathname);

  if (!me) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    console.log('User not logged in, redirecting to login');

    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (
    location.pathname.includes('dashboard/camps') &&
    splitPathname.length >= 5
  ) {
    // console.log('includes dashboard/camp', splitPathname);
    const currentPath = splitPathname[4];
    // console.log('curr', currentPath);
    const isAuth = isUserAuthForCamp(me, camp.camp_id, currentPath);
    console.log('User is authorized for path', currentPath, ':', isAuth);

    if (!isAuth) {
      return <Navigate to="/" />;
    }
  }

  return children;
});
