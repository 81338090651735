import React from 'react';

import { observer } from 'mobx-react';
import XCircle from 'react-feather/dist/icons/x-circle';
import { Container, Grid, IconButton, Paper, Typography } from '@mui/material';

import { styled } from '../../../styles/theme';

import { DropzoneComponent } from '../../../components/common/Dropzone';
import { ErrorLoading } from '../../../components/common/ErrorLoading';
import { SERVER_URL } from '../../../config';
import {
  useDeleteMediaMutation,
  useMediaLibraryQuery,
} from '../../../generated/graphql';
import { getThumbnailImageUrl } from '../../../utils/getThumbnailImageUrl';
import { useStores } from '../../../stores';

export const MediaPage = observer(function MediaPage() {
  const stores = useStores();
  const modalStore = stores.modalStore;
  const { data, error, loading, refetch } = useMediaLibraryQuery();
  const [deleteMedia] = useDeleteMediaMutation();

  return (
    <ErrorLoading {...{ error, loading }}>
      <Paper className="page">
        <Typography variant="h4">Ladda upp filer</Typography>
        <DropzoneComponent
          onAdded={() => {
            refetch();
          }}
        />
        <Typography variant="h4">Mediabibliotek</Typography>
        <StyledLibraryContainer>
          <Grid container spacing={2}>
            {data?.mediaLibrary.map((media, idx) => {
              const thumbUrl = getThumbnailImageUrl(media);

              return (
                <Grid key={media.id} item>
                  <Thumbnail>
                    <div className="tray">
                      <IconButton
                        onClick={async () => {
                          if (
                            !(await modalStore.confirm(
                              'Är du säker på att du vill ta bort denna fil?',
                            ))
                          ) {
                            return;
                          }
                          deleteMedia({
                            variables: {
                              id: media.id,
                            },
                          }).then((result) => {
                            refetch();
                          });
                        }}
                      >
                        <XCircle />
                      </IconButton>
                    </div>
                    <img
                      src={thumbUrl}
                      data-url={`${SERVER_URL}${media.url}`}
                      alt={media.name}
                      title={media.name}
                      onClick={openImage}
                      crossOrigin="anonymous"
                    />
                  </Thumbnail>
                </Grid>
              );
            })}
          </Grid>
        </StyledLibraryContainer>
      </Paper>
    </ErrorLoading>
  );
});

export const ImageLibrarySelect = observer(function ImageLibrarySelect({
  selected,
  onSelect,
}: {
  selected: number;
  onSelect: (imageId: number) => void;
}) {
  const { data, error, loading } = useMediaLibraryQuery();

  const pdfIcon = '/file-pdf-icon.svg';

  if (!data) {
    return <ErrorLoading error={error} loading={loading} />;
  }

  return (
    <StyledLibraryContainer className="image-library">
      <Grid container spacing={2}>
        {data.mediaLibrary.map((media, idx) => {
          let thumbUrl = `${SERVER_URL}${
            media.thumbnail ? media.thumbnail : null
          }`;
          if (!media.thumbnail && media.mimetype === 'application/pdf') {
            thumbUrl = pdfIcon;
          }

          return (
            <Grid key={media.id} item>
              <Thumbnail small>
                <img
                  className={selected === media.id ? 'selected' : ''}
                  src={thumbUrl}
                  alt={media.name}
                  title={media.name}
                  // data-id={media.id}
                  onClick={() => {
                    onSelect(media.id);
                  }}
                  crossOrigin="anonymous"
                />
              </Thumbnail>
            </Grid>
          );
        })}
      </Grid>
    </StyledLibraryContainer>
  );
});

function openImage(e: React.MouseEvent<HTMLImageElement, MouseEvent>) {
  const img = e.target as HTMLImageElement;
  const url = img.dataset.url;
  window.open(url, '_blank');
}

const StyledLibraryContainer = styled('div')`
  max-height: 500px;
  width: 100%;
  overflow-y: auto;
  margin-bottom: 10px;
  margin-top: 20px;
  overflow-x: hidden;
  /* padding: 8px 8px; */
  /* background: #eee; */
`;

const Thumbnail = styled('div')<{ small?: boolean }>`
  width: ${(p) => (p.small ? '60px' : '100px')};
  height: ${(p) => (p.small ? '60px' : '100px')};
  position: relative;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  /* .tray {
    height: 55px;
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    bottom: 0;
    width: 100%;
  } */
  button {
    position: absolute;
    top: 0px;
    right: 0px;
    svg {
      color: white;
      circle {
        fill: #ff5722;
      }
    }
  }
  /* svg {
    position: absolute;
    top: 5px;
    right: 5px;
    color: white;
    &:hover {
      color: #eee;
    }
  } */
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
    cursor: pointer;
    &.selected {
      border: 2px solid #111;
    }
  }
`;
