import {
  IdGetterObj,
  InMemoryCache,
  StoreObject,
  makeVar,
} from '@apollo/client/cache';
import {
  ApolloClient,
  ApolloLink,
  defaultDataIdFromObject,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { split, HttpLink } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { GraphQLError } from 'graphql';

import { GQL_SERVER_URL, WEBSOCKET_URL } from '../config';
import { handleGraphqlErrors, handleNetworkError } from '../helpers/apollo';
import { User } from '../generated/graphql';

import { MainStore } from './Store';

export const NETWORK_ERROR =
  'Server/Network error. There might be an issue with your internet connection. If the issue persists, please contact support!';
export const NETWORK_ERROR_SERVER =
  'Server/Network error. There might be an issue with our servers. If the issue persists, please contact support!';

export const allowedUnauthenticatedRoutes = [
  '/login',
  '/signup',
  '/verify',
  '/reset_password',
  '/alink',
  '/llink',
];

export const isAtUnauthenticatedRoute = function isAtUnauthenticatedRoute() {
  return allowedUnauthenticatedRoutes.find(
    (routeName) => window.location.pathname.indexOf(routeName) > -1,
  );
};

export const makeErrorLink = (store: MainStore) => {
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      handleGraphqlErrors(graphQLErrors as GraphQLError[], store);
    } else if (networkError) {
      handleNetworkError(networkError, store);
    }
  });

  return errorLink;
};

export const makeNewLink = (links?: ApolloLink[]) => {
  if (!links) return ApolloLink.from([splitLink]);

  return ApolloLink.from([...links, splitLink]);
};

export const meVar = makeVar<User | null>(null);
export const linkPasswordVar = makeVar('');

export const httpLink = new HttpLink({
  uri: GQL_SERVER_URL,
  credentials: 'include',
});

console.log('creating Websocket connection', WEBSOCKET_URL);

let timedOut: NodeJS.Timeout;
let socket: WebSocket;

const subscriptionClient = createClient({
  url: WEBSOCKET_URL,
  lazy: true,
  shouldRetry(errOrCloseEvent) {
    console.log('shouldRetry', errOrCloseEvent);

    return true;
  },
  keepAlive: 10_000, // ping server every 10 seconds
  on: {
    closed: (e) => {
      console.log(`WS onDisconnected`, e);
    },
    error: (e) => {
      console.log(`WS onError`, e);
    },
    connecting: () => {
      console.log(`WS connecting`);
    },
    message: (e) => {
      if (e.type === 'pong') return;
      console.log(`WS message`, e);
    },
    connected: (sock) => {
      console.log(`WS connected`, sock);
      socket = sock as WebSocket;
    },
    ping: (received) => {
      // console.log('ping');
      if (!received)
        // sent
        timedOut = setTimeout(() => {
          if (socket?.readyState === WebSocket.OPEN)
            socket.close(4408, 'Request Timeout');
        }, 5_000); // wait 5 seconds for the pong and then close the connection
    },
    pong: (received) => {
      // console.log('pong');
      if (received) clearTimeout(timedOut); // pong is received, clear connection close timeout
    },
  },
});

const wsLink = new GraphQLWsLink(subscriptionClient);

export const splitLink = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);

    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);

const link = makeNewLink();

const client = new ApolloClient({
  link,
  cache: new InMemoryCache({
    addTypename: true,
    typePolicies: {
      Camp: {
        keyFields: (object) => `Camp:${object['camp_id']}`,
      },
      Role: {
        keyFields: (object) => `Role:${object['role_id']}`,
      },
      CampCollection: {
        keyFields: (object) => getCampCollectionDataId(object),
      },
    },
  }),
});

export { client, subscriptionClient };

function getCampCollectionDataId(object: Readonly<StoreObject>) {
  if (!('collection' in object) || object['collection'] === null)
    return defaultDataIdFromObject(object);

  const collection = object['collection'] as string;

  if (!object.id) {
    return defaultDataIdFromObject(object);
  }

  const collectionName =
    collection.charAt(0).toUpperCase() + collection.slice(1);

  return `${collectionName}:${object.id}`;
}

export { defaultDataIdFromObject };
