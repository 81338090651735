import React from 'react';

import { observer } from 'mobx-react';

import { User } from '../../generated/graphql';
import { InputType } from '../../types/inputTypes';
import { TableColumn } from '../../types/tableColumn';

import { AutoTableModal } from './AutoTableModal';

interface CreateUserProps {
  existingItem?: User;
  onSubmit: (data) => void;
  onCancel: () => void;
  title: string;
}

const headers: TableColumn[] = [
  {
    title: 'Användarnamn',
    accessor: 'username',
    inputType: InputType.Text,
  },
  {
    title: 'Epost',
    accessor: 'email',
    inputType: InputType.Text,
  },
  {
    title: 'Förnamn',
    accessor: 'first_name',
    inputType: InputType.Text,
  },
  {
    title: 'Efternamn',
    accessor: 'last_name',
    inputType: InputType.Text,
  },
  {
    title: 'Telefon',
    accessor: 'phone',
    inputType: InputType.Text,
  },
  {
    title: 'Lösenord',
    accessor: 'password',
    inputType: InputType.Text,
  },
  {
    title: 'Lägerchef',
    accessor: 'siteAdmin',
    inputType: InputType.Boolean,
  },
];

export const UpdateUserModal = observer((props: CreateUserProps) => {
  return <AutoTableModal headers={headers} {...props} />;
});
