import { useEffect, useMemo } from 'react';

import { observer } from 'mobx-react';

import {
  CampCollections,
  useCampCollectionQuery,
} from '../../../generated/graphql';
import { useStores } from '../../../stores';

/** Updates the import store with items */
export const FetchCampCollection = observer(function FetchItemList({
  selectedCampId,
  collection,
}: {
  selectedCampId: number;
  collection: CampCollections;
}) {
  const stores = useStores();
  const importStore = stores.importStore;
  importStore.activeCollection = collection;

  const { data } = useCampCollectionQuery({
    variables: {
      camp_id: selectedCampId,
      collection,
    },
    skip: !selectedCampId,
  });

  const items = useMemo(() => {
    return data?.campCollection ?? [];
  }, [data]);

  useEffect(() => {
    importStore.setAllItems({ items });
  }, [importStore, items]);

  return null;
});
