import { useEffect } from 'react';

import { observer } from 'mobx-react';
import {
  Outlet,
  useLoaderData,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import { MeQuery } from '../generated/graphql';
import { useStores } from '../stores';

export const Root = observer(() => {
  const stores = useStores();
  const mainStore = stores.mainStore;
  const location = useLocation();
  const nav = useNavigate();
  const me = useLoaderData() as MeQuery['me'];

  useEffect(() => {
    if (me && mainStore.me?.id !== me.id) {
      console.log('setMe to', me);
      mainStore.setMe(me);
    }
  }, [mainStore, me]);

  // useEffect(() => {
  //   // console.log('location', location);
  //   console.log('store.state.dashboard', store.state.dashboard);
  //   console.log('store.state.login', store.state.login);

  //   if (store.state.login && !location.pathname.includes('login')) {
  //     console.log('nav to login');

  //     nav('/login');
  //     store.resetState();
  //   } else if (
  //     store.state.dashboard &&
  //     !location.pathname.includes('dashboard')
  //   ) {
  //     console.log('nav to dashboard');
  //     nav('/dashboard');
  //     store.resetState();
  //   }
  // }, [location.pathname, nav, store.state.dashboard, store.state.login]);

  useEffect(() => {
    if (location.pathname === '/') {
      console.log('Root redirecting', !!me);
      if (!me) {
        nav('/login', { state: { from: location }, replace: true });
      } else {
        nav('/dashboard');
      }
    }
  }, [me, location, nav]);

  // console.log('Root');

  return <Outlet />;
});
