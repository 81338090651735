import { observer } from 'mobx-react';
import { Breadcrumbs, Button } from '@mui/material';
import { NavigateNext, Menu } from '@mui/icons-material';
import { Form } from 'react-router-dom';

import { IconButton } from '../icons';
import { useBreadcrumbItems } from '../../hooks/layout';

import { StyledAppBar } from './styles';

export const TopNavigationBar = observer(function TopNavigationBar({
  handleDrawerToggle,
}: {
  handleDrawerToggle: () => void;
}) {
  const crumbs = useBreadcrumbItems();

  return (
    <StyledAppBar id="app_bar">
      <IconButton
        color="inherit"
        aria-label="Open drawer"
        onClick={handleDrawerToggle}
        className="menuButton"
        icon={<Menu />}
      ></IconButton>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNext fontSize="small" sx={{ ...crumbStyle }} />}
        sx={{
          ['.MuiLink-root']: {
            ...crumbStyle,
          },
        }}
      >
        {crumbs.map((crumb, index) => (
          <span key={index}>{crumb}</span>
        ))}
      </Breadcrumbs>
      <div style={{ flex: 1 }}></div>
      <Form method="post" action="/login">
        <Button
          variant="contained"
          size="medium"
          color="secondary"
          type="submit"
          name="intent"
          value="logout"
        >
          Logga ut
        </Button>
      </Form>
    </StyledAppBar>
  );
});

const crumbStyle = {
  color: 'white',
};
