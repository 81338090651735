import { action, makeObservable, observable } from 'mobx';

export class ModalStore {
  constructor() {
    makeObservable(this, {
      promptPassword: action,
      setShowingPromptDialog: action,
      showingAddItemModal: observable,
      addItem: action,
      closeAddItemModal: action,
      showingUpdateItemModal: observable,
      existingItem: observable,
      updateItem: action,
      closeUpdateItemModal: action,
      confirmResolve: observable,
      confirmQuestion: observable,
      showingConfirmDialog: observable,
      confirm: action,
      submitConfirm: action,
      promptResolve: observable,
      promptQuestion: observable,
      showingPromptDialog: observable,
      prompt: action,
      submitPrompt: action,
    });
  }

  showingAddItemModal = false;
  addItem = () => {
    this.showingAddItemModal = true;
  };
  closeAddItemModal = () => {
    this.showingAddItemModal = false;
  };

  showingUpdateItemModal = false;
  existingItem?: any;
  updateItem = (item: any) => {
    this.showingUpdateItemModal = true;
    this.existingItem = item;
  };
  closeUpdateItemModal = () => {
    this.showingUpdateItemModal = false;
  };

  // Confirm a yes/no question
  confirmResolve: (result: boolean) => void;
  confirmQuestion: string;
  showingConfirmDialog = false;
  confirm = (prompt: string) => {
    this.confirmQuestion = prompt;
    this.showingConfirmDialog = true;

    return new Promise<boolean>((resolve) => {
      this.confirmResolve = resolve;
    });
  };
  submitConfirm = (result: boolean) => {
    if (this.confirmResolve) {
      this.confirmResolve(result);
      this.confirmResolve = null;
    }
    this.showingConfirmDialog = false;
  };

  // Ask for a user input
  promptResolve: (result: string) => void;
  promptQuestion: { title: string };
  showingPromptDialog = false;
  setShowingPromptDialog = (bool: boolean) => {
    this.showingPromptDialog = bool;
  };
  prompt = async (prompt: string) => {
    // Using an object to avoid same reference
    this.promptQuestion = { title: prompt };

    this.showingPromptDialog = true;

    return new Promise<string>((resolve) => {
      this.promptResolve = resolve;
    });
  };
  submitPrompt = (result: string) => {
    if (this.promptResolve) {
      this.promptResolve(result);
      this.promptResolve = null;
    }
    this.showingPromptDialog = false;
  };
  promptPassword = async () => {
    return this.prompt('Skriv in lägrets lösenord');
  };
}
