import { useState } from 'react';

import { observer } from 'mobx-react';
import {
  Checkbox,
  FormControlLabel,
  ListItemText,
  MenuItem,
} from '@mui/material';
import { useRouteLoaderData } from 'react-router-dom';

import {
  CampChurchesDocument,
  useAddCampChurchesMutation,
  CampQuery,
} from '../../../generated/graphql';
import { CacheOperation, updateCache } from '../../../utils/updateCache';
import { FlexEnd, FlexRow } from '../../common/CommonDivs';
import { WhiteIconButton } from '../../icons/IconButton';
import { SelectCampDropdown, StyledImportList } from '../common';
import { ImportIcon } from '../../icons';
import { useStores } from '../../../stores';

import { FetchCampChurches } from '.';

/**
 * Note: ImportStore is used in all collections, however it is used in a special way on churches (and gdpr)
 *
 */
export const ImportChurches = observer(function ImportChurches() {
  const [selectedCampImportId, setSelectedCampImportId] = useState<number>(-1);

  return (
    <>
      <FlexRow>
        <SelectCampDropdown
          selected={selectedCampImportId}
          onChange={setSelectedCampImportId}
        />
        <SelectAllChurches />
      </FlexRow>
      <hr />
      <ChurchList />
      <FetchCampChurches selectedCampId={selectedCampImportId} />
    </>
  );
});

const SelectAllChurches = observer(function SelectAllChurches() {
  const stores = useStores();
  const importStore = stores.importStore;

  if (!importStore.allItems.length) {
    return null;
  }

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={importStore.isAllSelected}
          indeterminate={importStore.isSomeSelected}
          onChange={(event) => {
            const checked = event.target.checked;
            importStore.toggleSelectAllItems(checked);
          }}
        />
      }
      label={'Alla'}
      labelPlacement={'top'}
    />
  );
});

const ChurchList = observer(function ChurchList() {
  const stores = useStores();
  const importStore = stores.importStore;
  const handleAddChurches = useAddChurches();

  return (
    <StyledImportList>
      {importStore.allItems.map((church) => (
        <MenuItem
          key={church.id}
          value={church.id}
          onClick={() => {
            importStore.toggleItem(church);
          }}
        >
          <ListItemText primary={church.name} />
          <Checkbox
            checked={
              importStore.selectedItems.findIndex((c) => c.id === church.id) !==
              -1
            }
          />
        </MenuItem>
      ))}
      <FlexEnd>
        <WhiteIconButton
          title={'Lägg till'}
          icon={<ImportIcon />}
          onClick={handleAddChurches}
        />
      </FlexEnd>
    </StyledImportList>
  );
});

const useAddChurches = function useAddChurches() {
  const stores = useStores();
  const importStore = stores.importStore;
  const camp = useRouteLoaderData('camp') as CampQuery['camp'];
  const [addChurchMutation] = useAddCampChurchesMutation();

  const handleAddChurches = () => {
    const newChurches = importStore.newCampCollectionImportItems(camp.camp_id!);

    addChurchMutation({
      variables: { input: newChurches },
      update: (proxy: any, result: { data: { addCampChurches: any } }) => {
        updateCache({
          method: CacheOperation.CREATE,
          query: CampChurchesDocument,
          proxy,
          mutationResultObject: result.data.addCampChurches,
          variables: {
            camp_id: camp.camp_id,
          },
        });
      },
    }).then(() => {
      importStore.clearItems();
    });
  };

  return handleAddChurches;
};
