import React, { createContext, useContext } from 'react';

import {
  IReactionDisposer,
  action,
  computed,
  configure,
  makeObservable,
  observable,
} from 'mobx';
import { ApolloClient, FetchPolicy, gql } from '@apollo/client';
import { Subscription } from 'zen-observable-ts';
import { Client } from 'graphql-ws';

import {
  Heart,
  HeartCollection,
  HeartsSubscription,
  HeartsSubscriptionVariables,
  LogoutDocument,
  MeDocument,
  User,
} from '../generated/graphql';

import {
  ActiveCampStore,
  ImportCollectionStore,
  LinkStore,
  ModalStore,
  MovePersonStore,
  PersonStore,
} from '.';

configure({
  enforceActions: 'observed',
});

export class MainStore {
  wsListener: IReactionDisposer;
  activeWebsocket?: WebSocket;
  get activeWebsocketLink() {
    return !!this?.activeWebsocket;
  }

  client!: ApolloClient<any>;
  subscriptionClient: Client | null = null;

  previousPathBeforeLogin: string | null = null;
  me: Partial<User> | null = null;
  latestHeartedNewsPostCount: { id: number; count: number }[] = [];
  latestHeartedPushNotificationCount: {
    id: number;
    count: number;
  }[] = [];

  observer: Subscription | null = null;

  state: { login: true | null; dashboard: true | null } = {
    login: null,
    dashboard: null,
  };

  constructor() {
    if (this.me) {
      this.listenForPageFocus();
    }

    makeObservable(this, {
      resetState: action,
      activeWebsocket: observable,
      navigateTo: action,
      notificationProps: observable,
      displayNotification: action,
      removeNotification: action,
      activeWebsocketLink: computed,
      me: observable,
      setMe: action.bound,
      getMe: action.bound,
      resetStore: action.bound,
      logout: action.bound,
      wsListener: observable,
      client: observable,
      subscriptionClient: observable,
      previousPathBeforeLogin: observable,
      latestHeartedNewsPostCount: observable,
      latestHeartedPushNotificationCount: observable,
      observer: observable,
      state: observable,
      displayError: action,
      subscribe: action,
      refetchLoginStatus: action,
      listenForPageFocus: action,
    });
  }

  navigateTo(to: 'login' | 'dashboard') {
    if (to === 'login') {
      this.state.login = true;
      this.state.dashboard = null;
    } else if (to === 'dashboard') {
      this.state.dashboard = true;
      this.state.login = null;
    }
  }
  resetState() {
    this.state.login = null;
    this.state.dashboard = null;
  }

      // @ts-expect-error
  notificationProps: INotification | null = null;

  displayError(error: string) {
    this.displayNotification({
      message: error,
      options: {
        variant: 'error',
      },
    });
  }


    // @ts-expect-error
  displayNotification(notification: INotification) {
    this.notificationProps = {
      options: {
        variant: 'basic',
      },
      ...notification,
    };
  }

  removeNotification() {
    this.notificationProps = null;
  }

  resetStore = async () => {
    try {
      await this.subscriptionClient?.dispose();
      await this.client?.clearStore();
      this.setMe(null);
      this.navigateTo('login');
    } catch (e) {
      console.log('ERROR Resetting store', e);
    }
  };

  async subscribe() {
    if (this.observer) {
      this.observer.unsubscribe();
    }

    const query = gql`
      subscription ObserveHearts {
        hearts {
          id
          collection
          count
        }
      }
    `;

    this.observer = this.client
      .subscribe<HeartsSubscription, HeartsSubscriptionVariables>({ query })
      .subscribe((result) => {
        if (!result.data?.hearts) return null;

        const update: Heart = result.data.hearts;

        if (update.collection === HeartCollection.Newspost) {
          const index = this.latestHeartedNewsPostCount.findIndex(
            (h) => h.id === update.id,
          );

          if (index === -1) {
            this.latestHeartedNewsPostCount.push(update);
          } else {
            this.latestHeartedNewsPostCount[index].count = update.count;
          }

          return;
        }

        if (update.collection === HeartCollection.Pushnotification) {
          const index = this.latestHeartedPushNotificationCount.findIndex(
            (h) => h.id === update.id,
          );

          if (index === -1) {
            this.latestHeartedPushNotificationCount.push(update);
          } else {
            this.latestHeartedPushNotificationCount[index].count = update.count;
          }

          return;
        }
      });
  }

  async getMe(fetchPolicy: FetchPolicy = 'no-cache') {
    try {
      const result = await this.client.query({
        fetchPolicy,
        query: MeDocument,
      });
      this.setMe(result.data.me);
      // this.subscribe();

      return true;
    } catch (e) {
      this.navigateTo('login');

      return false;
    }
  }

  setMe(me: Partial<User> | null) {
    this.me = me;
  }

  refetchLoginStatus() {
    this.client
      .query({
        fetchPolicy: 'no-cache',
        query: MeDocument,
      })
      .catch();
  }
  listenForPageFocus() {
    setInterval(() => {
      if (document.visibilityState !== 'hidden') {
        console.log('Refetching login status (timer)');
        this.refetchLoginStatus();
      }
    }, 60 * 1000);
    window.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'hidden') {
      } else {
        console.log('Refetching login status due to visibilitychange');
        this.refetchLoginStatus();
      }
    });
  }

  logout = async () => {
    await this.client.mutate({
      mutation: LogoutDocument,
    });
    this.resetStore();
  };
}

    // @ts-expect-error
export const store: IStores = {
  activeCampStore: new ActiveCampStore(),
  importStore: new ImportCollectionStore(),
  linkStore: new LinkStore(),
  mainStore: new MainStore(),
  modalStore: new ModalStore(),
  movePersonStore: new MovePersonStore(),
  personStore: new PersonStore(),
};

export const StoreContext = createContext(store);

export const useStores = () => {
  return useContext(StoreContext);
};

