import { action, computed, makeObservable, observable } from 'mobx';

import { Camp, User } from '../generated/graphql';
import { campSorter } from '../utils/date';

export class ActiveCampStore {
  constructor() {
    makeObservable(this, {
      selectedCamp: observable,
      camps: observable,
      setAllCamps: action,
      activeCamps: computed,
      setActiveCamp: action,
    });
  }

  selectedCamp?: Camp;
  camps = observable.array<Camp>([]);
  setAllCamps = (camps?: Camp[]) => {
    if (camps) {
      this.camps.replace(camps);
    } else {
      this.camps.clear();
    }
  };

  get activeCamps() {
    return this.camps.slice().filter((camp) => camp.active === true);
  }

  setActiveCamp = (camp?: Camp) => {
    this.selectedCamp = camp;
  };
}
