import { observer } from 'mobx-react';
import { Paper } from '@mui/material';

import { TableHeaderButtonGroup } from '../../../../components/persons/header';
import { BorderedBox } from '../../../../components/common/CommonDivs';
import { PersonsTable } from '../../../../components/persons';

export const ChurchLinkPersonsPage = observer(function ChurchLinkPersonsPage() {
  return (
    <>
      <Paper>
        <TableHeaderButtonGroup />
        <BorderedBox>
          <PersonsTable />
        </BorderedBox>
      </Paper>
    </>
  );
});
