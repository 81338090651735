import { Person } from '../../generated/graphql';

export const getPersonDisplayName = function getPersonDisplayName(
  selectedPerson: Partial<Person>,
) {
  if (!selectedPerson) {
    return '';
  }

  return `${selectedPerson.details.first_name} ${selectedPerson.details.last_name}`;
};

export const ALL_ROLES = [
  { id: 1, slug: 'participant', name: 'Deltagare' },
  { id: 2, slug: 'helper', name: 'Hjälpledare', leader: true },
  { id: 3, slug: 'service', name: 'Serviceledare', leader: true },
  { id: 4, slug: 'parent', name: 'Medföljande' },
  { id: 5, slug: 'following_child', name: 'Medföljande' },
  { id: 6, slug: 'leader', name: 'Ledare', leader: true },
  { id: 7, slug: 'interesthelper', name: 'Intressegruppsledare', leader: true },
  { id: 8, slug: 'main_conductor', name: 'Huvudledare', leader: true },
  {
    id: 9,
    slug: 'specific_leader',
    name: 'Övergripande lägerledare',
    leader: true,
  },
];

export const LeaderSlugs = ALL_ROLES.reduce<string[]>((acc, curr) => {
  if (curr.leader) {
    acc.push(curr.slug);
  }

  return acc;
}, []);

export enum RolesEnum {
  participant = 'participant',
  helper = 'helper',
  service = 'service',
  parent = 'parent',
  following_child = 'following_child',
  leader = 'leader',
  interesthelper = 'interesthelper',
  main_conductor = 'main_conductor',
}

export const isPersonLeader = function isPersonLeader(selectedPerson: Person) {
  if (selectedPerson.role) {
    return LeaderSlugs.includes(selectedPerson.role.role_slug);
  }

  return false;
};
