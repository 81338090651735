import { InputType as GeneratedInputType } from '../generated/graphql';

enum LocalInputTypes {
  Text = 'text',
  Textarea = 'textarea',
  Boolean = 'boolean',
  Number = 'number',
  // button doesn't exist on remote
  Button = 'button',
  Values = 'values',
  Email = 'email',
  Phone = 'phone',
  // copy doesn't exist on remote
  Copy = 'copy',

  // date doesn't exist on remote
  // TODO: Not handled in the table component yet
  Date = 'date',
}

export const InputType = {
  ...LocalInputTypes,
  ...GeneratedInputType,
};
export type InputType = typeof InputType;
