import React from 'react';

import { observer } from 'mobx-react';

import { styled } from '../../styles/theme';

export const FlexSwitchColumn = styled('div')`
  display: flex;
  flex-direction: column;

  label:first-of-type {
    margin-left: 0;
  }
`;
export const FlexSwitchRow = styled('div')`
  display: flex;
  align-items: flex-start;
  > label:first-of-type {
    margin-left: 0;
  }
`;
export const SettingsContainer = styled('div')`
  .small {
    margin-left: 15px;
    font-size: 12px;
    color: #868686;
  }
`;
export const StatisticsDiv = styled('div')`
  padding: 20px;
  margin-top: 14px;
  display: flex;
  justify-content: space-between;

  text-align: center;
  font-size: 16px;
  font-weight: 700;

  .circle {
    font-size: 18px;
    color: white;
    background: ${(p) => p.theme.palette.primary.main};
    position: relative;
    border-radius: 25px;
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
    > span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;
