import { useMemo, useEffect } from 'react';

import { observer } from 'mobx-react';

import {
  useCampChurchesQuery,
  CampCollections,
} from '../../../generated/graphql';
import { useStores } from '../../../stores';

/** Updates the import store with items */
export const FetchCampChurches = observer(function FetchCampChurches({
  selectedCampId,
}: {
  selectedCampId: number;
}) {
  const stores = useStores();
  const importStore = stores.importStore;

  const { data } = useCampChurchesQuery({
    variables: {
      camp_id: selectedCampId,
    },
    onCompleted: (data) => {
      if (!data?.campChurches) return;

      importStore.setAllItems({
        items: data.campChurches,
      });
    },
    skip: selectedCampId === -1,
  });

  importStore.activeCollection = CampCollections.Churches;

  return null;
});
