import React, { useState, useEffect } from 'react';

export const useSyncState = function useSyncState<T>(
  props: T,
): [T, React.Dispatch<React.SetStateAction<T>>] {
  const [state, setState] = useState(props);

  useEffect(() => {
    setState(props);
  }, [props]);

  return [state, setState];
};
