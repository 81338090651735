import { useMemo } from 'react';

import { observer } from 'mobx-react';
import { Paper } from '@mui/material';
import { useRouteLoaderData } from 'react-router-dom';

import { SERVER_URL } from '../../../../../../config';
import {
  CampQuery,
  NewsPostInput,
  NewsPostsDocument,
  NewsPostUpdate,
  useCreateNewsPostMutation,
  useDeleteNewsPostMutation,
  useNewsPostsQuery,
  useUpdateNewsPostMutation,
} from '../../../../../../generated/graphql';
import {
  CacheOperation,
  updateCacheWithCampId,
} from '../../../../../../utils/updateCache';
import { CustomDialog } from '../../../../../../components/common/CustomDialog';
import { ErrorLoading } from '../../../../../../components/common/ErrorLoading';
import { CreateNewsPost } from '../../../../../../components/Modals/CreateNewsPost';
import {
  TableComponent,
  TableHeader,
} from '../../../../../../components/Table/Table';
import { TableColumn } from '../../../../../../types/tableColumn';
import { useStores } from '../../../../../../stores';
import { InputType } from '../../../../../../types/inputTypes';
import { sortByCreatedAtDescending } from '../../../../../../utils/date';

const headers: TableColumn[] = [
  {
    title: 'Bild',
    accessor: 'image',
    inputType: InputType.Button,
    displayer: ({ value }: { value: { url: string } }) =>
      value &&
      value.url && (
        <img src={`${SERVER_URL}${value.url}`} crossOrigin="anonymous" />
      ),
  },
  {
    title: 'Rubrik',
    accessor: 'title',
    inputType: InputType.Text,
  },
  {
    title: 'Meddelande',
    accessor: 'description',
    width: 300,
    inputType: InputType.Textarea,
  },
  {
    title: 'Knapptext',
    accessor: 'linkText',
    inputType: InputType.Text,
  },
  {
    title: 'Länk (URL)',
    accessor: 'link',
    inputType: InputType.Text,
  },
  {
    title: 'Likes',
    accessor: 'hearts',
    width: 80,
    displayer: ({ value, id }: { value: string; id: number }) => (
      <HeartDisplayer value={value} id={id} />
    ),
  }
];

export const AppNewsPage = observer(function AppNewsPage() {
  const stores = useStores();
  const modalStore = stores.modalStore;
  const camp = useRouteLoaderData('camp') as CampQuery['camp'];
  const camp_id = camp.camp_id;

  const { data, error, loading } = useNewsPostsQuery({
    variables: { camp_id },
  });
  const [deleteItem] = useDeleteNewsPostMutation();
  const [updateItem] = useUpdateNewsPostMutation();

  const rows = useMemo(() => {
    const arrayCopy = (data?.newsPosts ?? []).slice();

    return sortByCreatedAtDescending(arrayCopy);
  }, [data?.newsPosts]);
  console.log('rows', rows);
  if (!data) {
    return <ErrorLoading {...{ loading, error }} />;
  }

  return (
    <Paper>
      {!!rows && (
        <>
          <TableHeader
            title={'Nyheter'}
            onAddItem={() => {
              modalStore.addItem();
            }}
          />
          <TableComponent
            headers={headers}
            // @ts-ignore
            rows={rows}
            showEdit
            onEdit={(item) => {
              modalStore.updateItem(item);
            }}
            onUpdateRow={(id, accessor, newValue) => {
              return updateItem({
                variables: {
                  body: {
                    id: id as number,
                    [accessor]: newValue,
                  },
                },
              });
            }}
            onDelete={(row) => {
              //todo: delete row.
              // row.id
              deleteItem({
                variables: {
                  newsPostId: Number(row.id),
                },
                update: (proxy: any) =>
                  updateCacheWithCampId({
                    query: NewsPostsDocument,
                    proxy,
                    method: CacheOperation.DELETE,
                    camp_id: camp_id!,
                    id: row.id,
                  }),
              });
            }}
          />
        </>
      )}

      <AddItem camp_id={camp_id!} />
      <EditItem />
    </Paper>
  );
});

const AddItem = observer(({ camp_id }: { camp_id: number }) => {
  const stores = useStores();
  const modalStore = stores.modalStore;

  const [createItem] = useCreateNewsPostMutation();
  const close = modalStore.closeAddItemModal;

  return (
    <CustomDialog open={modalStore.showingAddItemModal} onClose={close}>
      <CreateNewsPost
        onCancel={close}
        onSubmit={(data) => {
          data.camp_id = camp_id;
          createItem({
            variables: {
              body: data as NewsPostInput,
            },
            update: (
              proxy: any,
              mutationResult: { data: { createNewsPost: any } },
            ) => {
              updateCacheWithCampId({
                query: NewsPostsDocument,
                proxy,
                method: CacheOperation.CREATE,
                camp_id,
                mutationResultObject: mutationResult.data.createNewsPost,
              });
            },
          });
          close();
        }}
      />
    </CustomDialog>
  );
});

const EditItem = observer(function EditItem() {
  const stores = useStores();
  const modalStore = stores.modalStore;
  const [updateItem] = useUpdateNewsPostMutation();

  const close = modalStore.closeUpdateItemModal;

  return (
    <CustomDialog open={modalStore.showingUpdateItemModal} onClose={close}>
      <CreateNewsPost
        existingItem={modalStore.existingItem}
        onCancel={close}
        onSubmit={(data) => {
          data.id = modalStore.existingItem.id;
          updateItem({
            variables: {
              body: data as NewsPostUpdate,
            },
          });
          close();
        }}
      />
    </CustomDialog>
  );
});

const HeartDisplayer = observer(function HeartDisplayer({
  value,
  id,
}: {
  value: string;
  id: number;
}) {
  const stores = useStores();
  const mainStore = stores.mainStore;

  const latestValue = mainStore.latestHeartedNewsPostCount.find(
    (i) => i.id === id,
  );

  return <>{latestValue ? latestValue.count : value}</>;
});
