import React, { useState, useEffect } from 'react';

import { observer } from 'mobx-react';
import {
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Button,
} from '@mui/material';

import { styled } from '../../styles/theme';
import { useStores } from '../../stores';

export const ConfirmDialog = observer(() => {
  const stores = useStores();
  const modalStore = stores.modalStore;

  return (
    <>
      <CustomConfirmDialog
        prompt={modalStore.confirmQuestion}
        open={modalStore.showingConfirmDialog}
        onSubmit={() => modalStore.submitConfirm(true)}
        onCancel={() => modalStore.submitConfirm(false)}
      />
      <PromptDialog />
    </>
  );
});

export const CustomConfirmDialog = observer(
  function CustomConfirmDialog(props) {
    return (
      <Dialog
        open={props.open}
        maxWidth="xs"
        fullWidth
        // disableBackdropClick
        onClose={props.onCancel}
        aria-labelledby="status-confirmation"
      >
        <DialogTitle id="status-confirmation">{props.prompt}</DialogTitle>
        <DialogActions>
          <Button
            onClick={props.onCancel}
            color="secondary"
            type="button"
            id="cancel"
          >
            Avbryt
          </Button>
          <Button
            onClick={props.onSubmit}
            color="primary"
            type="submit"
            id="submit"
          >
            Ja
          </Button>
        </DialogActions>
      </Dialog>
    );
  },
);

const PromptDialog = observer(function PromptDialog() {
  const stores = useStores();
  const modalStore = stores.modalStore;
  const open = modalStore.showingPromptDialog;
  const [text, setText] = useState('');

  const handleCancel = () => {
    modalStore.submitPrompt('');
  };

  const handleSubmit = () => {
    modalStore.submitPrompt(text);
  };
  const promptQuestion = modalStore.promptQuestion;

  useEffect(() => {
    setText('');
  }, [promptQuestion]);

  return (
    <Dialog
      open={open}
      maxWidth="xs"
      fullWidth
      // disableBackdropClick
      onClose={handleCancel}
      aria-labelledby="status-confirmation"
    >
      <DialogTitle id="status-confirmation">
        {promptQuestion && promptQuestion.title}
      </DialogTitle>
      <DialogContent>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();

            return false;
          }}
        >
          <TextField
            style={{
              width: '100%',
            }}
            autoFocus
            type="password"
            name="camp_password"
            id="camp_password"
            autoComplete="camp_password"
            onChange={(e) => {
              setText(e.currentTarget.value);
            }}
            value={text}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="secondary" type="button">
          Avbryt
        </Button>
        <Button onClick={handleSubmit} color="primary" type="submit">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
});
