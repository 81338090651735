import React, { useCallback, useEffect, useState } from 'react';

import { observer } from 'mobx-react';
import { useRouteLoaderData } from 'react-router-dom';

import { CampQuery, useUpdateCampMutation } from '../../../generated/graphql';
import { FlexColumn, DimmedText } from '../../common/CommonDivs';
import { LabelSwitch } from '../../switch';
import { FlexSwitchRow, SettingsContainer } from '../styles';
import { formatDateString } from '../../../utils/date';

export const CampSettingsDashboard = observer(function CampSettingsDashboard() {
  const camp = useRouteLoaderData('camp') as CampQuery['camp'];

  const [active, setActive] = useState(false);
  const [admissionOpen, setAdmissionOpen] = useState(false);
  const [update] = useUpdateCampMutation();

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value == 'true';
      const name = e.target.name;

      if (name === 'active') setActive(value);
      if (name === 'signup') setAdmissionOpen(value);

      update({
        variables: {
          body: {
            camp_id: camp.camp_id,
            [name]: value,
          },
        },
      });
    },
    [camp.camp_id, update],
  );

  useEffect(() => {
    if (camp) {
      setActive(camp.active);
      setAdmissionOpen(camp.signup);
    }
  }, [camp]);

  if (!camp) return null;

  const startDate = formatDateString(camp.start_date, 'yyyy-MM-dd');
  const endDate = formatDateString(camp.end_date, 'yyyy-MM-dd');
  const lastSignupDate = formatDateString(camp.last_signup_date, 'yyyy-MM-dd');

  return (
    <SettingsContainer>
      <h3>{camp.name}</h3>
      <DimmedText>{`${startDate} – ${endDate}`}</DimmedText>
      <hr />
      <FlexSwitchRow>
        <LabelSwitch
          name="active"
          value={active}
          onChange={onChange}
          label={'Aktivt'}
          labelPlacement="start"
        />
        <FlexColumn>
          <LabelSwitch
            name="signup"
            value={admissionOpen}
            onChange={onChange}
            label={'Anmälan öppen'}
            labelPlacement="start"
          />
          <span className="small">
            {`Sista anmälan`} <br /> {`${lastSignupDate}`}
          </span>
        </FlexColumn>
      </FlexSwitchRow>
    </SettingsContainer>
  );
});
