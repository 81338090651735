//returns Object: {[form_item_name]: value}
interface IObject {
  [id: string]: string | number | boolean;
}

export const convertFormData = function convertFormData<T extends IObject>(
  target: HTMLFormElement,
): T {
  const formData = new FormData(target);
  const object: Record<string, any> = {};

  formData.forEach(function (value, key) {
    if (value === 'true') {
      value = 1 as any;
    } else if (value === 'false') {
      value = 0 as any;
    }
    object[key] = value;
  });

  return object as T;
};

export const cancelSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
  e.preventDefault();
  e.stopPropagation();

  return false;
};

export const convertFormDataToArray = function convertFormDataToArray(
  target: HTMLFormElement,
): { id: string; value: string }[] {
  const formData = new FormData(target);
  const outputLog = [];
  const iterator = formData.entries();
  let end = false;

  while (end == false) {
    const item = iterator.next();

    if (item.value != undefined) {
      outputLog.push({
        id: item.value[0],
        value: item.value[1],
      });
    } else if (item.done == true) {
      end = true;
    }
  }

  return outputLog;
};
