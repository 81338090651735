import React, { memo } from 'react';

import { observer } from 'mobx-react';
import { Grid, MenuItem, Select } from '@mui/material';
import { useRouteLoaderData } from 'react-router-dom';

import { useMobile } from '../../../styles/theme';
import {
  AppCampChurchFragment,
  CampChurch,
  CampQuery,
  useCampChurchesQuery,
} from '../../../generated/graphql';
import { StyledChurchBox } from '../../item-card';
import { StyledItemListContainer } from '../common';

export const ChurchList = observer(function ChurchList({
  selectedChurch,
  setChurch,
}: {
  selectedChurch: AppCampChurchFragment;
  setChurch: (item: CampChurch) => void;
}) {
  const camp = useRouteLoaderData('camp') as CampQuery['camp'];
  const camp_id = camp.camp_id;
  const { data } = useCampChurchesQuery({
    variables: {
      camp_id,
    },
  });

  const isMobile = useMobile();

  if (!data) {
    return null;
  }

  const churches = data.campChurches;

  if (isMobile) {
    return (
      <>
        <Select
          value={selectedChurch ? selectedChurch.id : -1}
          onChange={(e) => {
            const item = churches.find((c) => c.id === Number(e.target.value));
            setChurch(item);
          }}
        >
          <MenuItem disabled value={-1}>
            - Välj församling -
          </MenuItem>
          {churches.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </>
    );
  }

  return (
    <StyledItemListContainer className="no_scrollbar ">
      <Grid container wrap={'wrap'} spacing={3}>
        {churches.map((item) => {
          const isSelected = selectedChurch && selectedChurch.id === item.id;

          return (
            <Grid key={item.id} item xs={12} sm={4} md={4}>
              <StyledChurchBox
                active={isSelected}
                onClick={() => {
                  if (!isSelected) {
                    setChurch(item);
                  }
                }}
              >
                {item.name}
              </StyledChurchBox>
            </Grid>
          );
        })}
      </Grid>
    </StyledItemListContainer>
  );
});
