import { styled } from '../../../styles/theme';
import { mediaMobile } from '../../../styles/theme';

export const StyledModalHeader = styled('header')`
  padding: 10px;
  background-color: ${(p) => p.theme.palette.primary.main};
  color: white;
  /* position: sticky;
  top: 0;
  z-index: 1; */
  position: relative;

  h2 {
    font-size: 22px;
    margin: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    ${mediaMobile} {
      position: initial;
      font-size: 12px;
      transform: none;
    }
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
`;
