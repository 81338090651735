import React, { useMemo } from 'react';

import { observer } from 'mobx-react';
import clsx from 'clsx';
import { GridCellProps } from 'react-virtualized';
import { ModeEditOutline } from '@mui/icons-material';
import { Tooltip } from '@mui/material';

import { CloseIcon, IconButton, StyledEditIcon } from '../icons';
import { getPersonDisplayName } from '../../helpers/persons/getPersonDetails';
import { ExtendedCampColumn } from '../../types/extendedCampColumn';
import { isDeleteColumn } from '../../helpers/camps';
import { ExtendedPerson } from '../../types/person';
import { getPersonValueFromColumn } from '../../helpers/persons';
import { useStores } from '../../stores';

import { StyledTableCell } from './VirtualizedTableStyles';

const usePersonData = function usePersonData(
  columnIndex: number,
  rowIndex: number,
): {
  person?: ExtendedPerson;
  cellValue: string;
  column?: ExtendedCampColumn;
  handleClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
} {
  const stores = useStores();
  const store = stores.personStore;
  const columns = store.activeColumns;
  const persons = store.sortedPersons;

  const column = columns[columnIndex];

  if (!column) {
    return { person: undefined, cellValue: '' };
  }
  const person = persons.length ? persons[rowIndex - 1] : undefined;
  let cellValue = '';
  if (person) {
    const { stringValue } = getPersonValueFromColumn(person, column);
    cellValue = stringValue;
  }

  return { person, cellValue, column };
};

export const TableCell = observer(function TableCell(props: GridCellProps) {
  const { columnIndex, rowIndex, style } = props;
  const isEvenRow = rowIndex % 2 === 0;
  const stores = useStores();
  const { linkStore, personStore } = stores;

  const { person, cellValue, column } = usePersonData(columnIndex, rowIndex);

  if (!person) {
    return (
      <StyledTableCell
        style={style}
        className={clsx('cell', 'empty', `col-${columnIndex}`, {
          even: isEvenRow,
        })}
      />
    );
  }

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (
      column?.writePermission &&
      column.writePermission >= linkStore.permissionLevel
    ) {
      if (isFirstCell && personStore.editingEnabled) {
        personStore.toggleEditModal(person);
      } else {
        personStore.startEditingPerson(person, event.currentTarget, column);
      }
    }
  };

  // const isHovered = person.isHoveredColumn;
  const isFirstCell = columnIndex === 0;
  const isDeleteCell = isDeleteColumn(column);
  const isDisabled =
    !personStore.editingEnabled ||
    column.writePermission < linkStore.permissionLevel;

  return (
    <StyledTableCell
      style={style}
      onMouseEnter={handleMouseEnter(person, columnIndex)}
      onMouseLeave={handleMouseLeave(person, columnIndex)}
      onClick={handleClick}
      title={cellValue.length > 40 ? cellValue : null}
      className={clsx(
        'cell',
        `col-${columnIndex}`,
        getCellBackgroundClassName(person),
        `person_id_${person.id}`,
        {
          disabled: isDisabled,
          even: isEvenRow,
          deleteButtonCell: isDeleteCell,
          secureField: column.secureField && !!Number(cellValue),
        },
      )}
    >
      {isFirstCell && personStore.editingEnabled && (
        <StyledEditIcon>
          <ModeEditOutline />
        </StyledEditIcon>
      )}
      {isDeleteCell && <DeleteCell person={person} disabled={isDisabled} />}
      {cellValue}
    </StyledTableCell>
  );
});

const DeleteCell = observer(function DeleteCell({
  disabled,
  person,
}: {
  disabled: boolean;
  person: ExtendedPerson;
}) {
  const stores = useStores();
  const { linkStore, modalStore, personStore } = stores;
  const isShowingDeleted = personStore.showingDeleted;
  const perm = linkStore.permissionLevel;

  const tooltipText = useMemo(() => {
    if (perm === 30) {
      return 'Endast huvudledare kan avanmäla';
    }
    if (perm === 20 && disabled) {
      return 'Lägerchefen har låst denna funktion';
    }

    return '';
  }, [disabled, perm]);

  return (
    <Tooltip title={tooltipText}>
      <span>
        <IconButton
          disabled={disabled}
          title={
            isShowingDeleted
              ? `Återanmäl ${person.details.first_name}`
              : `Avanmäl ${person.details.first_name}`
          }
          icon={<CloseIcon />}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            modalStore
              .confirm(
                `Är du säker på att du vill ${
                  personStore.showingDeleted ? 'återanmäla' : 'avanmäla'
                } ${getPersonDisplayName(person)}?`,
              )
              .then((result) => {
                if (result) {
                  personStore.toggleRemovePerson(person);
                }
              });
          }}
        ></IconButton>
      </span>
    </Tooltip>
  );
});

// Doesnt handle case when cell rerenders
const handleMouseLeave = function handleMouseLeave(
  person: ExtendedPerson,
  columnIndex: number,
) {
  return () => {
    document
      .querySelectorAll(
        `.cell.person_id_${person.id}`,
        // `.cell.person_id_${person.id}, .cell.col-${columnIndex}`,
      )
      .forEach((c) => c.classList.remove('hover'));
  };
};

const handleMouseEnter = function handleMouseEnter(
  person: ExtendedPerson,
  columnIndex: number,
) {
  return (e) => {
    document
      .querySelectorAll(`.cell.person_id_${person.id}`)
      .forEach((c) => c.classList.add('hover'));
  };
};

const getCellBackgroundClassName = function getCellBackgroundClassName(
  person: ExtendedPerson,
) {
  let className = '';
  const { leader_approved, is_at_camp, deleted } = person.details;

  if (Number(leader_approved) && Number(is_at_camp)) {
    className = 'row_approved';
  } else if (Number(leader_approved) || Number(is_at_camp)) {
    className = 'row_in_progress';
  }

  if (Number(deleted)) {
    className = 'row_deleted';
  }

  return className;
};
