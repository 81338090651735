import { SyntheticEvent, useEffect, useMemo, useRef } from 'react';

import { observer } from 'mobx-react';
import { Alert, Button, IconButton, Snackbar, Typography } from '@mui/material';
import { tss } from 'tss-react/mui';

import { styled } from '../../styles/theme';
import { useLatestValue } from '../../hooks/useLatestValue';
import { CloseIcon } from '../icons';
import { useStores } from '../../stores';



const autoCloseDuration = 10 * 1000;

    // @ts-expect-error
const defaults: INotification = {
  message: '',
  options: {
    variant: 'error',
    buttonTitle: '',
    timeout: autoCloseDuration,
  },
};

export const Notifications = observer(function Notifications() {
  // const [tempNotification, setTemp] = useState(null);
  const stores = useStores();
  const mainStore = stores.mainStore;
  const { classes, cx } = useStyles();

  const currentProps = mainStore.notificationProps;
  const showingNotification = !!currentProps;
  const props = useLatestValue(currentProps);

  const notificationProps = useMemo(
    () => ({
      ...defaults,
      ...props,
    }),
    [props],
  );

  const { variant, buttonTitle, handlePrimaryAction, timeout } =
    notificationProps.options;
  const isError = variant === 'error';
  const isSuccess = variant === 'success';

  function handleClose(
    event: Event | SyntheticEvent<any, Event>,
    reason?: string,
  ) {
    if (reason === 'clickaway') {
      return;
    }
    mainStore.removeNotification();
  }
  const lastMessageRef = useRef<string>('');

  useEffect(() => {
    if (notificationProps.message) {
      lastMessageRef.current = notificationProps.message;
    }
  }, [notificationProps]);
  const message = notificationProps.message || lastMessageRef.current;

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={showingNotification}
      autoHideDuration={timeout === -1 ? null : timeout}
      onClose={handleClose}
      ContentProps={{
        'aria-describedby': 'message-id',
      }}
    >
      <Alert
        severity={isError ? 'error' : isSuccess ? 'success' : 'info'}
        onClose={handleClose}
        variant="filled"
        // iconMapping={{
        //   error: <ErrorIcon className={cx(classes.iconVariant)} />,
        // }}
        sx={{ width: '100%', alignItems: 'center' }}
        action={
          <>
            {handlePrimaryAction && (
              <WhiteButton size="small" onClick={handlePrimaryAction}>
                {buttonTitle}
              </WhiteButton>
            )}
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      >
        <Typography id="message-id">{message}</Typography>
      </Alert>
    </Snackbar>
  );
});

const WhiteButton = styled(Button)`
  color: white;
`;

const useStyles = tss.withName('Snackbar').create((p) => ({
  iconVariant: {
    opacity: 0.9,
    marginRight: 8,
  },
}));
