import React from 'react';

import { observer } from 'mobx-react';
import { Button, Grid, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { FlexColumnAlignCenter } from '../../../../../components/common/CommonDivs';
import {
  BellIcon,
  CalendarIcon,
  ContactIcon,
  TVIcon,
} from '../../../../../components/icons';
import { mediaMobile } from '../../../../../styles/theme';

import { AppSchemaPage } from './schema/AppSchemaPage';
import { AppNewsPage } from './news/AppNewsPage';
import { AppNotificationsPage } from './notifications/AppNotificationsPage';
import { AppContactPage } from './contact/AppContactPage';

const AppRouteList = [
  {
    name: 'Schema',
    path: 'schema',
    icon: <CalendarIcon />,
    Component: AppSchemaPage,
  },
  {
    name: 'Nyheter',
    path: 'news',
    icon: <TVIcon />,
    Component: AppNewsPage,
  },
  {
    name: 'Notiser',
    path: 'notifications',
    icon: <BellIcon />,
    Component: AppNotificationsPage,
  },
  {
    name: 'Kontaktinfo',
    path: 'contact',
    icon: <ContactIcon />,
    Component: AppContactPage,
  },
];

export const AppPage = observer(function AppMenu() {
  const nav = useNavigate();

  return (
    <Grid container spacing={2}>
      {AppRouteList.map((item) => (
        <Grid item key={item.path}>
          <StyledDashboardItem
            onClick={() => {
              nav(item.path);
            }}
          >
            <FlexColumnAlignCenter style={styles.fullHeight}>
              <span>{item.icon}</span>
              <h3>{item.name}</h3>
            </FlexColumnAlignCenter>
          </StyledDashboardItem>
        </Grid>
      ))}
    </Grid>
  );
});

const styles = {
  fullHeight: {
    height: '100%',
  },
};

export const StyledDashboardItem = styled(Button)<{ focuscolor?: string }>`
  background: white;
  border-radius: 0;
  border: 1px solid ${(p) => p.theme.palette.primary.main};
  width: 320px;

  height: 240px;

  svg {
    font-size: 30px;
  }
  h3 {
    margin: 4px 0;
    font-weight: 100;
  }
  font-size: 30px;
  text-transform: initial;

  transition-property: background, color;
  color: ${(p) => p.theme.palette.primary.main};

  &:hover,
  &:focus {
    background: ${(p) => p.theme.palette.primary.main};
    color: white;
  }

  ${mediaMobile} {
    width: 280px;
  }
`;
