import { styled } from '../../../styles/theme';

export const StyledSettingsBox = styled('div')`
  height: 720px;
  background: white;
  border-radius: 0;
  border: 1px solid ${(p) => p.theme.palette.primary.main};

  h4 {
    color: #212121;
    margin: 0 0 4px 0;
  }
  .image-library {
    max-height: 160px;
    margin: 0;
  }
`;
