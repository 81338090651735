import React from 'react';

import { observer } from 'mobx-react';
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@mui/material';
import { action, observable } from 'mobx';
import { DeleteOutline, EditOutlined } from '@mui/icons-material';
import { tss } from 'tss-react/mui';

import { styled } from '../../styles/theme';
import { BluePlusIcon, Spacer } from '../common/CommonDivs';
import { useStores } from '../../stores';
import { InputType } from '../../types/inputTypes';
import { Row, TableColumn } from '../../types/tableColumn';

import { EditPostButton, InvisibleButton, TableTextInput } from './TableCells';

/*
headers: [
  {
    title: 'Name',
    accessor: 'name'
  }
]
rows: [
  {
    name: "simon",
    description: "13213",
  }
]
*/

export interface TableComponentProps<T> {
  headers: TableColumn[];
  rows: T[];

  EditCellRenderer?: (row: T) => JSX.Element;

  // onRowUpdate?: (row: T) => void;

  onUpdateRow: (
    id: string | number,
    accessor: string,
    newValue: string | number,
  ) => Promise<any>;
  onDelete?: (row: T) => void;
  onEdit: (row: T) => void;
  showEdit?: boolean;
}

const activeIndex = observable({
  rowIdx: -1,
  colIdx: -1,
});

const setActiveIndex = action((a: { rowIdx: number; colIdx: number }) => {
  activeIndex.colIdx = a.colIdx;
  activeIndex.rowIdx = a.rowIdx;
});

export const TableComponent = observer(function TableComponent({
  headers,
  rows,
  onUpdateRow,
  onDelete,
  onEdit,
  EditCellRenderer,
  showEdit = false,
}: TableComponentProps<Row>) {
  const stores = useStores();
  const modalStore = stores.modalStore;
  // const [activeIndex, setActiveIndex] = useState({
  //   rowIdx: -1,
  //   colIdx: -1,
  // });
  const { classes, cx } = useTableStyles();

  return (
    <>
      <TableContainer>
        <Table className={cx(classes.table)}>
          <TableHead>
            <HeaderRow headers={headers} />
          </TableHead>
          <TableBody>
            {rows.map((row, rowIdx) => (
              <TableRow key={rowIdx}>
                <TableCell align="left" className={cx(classes.firstCell)}>
                  {EditCellRenderer ? (
                    <EditCellRenderer {...row} />
                  ) : (
                    <>
                      <IconButton
                        onClick={async () => {
                          if (
                            await modalStore.confirm(
                              'Är du säker på att du vill ta bort detta inlägg?',
                            )
                          ) {
                            onDelete && onDelete(row);
                          }
                        }}
                      >
                        <DeleteOutline color="error" />
                      </IconButton>
                      {showEdit && (
                        <IconButton
                          onClick={() => {
                            onEdit(row);
                          }}
                        >
                          <EditOutlined className={cx(classes.deleteIcon)} />
                        </IconButton>
                      )}
                    </>
                  )}
                </TableCell>
                {headers.map((header, colIdx) => {
                  const stringValue = row[header.accessor];
                  // console.log('stringValue', stringValue);

                  let node: React.ReactNode = stringValue;
                  if (header.displayer) {
                    node = header.displayer({
                      id: Number(row.id),
                      value: stringValue,
                    });
                  }

                  if (header.CellRenderer) {
                    return (
                      <header.CellRenderer
                        row={row}
                      />
                    );
                  }

                  const handleSelect = () => {
                    setActiveIndex({ colIdx, rowIdx });
                  };

                  const isDisabled = header.isDisabled
                    ? header.isDisabled(row)
                    : false;

                  return (
                    <CellRender
                      row={row}
                      key={header.accessor}
                      rowIndex={rowIdx}
                      colIndex={colIdx}
                      header={header}
                      value={node}
                      isDisabled={isDisabled}
                      onUpdate={(newValue: any) => {
                        onUpdateRow(row.id, header.accessor, newValue).finally(
                          () => {
                            setActiveIndex({ rowIdx: -1, colIdx: -1 });
                          },
                        );
                      }}
                      onEdit={() => onEdit(row)}
                      onSelect={handleSelect}
                    />
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
});

export const TableContainer = styled('div')`
  /* width: 100%; */
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  width: calc(100vw - 288px);

  @media screen and (max-width: 599px) {
    width: calc(100vw - 48px);
  }
`;

const useTableStyles = tss.withName('TableStyles').create(() => ({
  deleteIcon: { fill: '#3f51b5' },
  table: {
    minWidth: 650,
    width: '100%',
    // Table layout fixed caused weird bugs. But stops columns from resizing.
    //The header decides the column height.
    tableLayout: 'fixed',
    borderCollapse: 'collapse',
  },
  cell: {
    width: '200px',
    // minWidth: '200px',
    // maxWidth: '300px',
  },
  firstCell: {
    width: '90px',
  },
}));

const HeaderRow = observer(function HeaderRow({
  headers,
}: {
  headers: TableColumn[];
}) {
  const { classes, cx } = useTableStyles();

  return (
    <TableRow>
      <TableCell className={cx(classes.firstCell)}></TableCell>
      {headers.map((header) => (
        <TableCell
          key={header.accessor}
          className={cx(classes.cell)}
          style={{ width: header.width || 200 }}
        >
          {header.title}
        </TableCell>
      ))}
    </TableRow>
  );
});

interface CellRenderProps {
  row: Row;
  rowIndex: number;
  colIndex: number;
  header: TableColumn;
  value: React.ReactNode;
  isDisabled: boolean;
  onSelect: () => void;
  onUpdate: (newValue: string | number) => void;
  onEdit: () => void;
}

const CellRender = observer(function CellRender({
  rowIndex,
  colIndex,
  header,
  value,
  isDisabled,
  onSelect,
  onUpdate,
  onEdit,
}: CellRenderProps) {
  const activeCell =
    activeIndex.colIdx === colIndex && activeIndex.rowIdx === rowIndex;

  if (activeCell) {
    if (header.inputType === InputType.Copy) {
      return (
        <StyledCell key={header.accessor}>
          <TableTextInput value={value as string} />
        </StyledCell>
      );
    }

    return (
      <StyledCell key={header.accessor}>
        <TableTextInput
          multiline={header.inputType === InputType.Textarea}
          rows={header.inputType === InputType.Textarea ? 8 : 1}
          onKeyDown={(e) => {
            if (
              e.key === 'Escape' ||
              (e.key === 'Enter' && header.inputType === InputType.Text)
            ) {
              (e.target as HTMLInputElement).blur();
            }
          }}
          onBlur={(e) => {
            // return;
            //change value.
            const newTextVal = e.target.value;
            const newValue =
              header.inputType == InputType.Number
                ? parseInt(newTextVal)
                : newTextVal;

            if (value === newValue) {
              setActiveIndex({ rowIdx: -1, colIdx: -1 });

              return;
            }
            onUpdate(newValue);
            //id = row.id
          }}
          type="text"
          autoFocus
          defaultValue={value as string}
        />
      </StyledCell>
    );
  }

  let innerNode: React.ReactNode = value;

  switch (header.inputType) {
    case InputType.Number:
    case InputType.Text:
    case InputType.Copy:
    case InputType.Date:
    case InputType.Textarea: {
      let str: string = value ? String(value) : '';
      str = str.length > 300 ? str.substring(0, 300) + '...' : str;

      innerNode = (
        <InvisibleButton
          disabled={isDisabled}
          onClick={onSelect}
          color="inherit"
          sx={{ justifyContent: 'flex-start' }}
        >
          {str}
        </InvisibleButton>
      );
      break;
    }
    case InputType.Values: {
      let values: {
        id: string;
        name: string;
      }[] = [];

      if (typeof header.values === 'string') {
        values = (header.values as string)
          .split('\n')
          .map((s) => ({ id: s, name: s }));
        values.unshift({ id: '', name: '' });
      } else if (Array.isArray(header.values)) {
        values = header.values.map((item) => ({
          id: String(item.id),
          name: item.name,
        }));
      }

      innerNode = (
        <FormControl fullWidth>
          <InputLabel id={header.accessor} shrink>
            {header.title}
          </InputLabel>
          {values?.length && (
            <Select
              labelId={header.accessor}
              label={header.title}
              value={value || ''}
              onChange={(e, val) => {
                onUpdate(val as string);
              }}
              notched
              inputProps={{
                name: header.accessor,
                id: header.accessor,
              }}
              renderValue={(val) => {
                const item = values.find((i) => i.id == val);

                return item?.name || '';
              }}
              displayEmpty
            >
              {/* <option value="" /> */}
              {values.map((v) => (
                <MenuItem key={v.id} value={v.id}>
                  {v.name}
                </MenuItem>
              ))}
            </Select>
          )}
        </FormControl>
      );
      break;
    }
    case InputType.Boolean: {
      innerNode = (
        <Switch
          disabled={isDisabled}
          checked={value as boolean}
          onChange={(event) => {
            onUpdate(String(event.target.checked));
          }}
        />
      );
      break;
    }
    case InputType.Button: {
      if (value) {
        innerNode = (
          <InvisibleButton
            disabled={isDisabled}
            onClick={onEdit}
            color="inherit"
            sx={{ justifyContent: 'flex-start' }}
          >
            {value}
          </InvisibleButton>
        );
      } else {
        innerNode = (
          <EditPostButton
            disabled={isDisabled}
            onClick={onEdit}
            color="primary"
            type="button"
            variant="outlined"
            sx={{ justifyContent: 'flex-start' }}
          >
            {header.buttonTitle || 'Redigera'}
          </EditPostButton>
        );
      }
      break;
    }
  }

  return (
    <StyledCell style={{ width: header.width ? header.width : 200 }}>
      {innerNode}
    </StyledCell>
  );
});

export const TableHeader = observer(function TableHeader({
  title,
  onAddItem,
}: {
  title: string;
  onAddItem?: (e: any) => void;
}) {
  return (
    <Toolbar>
      <Typography variant="h6" id="tableTitle">
        {title}
      </Typography>
      <Spacer />
      {onAddItem && (
        <IconButton onClick={onAddItem}>
          <BluePlusIcon />
        </IconButton>
      )}
    </Toolbar>
  );
});

const StyledCell = styled(TableCell, { name: 'StyledCell' })(({ theme }) => ({
  maxWidth: 400,
  padding: 10,
}));
