import React from 'react';

import { observer } from 'mobx-react';
import { ButtonProps, styled } from '@mui/material';
import { Button, Tooltip } from '@mui/material';

import { AppCampRoleFragment } from '../../generated/graphql';
import { ErrorIcon } from '../icons';

interface ItemCardProps extends ButtonProps {
  title: string;
  errorMessage?: string;
  success?: boolean;
}

export const ItemCard = observer(function ItemCard({
  title,
  errorMessage,
  success,
  ...props
}: ItemCardProps) {
  return (
    <Tooltip title={errorMessage} placement="bottom">
      <span>
        <StyledCardButton
          {...props}
          error={!!errorMessage}
          success={success}
          endIcon={errorMessage?.length ? <ErrorIcon /> : undefined}
        >
          {title}
        </StyledCardButton>
      </span>
    </Tooltip>
  );
});

type StyledCardButtonProps = {
  active?: boolean;
  error?: boolean;
  success?: boolean;
};

const StyledCardButton = styled(Button)<StyledCardButtonProps>`
  width: 100%;
  height: 50px;
  background: #f4f4f4;
  border: 1px solid
    ${(p) =>
      p.success
        ? p.theme.palette.success.main
        : p.error
          ? p.theme.palette.primary.main
          : '#F4F4F4'};
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  font-size: 1rem;
  margin-bottom: 16px;

  position: relative;
  svg {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate(0, -50%);
    width: 18px;
    height: 18px;
    color: ${(p) =>
      p.success
        ? p.theme.palette.success.main
        : p.error
          ? p.theme.palette.error.main
          : 'inherit'};
  }
`;

export const SingleRoleBox = observer(
  ({
    role,
    onClick,
  }: {
    role: AppCampRoleFragment;
    onClick: (role: AppCampRoleFragment) => void;
  }) => {
    return (
      <StyledRoleBox
        onClick={() => {
          onClick(role);
        }}
        success={role.enabled}
        error={!role.enabled}
      >
        {role.name}
      </StyledRoleBox>
    );
  },
);
export const StyledRoleBox = styled(StyledCardButton)`
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
  }
`;
export const StyledChurchBox = styled(Button)<StyledCardButtonProps>`
  color: ${(p) => (p.active ? 'white' : '#111')};
  background-color: ${(p) =>
    p.active ? p.theme.palette.primary.main : '#F4F4F4'};
  font-weight: ${(p) => (p.active ? 700 : 400)};

  transition: all 0.3s ease-in-out;
  transition-duration: color, background-color;

  text-transform: capitalize;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
  }

  ${(p) =>
    p.active
      ? `
  &:hover {
    background-color: ${p.theme.palette.primary.main};
  }
  `
      : ''}

  width: 100%;
  height: 50px;
`;
