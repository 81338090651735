import React, { memo, useState, useEffect, useMemo } from 'react';

import { observer } from 'mobx-react';
import {
  Checkbox,
  FormControlLabel,
  ListItemText,
  MenuItem,
} from '@mui/material';
import { useRouteLoaderData } from 'react-router-dom';

import { FlexEnd, FlexRow } from '../../common/CommonDivs';
import { WhiteIconButton } from '../../icons/IconButton';
import { SelectCampDropdown, StyledImportList } from '../common';
import {
  useChurchLinkInfoBoxesQuery,
  useAddChurchLinkInfoBoxMutation,
  ChurchLinkInfoBoxesDocument,
  CampQuery,
} from '../../../generated/graphql';
import { updateCache } from '../../../utils/updateCache';
import { ImportIcon } from '../../icons';
import { useStores } from '../../../stores';

import { FetchChurchLinkInfoBoxes } from './FetchChurchLinkInfoBoxes';

export const ImportInfobox = observer(function ImportInfobox() {
  const [selectedCampImportId, setSelectedCampImportId] = useState<number>(-1);
  const stores = useStores();
  const importStore = stores.importStore;
  const camp = useRouteLoaderData('camp') as CampQuery['camp'];
  const [addItem] = useAddChurchLinkInfoBoxMutation();

  const handleAddItems = async () => {
    const promises = importStore.selectedItems.map((item) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { camp_id: _camp_id, id: _id, __typename, ...rest } = item.meta;

      return addItem({
        variables: {
          camp_id: camp.camp_id,
          body: rest,
        },
        update: (
          proxy: any,
          result: { data: { addChurchLinkInfoBox: any } },
        ) => {
          updateCache({
            query: ChurchLinkInfoBoxesDocument,
            proxy,
            mutationResultObject: result.data.addChurchLinkInfoBox,
            variables: {
              camp_id: camp.camp_id,
            },
          });
        },
      });
    });
    await Promise.all(promises).catch();
    importStore.clearItems();
  };

  return (
    <>
      <FlexRow>
        <SelectCampDropdown
          selected={selectedCampImportId}
          onChange={setSelectedCampImportId}
        />
        <SelectAllItemCheckbox />
      </FlexRow>
      <hr />
      <ItemList handleAddItems={handleAddItems} />
      <FetchChurchLinkInfoBoxes selectedCampId={selectedCampImportId} />
    </>
  );
});

const SelectAllItemCheckbox = observer(function SelectAllItemCheckbox() {
  const stores = useStores();
  const importStore = stores.importStore;

  if (!importStore.allItems.length) {
    return null;
  }

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={importStore.isAllSelected}
          indeterminate={importStore.isSomeSelected}
          onChange={(event) => {
            const checked = event.target.checked;
            importStore.toggleSelectAllItems(checked);
          }}
        />
      }
      label={'Alla'}
      labelPlacement={'top'}
    />
  );
});

const ItemList = observer(function ItemList({
  handleAddItems,
}: {
  handleAddItems: () => void;
}) {
  const stores = useStores();
  const importStore = stores.importStore;

  return (
    <StyledImportList>
      {importStore.allItems.map((item) => (
        <MenuItem
          key={item.id}
          value={item.id}
          onClick={() => {
            importStore.toggleItem(item);
          }}
        >
          <ListItemText primary={item.name} />
          <Checkbox
            checked={
              importStore.selectedItems.findIndex((c) => c.id === item.id) !==
              -1
            }
          />
        </MenuItem>
      ))}
      <FlexEnd>
        <WhiteIconButton
          title={'Lägg till'}
          icon={<ImportIcon />}
          onClick={handleAddItems}
        />
      </FlexEnd>
    </StyledImportList>
  );
});
