import React, { useState } from 'react';

import { observer } from 'mobx-react';
import {
  Checkbox,
  FormControlLabel,
  ListItemText,
  MenuItem,
} from '@mui/material';
import { runInAction } from 'mobx';

import { CampCollections } from '../../../generated/graphql';
import { FlexEnd, FlexRow } from '../../common/CommonDivs';
import { WhiteIconButton } from '../../icons/IconButton';
import { ImportIcon } from '../../icons';
import { useStores } from '../../../stores';

import { FetchCampCollection, SelectCampDropdown, StyledImportList } from '.';

export const ImportFromCamp = observer(function ImportFromCamp({
  collection,
}: {
  collection: CampCollections;
}) {
  const [selectedCampImportId, setSelectedCampImportId] = useState<number>(-1);

  return (
    <>
      <FlexRow>
        <SelectCampDropdown
          selected={selectedCampImportId}
          onChange={setSelectedCampImportId}
        />
        <SelectAllItemCheckbox />
      </FlexRow>
      <hr />
      <ItemList />
      <FetchCampCollection
        selectedCampId={selectedCampImportId}
        collection={collection}
      />
    </>
  );
});

const SelectAllItemCheckbox = observer(function SelectAllItemCheckbox() {
  const stores = useStores();
  const importStore = stores.importStore;

  if (!importStore.allItems.length) {
    return null;
  }

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={importStore.isAllSelected}
          indeterminate={importStore.isSomeSelected}
          onChange={(event) => {
            const checked = event.target.checked;
            importStore.toggleSelectAllItems(checked);
          }}
        />
      }
      label={'Alla'}
      labelPlacement={'top'}
    />
  );
});

const ItemList = observer(function ItemList() {
  const stores = useStores();
  const { activeCampStore, importStore } = stores;

  return (
    <StyledImportList>
      {importStore.allItems.map((item) => (
        <MenuItem
          key={item.id}
          value={item.id}
          onClick={() => {
            importStore.toggleItem(item);
          }}
        >
          <ListItemText primary={item.name} />
          <Checkbox
            checked={
              importStore.selectedItems.findIndex((c) => c.id === item.id) !==
              -1
            }
          />
        </MenuItem>
      ))}
      <FlexEnd>
        <WhiteIconButton
          title={'Lägg till'}
          icon={<ImportIcon />}
          onClick={() => {
            runInAction(() => {
              const selectedCamp = activeCampStore.selectedCamp;

              if (!selectedCamp?.camp_id || selectedCamp?.camp_id === -1) {
                throw new Error('CampId needs to be set.');
              }

              const newItems = importStore.newCampCollectionImportItems(
                selectedCamp.camp_id,
              );

              importStore.addItems(newItems, selectedCamp);
            });
          }}
        />
      </FlexEnd>
    </StyledImportList>
  );
});
