import React, { useCallback, useEffect } from 'react';

import { observer } from 'mobx-react';
import {
  DialogActions,
  Checkbox,
  FormControlLabel,
  Button,
} from '@mui/material';

import { CustomDialog } from '../common/CustomDialog';
import { PaddedView } from '../common/CommonDivs';
import { getPersonDisplayName } from '../../helpers/persons/getPersonDetails';
import { useStores } from '../../stores';

let hasOpenedOnce = false;

export const PanicModal = observer(function PanicModal() {
  const stores = useStores();
  const linkStore = stores.linkStore;

  const camp = linkStore.camp;
  const isOpen = hasOpenedOnce ? false : linkStore.displayingPanicModeModal;

  useEffect(() => {
    if (isOpen) {
      hasOpenedOnce = true;
    }
  }, [isOpen]);

  const handleClose = useCallback(() => {
    linkStore.togglePanicModal();
  }, [linkStore]);

  if (isOpen) {
    hasOpenedOnce = true;
  }
  if (!camp) {
    return null;
  }

  return (
    <CustomDialog open={isOpen} onClose={handleClose} maxWidth={'sm'}>
      <PaddedView>
        <h2>Kritiskt läge aktiverat</h2>
        <p>{camp.panicModeText}</p>
        <PersonList />
      </PaddedView>
      <DialogActions>
        {/* <Button color="secondary" type="button" onClick={handleCancel}>
          {'Avbryt'}
        </Button> */}
        <Button color="primary" type="submit" onClick={handleClose}>
          {'Klar'}
        </Button>
      </DialogActions>
    </CustomDialog>
  );
});

const PersonList = observer(function PersonList() {
  const stores = useStores();
  const personStore = stores.personStore;

  return (
    <>
      {personStore.persons.map((person, idx) => (
        <div key={person.id}>
          <FormControlLabel
            control={
              <Checkbox
                checked={Boolean(person.details.panicModeIsHere)}
                onChange={(event) => {
                  const checked = event.target.checked;

                  personStore.updatePerson(person, {
                    panicModeIsHere: checked,
                  });
                }}
              />
            }
            label={getPersonDisplayName(person)}
          />
        </div>
      ))}
    </>
  );
});
