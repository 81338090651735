import React, { memo, useEffect, useMemo, useState } from 'react';

import { observer } from 'mobx-react';
import {
  Checkbox,
  FormControl,
  IconButton as MUIIconButton,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Switch,
  TextField,
  SelectChangeEvent,
  Typography,
  OutlinedInput,
  InputAdornment,
  Divider,
  Box,
} from '@mui/material';
import { Refresh } from '@mui/icons-material';
import { useRouteLoaderData } from 'react-router-dom';

import { getHeadLeaderLink, getLeaderLink } from '../../../config';
import {
  AppCampChurchFragment,
  CampChurch,
  CampQuery,
  Person,
  useCampChurchesQuery,
  useCampMainConductorsQuery,
  useMergeChurchWithGroupCollectionMutation,
  useSendHeadleaderEmailMutation,
} from '../../../generated/graphql';
import { getPersonDisplayName } from '../../../helpers/persons/getPersonDetails';
import { copyToClipboard } from '../../../utils/clipboard';
import {
  FlexBetween,
  FlexColumn,
  FlexRowAlignCenter,
} from '../../common/CommonDivs';
import { DeleteIcon, SavedIconText, SendIcon } from '../../icons';
import { WhiteIconButton } from '../../icons/IconButton';
import { LabelSwitch } from '../../switch';
import { useChurchForm } from '../../../hooks/camps';
import { useStores } from '../../../stores';

export const ChurchForm = observer(function ChurchForm({
  selectedChurch,
  setChurch,
}: {
  selectedChurch: AppCampChurchFragment;
  setChurch: (church: CampChurch) => void;
}) {
  const stores = useStores();
  const mainStore = stores.mainStore;

  const {
    state,
    hasSaved,
    deleteChurch,
    onKeyDown,
    handleChange,
    handleSwitchChange,
    handleBlur,
    regenerateHeadLeaderLink,
    regenerateLeaderLink,
  } = useChurchForm({ selectedChurch, setChurch });

  const copyLinkToClipboard: React.FocusEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    if (process.env.NODE_ENV !== 'development') {
      return;
    }
    const copiedString = copyToClipboard(e.currentTarget as HTMLInputElement);

    if (!copiedString) {
      return;
    }
    mainStore.displayNotification({
      message: 'Länk kopierad!',
      options: {
        variant: 'success',
        timeout: 2000,
      },
    });
  };

  if (!state) {
    return <div>Ingen vald församling</div>;
  }

  const {
    name,
    tshirtVariant,
    admin_link,
    admin_link_enabled,
    leader_link,
    leader_link_enabled,
    group_collection_id,
    deleted,
  } = state;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
      <TextField
        margin="normal"
        required={true}
        fullWidth
        id={'name'}
        name={'name'}
        label={'Församlingsnamn'}
        value={name}
        className="maxWidthTextField"
        onChange={handleChange}
        onKeyDown={onKeyDown}
        onBlur={handleBlur}
      />
      <FormControl variant="outlined">
        <InputLabel htmlFor="head-leader-link" shrink>
          Huvudledarlänk
        </InputLabel>
        <OutlinedInput
          id="head-leader-link"
          label="Huvudledarlänk"
          fullWidth
          notched
          className="maxWidthTextField"
          value={getHeadLeaderLink(admin_link!)}
          contentEditable={false}
          onFocus={copyLinkToClipboard}
          startAdornment={
            <InputAdornment position="start">
              <MUIIconButton
                aria-label="Regenerera huvudledarlänk"
                title="Regenerera huvudledarlänk"
                onClick={regenerateHeadLeaderLink}
                edge="end"
              >
                <Refresh />
              </MUIIconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      <FlexRowAlignCenter>
        <Switch
          checked={admin_link_enabled!}
          name="admin_link_enabled"
          onChange={handleSwitchChange}
        />
        <SendToChurchLeaderDropdown
          selectedChurch={selectedChurch}
          onChange={handleSwitchChange}
        />
      </FlexRowAlignCenter>
      <Divider sx={{ margin: '10px 0' }} />
      <FormControl variant="outlined">
        <InputLabel htmlFor="leader-link" shrink>
          Ledarlänk
        </InputLabel>
        <OutlinedInput
          id="leader-link"
          label="Ledarlänk"
          fullWidth
          notched
          className="maxWidthTextField"
          value={getLeaderLink(leader_link!)}
          contentEditable={false}
          onFocus={copyLinkToClipboard}
          startAdornment={
            <InputAdornment position="start">
              <MUIIconButton
                aria-label="Regenerera länk"
                title="Regenerera länk"
                onClick={regenerateLeaderLink}
                edge="end"
              >
                <Refresh />
              </MUIIconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      <FlexRowAlignCenter>
        <Switch
          checked={leader_link_enabled!}
          name="leader_link_enabled"
          onChange={handleSwitchChange}
        />
      </FlexRowAlignCenter>
      <LabelSwitch
        label={'Är aktiv i Anmälningsformuläret'}
        // note this is saved as deleted in the database
        // check useUpdateCollection:58
        name={'enabled'}
        value={!deleted}
        onChange={(e) => {
          // We patch the enabled prop, because in the database, we have stored a deleted flag intead;
          // TODO: migrate the deleted flag to enabled = !deleted
          handleSwitchChange({
            ...e,
            target: {
              // type: null,
              ...e.target,
              name: 'deleted',
              value: String(!e.target.checked),
            },
          });
        }}
      />
      <FlexBetween>
        <SavedIconText visible={hasSaved} />
        {/* <span>{loading ? 'Laddar...' : saved ? 'Sparat!' : ''}</span> */}
        <WhiteIconButton
          icon={<DeleteIcon />}
          title={'Radera Församling'}
          onClick={deleteChurch}
        />
      </FlexBetween>
    </Box>
  );
});

export const StudyGroupSection = observer(function StudyGroupSection() {
  return (
    <>
      <FlexRowAlignCenter>
        {/* <ChurchDropDown selectedChurch={selectedChurch} /> */}
      </FlexRowAlignCenter>
      <hr />
    </>
  );
});

export const SendToChurchLeaderDropdown = observer(
  function SendToChurchLeaderDropdown({
    selectedChurch,
    onChange,
  }: {
    selectedChurch: AppCampChurchFragment;
    onChange: (event: SelectChangeEvent<number>) => void;
  }) {
    const stores = useStores();
    const mainStore = stores.mainStore;
    const camp = useRouteLoaderData('camp') as CampQuery['camp'];
    const camp_id = camp.camp_id;

    const { data } = useCampMainConductorsQuery({
      variables: {
        camp_id,
      },
    });

    const [sendEmail, { loading }] = useSendHeadleaderEmailMutation();
    const [selected, setSelected] = useState<number>(-1);

    useEffect(() => {
      if (selectedChurch && selectedChurch.mainConductorId) {
        setSelected(selectedChurch.mainConductorId);
      } else {
        setSelected(-1);
      }
    }, [selectedChurch]);

    const sendEmailToHeadLeader = () => {
      console.log(
        `Sending headleader email to admin: ${selectedChurch.admin_link} -> selected person: ${selected}`,
      );
      sendEmail({
        variables: {
          id: selectedChurch.id,
        },
      }).then(() => {
        mainStore.displayNotification({
          message: 'Länken skickad!',
          options: {
            variant: 'success',
            timeout: 4000,
          },
        });
      });
    };

    const handleSelected: (event: SelectChangeEvent<number>) => void = (e) => {
      const value = e.target.value;

      onChange({
        ...e,
        target: {
          ...e.target,
          name: 'mainConductorId',
          value: Number(value),
        },
      } as SelectChangeEvent<number>);
      setSelected(Number(value));
    };

    if (!data) {
      return null;
    }
    const mainConductors = data.campMainConductors || [];
    const selectedPerson = mainConductors.find(
      (p: { id: number }) => p.id === selected,
    );

    return (
      <>
        <FormControl style={{ flex: 1 }}>
          {/* <InputLabel id="send_to_leader">Skicka till:</InputLabel> */}
          <Select
            labelId="send_to_leader"
            value={selected}
            displayEmpty
            onChange={handleSelected}
            renderValue={(selected) => {
              if (selected === -1) {
                return (
                  <Typography variant="subtitle1" color="GrayText">
                    {'Skicka till:'}
                  </Typography>
                );
              }

              return getPersonDisplayName(selectedPerson);
            }}
            MenuProps={MenuProps}
          >
            <MenuItem disabled value={-1}>
              <ListItemText primary={'Skicka till:'} />
            </MenuItem>
            {mainConductors.map((person: Partial<Person>) => (
              <MenuItem key={person.id} value={person.id}>
                <ListItemText primary={getPersonDisplayName(person)} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <MUIIconButton
          color="primary"
          onClick={sendEmailToHeadLeader}
          disabled={loading || selected == -1}
        >
          <SendIcon />
        </MUIIconButton>
      </>
    );
  },
);

export const ChurchDropDown = observer(function ChurchDropDown({
  selectedChurch,
}: {
  selectedChurch: AppCampChurchFragment;
}) {
  const camp = useRouteLoaderData('camp') as CampQuery['camp'];
  const camp_id = camp.camp_id;

  const { data } = useCampChurchesQuery({
    variables: {
      camp_id,
    },
  });

  const [selectedChurches, setSelectedChurches] = useState<number>(-1);

  useEffect(() => {
    setSelectedChurches(-1);
  }, [selectedChurch]);

  const [merge] = useMergeChurchWithGroupCollectionMutation();

  const handleChange: (
    event: SelectChangeEvent<number>,
    child: React.ReactNode,
  ) => void = (e) => {
    const values = e.target.value as number;
    setSelectedChurches(values);

    //TODO: fetch current shared churches

    // const church2 = values[1];

    // merge({
    //   variables: {
    //     id: church2,
    //     groupCollectionId: currentChurch.group_collection_id,
    //   },
    // });
  };

  const churches: AppCampChurchFragment[] = useMemo(() => {
    return selectedChurch
      ? data.campChurches.filter(
          (c: { id: number }) => c.id !== selectedChurch.id,
        )
      : data.campChurches;
  }, [data.campChurches, selectedChurch]);

  if (!data) {
    return null;
  }

  return (
    <FormControl style={{ flex: 1 }}>
      <InputLabel id="shared_with_churches">Delas med:</InputLabel>
      <Select
        labelId="shared_with_churches"
        // multiple
        value={selectedChurches}
        onChange={handleChange}
        input={<Input />}
        // renderValue={(selected: number[]) => {
        renderValue={(selected: number) => {
          if (selected !== null && selected !== -1) {
            const exisitng = churches.find(
              (c: { id: number }) => selected === c.id,
            );

            return exisitng ? exisitng.name : '';
          }

          return '';

          // if (selected.length) {
          //   return selected.length > 1
          //     ? 'Flera...'
          //     : churches.find(c => selected[0] === c.id).name;
          // }
          // return '';
        }}
        MenuProps={MenuProps}
      >
        <MenuItem disabled value={-1}>
          <ListItemText primary={'Delas med:'} />
        </MenuItem>
        {churches.map((church) => (
          <MenuItem key={church.id} value={church.id}>
            <ListItemText primary={church.name} />
            <Checkbox checked={selectedChurches === church.id} />
            {/* <Checkbox checked={selectedChurches.indexOf(church.id) > -1} /> */}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
