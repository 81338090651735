import { useState } from 'react';

import { observer } from 'mobx-react';
import { useRouteLoaderData } from 'react-router-dom';
import { ModalProps } from '@mui/material';

import {
  CampCollections,
  CampQuery,
  useCampChurchesQuery,
} from '../../generated/graphql';
import { ItemCard } from '../item-card';
import { useGdprColumns } from '../../hooks/camps';

import { CampChurchModal } from './churches';
import { RoleModal } from './roles/RoleModal';
import { LeaderSettingsModal } from './leader-settings';
import { CampLivingsModal } from './livings';
import { CampInterestsModal } from './interests';
import { CampTshirtsModal } from './tshirts';
import { GDPRModalModal } from './gdpr-modal';
import { InfoBoxModal } from './infoboxes';

const collectionItems = [
  {
    path: 'churches',
    collectionNameSingular: 'församling',
    collectionNameDefinitive: 'församlingen',
    collectionNamePlural: 'församlingar',
    routeTitle: 'församlingar',
    modal: (props: Omit<ModalProps, 'children'>) => (
      <CampChurchModal {...props} />
    ),
  },
  {
    path: 'roles',
    collectionNameSingular: 'roll',
    collectionNameDefinitive: 'rollen',
    collectionNamePlural: 'roller',
    routeTitle: 'roller',
    modal: (props: Omit<ModalProps, 'children'>) => <RoleModal {...props} />,
  },
  {
    path: 'leader_settings',
    collectionNameSingular: 'Ledarinställning',
    collectionNameDefinitive: 'Ledarinställningen',
    collectionNamePlural: 'Ledarinställningar',
    routeTitle: 'Ledarinställningar',
    modal: (props: Omit<ModalProps, 'children'>) => (
      <LeaderSettingsModal {...props} />
    ),
  },
  {
    campCollection: CampCollections.Livings,
    path: 'livings',
    collectionNameSingular: 'boende',
    collectionNameDefinitive: 'boendet',
    collectionNamePlural: 'boenden',
    routeTitle: 'boenden',
    modal: (props: Omit<ModalProps, 'children'>) => (
      <CampLivingsModal {...props} />
    ),
  },

  {
    campCollection: CampCollections.Interests,
    path: 'interests',
    collectionNameSingular: 'intressegrupp',
    collectionNameDefinitive: 'intressegruppen',
    collectionNamePlural: 'intressegrupper',
    routeTitle: 'intressegrupper',
    modal: (props: Omit<ModalProps, 'children'>) => (
      <CampInterestsModal {...props} />
    ),
  },
  {
    campCollection: CampCollections.Tshirts,
    path: 'tshirts',
    collectionNameSingular: 'T-shirtstorlek',
    collectionNameDefinitive: 'T-shirtstorleken',
    collectionNamePlural: 'T-shirtstorlekar',
    routeTitle: 'T-shirtstorlek',
    modal: (props: Omit<ModalProps, 'children'>) => (
      <CampTshirtsModal {...props} />
    ),
  },
  {
    path: 'infoboxes',
    routeTitle: 'Extra info boxar',
    modal: (props: Omit<ModalProps, 'children'>) => <InfoBoxModal {...props} />,
  },
  {
    path: 'gdpr_settings',
    collectionNameSingular: 'GDPR',
    collectionNameDefinitive: 'GDPR',
    collectionNamePlural: 'GDPR',
    routeTitle: 'GDPR',
    modal: (props: Omit<ModalProps, 'children'>) => (
      <GDPRModalModal {...props} />
    ),
  },
];

export const CampCollectionItems = observer(function CampCollectionItems() {
  const [currentModal, setCurrentModal] = useState<
    (typeof collectionItems)[number]['path'] | null
  >(null);
  const camp = useRouteLoaderData('camp') as CampQuery['camp'];
  const camp_id = camp.camp_id;

  const { data, loading } = useCampChurchesQuery({
    variables: {
      camp_id,
    },
  });

  const errors = {} as Record<CampCollections | 'gdpr_settings', string>;
  const sucesses: Record<string, boolean> = {};

  if (!loading) {
    if (!data?.campChurches?.length) {
      errors.churches = 'Det finns inga församlingar inlagda på detta läger.';
    }
  }
  if (!camp.livings_active) {
    errors.livings = 'Boende är inte aktiverat i anmälningsformuläret';
  }
  if (!camp.interests_active) {
    errors.interests =
      'Intressegrupper är inte aktiverat i anmälningsformuläret';
  }
  if (!camp.tshirts_active) {
    errors.tshirts = 'T-shirt är inte aktiverat i anmälningsformuläret';
  }

  const gdprColumns = useGdprColumns();
  const emptyColumns = gdprColumns.filter(
    (c: any) => c.gdprDeleteAfter === null,
  );

  if (emptyColumns.length) {
    errors.gdpr_settings =
      'Minst ett fält är inte ifyllt korrekt. Vänligen fyll i utgångsdatum för alla kolumner.';
  } else {
    sucesses.gdpr_settings = true;
  }

  return (
    <>
      {collectionItems.map((c) => (
        <ItemCard
          key={c.path}
          errorMessage={errors[c.path]}
          // success={sucesses[c.path]}
          title={c.routeTitle}
          onClick={() => {
            setCurrentModal(c.path);
          }}
        />
      ))}
      {collectionItems.map((c) => (
        <c.modal
          key={c.path}
          open={currentModal === c.path}
          onClose={() => {
            setCurrentModal(null);
          }}
        />
      ))}
    </>
  );
});
