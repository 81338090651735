import React from 'react';

import { observer } from 'mobx-react';
import {
  FormControl,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';

import { campSorter } from '../../../utils/date';
import { useCampsQueryWithAuth } from '../../../hooks/camps';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const DropDownMenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 5.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const SELECT_TITLE = 'Välj Läger:';

/**
 * SelectCampDropdown
 * Renders a dropdown where the user can select a camp
 */
export const SelectCampDropdown = observer(function SelectCampDropdown({
  selected,
  onChange,
}: {
  selected: number;
  onChange: (id: number) => void;
}) {
  const { data } = useCampsQueryWithAuth();

  if (!data) {
    return null;
  }
  const camps = [...data.camps].sort(campSorter);

  const handleSelectChange: (
    event: SelectChangeEvent<number>,
    child: React.ReactNode,
  ) => void = (e) => {
    onChange(Number(e.target.value));
  };

  const renderSelectedCamp = (campId: number) => {
    if (campId !== null && campId !== -1) {
      return camps.find((c: { camp_id: number }) => campId === c.camp_id).name;
    }

    return '';
  };

  return (
    <FormControl style={{ flex: 1 }}>
      <InputLabel id="campSelect">{SELECT_TITLE}</InputLabel>
      <Select
        labelId="campSelect"
        value={selected}
        onChange={handleSelectChange}
        input={<Input />}
        renderValue={renderSelectedCamp}
        MenuProps={DropDownMenuProps}
      >
        <MenuItem disabled value={-1}>
          <ListItemText primary={SELECT_TITLE} />
        </MenuItem>
        {camps.map((camp) => (
          <MenuItem key={camp.camp_id} value={camp.camp_id}>
            <ListItemText primary={camp.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
});
