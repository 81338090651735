import React, { useState, useRef, useEffect } from 'react';

import { observer } from 'mobx-react';
import {
  IconButton as MUIIconButton,
  StandardTextFieldProps,
  TextField,
} from '@mui/material';

import { styled } from '../../styles/theme';
import { Visibility, VisibilityOff } from '../icons';

const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

type ViewPasswordFieldProps = StandardTextFieldProps;

export const ViewPasswordField = observer(function ViewPasswordField(
  props: ViewPasswordFieldProps,
) {
  const [hidePassword, setHidePassword] = useState(true);

  return (
    <FlexRowAlignCenter>
      <TextField
        {...props}
        type={!isFirefox ? 'text' : hidePassword ? 'password' : 'text'}
        className={hidePassword ? 'hidePassword' : ''}
      />

      <MUIIconButton
        onClick={() => {
          setHidePassword((s) => !s);
        }}
      >
        {hidePassword ? <Visibility /> : <VisibilityOff />}
      </MUIIconButton>
    </FlexRowAlignCenter>
  );
});

const FlexRowAlignCenter = styled('div')`
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  > div {
    width: 100%;
  }
  .hidePassword input {
    text-security: disc;
    -webkit-text-security: disc;
    -moz-text-security: disc;
  }
`;
