import { observer } from 'mobx-react';
import {
  Link,
  List,
  Paper,
  TableSortLabel,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import { useNavigate, useRouteLoaderData } from 'react-router';

import { mediaLarge } from '../../../styles/theme';
import { CustomDialog } from '../../../components/common/CustomDialog';
import { CreateNewCamp } from '../../../components/Modals/CreateNewCamp';
import { TableHeader } from '../../../components/Table/Table';
import {
  Camp,
  CampsDocument,
  useCreateCampMutation,
} from '../../../generated/graphql';
import { formatDateString } from '../../../utils/date';
import { getThumbnailImageUrl } from '../../../utils/getThumbnailImageUrl';
import { updateCache } from '../../../utils/updateCache';
import { useStores } from '../../../stores';
import { useCampsQueryWithAuth } from '../../../hooks/camps';
import { ErrorLoading } from '../../../components/common';

const styles = {
  root: {
    width: '100%',
    backgroundColor: '#fff',
  },
};

export const CampsPage = observer(function CampsPage() {
  const stores = useStores();
  const { modalStore } = stores;
  const { data, error, loading } = useCampsQueryWithAuth();

  const authorizedCamps = data?.camps || [];

  return (
    <div>
      <Paper style={{ overflow: 'auto' }}>
        <ErrorLoading {...{ error, loading }}>
          {authorizedCamps.length > 0 && (
            <>
              <TableHeader
                title={'Läger'}
                onAddItem={() => {
                  modalStore.addItem();
                }}
              />
              <StyledListItemHeader>
                <span className="image-cell">Bild</span>
                <div>Namn</div>
                <div>
                  <TableSortLabel active={true}>Startdatum</TableSortLabel>
                </div>
                <div>Slutdatum</div>
                <div>Sista anmälningsdatum</div>
              </StyledListItemHeader>
              <List style={styles.root} disablePadding>
                {authorizedCamps.map((camp) => (
                  <CampItem key={camp.camp_id} camp={camp} />
                ))}
                {authorizedCamps.length === 0 && (
                  <div style={{ padding: 40, textAlign: 'center' }}>
                    <Typography variant="h5">Här var det tomt!</Typography>
                    <br />
                    <Typography>
                      Det betyder att din användare inte blivit tilldelat några
                      läger ännu. Vänligen kontakta en administratör.
                    </Typography>
                  </div>
                )}
              </List>
              <AddItem />
            </>
          )}
        </ErrorLoading>
      </Paper>
    </div>
  );
});

const CampItem = observer(function CampItem({ camp }: { camp: Camp }) {
  const startDate = formatDateString(camp.start_date, 'yyyy-MM-dd');
  const endDate = formatDateString(camp.end_date, 'yyyy-MM-dd');
  const lastSignupDate = formatDateString(camp.last_signup_date, 'yyyy-MM-dd');
  const theme = useTheme();

  return (
    <StyledListItem href={`/dashboard/camps/${camp.camp_id}`}>
      {camp.image && (
        <img
          src={getThumbnailImageUrl(camp.image)}
          alt={camp.image?.name ? camp.image.name : undefined}
          crossOrigin="anonymous"
        />
      )}
      <div>
        <span>{camp.name}</span>
        <br />
        <span
          style={{
            color: camp.signup
              ? theme.palette.success.dark
              : theme.palette.primary.main,
          }}
        >
          {camp.signup ? 'Anmälan öppen' : 'Anmälan stängd'}
        </span>
      </div>
      <div>{startDate} </div>
      <div>{endDate}</div>
      <div>{lastSignupDate}</div>
    </StyledListItem>
  );
});

const AddItem = observer(function AddItem() {
  const stores = useStores();
  const modalStore = stores.modalStore;
  const close = modalStore.closeAddItemModal;

  const [create] = useCreateCampMutation();
  const nav = useNavigate();

  return (
    <CustomDialog open={modalStore.showingAddItemModal} onClose={close}>
      <CreateNewCamp
        onCancel={close}
        onSubmit={(data) => {
          create({
            variables: {
              body: data,
            },
            update: (proxy, mutationResult) => {
              updateCache({
                query: CampsDocument,
                proxy,
                mutationResultObject: mutationResult.data.createCamp,
                idProperty: 'camp_id',
              });
            },
          }).then(({ data }) => {
            nav('/dashboard/camps/' + data.createCamp.camp_id);
          });
          close();
        }}
      />
    </CustomDialog>
  );
});

const StyledListItemHeader = styled('div')`
  padding: 20px;
  align-items: center;

  width: 100%;

  > div {
    min-width: 200px;
    flex: 1 1 auto;
  }
  img {
    border-radius: 4px;
  }
  > img,
  .image-cell {
    width: 70px;
    height: auto;
    margin: 0 20px 0 0;
  }

  display: flex;

  ${mediaLarge} {
    display: inline-flex;
  }

  flex: 1 1 auto;
  font-weight: 700;
  .MuiButtonBase-root {
    cursor: default;
  }
`;

const StyledListItem = styled(Link)`
  padding: 20px;
  align-items: center;
  width: 100%;
  clear: both;

  > div {
    min-width: 200px;
    flex: 1 1 auto;
  }
  img {
    border-radius: 4px;
  }
  > img,
  .image-cell {
    width: 70px;
    height: auto;
    margin: 0 20px 0 0;
  }

  display: flex;

  ${mediaLarge} {
    display: inline-flex;
  }

  &:hover {
    background-color: #f3f3f3;
  }
  &:nth-last-of-type(odd) {
    background-color: #f5f5f5;
    &:hover {
      background-color: #eee;
    }
  }
  flex: 1 1 auto;
  text-decoration: none;
  color: inherit;
`;
