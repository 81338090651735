import {
  Route,
  Navigate,
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  redirect,
} from 'react-router-dom';
import { Link } from '@mui/material';

import {
  EXTERNAL_SHARED_LINK_PREFIX,
  HEADLEADER_LINK_PREFIX,
  LEADER_LINK_PREFIX,
} from './config';
import {
  AdminChurchLinkDocument,
  AdminChurchLinkQuery,
  AdminChurchLinkQueryVariables,
  Camp,
  CampDocument,
  CampQuery,
  CampQueryVariables,
  ChurchLink,
  LeaderChurchLinkDocument,
  LeaderChurchLinkQuery,
  LeaderChurchLinkQueryVariables,
  LoginDocument,
  LoginMutation,
  LoginMutationVariables,
  LogoutDocument,
  LogoutMutation,
  LogoutMutationVariables,
  MeDocument,
  MeQuery,
  MeQueryVariables,
  UsersDocument,
  UsersQuery,
  UsersQueryVariables,
} from './generated/graphql';
import { client, linkPasswordVar, meVar } from './stores/apollo';
import { Root } from './pages';
import {
  ChurchLinkMenu,
  ChurchLinkPage,
  ChurchLinkPersonsPage,
  ChurchLinkStudygroupsPage,
} from './pages/__link_prefix/__link';
import { ExternalLinkPage } from './pages/__external_link_prefix/__link';
import { LoginPage } from './pages/login';
import { DashboardPage } from './pages/dashboard';
import { CampsPage } from './pages/dashboard/camps';
import {
  AppContactPage,
  AppNewsPage,
  AppNotificationsPage,
  AppPage,
  AppSchemaPage,
  CampSharedLinksPage,
  CampColumnsPage,
  CampDashboardPage,
  CampDetailsPage,
  CampSettingsPage,
  CampStudygroupsPage,
  CampPersonsPage,
  CampStatisticsPage,
} from './pages/dashboard/camps/__camp_id';
import { MediaPage } from './pages/dashboard/media';
import { ReportsPage } from './pages/dashboard/reports';
import { SettingsPage } from './pages/dashboard/settings';
import { UsersPage } from './pages/dashboard/users';
import { RequireAuth } from './components/common';

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path="/"
      loader={async ({ params }) => {
        try {
          const result = await client.query<MeQuery, MeQueryVariables>({
            fetchPolicy: 'no-cache',
            errorPolicy: 'ignore',
            query: MeDocument,
          });
          meVar(result.data.me);

          return result.data.me;
        } catch (e) {
          console.warn('Root fetch user error:', e);

          return redirect('/login');
        }
      }}
      id="root"
      element={<Root />}
    >
      <Route
        key={'dashboard'}
        path="dashboard"
        element={
          <RequireAuth>
            <DashboardPage />
          </RequireAuth>
        }
        handle={{
          crumb: () => <Link href="/dashboard">Hem</Link>,
        }}
      >
        <Route
          path="camps"
          element={<CampsPage />}
          handle={{
            crumb: (data: Record<string, any>) => (
              <Link href={`${data.path}`}>Läger</Link>
            ),
          }}
        />
        <Route
          path="camps/:camp_id"
          element={<CampDetailsPage />}
          id="camp"
          loader={async ({ params }) => {
            const result = await client.query<CampQuery, CampQueryVariables>({
              variables: { camp_id: Number(params.camp_id) },
              query: CampDocument,
            });

            return result.data?.camp;
          }}
          handle={{
            crumb: (data: Camp) => (
              <Link href={`camps/${data.camp_id}`}>{data.name}</Link>
            ),
          }}
        >
          <Route index element={<CampDashboardPage />} />
          <Route path="columns" element={<CampColumnsPage />} />
          <Route
            path="persons"
            element={<CampPersonsPage />}
            handle={{
              crumb: (data: Record<string, any>) => (
                <Link href={`${data.path}`}>Anmälningslista</Link>
              ),
            }}
          />
          <Route
            path="app"
            element={<Outlet />}
            handle={{
              crumb: (data: Record<string, any>) => (
                <Link href={`${data.path}`}>Appen</Link>
              ),
            }}
          >
            <Route index element={<AppPage />} />
            <Route
              path="schema"
              element={<AppSchemaPage />}
              handle={{
                crumb: (data: Record<string, any>) => (
                  <Link href={`${data.path}`}>Schema</Link>
                ),
              }}
            />
            <Route
              path="news"
              element={<AppNewsPage />}
              handle={{
                crumb: (data: Record<string, any>) => (
                  <Link href={`${data.path}`}>Nyheter</Link>
                ),
              }}
            />
            <Route
              path="notifications"
              element={<AppNotificationsPage />}
              handle={{
                crumb: (data: Record<string, any>) => (
                  <Link href={`${data.path}`}>Notiser</Link>
                ),
              }}
            />
            <Route
              path="contact"
              element={<AppContactPage />}
              handle={{
                crumb: (data: Record<string, any>) => (
                  <Link href={`${data.path}`}>Kontaktinfo</Link>
                ),
              }}
            />
          </Route>
          <Route
            path="settings"
            element={<CampSettingsPage />}
            handle={{
              crumb: (data: Record<string, any>) => (
                <Link href={`${data.path}`}>Inställningar</Link>
              ),
            }}
          />
          <Route
            path="sharedlinks"
            element={<CampSharedLinksPage />}
            handle={{
              crumb: (data: Record<string, any>) => (
                <Link href={`${data.path}`}>Special-länkar</Link>
              ),
            }}
          />
          <Route
            path="statistics"
            element={<CampStatisticsPage />}
            handle={{
              crumb: (data: Record<string, any>) => (
                <Link href={`${data.path}`}>Statistik</Link>
              ),
            }}
          />
          <Route
            path="studygroups"
            element={<CampStudygroupsPage />}
            handle={{
              crumb: (data: Record<string, any>) => (
                <Link href={`${data.path}`}>Bildagrupper</Link>
              ),
            }}
          />
        </Route>
        <Route
          path="media"
          element={<MediaPage />}
          handle={{
            crumb: (data: Record<string, any>) => (
              <Link href={`${data.path}`}>Media</Link>
            ),
          }}
        />
        <Route
          path="reports"
          element={<ReportsPage />}
          handle={{
            crumb: (data: Record<string, any>) => (
              <Link href={`${data.path}`}>Årsrapporter</Link>
            ),
          }}
        />
        <Route
          path="settings"
          element={<SettingsPage />}
          handle={{
            crumb: (data: Record<string, any>) => (
              <Link href={`${data.path}`}>Inställningar</Link>
            ),
          }}
        />
        <Route
          path="users"
          element={<UsersPage />}
          loader={async ({ params }) => {
            const result = await client.query<UsersQuery, UsersQueryVariables>({
              variables: {},
              query: UsersDocument,
            });

            return result.data.users;
          }}
          handle={{
            crumb: (data: Record<string, any>) => (
              <Link href={`${data.path}`}>Användare</Link>
            ),
          }}
        />
      </Route>
      <Route
        path="login"
        action={async ({ params, request }) => {
          try {
            const formData = await request.formData();
            const intent = formData.get('intent');
            // console.log('intent', intent);
            console.log('params', JSON.stringify(params));

            // to add intent, add name="intent" and value="abc" to submit btn
            if (intent) {
              if (intent === 'logout') {
                const result = await client.mutate<
                  LogoutMutation,
                  LogoutMutationVariables
                >({
                  mutation: LogoutDocument,
                });

                return result;
              }
            }

            // console.log('formData', formData.get('username'));

            const result = await client.mutate<
              LoginMutation,
              LoginMutationVariables
            >({
              mutation: LoginDocument,
              variables: {
                username: formData.get('username') as string,
                password: formData.get('password') as string,
              },
            });

            return result;
          } catch (e) {
            console.log('Login error: ', e);
            throw e;
          }
        }}
        element={<LoginPage />}
        handle={{
          crumb: () => <Link href="/login">Logga in</Link>,
        }}
      />
      {[HEADLEADER_LINK_PREFIX, LEADER_LINK_PREFIX].map((prefix) => (
        <Route
          key={prefix}
          path={`${prefix}/:link`}
          id={`${prefix}/link`}
          loader={async ({ params }) => {
            try {
              if (prefix === HEADLEADER_LINK_PREFIX) {
                const result = await client.query<
                  AdminChurchLinkQuery,
                  AdminChurchLinkQueryVariables
                >({
                  variables: { link: params.link, password: linkPasswordVar() },
                  query: AdminChurchLinkDocument,
                });

                return result.data?.adminChurchLink;
              } else {
                const result = await client.query<
                  LeaderChurchLinkQuery,
                  LeaderChurchLinkQueryVariables
                >({
                  variables: { link: params.link, password: linkPasswordVar() },
                  query: LeaderChurchLinkDocument,
                });

                return result.data?.leaderChurchLink;
              }
            } catch (e) {
              return 'Länkfel: ' + e;
            }
          }}
          element={
            <ChurchLinkPage isHeadleader={prefix === HEADLEADER_LINK_PREFIX} />
          }
          handle={{
            crumb: (data: ChurchLink & { path: string }) => {
              return (
                <Link href={`${data.path}`}>
                  {data.name + ' — Länk'}
                  {/* <Button
                    startIcon={<BackArrow />}
                    variant="outlined"
                    color="inherit"
                  >
                    Tillbaka
                  </Button> */}
                </Link>
              );
            },
          }}
        >
          <Route index element={<ChurchLinkMenu />} />
          <Route
            path="persons"
            element={<ChurchLinkPersonsPage />}
            handle={{
              crumb: (data: Record<string, any>) => (
                <Link href={`${data.path}`}>Anmälningslista</Link>
              ),
            }}
          />
          <Route
            path="studygroups"
            element={<ChurchLinkStudygroupsPage />}
            handle={{
              crumb: (data: Record<string, any>) => (
                <Link href={`${data.path}`}>Bilda Listor</Link>
              ),
            }}
          />
        </Route>
      ))}
      <Route
        path={`${EXTERNAL_SHARED_LINK_PREFIX}/:link`}
        loader={async ({ params }) => {
          console.log('external link', params.link);
          return params.link;
        }}
        element={
          <ExternalLinkPage />
        }
      />
      <Route path="*" element={<Navigate to="/" replace />} />,
    </Route>,
  ),
);
