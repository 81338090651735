import React, { useEffect, useState } from 'react';

import { observer } from 'mobx-react';
import {
  Box,
  Button,
  DialogActions,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { addDays, parseISO } from 'date-fns';
import { DateTimePicker } from '@mui/x-date-pickers';
import { DeleteOutline } from '@mui/icons-material';

import { styled } from '../../styles/theme';
import { CampTimeTable, TimeTableUpdate } from '../../generated/graphql';
import { convertFormData } from '../../utils/form';
import { BluePlusIcon, FullWidthForm } from '../common/CommonDivs';

interface CreateTimeTableProps {
  existingItem?: CampTimeTable;
  activeDate?: Date;
  onSubmit: (data: { dates: Date[]; data: TimeTableUpdate }) => void;
  onCancel: () => void;
}

export const AddTimeTableItemModal = observer(
  ({ onSubmit, onCancel, existingItem, activeDate }: CreateTimeTableProps) => {
    const [selectedDates, setDates] = useState([activeDate || new Date()]);
    const isEdit = !!existingItem;

    useEffect(() => {
      if (!existingItem) return;
      let existingDate: string | Date = existingItem.date;
      if (typeof existingItem.date === 'string') {
        existingDate = parseISO(existingItem.date);
      }
      setDates([existingDate as Date]);
    }, [existingItem]);

    const submit = (e) => {
      e.preventDefault();
      e.stopPropagation();
      const data = convertFormData<TimeTableUpdate>(e.target);

      onSubmit({
        dates: selectedDates,
        data,
      });
    };

    return (
      <Box pl={3} pr={3} pt={3} pb={1}>
        <Typography component="h2" variant="h5" id="modal-title">
          {isEdit ? 'Uppdatera schemainlägg' : 'Skapa nytt schemainlägg'}
        </Typography>
        <FullWidthForm noValidate onSubmit={submit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="name"
            name="name"
            label="Namn"
            autoFocus
            defaultValue={isEdit ? existingItem.name : ''}
          />
          <StyledKeybordItems>
            {selectedDates.map((d, idx) => (
              <div key={idx} style={styles.padding4}>
                <DateTimePicker
                  // variant="inline"
                  // minDate={new Date('1900-01-01')}
                  ampm={false}
                  label="Datum"
                  value={d}
                  onChange={(date) => {
                    setDates((dates) => {
                      dates[idx] = date;

                      return dates.slice();
                    });
                  }}
                  onError={console.log}
                  // disablePast
                  format="yyyy-MM-dd HH:mm"
                />
                {!isEdit && (
                  <IconButton
                    style={styles.deleteIconButton}
                    onClick={() => {
                      selectedDates.splice(idx, 1);
                      setDates(selectedDates.slice());
                    }}
                  >
                    <DeleteOutline color="error" />
                  </IconButton>
                )}
              </div>
            ))}
            {!isEdit && (
              <div>
                <IconButton
                  style={styles.block}
                  onClick={() => {
                    const lastDate = selectedDates[selectedDates.length - 1];
                    selectedDates.push(addDays(lastDate, 1));
                    setDates(selectedDates.slice());
                  }}
                >
                  <BluePlusIcon />
                </IconButton>
              </div>
            )}
          </StyledKeybordItems>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="description"
            name="description"
            label="Meddelande"
            rows={2}
            multiline
            defaultValue={isEdit ? existingItem.description : ''}
          />

          <DialogActions>
            <Button color="secondary" type="button" onClick={onCancel}>
              {'Avbryt'}
            </Button>
            <Button color="primary" type="submit">
              {isEdit ? 'Spara' : 'Skapa schemainlägg'}
            </Button>
          </DialogActions>
        </FullWidthForm>
      </Box>
    );
  },
);

const styles = {
  padding4: {
    padding: 4,
  },
  deleteIconButton: {
    marginTop: '8px',
  },
  block: {
    display: 'block',
  },
};

const StyledKeybordItems = styled('div')`
  display: flex;
  flex-direction: column;
  width: fit-content;

  > div {
    display: flex;
    justify-content: center;
  }
  .MuiTextField-root {
    flex: 1 1 auto;
  }
`;
