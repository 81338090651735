import { download } from './download';

export const convertPersonToString = function convertPersonToString(
  person: any,
  columns: {
    name: string;
    displayer?: (id: number) => any;
    inputType: string;
  }[],
) {
  return columns
    .map((c) => {
      let value = person[c.name];

      if (c.displayer) {
        value = c.displayer(value);
      }
      if (value && value.name !== undefined) {
        value = value.name;
      }
      if (value === undefined) {
        value = person.details[c.name];
      }

      if (value === null || value === undefined) {
        value = '';
      }
      if (c.inputType === 'boolean') {
        if (Number(value) === 0) {
          value = 'Nej';
        }
        if (Number(value) === 1) {
          value = 'Ja';
        }
      }

      value = String(value);
      value = value.replace(/\t/gi, ' '); //replace tabs
      value = value.replace(/"/g, '""'); //replace quotes
      if (typeof value === 'string') {
        value = '"' + value + '"';
      }

      return value;
    })
    .join('\t');
};

const columns = [
  // { accessor: 'person_id', Header: 'ID' },
  { accessor: 'first_name', Header: 'Namn' },
  { accessor: 'last_name', Header: 'Efternamn' },
  { accessor: 'church', Header: 'Församling' },
  { accessor: 'role', Header: 'Roll' },
  { accessor: 'gender', Header: 'Kön' },
  { accessor: 'birth_year', Header: 'Födelseår' },
  { accessor: 'birth_month', Header: 'Födelsemånad' },
  { accessor: 'birth_day', Header: 'Födelsedag' },
  { accessor: 'birth_last_4', Header: '4 sista' },
  { accessor: 'street', Header: 'Adress' },
  { accessor: 'zip', Header: 'Postnr' },
  { accessor: 'city', Header: 'Ort' },
  { accessor: 'phone_parent', Header: 'Tel. Målsman' },
  { accessor: 'phone', Header: 'Tel.' },
  { accessor: 'email', Header: 'Email' },
  // { accessor: 'info', Header: 'Info' },
  // { accessor: 'living', Header: 'Boende' },
  {
    accessor: 'signup_date',
    Header: 'Anmäld',
    displayer: (val) => new Date(val),
  },
  {
    accessor: 'ksmung',
    Header: 'KSM Ung',
    displayer: (val) => (val ? 'Ja' : 'Nej'),
  },
  // { accessor: 'leader_day', Header: 'Ledardygn' },
  // { accessor: 'leader_meet', Header: 'Storledarsamling' },
  // { accessor: 'campfund_cost', Header: 'Lägerfondspengar' },
  // { accessor: 'leader_approved', Header: 'Korrekta uppgifter' },
  // { accessor: 'is_at_camp', Header: 'Finns på lägret' },
  // { accessor: 'living_details', Header: 'Bor i ...' },
  // { accessor: 'notes', Header: 'Anteckningar' },
];

export const exportCSVFile = (data: any[], columns: any[]) => {
  let csv = columns.map((c) => c.header).join('\t') + '\n';

  csv += data.reduce((acc, curr) => {
    const personString = convertPersonToString(curr, columns);
    acc += personString + '\n';

    return acc;
  }, '');

  download(csv, 'data-export.csv', 'text/csv;encoding:utf-8');
};
