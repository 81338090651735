import React, { useCallback, useEffect, useRef, useState } from 'react';

import { observer } from 'mobx-react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Popover,
  Select,
  SelectChangeEvent,
  styled,
} from '@mui/material';
import { runInAction, set } from 'mobx';

import { getPersonDisplayName } from '../../../helpers/persons/getPersonDetails';
import { useLatestValue } from '../../../hooks/useLatestValue';
import { useUpdatePersonMutation, InputType } from '../../../generated/graphql';
import { convertFormData } from '../../../utils/form';
import { useSyncState } from '../../../hooks/useSyncState';
import { SaveButton, CloseButton, ButtonGroup } from '../../icons';
import { ExtendedCampColumn } from '../../../types/extendedCampColumn';
import { getPersonValueFromColumn } from '../../../helpers/persons';
import { useStores } from '../../../stores';

export const TableOverlays = observer(function Overlays() {
  const stores = useStores();
  const store = stores.personStore;
  const editingProps = useLatestValue(store.editingPerson);
  const person = editingProps?.person;
  const column = editingProps?.column;
  const [update] = useUpdatePersonMutation();

  useEffect(() => {
    const escapeHandler: (e: KeyboardEvent) => any = (e) => {
      if (e.key === 'Escape') {
        store.clearEditing();
      }
    };
    document.addEventListener('keydown', escapeHandler);

    return () => {
      document.removeEventListener('keydown', escapeHandler);
    };
  }, [store]);

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      const formData = convertFormData(event.target as HTMLFormElement);
      event.preventDefault();
      event.stopPropagation();
      update({
        variables: {
          id: person.id,
          values: formData,
        },
      })
        .then((res) => {
          runInAction(() => {
            set(person, res.data.updatePerson);
            // person.details = res.data.updatePerson.details;
          });
          store.clearEditing();
        })
        .catch((e) => {
          console.log(e);
        });

      return false;
    },
    [person, store, update],
  );

  if (!editingProps) {
    return null;
  }

  const { stringValue, idValue } = getPersonValueFromColumn(person, column);

  return (
    <Popover
      anchorEl={editingProps.anchorEl}
      style={overlayStyle}
      open={!!store.editingPerson}
      onClose={() => {
        store.clearEditing();
      }}
    >
      <InnerOverlay>
        <span className="name">{getPersonDisplayName(person)}</span>
        <form onSubmit={handleSubmit}>
          <EditComponent
            column={column}
            cellValue={stringValue}
            idValue={idValue}
          />
          <ButtonGroup>
            <CloseButton
              type="button"
              onClick={() => {
                store.clearEditing();
              }}
            />
            <SaveButton type="submit" />
          </ButtonGroup>
        </form>
      </InnerOverlay>
    </Popover>
  );
});

export const EditComponent = observer(function EditComponent({
  column,
  idValue,
  cellValue,
  shouldFocus = true,
}: {
  column: ExtendedCampColumn;
  idValue: any;
  cellValue: string;
  shouldFocus?: boolean;
}) {
  const stores = useStores();
  const linkStore = stores.linkStore;
  const [state, setState] = useSyncState(cellValue);
  const inputRef = useRef<any>();

  useEffect(() => {
    if (shouldFocus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [cellValue, shouldFocus]);

  const handleChange = (e) => {
    setState(e.target.value);
  };

  // Disable church link stuff if not enough permissions
  const disabled =
    column.disabled === true ||
    (column.writePermission &&
      column.writePermission < linkStore.permissionLevel);

  // if (
  //   column.writePermission &&
  //   column.writePermission < linkStore.permissionLevel
  // ) {
  //   disabled = true;
  // }

  if (!column?.name) return null;

  switch (column.inputType) {
    case InputType.Text:
    case InputType.Textarea:
    case InputType.Email:
    case InputType.Phone:
    case InputType.Number:
      return (
        <FormControl disabled={disabled || false} margin="dense" fullWidth>
          <InputLabel htmlFor={String(idValue)} shrink>
            {column.header}
          </InputLabel>
          <OutlinedInput
            id={String(idValue)}
            type="text"
            multiline={column.inputType === InputType.Textarea}
            rows={4}
            label={column.header}
            name={column.name}
            inputRef={inputRef}
            notched
            placeholder={column.placeholder}
            value={state}
            onChange={handleChange}
            autoFocus={shouldFocus}
          />
        </FormControl>
      );
    case InputType.Values:
    case InputType.Boolean:
    case InputType.Collection:
      return (
        <FormControl disabled={disabled || false} margin="dense" fullWidth>
          <InputLabel id={String(idValue)} shrink>
            {column.header}
          </InputLabel>
          <SelectDropdown
            idValue={idValue}
            cellValue={cellValue}
            column={column}
            innerRef={inputRef}
            autoFocus={shouldFocus}
          />
        </FormControl>
      );

    default:
      break;
  }

  return null;
});

const SelectDropdown = observer(function SelectDropdown({
  autoFocus,
  cellValue,
  column,
  idValue,
  innerRef,
}: {
  autoFocus?: boolean;
  cellValue: string;
  column: ExtendedCampColumn;
  idValue: any;
  innerRef: any;
}) {
  const items = useCollectionItems(column);
  const [state, setState] = useSyncState(String(idValue));

  const handleChange:
    | ((event: SelectChangeEvent<string>, child: React.ReactNode) => void)
    | undefined = (e) => {
    const idVal = e.target.value;
    setState(idVal);
  };
  // const isAllChecked = filter.length === 0 || items.length === filter.length;

  // const labelId = `mutiple-checkbox-label-${column.accessor}`;

  const fieldName = column.relationalId || column.name;

  if (!fieldName || !items?.length) return null;

  return (
    <Select
      labelId={String(idValue)}
      label={column.header}
      notched
      inputRef={innerRef}
      name={fieldName}
      value={state}
      onChange={handleChange}
      renderValue={(val) => {
        const item = items.find((i) => i.id === val);

        return item?.name || '';
      }}
      displayEmpty
      autoFocus={autoFocus}
    >
      {items.map((item) => (
        <MenuItem key={item.id} value={item.id}>
          {item.name}
        </MenuItem>
      ))}
    </Select>
  );
});

const useCollectionItems = (column: ExtendedCampColumn) => {
  const stores = useStores();
  const store = stores.personStore;

  const [items, setItems] = useState<{ id: string; name: string }[]>([]);

  useEffect(() => {
    if (column.inputType === InputType.Values) {
      setItems(column.parsedValues);
    } else if (column.inputType === InputType.Boolean) {
      setItems([
        {
          id: '1',
          name: 'Ja',
        },
        {
          id: '0',
          name: 'Nej',
        },
      ]);
    } else if (column.inputType === InputType.Collection) {
      if (column.collection && store.campCollections.has(column.collection)) {
        const items = store.campCollections.get(column.collection);

        setItems(
          items.map((c) => ({
            id: String(c.id),
            name: c.name,
          })),
        );
      }
    }
  }, [
    column.collection,
    column.inputType,
    column.parsedValues,
    store.campCollections,
  ]);

  return items;
};

// Not used since we use native select"

// const ITEM_HEIGHT = 42;
// const ITEM_PADDING_TOP = 6;
// const styles = {
//   menuItem: {
//     padding: ITEM_PADDING_TOP,
//   },
//   paddingLeft42: {
//     padding: '6px 42px',
//     // height: ITEM_HEIGHT,
//   },
// };
// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       width: 250,
//     },
//   },
// };

const overlayStyle = { padding: 0, border: 0 };

export const InnerOverlay = styled('div')`
  padding: 14px 20px;

  border: 1px solid ${(p) => p.theme.palette.primary.main};
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);

  display: flex;
  flex-direction: column;
  max-width: 250px;

  position: relative;

  .name {
    text-align: center;
    margin-bottom: 4px;
  }

  .columnName {
    color: ${(p) => p.theme.palette.primary.main};
    font-weight: 900;
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: column;

    > * {
      margin-bottom: 4px;
    }
  }

  &:before {
    content: '';
    position: absolute;

    border: 1px solid ${(p) => p.theme.palette.primary.main};
    background: white;
    border-right: 0;
    border-bottom: 0;

    top: -6px;
    left: 50%;
    width: 10px;
    height: 10px;
    transform: translate(-50%, 0) rotate(45deg);
  }
`;
