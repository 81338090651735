import React, { useCallback, useEffect } from 'react';

import { observer } from 'mobx-react';
import { useRouteLoaderData } from 'react-router-dom';

import {
  useStudygroupCollectionQuery,
  ChurchLink,
  CampQuery,
} from '../../../../../generated/graphql';
import { StudyGroupList } from '../../../../../components/studygroup/StudyGroup';
import { ChurchListDropdown } from '../../../../../components/studygroup/ChurchListDropdown';
import { MovePersonModal } from '../../../../../components/studygroup/MovePersonModal';
import { useStores } from '../../../../../stores';

export const CampStudygroupsPage = observer(function CampStudygroupsPage() {
  const stores = useStores();
  const store = stores.movePersonStore;
  const selectedChurch = store.activeChurch;
  const camp = useRouteLoaderData('camp') as CampQuery['camp'];
  const camp_id = camp.camp_id;

  const setSelected = useCallback(
    (church?: ChurchLink) => {
      store.setActiveChurch(church);
    },
    [store],
  );

  useEffect(() => {
    store.setActiveChurch();
  }, [camp_id, store]);

  return (
    <>
      <div>
        <ChurchListDropdown
          selected={selectedChurch}
          setSelected={setSelected}
        />
        {selectedChurch && (
          <AdminStudyGroupList selectedChurch={selectedChurch} />
        )}
        <MovePersonModal />
      </div>
    </>
  );
});

const AdminStudyGroupList = observer(function AdminStudyGroupList({
  selectedChurch,
}: {
  selectedChurch: ChurchLink;
}) {
  const { data } = useStudygroupCollectionQuery({
    fetchPolicy: 'network-only',
    variables: {
      id: selectedChurch.group_collection_id,
    },
    skip: !selectedChurch.group_collection_id,
  });

  if (!data) {
    return null;
  }
  console.log('render admin group list');

  return (
    <StudyGroupList
      groupCollection={data.studygroupCollection}
      isAdmin
      isHeadleader
    />
  );
});
