import React, { useState, useEffect } from 'react';

import { observer } from 'mobx-react';
import { TextField } from '@mui/material';
import { useRouteLoaderData } from 'react-router-dom';

import { FlexBetween } from '../../common/CommonDivs';
import { DeleteIcon, SavedIconText, WhiteIconButton } from '../../icons';
import { LabelSwitch } from '../../switch';
import {
  useUpdateChurchLinkInfoBoxMutation,
  useDeleteChurchLinkInfoBoxMutation,
  ChurchLinkInfoBoxesDocument,
  AppInfoBoxFragment,
  CampQuery,
} from '../../../generated/graphql';
import { updateCache, CacheOperation } from '../../../utils/updateCache';

const itemSingular = 'infobox';

export const InfoboxForm = observer(function InfoboxForm({
  selectedItem,
  setSelectedItem,
}: {
  selectedItem: any;
  setSelectedItem: (item: any) => void;
}) {
  const {
    state,
    hasSaved,
    handleChange,
    handleSwitchChange,
    onKeyDown,
    handleBlur,
    deleteItem,
  } = useUpdateMethods({ item: selectedItem });

  const handleDeleteItem = () => {
    deleteItem()
      .then(() => setSelectedItem(null))
      .catch();
  };

  if (!state) {
    return <div>Ingen vald {itemSingular}</div>;
  }
  const { title, description, linkText, link, isLeader, isHeadLeader } = state;

  return (
    <div>
      <TextField
        margin="normal"
        required={true}
        fullWidth
        id={'title'}
        name={'title'}
        label={`Titel`}
        value={title || ''}
        onChange={handleChange}
        onKeyDown={onKeyDown}
        onBlur={handleBlur}
      />

      <TextField
        margin="normal"
        fullWidth
        id={'description'}
        name={'description'}
        label={`Extra information`}
        value={description || ''}
        onChange={handleChange}
        onKeyDown={onKeyDown}
        onBlur={handleBlur}
      />
      <TextField
        margin="normal"
        fullWidth
        id="linkText"
        name="linkText"
        label="Knapptext"
        value={linkText}
        onChange={handleChange}
        onKeyDown={onKeyDown}
        onBlur={handleBlur}
      />
      <TextField
        margin="normal"
        fullWidth
        id="link"
        name="link"
        label="Länk (URL)"
        type="url"
        value={link}
        onChange={handleChange}
        onKeyDown={onKeyDown}
        onBlur={handleBlur}
      />
      <LabelSwitch
        label={'Visa för ledare'}
        name={'isLeader'}
        value={isLeader}
        onChange={handleSwitchChange}
      />
      <LabelSwitch
        label={'Visa för huvudledare'}
        name={'isHeadLeader'}
        value={isHeadLeader}
        onChange={handleSwitchChange}
      />
      <br />
      <br />
      <FlexBetween>
        <SavedIconText visible={hasSaved} />

        <WhiteIconButton
          icon={<DeleteIcon />}
          title={`Radera ${itemSingular}`}
          onClick={handleDeleteItem}
        />
      </FlexBetween>
    </div>
  );
});

const useUpdateMethods = function useUpdateMethods({ item }: { item: any }) {
  const [state, setState] = useState<AppInfoBoxFragment>(null);
  const [hasSaved, setSaved] = useState(false);

  useEffect(() => {
    setState(item);
  }, [item]);

  const [update] = useUpdateChurchLinkInfoBoxMutation();

  const [del] = useDeleteChurchLinkInfoBoxMutation();
  const camp = useRouteLoaderData('camp') as CampQuery['camp'];

  const deleteItem = async () => {
    await del({
      variables: {
        id: state.id,
      },
      update: (proxy: any) => {
        updateCache({
          id: state.id!,
          method: CacheOperation.DELETE,
          query: ChurchLinkInfoBoxesDocument,
          proxy,
          variables: {
            camp_id: camp.camp_id,
          },
        });
      },
    });
  };

  const handleChange = (e: any) => {
    const input = e.target as HTMLInputElement;
    const name = input.name;
    const val = (name == 'isHeadLeader' ||
      name == 'isLeader'
    ) ? input.checked : input.value;

    setSaved(false);
    const newState = { ...state, [name]: val };
    setState(newState);

    return newState;
  };

  const handleSave = (state: AppInfoBoxFragment) => {
    const { id, title, description, linkText, link, isLeader, isHeadLeader } =
      state;

    update({
      variables: {
        id,
        body: {
          title,
          description,
          linkText,
          link,
          isLeader,
          isHeadLeader,
        },
      },
    })
      .then(() => setSaved(true))
      .catch();
  };

  const handleBlur = () => {
    handleSave(state);
  };

  const handleSwitchChange = (e: any) => {
    handleSave(handleChange(e));
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      (e.target as HTMLInputElement).blur();
    }
  };

  return {
    state,
    hasSaved,
    handleChange,
    handleSwitchChange,
    onKeyDown,
    handleBlur,
    deleteItem,
  };
};
