import React, { useCallback } from 'react';

import { observer } from 'mobx-react';
import { withStyles } from 'tss-react/mui';
import { Switch, FormControlLabel } from '@mui/material';

export const WhiteSwitch = withStyles(Switch, (theme, _params, classes) => ({
  switchBase: {
    color: '#fff',
    [`&.${classes.checked}`]: {
      color: '#fff',
    },
    [`&.${classes.checked} + .${classes.track}`]: {
      backgroundColor: theme.palette.primary.main,
    },
  },
  checked: {},
  track: {},
}));

export type LabelPlacement = 'end' | 'start' | 'top' | 'bottom';
interface LabelSwitchProps {
  label: string;
  name: string;
  value: any;
  labelPlacement?: LabelPlacement;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => void;
}

export const LabelSwitch = observer(function LabelSwitch({
  name,
  label,
  onChange,
  value,
  labelPlacement = 'end',
}: LabelSwitchProps) {
  const handleChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => void = useCallback(
    (e, checked) => {
      onChange(
        {
          ...e,
          target: {
            ...e.target,
            name: name,
            value: String(checked),
            checked,
          },
        },
        checked,
      );
    },
    [name, onChange],
  );

  return (
    <FormControlLabel
      control={<Switch checked={value} name={name} onChange={handleChange} />}
      labelPlacement={labelPlacement}
      label={label}
    />
  );
});
