import { useEffect, useState } from 'react';

export const useTimedOutSuccessState = function useTimedOutSuccessState(
  externalState: boolean,
  timeout = 2000,
) {
  const [state, setState] = useState(false);

  useEffect(() => {
    let id = -1;
    setState(externalState);
    if (externalState === true) {
      id = setTimeout(() => {
        setState(false);
      }, timeout) as any;
    }

    return () => clearTimeout(id);
  }, [externalState, timeout]);

  return state;
};
