import React, { useMemo } from 'react';

import { observer } from 'mobx-react';
import { Paper } from '@mui/material';
import { useRouteLoaderData } from 'react-router-dom';

import {
  CampColumnsDocument,
  CampQuery,
  UpdateCampColumnMutationFn,
  useCampColumnsQuery,
  useCreateCampColumnMutation,
  useDeleteCampColumnMutation,
  useUpdateCampColumnMutation,
} from '../../../../../generated/graphql';
import {
  CacheOperation,
  updateCacheWithCampId,
} from '../../../../../utils/updateCache';
import { CustomDialog } from '../../../../../components/common/CustomDialog';
import { ErrorLoading } from '../../../../../components/common/ErrorLoading';
import { AutoTableModal } from '../../../../../components/Modals/AutoTableModal';
import {
  TableComponent,
  TableHeader,
} from '../../../../../components/Table/Table';
import { Row, TableColumn } from '../../../../../types/tableColumn';
import { campColumnsSort } from '../../../../../helpers/camps';
import { useStores } from '../../../../../stores';
import { InputType } from '../../../../../types/inputTypes';

const allCampCollections = [
  {
    id: '',
    name: '',
  },
  {
    id: 'churches',
    name: 'Församlingar',
  },
  {
    id: 'roles',
    name: 'Deltagartyper',
  },
  {
    id: 'livings',
    name: 'Boenden',
  },
  {
    id: 'interests',
    name: 'Intressegrupper',
  },
];

const headers: TableColumn[] = [
  {
    title: 'Data-Slug',
    accessor: 'name',
    inputType: InputType.Text,
  },
  {
    title: 'Titel (Admin)',
    accessor: 'header',
    inputType: InputType.Text,
  },
  {
    title: 'Titel (Användare)',
    accessor: 'title',
    inputType: InputType.Text,
  },
  {
    title: 'Placeholder',
    accessor: 'placeholder',
    inputType: InputType.Text,
  },
  {
    title: 'Fälttyp',
    accessor: 'inputType',
    inputType: InputType.Values,
    values: Object.values(InputType).map((i) => ({ id: i, name: i })),
  },
  {
    title: 'Anmälningssida (1-3)',
    accessor: 'page',
    inputType: InputType.Number,
  },
  {
    title: 'Ordning',
    accessor: 'order',
    inputType: InputType.Number,
  },
  {
    title: 'Obligatoriskt',
    accessor: 'required',
    inputType: InputType.Boolean,
  },
  {
    // If inputType = values
    title: 'Välj från lista (Bryt med ny rad)',
    accessor: 'values',
    inputType: InputType.Textarea,
    // inputType: InputType.JSONObject,
  },
  {
    // If inputType = collection
    title: 'Läger samling',
    accessor: 'collection',
    inputType: InputType.Values,
    values: allCampCollections,
  },
  {
    title: 'Fältbeskrivning',
    accessor: 'description',
    inputType: InputType.Text,
  },
  {
    title: 'Extern-länk',
    accessor: 'link',
    inputType: InputType.Text,
  },
  // {
  //   title: 'Pris',
  //   accessor: 'price',
  //   inputType: InputType.Text,
  // },
  // {
  //   title: 'Länk (till ett annat fält)',
  //   accessor: 'subfield',
  //   inputType: InputType.Number,
  // },
  {
    title: 'Visa om (JSON)',
    accessor: 'showIf',
    inputType: InputType.Textarea,
    // inputType: InputType.JSONObject,
  },
  {
    title: 'Visas inte i formuläret',
    accessor: 'hiddenOnSignup',
    inputType: InputType.Boolean,
  },
  // {
  //   title: 'Fixerat fält (Admin)',
  //   accessor: 'fixed',
  //   inputType: InputType.Boolean,
  // },
  // {
  //   title: 'Filter-Typ',
  //   accessor: 'filterType',
  //   inputType: InputType.Number,
  // },
  {
    title: 'Hidden',
    accessor: 'hidden',
    inputType: InputType.Boolean,
  },
  {
    title: 'Ej-redigerbart',
    accessor: 'disabled',
    inputType: InputType.Boolean,
  },
  // {
  //   title: 'Går ej att ta bort',
  //   accessor: 'nonDeletable',
  //   inputType: InputType.Boolean,
  // },
  {
    title: 'Skyddat fält',
    accessor: 'secureField',
    inputType: InputType.Boolean,
  },
];

export const CampColumnsPage = observer(function CampColumnsPage() {
  const stores = useStores();
  const modalStore = stores.modalStore;
  const camp = useRouteLoaderData('camp') as CampQuery['camp'];
  const camp_id = camp.camp_id;

  const { data, error, loading } = useCampColumnsQuery({
    variables: { camp_id },
  });

  const [deleteItem] = useDeleteCampColumnMutation();
  const [updateItem] = useUpdateCampColumnMutation();

  const campColumns = useMemo(
    () =>
      (data?.campColumns ? [...data.campColumns] : []).sort(campColumnsSort),
    [data?.campColumns],
  );

  const handleDelete = (row: Row) => {
    deleteItem({
      variables: {
        id: Number(row.id),
      },
      update: (proxy: any) =>
        updateCacheWithCampId({
          query: CampColumnsDocument,
          proxy,
          method: CacheOperation.DELETE,
          camp_id: camp_id!,
          id: row.id,
        }),
    });
  };

  const handleUpdate = (id: number, accessor: string, newValue: string) => {
    return updateItem({
      variables: {
        id: id as number,
        details: {
          [accessor]: newValue,
        },
      },
    });
  };

  return (
    <>
      <ErrorLoading {...{ error, loading }}>
        <Paper className="page">
          <TableHeader
            title={'Kolumner'}
            onAddItem={() => {
              modalStore.addItem();
            }}
          />
          <TableComponent
            headers={headers}
            rows={campColumns}
            showEdit
            onUpdateRow={handleUpdate}
            onEdit={(row) => modalStore.updateItem(row)}
            onDelete={handleDelete}
          />
          <AddItem campId={camp_id!} headers={headers} />
          <EditItem
            campId={camp_id!}
            headers={headers}
            updateItem={updateItem}
          />
        </Paper>
      </ErrorLoading>
    </>
  );
});

const AddItem = observer(function AddItem({
  campId,
  headers,
}: {
  campId: number;
  headers: TableColumn[];
}) {
  const stores = useStores();
  const modalStore = stores.modalStore;

  const [createItem] = useCreateCampColumnMutation();

  const close = modalStore.closeAddItemModal;

  return (
    <CustomDialog open={modalStore.showingAddItemModal} onClose={close}>
      <AutoTableModal
        title="kolumn"
        headers={headers}
        onCancel={close}
        onSubmit={async (data) => {
          await createItem({
            variables: {
              camp_id: campId,
              details: data,
            },
            update: (proxy: any, mutationResult: any) => {
              updateCacheWithCampId({
                query: CampColumnsDocument,
                proxy,
                method: CacheOperation.CREATE,
                camp_id: campId,
                mutationResultObject: mutationResult.data.createCampColumn,
              });
            },
          });
          close();
        }}
      />
    </CustomDialog>
  );
});

interface EditItemProps {
  campId: number;
  headers: TableColumn[];
  updateItem: UpdateCampColumnMutationFn;
}

const EditItem = observer(function EditItem({
  headers,
  updateItem,
}: EditItemProps) {
  const stores = useStores();
  const modalStore = stores.modalStore;
  const close = modalStore.closeUpdateItemModal;

  return (
    <CustomDialog open={modalStore.showingUpdateItemModal} onClose={close}>
      <AutoTableModal
        title="kolumn"
        headers={headers}
        existingItem={modalStore.existingItem}
        onCancel={close}
        onSubmit={(data) => {
          updateItem({
            variables: {
              id: modalStore.existingItem.id,
              details: data,
            },
          });
          close();
        }}
      />
    </CustomDialog>
  );
});
