import React, { useCallback, useMemo, useState } from 'react';

import { observer } from 'mobx-react';
import { Grid, ModalProps } from '@mui/material';
import clsx from 'clsx';
import { useRouteLoaderData } from 'react-router-dom';

import {
  AppCampCollectionFragment,
  CampCollection,
  CampCollectionDocument,
  CampCollections,
  CampColumnsDocument,
  CampQuery,
  useAddCampCollectionItemsMutation,
  useCampCollectionQuery,
  useUpdateCampCollectionMutation,
  useUpdateCampMutation,
} from '../../../generated/graphql';
import { useModalRoute } from '../../../hooks';
import { reorder } from '../../../utils/arrayutils';
import { CacheOperation, updateCache } from '../../../utils/updateCache';
import {
  BorderedBox,
  FlexColumn,
  StyledModalView,
} from '../../common/CommonDivs';
import { CustomDialog } from '../../common/CustomDialog';
import { DragList } from '../../drag-list';
import { ImportIcon, SortIcon } from '../../icons';
import {
  AddButton,
  ButtonGroup,
  CloseButton,
  IconButton,
} from '../../icons/IconButton';
import { StyledRoleBox } from '../../item-card';
import { ChurchTshirtVariants } from '../churches/ChurchTshirtVariants';
import {
  ActiveOnCampSwitch,
  CampCollectionForm,
  ImportFromCamp,
  StyledItemListContainer,
  StyledModalHeader,
} from '../common';

const NEW_ITEM_NAME = 'Ny T-shirtstorlek';
const collection = CampCollections.Tshirts;
const MODAL_TITLE = 'T-shirtstorlek';
const ITEM_SINGULAR = 'T-shirtstorlek';

export const CampTshirtsModal = observer(function CampTshirtsModal(
  props: Omit<ModalProps, 'children'>,
) {
  const [selectedItem, setSelectedItem] = useState<AppCampCollectionFragment>();

  const [importActive, setImportActive] = useState(false);
  const toggleImport = () => setImportActive((s) => !s);
  const [sortActive, setSortActive] = useState(false);
  const [churchTshirtActive, setChurchTshirtActive] = useState(false);

  const camp = useRouteLoaderData('camp') as CampQuery['camp'];

  const [activeValue, setActiveValue] = useState(camp.tshirts_active!);

  const [addItems] = useAddCampCollectionItemsMutation();
  const [updateCamp] = useUpdateCampMutation();

  const toggleSort = useCallback(() => {
    if (sortActive) {
      setSortActive(false);
    } else {
      setSortActive(true);
      setChurchTshirtActive(false);
    }
  }, [sortActive]);

  const toggleChurchTshirt = useCallback(() => {
    if (churchTshirtActive) {
      setChurchTshirtActive(false);
    } else {
      setChurchTshirtActive(true);
      setSortActive(false);
    }
  }, [churchTshirtActive]);

  const addItem = useCallback(() => {
    addItems({
      variables: {
        collection,
        input: [
          {
            name: NEW_ITEM_NAME,
            camp_id: camp.camp_id,
          },
        ],
      },
      update: (
        proxy: any,
        result: { data: { addCampCollectionItems: any } },
      ) => {
        updateCache({
          method: CacheOperation.CREATE,
          query: CampCollectionDocument,
          proxy,
          mutationResultObject: result.data.addCampCollectionItems,
          variables: {
            camp_id: camp.camp_id,
            collection,
          },
        });
      },
    }).then(({ data }: { data: any }) => {
      setSelectedItem(data.addCampCollectionItems[0]);
    });
  }, [addItems, camp.camp_id]);

  const importList = <ImportFromCamp collection={collection} />;

  const itemForm = (
    <CampCollectionForm
      descriptionEnabled={false}
      itemSingular={ITEM_SINGULAR}
      selectedItem={selectedItem as AppCampCollectionFragment}
      setSelectedItem={setSelectedItem}
    />
  );

  return (
    <CustomDialog {...props} maxWidth="lg">
      <StyledModalHeader>
        <ButtonGroup>
          <IconButton
            title="Importera"
            icon={<ImportIcon />}
            active={importActive}
            onClick={toggleImport}
          />
          <IconButton
            title="Sortera"
            icon={<SortIcon />}
            active={sortActive}
            onClick={toggleSort}
          />
          <IconButton
            title="Färger"
            icon={<SortIcon />}
            active={churchTshirtActive}
            onClick={toggleChurchTshirt}
          />
          <AddButton onClick={addItem} />
        </ButtonGroup>
        <h2>{MODAL_TITLE}</h2>
        <CloseButton
          onClick={(e) => {
            props.onClose(e, 'backdropClick');
          }}
        />
      </StyledModalHeader>
      <StyledModalView>
        <Grid container spacing={3} className="relative">
          {churchTshirtActive ? (
            <ChurchTshirtVariants />
          ) : (
            <>
              <Grid
                item
                xs={4}
                className={clsx('animate', { active: importActive })}
              >
                <BorderedBox>
                  <FlexColumn>{importList}</FlexColumn>
                </BorderedBox>
              </Grid>

              <Grid item xs={8}>
                <ItemList
                  setSelectedItem={setSelectedItem}
                  sortActive={sortActive}
                />
              </Grid>

              <Grid
                item
                xs={4}
                className={clsx('animate', {
                  active: !importActive && !sortActive,
                })}
              >
                <BorderedBox>{itemForm}</BorderedBox>
              </Grid>
            </>
          )}
        </Grid>
        <ActiveOnCampSwitch
          checked={activeValue}
          onChange={(e, checked) => {
            setActiveValue(checked);
            updateCamp({
              variables: {
                body: {
                  camp_id: camp.camp_id,
                  tshirts_active: checked,
                },
              },
              refetchQueries: [
                {
                  query: CampColumnsDocument,
                  variables: { camp_id: camp.camp_id },
                },
              ],
            });
          }}
        />
      </StyledModalView>
    </CustomDialog>
  );
});

const ItemList = observer(function ItemList({
  setSelectedItem,
  sortActive,
}: {
  setSelectedItem: React.Dispatch<
    React.SetStateAction<AppCampCollectionFragment>
  >;
  sortActive: boolean;
}) {
  const camp = useRouteLoaderData('camp') as CampQuery['camp'];
  const camp_id = camp.camp_id;

  const [update] = useUpdateCampCollectionMutation({
    fetchPolicy: 'no-cache',
  });

  const { data, refetch } = useCampCollectionQuery({
    variables: {
      collection,
      camp_id,
    },
  });

  const items: any[] = useMemo(() => {
    return (
      data?.campCollection || []
    );
  }, [data]);

  const handleReorder = (from: number, to: number) => {
    console.log(from, to);
    const newItems = reorder(items, from, to);
    const promises = newItems.map(async (item, idx) => {
      return await update({
        variables: {
          collection,
          input: {
            camp_id,
            id: item.id,
            name: item.name,
            sortId: idx,
          },
        },
        optimisticResponse: {
          updateCampCollection: {
            __typename: 'CampCollection',
            collection,
            id: item.id,
            name: item.name,
            deleted: item.deleted,
            description: item.description,
            camp_id,
            sortId: idx,
          },
        },
      });
    });

    Promise.all([promises]).then(() => {
      refetch();
    });
  };

  if (sortActive) {
    return (
      <StyledItemListContainer className="no_scrollbar ">
        <DragList items={items} reorder={handleReorder} />
      </StyledItemListContainer>
    );
  }

  return (
    <StyledItemListContainer className="no_scrollbar ">
      <Grid container wrap="wrap" spacing={3}>
        {data?.campCollection?.map((item) => (
          <Item key={item.id} item={item} setSelectedItem={setSelectedItem} />
        ))}
      </Grid>
    </StyledItemListContainer>
  );
});

const Item = observer(function Item({
  item,
  setSelectedItem,
}: {
  item: CampCollection;
  setSelectedItem: React.Dispatch<
    React.SetStateAction<AppCampCollectionFragment>
  >;
}) {
  return (
    <Grid item xs={12} sm={4} md={4}>
      <StyledRoleBox
        onClick={() => {
          setSelectedItem(item);
        }}
        success={item.deleted}
        error={item.deleted}
      >
        {item.name}
      </StyledRoleBox>
    </Grid>
  );
});
