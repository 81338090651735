import React, { useState, useEffect, useMemo } from 'react';

import { observer } from 'mobx-react';
import {
  Checkbox,
  FormControlLabel,
  ListItemText,
  MenuItem,
} from '@mui/material';
import { useRouteLoaderData } from 'react-router-dom';

import { FlexEnd, FlexRow } from '../../common/CommonDivs';
import { WhiteIconButton } from '../../icons/IconButton';
import { SelectCampDropdown, StyledImportList } from '../common';
import {
  CampQuery,
  useCampColumnsQuery,
  useUpdateGdprDaysMutation,
} from '../../../generated/graphql';
import { ImportIcon } from '../../icons';
import { useStores } from '../../../stores';

export const ImportGdpr = observer(function ImportGdpr() {
  const stores = useStores();
  const importStore = stores.importStore;
  const [selectedCampImportId, setSelectedCampImportId] = useState<number>(-1);
  const camp = useRouteLoaderData('camp') as CampQuery['camp'];
  const [updateGdprDays] = useUpdateGdprDaysMutation();

  const handleAddItems = () => {
    const columns = importStore.selectedItems.reduce((acc, i) => {
      const deleteAfter = i.meta.gdprDeleteAfter;

      if (deleteAfter !== null && deleteAfter !== undefined) {
        // @ts-ignore
        acc[i.meta.name] = deleteAfter;
      } else {
        // @ts-ignore
        delete acc[i.meta.name];
      }

      return acc;
    }, {});

    updateGdprDays({
      variables: {
        camp_id: camp.camp_id,
        columnsObject: columns,
      },
    });
  };

  return (
    <>
      <FlexRow>
        <SelectCampDropdown
          selected={selectedCampImportId}
          onChange={setSelectedCampImportId}
        />
        <SelectAllItemCheckbox />
      </FlexRow>
      <hr />
      <ItemList handleAddItems={handleAddItems} />
      <FetchItemList selectedCampId={selectedCampImportId} />
    </>
  );
});

export const FetchItemList = observer(function FetchItemList({
  selectedCampId,
}: {
  selectedCampId: number;
}) {
  const stores = useStores();
  const importStore = stores.importStore;

  const { data } = useCampColumnsQuery({
    variables: {
      camp_id: selectedCampId,
    },
    skip: !selectedCampId || selectedCampId === -1,
  });

  const items = useMemo(() => {
    return data ? data.campColumns : [];
  }, [data]);

  useEffect(() => {
    importStore.setAllItems({
      items: items.map((i) => ({
        id: i.id,
        name: i.header,
        meta: i,
      })),
    });
  }, [importStore, items]);

  return null;
});

const SelectAllItemCheckbox = observer(function SelectAllItemCheckbox() {
  const stores = useStores();
  const importStore = stores.importStore;

  if (!importStore.allItems.length) {
    return null;
  }

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={importStore.isAllSelected}
          indeterminate={importStore.isSomeSelected}
          onChange={(event) => {
            const checked = event.target.checked;
            importStore.toggleSelectAllItems(checked);
          }}
        />
      }
      label={'Alla'}
      labelPlacement={'top'}
    />
  );
});

const ItemList = observer(function ItemList({
  handleAddItems,
}: {
  handleAddItems: () => void;
}) {
  const stores = useStores();
  const importStore = stores.importStore;

  return (
    <StyledImportList>
      {importStore.allItems.map((item) => (
        <MenuItem
          key={item.id}
          value={item.id}
          onClick={() => {
            importStore.toggleItem(item);
          }}
        >
          <ListItemText primary={item.name} />
          <Checkbox
            checked={
              importStore.selectedItems.findIndex((c) => c.id === item.id) !==
              -1
            }
          />
        </MenuItem>
      ))}
      <FlexEnd>
        <WhiteIconButton
          title={'Lägg till'}
          icon={<ImportIcon />}
          onClick={handleAddItems}
        />
      </FlexEnd>
    </StyledImportList>
  );
});
