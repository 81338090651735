import { observer } from 'mobx-react';
import { useRouteLoaderData } from 'react-router';

import { CampQuery, usePersonsStatisticsQuery } from '../../generated/graphql';
import { StatisticsDiv } from '../camp/styles';

export const PersonsDashboard = observer(function PersonsDashboard() {
  const camp = useRouteLoaderData('camp') as CampQuery['camp'];

  const { data, loading, error } = usePersonsStatisticsQuery({
    variables: {
      camp_id: camp.camp_id,
    },
    skip: !camp,
  });

  if (loading) {
    return <span>{'Laddar...'}</span>;
  }
  if (error && !data) {
    return <span>{`${error.message}`}</span>;
  }
  if (!data) {
    return <span>{'No data'}</span>;
  }
  const { total, today, week } = data.personsStatistics;

  return (
    <StatisticsDiv>
      <div>
        <div className="circle">
          <span>{total}</span>
        </div>
        <span>Totalt</span>
      </div>
      <div>
        <div className="circle">
          <span>{week}</span>
        </div>
        <span>7 dagar</span>
      </div>
      <div>
        <div className="circle">
          <span>{today}</span>
        </div>
        <span>Idag</span>
      </div>
    </StatisticsDiv>
  );
});
