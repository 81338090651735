import React, { useState, useCallback } from 'react';

import { observer } from 'mobx-react';
import {
  Avatar,
  Button,
  TextField,
  Link,
  Grid,
  Box,
  Typography,
  useTheme,
  CircularProgress,
} from '@mui/material';
import {
  Navigate,
  useLocation,
  useNavigate,
  useRouteLoaderData,
} from 'react-router';
import { LockOutlined } from '@mui/icons-material';

import { styled } from '../../styles/theme';
import { useLoginMutation } from '../../generated/graphql';
import { FullWidthForm } from '../../components/common/CommonDivs';
import { useStores } from '../../stores';

export const LoginPage = observer(function LoginPage() {
  const stores = useStores();
  const mainStore = stores.mainStore;
  const me = useRouteLoaderData('root');
  const [loginMutation, { data, loading, error: err }] = useLoginMutation();
  const [error, setError] = useState<string>();
  const nav = useNavigate();
  const theme = useTheme();
  const location = useLocation();
  const from = location.state?.from?.pathname || '/';
  // console.log('from', JSON.stringify(location.state));

  const onSubmit: React.FormEventHandler<HTMLFormElement> = useCallback(
    async (e) => {
      e.preventDefault();
      e.stopPropagation();
      const form = e.target;
      const data = new FormData(form as HTMLFormElement);

      console.log('onSubmit', data.get('username'), ', ', data.get('password'));
      try {
        await loginMutation({
          variables: {
            username: data.get('username') as string,
            password: data.get('password') as string,
          },
        });

        await mainStore.getMe('network-only');

        if (mainStore.previousPathBeforeLogin) {
          console.log('returning to previous path');

          // navigate(store.previousPathBeforeLogin, { replace: true });
          window.location.href = mainStore.previousPathBeforeLogin;
          mainStore.previousPathBeforeLogin = null;
        } else {
          console.log('nav to dashboard');

          nav('/dashboard', { replace: true });
        }
      } catch (e) {
        console.log('Login error: ', e);
        let error = e.message;
        if (e.graphQLErrors.length) {
          error = e.graphQLErrors[0].message;
        }
        setError(error);
      }
    },
    [loginMutation, mainStore, nav],
  );

  if (me) {
    return <Navigate to={from} replace />;
  }

  return (
    <Grid container component="main" style={styles.fullHeight}>
      <FullWidthGrid item xs={false} sm={4} md={7} style={styles.fullHeight}>
        <ImageHolder>
          <BGImg src="/hampe_bg.jpg" alt="" />
          <img className={`logo`} src="/logo_hampeveckan-svart.png" crossOrigin="anonymous" />
        </ImageHolder>
      </FullWidthGrid>
      <Grid
        item
        container
        xs={12}
        sm={8}
        md={5}
        direction="column"
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
        }}
        style={styles.padding20}
      >
        <Avatar style={{ backgroundColor: theme.palette.primary.main }}>
          <LockOutlined />
        </Avatar>
        <Typography component="h1" variant="h5">
          Logga in
        </Typography>
        <FullWidthForm method="post">
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Användarnamn"
            name="username"
            autoComplete="username"
            autoFocus
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Lösenord"
            type="password"
            id="password"
            autoComplete="current-password"
            // autoFocus
          />
          {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Håll mig inloggad"
          /> */}
          <Button
            type="submit"
            id="submit"
            fullWidth
            variant="contained"
            color="primary"
            size={'large'}
            disabled={loading}
          >
            <Typography variant="button" color="inherit" align="center">
              {loading ? (
                <CircularProgress
                  color="inherit"
                  size="0.9375rem"
                  style={{ verticalAlign: 'middle' }}
                />
              ) : (
                'Logga in'
              )}
            </Typography>
          </Button>
          <Typography variant="body1" color="error" align="center" gutterBottom>
            {error}
          </Typography>
          <Box mt={5}>
            <MadeWithLove />
          </Box>
        </FullWidthForm>
        {/* </Box> */}
      </Grid>
    </Grid>
  );
});

const MadeWithLove = observer(function MadeWithLove() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Levererad av '}
      <Link color="inherit" href="https://jmgraphic.se/">
        Jmgraphic AB
      </Link>
    </Typography>
  );
});

const styles = {
  fullHeight: { height: '100vh' },
  padding20: { padding: '20px' },
};

const BGImg = styled('img')`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

const ImageHolder = styled('div')`
  position: relative;
  height: 100%;
  width: 100%;
  img.logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -60%);

    width: 70%;
    transition: opacity 0.8s cubic-bezier(0.39, 0.575, 0.565, 1);
    transition-property: opacity, transform;
    opacity: 1;
    /* &.loaded {
      transform: translate(-50%, -50%);
      opacity: 1;
    } */
  }
`;

const FullWidthGrid = styled(Grid)`
  width: 100%;
  height: 100vh;
`;
