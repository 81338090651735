import React, { useEffect, useState } from 'react';

import { observer } from 'mobx-react';
import { DialogActions, Button, Box } from '@mui/material';
import { runInAction, set } from 'mobx';

import { styled } from '../../../styles/theme';
import { useLatestValue } from '../../../hooks/useLatestValue';
import { CustomDialog } from '../../common/CustomDialog';
import { getPersonDisplayName } from '../../../helpers/persons/getPersonDetails';
import { convertFormData } from '../../../utils/form';
import { useUpdatePersonMutation } from '../../../generated/graphql';
import { ExtendedPerson } from '../../../types/person';
import { isDeleteColumn } from '../../../helpers/camps';
import { getPersonValueFromColumn } from '../../../helpers/persons';
import { useStores } from '../../../stores';

import { EditComponent } from './TableOverlays';

export const EditPersonModal = observer(function EditPersonModal() {
  const stores = useStores();
  const personStore = stores.personStore;
  const person = personStore.editModalPerson;
  const latestPerson = useLatestValue(person);

  // A warning counter
  const [closeCounter, setCloseCounter] = useState(0);

  useEffect(() => {
    setCloseCounter(0);
  }, [person]);

  const close = () => {
    if (closeCounter) {
      personStore.toggleEditModal();
    } else {
      setCloseCounter(1);
    }
  };

  return (
    <CustomDialog open={!!person} onClose={close}>
      <>{latestPerson && <PersonForm person={latestPerson} />}</>
    </CustomDialog>
  );
});

const PersonForm = observer(function PersonForm({
  person,
}: {
  person: ExtendedPerson;
}) {
  const stores = useStores();
  const personStore = stores.personStore;
  const columns = personStore.columns;
  const [update] = useUpdatePersonMutation();

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    const formData = convertFormData(event.target as HTMLFormElement);
    event.preventDefault();
    event.stopPropagation();

    update({
      variables: {
        id: person.id,
        values: formData,
      },
    })
      .then((res: any) => {
        runInAction(() => {
          set(person, res.data.updatePerson);
        });
        personStore.toggleEditModal();
      })
      .catch((e: any) => {
        console.log(e);
      });

    return false;
  };

  const onCancel = () => {
    personStore.toggleEditModal();
  };

  return (
    <FormInnerOverlay>
      <h1>{getPersonDisplayName(person)}</h1>
      <form onSubmit={handleSubmit}>
        <div className="formFields">
          {columns.map((column, idx) => {
            const isDeleteCell = isDeleteColumn(column);

            if (isDeleteCell) {
              return null;
            }

            const { stringValue, idValue } = getPersonValueFromColumn(
              person,
              column,
            );

            return (
              <Box key={column.id}>
                <EditComponent
                  column={column}
                  cellValue={stringValue}
                  idValue={idValue}
                  shouldFocus={false}
                />
              </Box>
            );
          })}
        </div>
        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            type="button"
            onClick={onCancel}
          >
            {'Avbryt'}
          </Button>
          <Button color="primary" variant="contained" type="submit">
            {'Spara'}
          </Button>
        </DialogActions>
      </form>
    </FormInnerOverlay>
  );
});

const FormInnerOverlay = styled('div')`
  padding: 14px 20px;

  form {
    width: 100%;
    display: flex;
    flex-direction: column;

    > * {
      margin-bottom: 4px;
    }
  }
  .formFields {
    overflow: auto;
    overflow: auto;
    max-height: calc(80vh - 200px);

    > * {
      margin-bottom: 8px;
    }
  }
`;
