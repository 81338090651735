import React, { useCallback, useMemo } from 'react';

import { observer } from 'mobx-react';
import { useRouteLoaderData } from 'react-router-dom';

import {
  CampChurch,
  useCampChurchesQuery,
  useUpdateCampChurchMutation,
  AppCampChurchFragment,
  CampQuery,
} from '../../../generated/graphql';
import { MultipleDragList } from '../../drag-list/MultipleDragList';
import { StyledItemListContainer } from '../common';
import { ErrorLoading } from '../../common';

interface SourceItem {
  id: string;
  name: string;
  items: CampChurch[];
}
const initialTshirtVariants = (): SourceItem[] => [
  { id: 'Ej vald', name: 'Ej vald', items: [] },
  { id: '1', name: '1', items: [] },
  { id: '2', name: '2', items: [] },
  { id: '3', name: '3', items: [] },
];

export const ChurchTshirtVariants = observer(function ChurchTshirtVariants() {
  const camp = useRouteLoaderData('camp') as CampQuery['camp'];
  const camp_id = camp.camp_id;
  const { data, error, loading } = useCampChurchesQuery({
    variables: {
      camp_id,
    },
  });
  const churches = data?.campChurches || null;
  const sortedTshirtVariants = useChurchVariants(churches);
  const [updateChurch] = useUpdateCampChurchMutation();

  const handleReorder = useCallback(
    function handleReorder(
      sourceItem: AppCampChurchFragment,
      newValue: string,
    ) {
      // const destArray = columns.find(c => c.id === to).items;
      // const [newSource, newDest] = move(
      //   sourceArray,
      //   destArray,
      //   source,
      //   destination,
      // );
      updateChurch({
        variables: {
          input: {
            id: sourceItem.id,
            camp_id: sourceItem.camp_id,
            tshirtVariant: newValue,
          },
        },
        optimisticResponse: {
          updateCampChurch: {
            // eslint-disable-next-line @typescript-eslint/prefer-as-const
            __typename: 'CampChurch' as 'CampChurch',
            ...sourceItem,
            tshirtVariant: newValue,
          },
        },
      }).catch();
    },
    [updateChurch],
  );

  return (
    <>
      <ErrorLoading {...{ error, loading }} />
      {churches?.length && (
        <StyledItemListContainer className="no_scrollbar ">
          <MultipleDragList
            columns={sortedTshirtVariants}
            reorder={handleReorder}
          />
        </StyledItemListContainer>
      )}
    </>
  );
});

const useChurchVariants = function useChurchVariants(
  churches: AppCampChurchFragment[],
) {
  return useMemo(() => {
    const sortedTshirtVariants = initialTshirtVariants();

    for (const church of churches) {
      const existingIndex = sortedTshirtVariants.find(
        (s) => s.id === (church.tshirtVariant || 'Ej vald'),
      );

      if (existingIndex) {
        existingIndex.items.push(church);
      } else {
        sortedTshirtVariants.push({
          name: church.tshirtVariant!,
          id: church.tshirtVariant!,
          items: [church],
        });
      }
    }
    console.log(sortedTshirtVariants);

    return sortedTshirtVariants;
  }, [churches]);
};
