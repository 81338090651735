import {
  AppStudyGroupFragmentDoc,
  Person,
  StudyGroup,
  useUpdatePersonStudygroupMutation,
} from '../../generated/graphql';

export const useUpdatePersonsStudygroup =
  function useUpdatePersonsStudygroup() {
    const [updatePerson] = useUpdatePersonStudygroupMutation();

    return (
      sourcePerson: Person,
      fromStudygroup: StudyGroup,
      toStudygroup: StudyGroup,
    ) =>
      updatePerson({
        variables: {
          id: sourcePerson.id,
          studygroupId: toStudygroup.id,
        },
        update: (proxy, { data }) => {
          proxy.writeFragment({
            fragment: AppStudyGroupFragmentDoc,
            fragmentName: 'AppStudyGroup',
            id: `StudyGroup:${fromStudygroup.id}`,
            data: fromStudygroup,
          });
          proxy.writeFragment({
            fragment: AppStudyGroupFragmentDoc,
            fragmentName: 'AppStudyGroup',
            id: `StudyGroup:${toStudygroup.id}`,
            data: toStudygroup,
          });
        },
      });
  };
