import React, { useEffect, useState } from 'react';

import { SelectChangeEvent } from '@mui/material';

import {
  AppCampChurchFragment,
  CampChurch,
  CampChurchesDocument,
  RegenerateHeadLeaderLinkMutation,
  RegenerateLeaderLinkMutation,
  useDeleteCampChurchMutation,
  useRegenerateHeadLeaderLinkMutation,
  useRegenerateLeaderLinkMutation,
  useUpdateCampChurchMutation,
} from '../../generated/graphql';
import { CacheOperation, updateCache } from '../../utils/updateCache';

export const useChurchForm = function useChurchForm({
  selectedChurch,
  setChurch,
}: {
  selectedChurch: AppCampChurchFragment;
  setChurch: (church: CampChurch) => void;
}): {
  state: AppCampChurchFragment;
  hasSaved: boolean;
  deleteChurch: () => Promise<void>;
  onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  handleChange: (e: any) => {
    __typename?: 'CampChurch';
    id: number;
    camp_id: number;
    name: string;
    tshirtVariant?: string;
    deleted?: boolean;
    mainConductorId?: number;
    admin_link?: string;
    admin_link_enabled?: boolean;
    leader_link?: string;
    leader_link_enabled?: boolean;
    group_collection_id?: number;
  };
  handleSwitchChange: (event: SelectChangeEvent<number>) => void;
  handleBlur: () => void;
  regenerateHeadLeaderLink: () => void;
  regenerateLeaderLink: () => void;
} {
  // const client = useApolloClient();
  const [state, setState] = useState<AppCampChurchFragment>(selectedChurch);
  const [hasSaved, setSaved] = useState(false);

  useEffect(() => {
    // const churchFrag = client.readFragment({
    //   id: `CampChurch:${selectedChurchId}`,
    //   fragment: AppCampChurchFragmentDoc,
    // });
    setState(selectedChurch);
  }, [selectedChurch]);

  const [regenerateAdmin] = useRegenerateHeadLeaderLinkMutation({
    onCompleted: (result: RegenerateHeadLeaderLinkMutation) =>
      setState(result.regenerateHeadLeaderLink),
  });

  const [regenerateLeader] = useRegenerateLeaderLinkMutation({
    onCompleted: (result: RegenerateLeaderLinkMutation) =>
      setState(result.regenerateLeaderLink),
  });

  const [update] = useUpdateCampChurchMutation();
  const [del] = useDeleteCampChurchMutation();

  const deleteChurch = async () => {
    await del({
      variables: {
        id: state.id,
      },
      update: (proxy: any) => {
        updateCache({
          id: state.id,
          method: CacheOperation.DELETE,
          query: CampChurchesDocument,
          proxy,
          variables: {
            camp_id: state.camp_id,
          },
        });
      },
    });
    setChurch(null);
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      (e.target as HTMLInputElement).blur();
    }
  };

  const handleChange: (
    event: SelectChangeEvent<number>,
  ) => AppCampChurchFragment = (e) => {
    const input = e.target as HTMLInputElement;
    let val = input.type === 'checkbox' ? input.checked : input.value;
    const name = input.name;

    if (name == 'deleted')
    { val = val === 'true';

      console.log('val', val);

    }



    setSaved(false);
    const newState: AppCampChurchFragment = { ...state, [name]: val };
    setState(newState);

    return newState;
  };

  const handleSave = (state: AppCampChurchFragment) => {
    const {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      __typename,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      admin_link,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      leader_link,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      group_collection_id,
      ...updates
    } = state;

    update({
      variables: {
        input: updates,
      },
    })
      .then(() => setSaved(true))
      .catch();
  };

  const handleSwitchChange: (event: SelectChangeEvent<number>) => void = (
    e,
  ) => {
    handleSave(handleChange(e));
  };

  const handleBlur = () => {
    handleSave(state);
  };

  const regenerateHeadLeaderLink = () => {
    regenerateAdmin({
      variables: {
        id: selectedChurch.id,
      },
    });
  };

  const regenerateLeaderLink = () => {
    regenerateLeader({
      variables: {
        id: selectedChurch.id,
      },
    });
  };

  return {
    state,
    hasSaved,
    deleteChurch,
    onKeyDown,
    handleChange,
    handleSwitchChange,
    handleBlur,
    regenerateHeadLeaderLink,
    regenerateLeaderLink,
  };
};
