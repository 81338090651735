import React, { ChangeEvent, useEffect, useState } from 'react';

import { observer } from 'mobx-react';
import {
  ButtonGroup,
  Grid,
  ModalProps,
  TextField,
  Typography,
} from '@mui/material';
import { useRouteLoaderData } from 'react-router-dom';

import {
  Camp,
  CampQuery,
  useUpdateCampMutation,
} from '../../../generated/graphql';
import { useModalRoute } from '../../../hooks';
import {
  BorderedBox,
  FlexColumn,
  FlexEnd,
  PaddedModalView,
} from '../../common/CommonDivs';
import { CustomDialog } from '../../common/CustomDialog';
import { CloseButton, SavedIconText } from '../../icons';
import { LabelSwitch } from '../../switch';
import { StyledModalHeader } from '../common';
import { useUpdateCamp } from '../../../hooks/camps';

const title = 'Ledarinställningar';

export const LeaderSettingsModal = observer(function LeaderSettingsModal(
  props: Omit<ModalProps, 'children'>,
) {
  const {
    state,
    hasSaved,
    handleChange,
    handleSwitchChange,
    onKeyDown,
    handleBlur,
  } = useUpdateCamp();

  return (
    <CustomDialog {...props} maxWidth={'lg'}>
      <StyledModalHeader>
        <span />
        <h2>{title}</h2>
        <ButtonGroup>
          {/* <IconButton title={'Spara'} icon={<SaveIcon />} onClick={save} /> */}
          <CloseButton
            onClick={(e) => {
              props.onClose(e, 'backdropClick');
            }}
          />
        </ButtonGroup>
      </StyledModalHeader>
      <PaddedModalView style={{ minHeight: 'auto' }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <BorderedBox>
              <FlexColumn>
                <LabelSwitch
                  label={'Möjlighet att anmäla sig till Storledarsamling:'}
                  name={'leader_meet'}
                  value={state.leader_meet}
                  onChange={handleSwitchChange}
                />
                <br />
                <TextField
                  label={'Information om storledarsamling:'}
                  name="leader_meet_info"
                  value={state.leader_meet_info || ''}
                  onChange={handleChange}
                  onKeyDown={onKeyDown}
                  onBlur={handleBlur}
                />
              </FlexColumn>
            </BorderedBox>
          </Grid>
          <Grid item xs={12} sm={6}>
            <BorderedBox>
              <FlexColumn>
                <LabelSwitch
                  label={'Möjlighet att anmäla sig till Ledardygnet:'}
                  name={'leader_day'}
                  value={state.leader_day}
                  onChange={handleSwitchChange}
                />
                <br />
                <TextField
                  label={'Information om ledardygnet:'}
                  name="leader_day_info"
                  value={state.leader_day_info || ''}
                  onChange={handleChange}
                  onKeyDown={onKeyDown}
                  onBlur={handleBlur}
                />
              </FlexColumn>
            </BorderedBox>
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <BorderedBox>
              <FlexColumn>
                <Typography>
                  Möjlighet att anmäla sig till Storledarsamling 2
                </Typography>

                <LabelSwitch
                  label={'Möjlighet att anmäla sig till Storledarsamling 2:'}
                  name={'leader_day2'}
                  value={leader_day2}
                  onChange={handleSwitchChange}
                />
                <br />
                <TextField
                  label={'Information om storledarsamling 2:'}
                  name="leader_day2_info"
                  value={leader_day2_info || ''}
                  onChange={handleChange}
                  onKeyDown={onKeyDown}
                  onBlur={handleBlur}
                />
              </FlexColumn>
            </BorderedBox>
          </Grid> */}
          <Grid item xs={12} sm={6}>
            <BorderedBox>
              <FlexColumn>
                <Typography>
                  Ledarlösenord som ger tillgång via länkar
                </Typography>

                <br />
                <TextField
                  label={'Ledarlösenord'}
                  name="shared_link_password"
                  type="string"
                  autoComplete="off"
                  value={state.shared_link_password || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onKeyDown={onKeyDown}
                />
              </FlexColumn>
            </BorderedBox>
          </Grid>

          <Grid item xs={12} sm={6}>
            <BorderedBox>
              <FlexColumn>
                <Typography>
                  Ledarlösenord till appen
                </Typography>

                <br />
                <TextField
                  label={'App Ledarlösenord'}
                  name="app_passwords"
                  type="string"
                  autoComplete="off"
                  value={state.app_passwords || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onKeyDown={onKeyDown}
                />
              </FlexColumn>
            </BorderedBox>
          </Grid>

          <Grid item xs={12} sm={6}>
            <BorderedBox>
              <FlexColumn>
                <LabelSwitch
                  label={'Visa Bildagrupper för ledare'}
                  name={'studygroups_active'}
                  value={state.studygroups_active}
                  onChange={handleSwitchChange}
                />
                <br />
              </FlexColumn>
            </BorderedBox>
          </Grid>
        </Grid>
        <FlexEnd>
          <SavedIconText visible={hasSaved} />
        </FlexEnd>
      </PaddedModalView>
    </CustomDialog>
  );
});
