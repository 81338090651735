
import { SERVER_URL } from '../config';
import { Media } from '../generated/graphql';

export const getThumbnailImageUrl = function getThumbnailImageUrl(
  media: Media,
) {
  if (!media) {
    return '';
  }

  let thumbUrl = `${SERVER_URL}${media.thumbnail ? media.thumbnail : null}`;
  const isPDF = media.mimetype === 'application/pdf';
  const pdfIcon = '/file-pdf-icon.svg';

  if (!media.thumbnail && isPDF) {
    thumbUrl = pdfIcon;
  }

  return thumbUrl;
};
