import React, { useState } from 'react';

import { observer } from 'mobx-react';
import { Grid, ModalProps } from '@mui/material';
import { useRouteLoaderData } from 'react-router-dom';

import {
  AppCampRoleFragment,
  CampQuery,
  Maybe,
  useCampRolesQuery,
  useCreateMissingCampRolesMutation,
} from '../../../generated/graphql';
import { useModalRoute } from '../../../hooks';
import {
  BorderedBox,
  FlexColumn,
  PaddedModalView,
} from '../../common/CommonDivs';
import { CustomDialog } from '../../common/CustomDialog';
import {
  ButtonGroup,
  CloseButton,
  WhiteIconButton,
} from '../../icons/IconButton';
import { SingleRoleBox } from '../../item-card';
import { StyledModalHeader } from '../common/StyledModalHeader';

import { RoleForm } from './RoleForm';

const MODAL_TITLE = 'Roller';

export const RoleModal = observer(function RoleModal(
  props: Omit<ModalProps, 'children'>,
) {
  const [selectedCampRoleId, setSelectedCampRoleId] = useState<number>();

  const camp = useRouteLoaderData('camp') as CampQuery['camp'];
  const camp_id = camp.camp_id;

  const { data: campRolesData, refetch } = useCampRolesQuery({
    variables: {
      camp_id,
    },
  });

  const campRoles = campRolesData ? campRolesData.campRoles : [];

  const selectedCampRole = campRoles.find((r) => r.id === selectedCampRoleId);

  const handleSelectRole = (role: AppCampRoleFragment) => {
    setSelectedCampRoleId(role.id);
  };

  const [createMissing] = useCreateMissingCampRolesMutation({
    variables: {
      camp_id,
    },
    // refetchQueries: [{ query: CampRolesDocument, variables: { camp_id } }],
    // update: (proxy, data) => {

    // }
  });

  return (
    <CustomDialog {...props} maxWidth={'md'}>
      <StyledModalHeader>
        <ButtonGroup></ButtonGroup>
        <h2>{MODAL_TITLE}</h2>
        <CloseButton
          onClick={(e) => {
            props.onClose(e, 'backdropClick');
          }}
        />
      </StyledModalHeader>
      <PaddedModalView>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <FlexColumn>
              {!campRoles.length ? (
                <WhiteIconButton
                  title={'Add missing roles'}
                  onClick={() => createMissing().then(() => refetch())}
                />
              ) : null}
              {campRoles.map(
                (role: {
                  id: any;
                  __typename?: 'CampRole' | undefined;
                  role_slug?: string;
                  camp_id?: number;
                  name?: Maybe<string> | undefined;
                  isLeader?: Maybe<boolean> | undefined;
                  enabled?: boolean;
                  description?: Maybe<string> | undefined;
                  receivesExtraMailInformation?: Maybe<boolean> | undefined;
                  extraMailInformation?: Maybe<string> | undefined;
                  receivesAppCode?: Maybe<boolean> | undefined;
                }) => {
                  return (
                    <SingleRoleBox
                      key={role.id}
                      // @ts-ignore
                      role={role}
                      onClick={handleSelectRole}
                    />
                  );
                },
              )}
            </FlexColumn>
          </Grid>

          <Grid item xs={8}>
            <BorderedBox>
              <RoleForm campRole={selectedCampRole} />
            </BorderedBox>
          </Grid>
        </Grid>
      </PaddedModalView>
    </CustomDialog>
  );
});
