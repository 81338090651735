import { useEffect } from 'react';

import { observer } from 'mobx-react';
import { Outlet } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { CircularProgress, Container, Typography } from '@mui/material';

import { Layout } from '../../../components/layout';
import { PanicModal } from '../../../components/shared-links/PanicModal';
import { useChurchLinkQuery } from '../../../hooks/shared-links/useChurchLinkQuery';
import { linkPasswordVar, useStores } from '../../../stores';

type ChurchLinkProps = {
  isHeadleader?: boolean;
};

export const ChurchLinkPage = observer(function ChurchLinkPage({
  isHeadleader,
}: ChurchLinkProps) {
  const stores = useStores();
  const { linkStore, modalStore } = stores;

  let churchLink = useChurchLinkQuery({
    isHeadleader,
  });
  const error = typeof churchLink === 'string' && churchLink;

  useEffect(() => {
    if (error) {
      const prompt = async () => {
        try {
          const pwd = await modalStore.promptPassword();
          linkPasswordVar(pwd);
        } catch (e) {
          console.error('ChurchLinkPage error', e);
        }
      };
      prompt();
    } else if (modalStore.showingPromptDialog) {
      modalStore.setShowingPromptDialog(false);
    }
  }, [error, linkStore, modalStore]);

  useEffect(() => {
    linkStore.setIsHeadleader(isHeadleader);
  }, [isHeadleader, linkStore]);

  // if (typeof churchLink === 'string') {
  //   return <Navigate to="/" />;
  // }

  console.log('churchLink', churchLink);

  const title =
    typeof churchLink === 'string'
      ? ''
      : `Länk${churchLink?.name && ` - ${churchLink?.name}`}`;

  return (
    <>
      <Layout>
        <Helmet title={title} />
        {!churchLink || typeof churchLink === 'string' ? (
          <>
            <Container
              sx={{
                height: '200px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {error ? (
                <>
                  <Typography>Kunde inte ladda in länken</Typography>
                </>
              ) : (
                <CircularProgress />
              )}
            </Container>
          </>
        ) : (
          <Outlet />
        )}
        <PanicModal />
      </Layout>
    </>
  );
});
