import React, { useRef } from 'react';

import { observer } from 'mobx-react';
import { AutoSizer, GridCellProps, MultiGrid } from 'react-virtualized';

import { useStores } from '../../stores';

import { TableCell } from './TableCell';
import { TableHeader } from './TableHeader';
import { StyledTable } from './VirtualizedTableStyles';
import { TableOverlays } from './table-overlay';
import { EditPersonModal } from './table-overlay/EditModal';

const HEADER_HEIGHT = 100;
const CELL_HEIGHT = 60;
const MIN_ROWS = 5;

// eslint-disable-next-line mobx/missing-observer
function RowHeightFn({ index }: { index: number }) {
  return index === 0 ? HEADER_HEIGHT : CELL_HEIGHT;
}

// eslint-disable-next-line mobx/missing-observer
function CellRendererFn(props: GridCellProps) {
  if (props.rowIndex === 0) {
    return <TableHeader {...props} />;
  }

  return <TableCell {...props} />;
}

export const PersonsTable = observer(function PersonsTable() {
  const stores = useStores();
  const store = stores.personStore;
  const tableRef = useRef<MultiGrid>();
  const columns = store.activeColumns;
  const persons = store.filteredPersons;

  // TODO: Loading timeout

  return (
    <>
      {/* <h2>{store.dataLength}</h2> */}
      <StyledTable>
        {!columns.length ? (
          <></>
        ) : (
          <AutoSizer>
            {({ height, width }) => (
              <MultiGrid
                cellRenderer={CellRendererFn}
                columnWidth={({ index }) => {
                  // Keep fixed columns constant in width otherwise it bugs out
                  if (index === 0) {
                    return 200;
                  }

                  return store.activeColumns[index].width || 200;
                }}
                columnCount={columns.length}
                fixedColumnCount={1}
                fixedRowCount={1}
                ref={tableRef}
                rowHeight={RowHeightFn}
                rowCount={Math.max(persons.length + 1, MIN_ROWS)}
                width={width}
                height={height}
                enableFixedColumnScroll
                enableFixedRowScroll
                classNameBottomLeftGrid="no_scrollbar"
                classNameTopRightGrid="no_scrollbar"
                // width={1000}
                // height={700}
              />
            )}
          </AutoSizer>
        )}
      </StyledTable>
      <TableOverlays />
      <EditPersonModal />
    </>
  );
});
