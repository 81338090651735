import { observer } from 'mobx-react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Button, IconButton as MUIIconButton, Tooltip } from '@mui/material';

import {
  StudyGroup,
  StudyGroupCollection,
  useUpdateStudygroupMutation,
} from '../../generated/graphql';
import {
  getPersonDisplayName,
  isPersonLeader,
} from '../../helpers/persons/getPersonDetails';
import { FlexRowAlignCenter } from '../common/CommonDivs';
import { DeleteIcon, SettingsIcon, StarIcon } from '../icons';
import { useDragAndDrop } from '../../hooks/studygroups/useDragAndDrop';
import {
  useCreateList,
  useDeleteList,
} from '../../hooks/studygroups/useCreateList';
import { personSorter } from '../../helpers/persons';
import { useStores } from '../../stores';

import {
  DropListContainer,
  DropListHeader,
  FlexWrapper,
  getItemStyle,
  getListStyle,
  HeaderContainer,
  StyledDeleteButton,
  StyledPlainInput,
} from './styles';

export const StudyGroupList = observer(function StudyGroupList({
  groupCollection,
  isAdmin = false,
  isHeadleader = false,
}: {
  groupCollection: StudyGroupCollection;
  isAdmin?: boolean;
  isHeadleader?: boolean;
}) {
  const { onDragEnd, state } = useDragAndDrop(groupCollection);
  const createList = useCreateList(groupCollection);

  // console.log('render group list');
  return (
    <FlexWrapper>
      <DragDropContext onDragEnd={onDragEnd}>
        {Object.keys(state).map((key) => (
          <DroppableStudygroup
            key={key}
            studygroup={state[key]}
            isAdmin={isAdmin}
            isHeadleader={isHeadleader}
          />
        ))}
      </DragDropContext>
      {isHeadleader && (
        <Button
          style={{
            height: 44,
            width: 180,
            flexShrink: 0,
            boxShadow: 'none',
          }}
          color="secondary"
          variant="contained"
          onClick={() => {
            createList();
          }}
        >
          {'Skapa ny grupp'}
        </Button>
      )}
    </FlexWrapper>
  );
});

const DroppableStudygroup = observer(function DroppableStudygroup({
  studygroup,
  isAdmin,
  isHeadleader,
}: {
  studygroup: StudyGroup;
  isAdmin: boolean;
  isHeadleader: boolean;
}) {
  const stores = useStores();
  const movePersonStore = stores.movePersonStore;
  // Sorts on last name. Slightly weird interaction for the user
  console.log('studygroup.persons', studygroup.persons);

  const sortedPersons = studygroup.persons; //.sort(personSorter);

  // console.log('render group', studygroup.id);
  return (
    <DropListContainer>
      <StudygroupHeader studygroup={studygroup} isHeadleader={isHeadleader} />
      <Droppable droppableId={String(studygroup.id)}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {sortedPersons.map((item, index) => (
              <Draggable
                key={item.id}
                draggableId={String(item.id)}
                index={index}
                isDragDisabled={!isHeadleader}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style,
                    )}
                  >
                    {getPersonDisplayName(item)}
                    <FlexRowAlignCenter>
                      {isPersonLeader(item) && (
                        <Tooltip title={item.role.name}>
                          <StarIcon />
                        </Tooltip>
                      )}
                      {isAdmin ? (
                        <MUIIconButton
                          color="secondary"
                          onClick={() => {
                            movePersonStore.setActivePerson(item);
                          }}
                        >
                          <SettingsIcon />
                        </MUIIconButton>
                      ) : null}
                    </FlexRowAlignCenter>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DropListContainer>
  );
});

const StudygroupHeader = observer(function StudygroupHeader({
  studygroup,
  isHeadleader,
}: {
  studygroup: StudyGroup;
  isHeadleader: boolean;
}) {
  const stores = useStores();
  const modalStore = stores.modalStore;
  // const [headerTitle, setHeaderTitle] = useSyncState(studygroup.name);
  const [updateHeader] = useUpdateStudygroupMutation();
  const del = useDeleteList(studygroup);

  return (
    <HeaderContainer>
      <DropListHeader id={`studygroup-${studygroup.id}`}>
        <StyledPlainInput
          type="text"
          disabled={!!studygroup.church_id}
          onBlur={(e) => {
            const name = e.target.value;

            if (name === studygroup.name) {
              return;
            }
            updateHeader({
              variables: {
                id: studygroup.id,
                name: name,
              },
            });
          }}
          defaultValue={`${studygroup.church_id ? 'Startgrupp: ' : ''}${
            studygroup.name
          }`}
        />
        {isHeadleader && !studygroup.church_id && (
          <StyledDeleteButton
            onClick={() => {
              modalStore
                .confirm(
                  `Är du säker på att du vill ta bort ${studygroup.name}?`,
                )
                .then((result) => {
                  if (result) {
                    del();
                  }
                });
            }}
          >
            <DeleteIcon />
          </StyledDeleteButton>
        )}
      </DropListHeader>
    </HeaderContainer>
  );
});
