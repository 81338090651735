import React from 'react';

import { observer } from 'mobx-react';
import { FormControlLabel, Switch } from '@mui/material';

import { FlexEnd } from '../../common/CommonDivs';

export const ActiveOnCampSwitch = observer(function ActiveOnCampSwitch({
  checked,
  onChange,
}: {
  checked: boolean;
  onChange: (e: any, checked: boolean) => void;
}) {
  return (
    <FlexEnd>
      <FormControlLabel
        control={<Switch checked={checked} onChange={onChange} />}
        label={'Är aktiv i Anmälningsformuläret'}
      />
    </FlexEnd>
  );
});
