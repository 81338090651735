import { useEffect, useMemo, useState } from 'react';

import { observer } from 'mobx-react';
import { Paper } from '@mui/material';
import parseISO from 'date-fns/parseISO';
import { NetworkStatus } from '@apollo/client';
import { useRouteLoaderData } from 'react-router-dom';
import { IconButton, Tabs, Tab, Toolbar, Typography } from '@mui/material';

import { styled } from '../../../../../../styles/theme';
import { formatDateString } from '../../../../../../utils/date';
import {
  CampQuery,
  CampTimeTableDocument,
  TimeTableInput,
  TimeTableUpdate,
  useCampTimeTableQuery,
  useCreateTimeTableEntryMutation,
  useDeleteTimeTableEntryMutation,
  useUpdateTimeTableEntryMutation,
} from '../../../../../../generated/graphql';
import { formatDate, toISOString } from '../../../../../../utils/date';
import { getSectionsFromCampTimetable } from '../../../../../../utils/getSectionsFromCampTimetable';
import {
  CacheOperation,
  updateCacheWithCampId,
} from '../../../../../../utils/updateCache';
import { CustomDialog } from '../../../../../../components/common/CustomDialog';
import { ErrorLoading } from '../../../../../../components/common/ErrorLoading';
import { AddTimeTableItemModal } from '../../../../../../components/Modals/CreateTimeTable';
import { TableComponent } from '../../../../../../components/Table/Table';
import { TableColumn } from '../../../../../../types/tableColumn';
import {
  Spacer,
  BluePlusIcon,
} from '../../../../../../components/common/CommonDivs';
import { useStores } from '../../../../../../stores';
import { InputType } from '../../../../../../types/inputTypes';

const headers: TableColumn[] = [
  {
    title: 'Tid',
    accessor: 'date',
    displayer: ({ value }: { value: Date }) => (
      <>{formatDate(value, 'HH:mm')}</>
    ),
    inputType: InputType.Button,
  },
  {
    title: 'Namn',
    accessor: 'name',
    inputType: InputType.Text,
  },
  {
    title: 'Meddelande',
    accessor: 'description',
    inputType: InputType.Textarea,
  },
];

export const AppSchemaPage = observer(function AppSchemaPage() {
  const stores = useStores();
  const modalStore = stores.modalStore;
  const camp = useRouteLoaderData('camp') as CampQuery['camp'];
  const camp_id = camp.camp_id;
  const { data, error, loading, refetch, networkStatus } =
    useCampTimeTableQuery({ variables: { camp_id } });
  const [deleteItem] = useDeleteTimeTableEntryMutation();

  const refetching = networkStatus === NetworkStatus.refetch;

  const [tabIndex, setTabIndex] = useState(0);
  const sections = useMemo(() => getSectionsFromCampTimetable(data), [data]);

  const section = sections && sections[tabIndex];

  useEffect(() => {
    if (!section && tabIndex !== 0) {
      setTabIndex((t) => t - 1);
    }
  }, [section, tabIndex]);

  const [updateItem] = useUpdateTimeTableEntryMutation();

  if (!data) {
    return <ErrorLoading {...{ loading, error }} />;
  }

  const activeDate = section && parseISO(section.title);

  return (
    <>
      <Paper>
        <TimeTableMenu
          sections={sections}
          index={!section ? 0 : tabIndex}
          onChangeIndex={setTabIndex}
          onAddItem={modalStore.addItem}
        />
        {!!section && (
          <TableComponent
            headers={headers}
            rows={section.data}
            onUpdateRow={(id, accessor, newValue) => {
              return updateItem({
                variables: {
                  body: {
                    camp_id,
                    id: id as string,
                    [accessor]: newValue,
                  },
                },
              });
            }}
            onEdit={(row) => modalStore.updateItem(row)}
            onDelete={(row) => {
              //todo: delete row.
              // row.id
              deleteItem({
                variables: {
                  camp_id,
                  timeTableId: String(row.id),
                },
                update: (proxy) =>
                  updateCacheWithCampId({
                    query: CampTimeTableDocument,
                    proxy,
                    method: CacheOperation.DELETE,
                    camp_id,
                    mutationResultObject: null,
                    id: row.id,
                  }),
              });
            }}
          />
        )}

        <AddItem camp_id={camp_id} activeDate={activeDate} />
        <EditItem camp_id={camp_id} />
      </Paper>
    </>
  );
});

const AddItem = observer(function AddItem({
  activeDate,
  camp_id,
}: {
  camp_id: number;
  activeDate: Date;
}) {
  const stores = useStores();
  const modalStore = stores.modalStore;

  const [createItem] = useCreateTimeTableEntryMutation();

  const close = modalStore.closeAddItemModal;

  return (
    <CustomDialog open={modalStore.showingAddItemModal} onClose={close}>
      <AddTimeTableItemModal
        onCancel={close}
        activeDate={activeDate}
        onSubmit={async ({ dates, data }) => {
          data.camp_id = camp_id;

          const entries = dates.map((d) => {
            const entry = { date: toISOString(d), ...data };

            return entry;
          });

          for (const e of entries) {
            await createItem({
              variables: {
                timeTableEntry: e as TimeTableInput,
              },
              update: (proxy, mutationResult) => {
                updateCacheWithCampId({
                  query: CampTimeTableDocument,
                  proxy,
                  method: CacheOperation.CREATE,
                  camp_id,
                  mutationResultObject:
                    mutationResult.data.createTimeTableEntry,
                });
              },
            });
          }
          close();
        }}
      />
    </CustomDialog>
  );
});

const EditItem = observer(function EditItem({ camp_id }) {
  const stores = useStores();
  const modalStore = stores.modalStore;
  const [updateItem] = useUpdateTimeTableEntryMutation();

  const close = modalStore.closeUpdateItemModal;

  return (
    <CustomDialog open={modalStore.showingUpdateItemModal} onClose={close}>
      <AddTimeTableItemModal
        existingItem={modalStore.existingItem}
        onCancel={close}
        onSubmit={({ data, dates }) => {
          data.camp_id = camp_id;
          data.id = modalStore.existingItem.id;
          data.date = toISOString(dates[0]);
          updateItem({
            variables: {
              body: data as TimeTableUpdate,
            },
          });
          close();
        }}
      />
    </CustomDialog>
  );
});

const StyledMenuContainer = styled('div')`
  width: calc(100vw - 288px);
  /* Material ui smUp = 600px */
  @media screen and (max-width: 599px) {
    width: calc(100vw - 48px);
  }
`;

const TimeTableMenu = observer(function TimeTableMenu({
  sections,
  index,
  onChangeIndex,
  onAddItem,
}: {
  index: number;
  onChangeIndex: (index: number) => void;
  onAddItem: () => void;
  sections: {
    title: string; //is a date time string (since unix epoch).
    data: any;
  }[];
}) {
  function handleChange(event, newValue) {
    onChangeIndex(newValue);
  }

  return (
    <StyledMenuContainer>
      <Toolbar>
        <Typography variant="h6" id="tableTitle">
          Schema
        </Typography>
        <Spacer />
        <IconButton
          onClick={() => {
            onAddItem();
          }}
        >
          <BluePlusIcon />
        </IconButton>
      </Toolbar>
      <Tabs
        value={index}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        // className={styles.root}
      >
        {sections.map((s, idx) => {
          return (
            <Tab
              key={idx}
              label={formatDateString(s.title, 'EEEE (dd/MM)')}
            ></Tab>
          );
        })}
      </Tabs>
    </StyledMenuContainer>
  );
});
