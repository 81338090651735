import { DataProxy } from '@apollo/client';

type K = { camp_id: number };

export enum CacheOperation {
  DELETE,
  CREATE,
}

export const updateCacheWithCampId = function updateCacheWithCampId(props: {
  query: any;
  proxy: DataProxy;
  method: CacheOperation;
  camp_id: number;
  mutationResultObject?: any; //if create
  id?: string | number; //if delete
  // order?: 'asc'| 'desc'
}) {
  const {
    query,
    proxy,
    method,
    camp_id,
    mutationResultObject = null,
    id,
  } = props;

  const data = proxy.readQuery<any, K>({
    query,
    variables: {
      camp_id,
    },
  });
  const key = Object.keys(data)[0];
  let item = data[key];
  let newItem: typeof item;
  if (method === CacheOperation.CREATE) {
    if (!item) item = [];
    newItem = item.concat([mutationResultObject]);
  } else if (method === CacheOperation.DELETE) {
    newItem = item.filter((e) => e.id !== id);
  }

  proxy.writeQuery<any, K>({
    query,
    variables: {
      camp_id,
    },
    data: {
      [key]: newItem,
    },
  });
};

// Optional due to graphql code generation that makes all fragment properties optional
type IdObject = { id?: any };

export const updateCache = function updateCache<T, V>(props: {
  variables?: any;
  query: any;
  proxy: DataProxy;
  method?: CacheOperation;

  /** Data key example: event.runsheets. Allows updating a deeper query than root */
  dataPath?: string;

  /** If create */
  mutationResultObject?: any; //if create
  /** IF delete */
  id?: string | number;
  idProperty?: string;
  // order?: 'asc'| 'desc'
}) {
  if (props.id && props.mutationResultObject) {
    throw new Error(
      'Cannot both be a delete and a create. Pass either mutationResultObject or id',
    );
  }
  if (!props.id && !props.mutationResultObject) {
    throw new Error('Pass either mutationResultObject or id');
  }
  let method = props.method;
  // Infer method based on if id is set
  if (!method) {
    method = props.id ? CacheOperation.DELETE : CacheOperation.CREATE;
  }

  const {
    variables,
    query,
    proxy,
    dataPath,
    mutationResultObject = null,
    id,
    idProperty = 'id',
  } = props;

  const data = proxy.readQuery({
    query,
    variables,
  });
  //The data array is the first accessor in data (example data.events: [])
  const key = Object.keys(data)[0];
  const dataArray = data[key];
  let oldItems = dataArray;
  if (dataPath) {
    oldItems = dataArray[dataPath];
  }

  const newItemArray = performUpdate(
    oldItems,
    method,
    mutationResultObject,
    id,
    idProperty,
  );
  let finalResult = newItemArray;
  if (dataPath) {
    finalResult = { ...dataArray, [dataPath]: newItemArray };
  }

  proxy.writeQuery<any, K>({
    query,
    variables,
    data: {
      [key]: finalResult,
    },
  });
};

function performUpdate<T extends IdObject>(
  items: T[],
  method: CacheOperation,
  mutationResultObject: any,
  id: string | number,
  idProperty: string,
) {
  let newItem: typeof items;
  if (method === CacheOperation.CREATE) {
    if (!items) items = [];
    if (Array.isArray(mutationResultObject)) {
      newItem = items.concat(mutationResultObject);
    } else {
      newItem = items.concat([mutationResultObject]);
    }
  } else if (method === CacheOperation.DELETE) {
    newItem = items.filter((existing) => existing[idProperty] !== id);
  }

  return newItem;
}
