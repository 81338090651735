import React from 'react';

import { observer } from 'mobx-react';
import { BarChart, PermMedia, PersonSearch } from '@mui/icons-material';
import { red } from '@mui/material/colors';
import {
  Link,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { useRouteLoaderData } from 'react-router-dom';

import { MeQuery } from '../../generated/graphql';

const mediaMenuItem = {
  name: 'Media',
  to: 'media',
  pathName: '/dashboard/media',
  icon: <PermMedia style={{ color: red[900], width: '20px', height: '20px' }} />,
};

export const secondaryPages: MenuListProps[] = [
  mediaMenuItem,
  {
    name: 'Rapporter',
    to: 'reports',
    pathName: '/dashboard/reports',
    icon: <BarChart style={{ color: red[900], width: '20px', height: '20px'  }} />,
  },
  {
    name: 'Användare',
    to: 'users',
    pathName: '/dashboard/users',
    icon: <PersonSearch style={{ color: red[900], width: '20px', height: '20px'  }} />,
  },
];

export const SecondaryNav = observer(function SecondaryNav() {
  const me = useRouteLoaderData('root') as MeQuery['me'];

  if (!me) {
    return null;
  }

  const isAdmin = me.siteAdmin;

  if (!isAdmin) {
    // if not siteadmins can
    if (me.userAuthorizedAreas) {
      const hasApp = me.userAuthorizedAreas.some((userArea) => {
        return (
          userArea &&
          (userArea.isCampEditor || userArea.authorizedAreas.includes('app'))
        );
      });

      if (hasApp) {
        return <MenuListItem listItem={mediaMenuItem} />;
      }

      return null;
    }
  }

  return (
    <List>
      {secondaryPages.map((p, idx) => (
        <MenuListItem listItem={p} key={p.pathName} />
      ))}
    </List>
  );
});

export type MenuListProps = {
  name: string;
  to: string;
  pathName: string;
  icon: JSX.Element;
};

const MenuListItem = observer(function MenuListItem({
  listItem,
}: {
  listItem: MenuListProps;
}) {
  return (
    <ListItemButton
      component={Link}
      href={listItem.to}
      selected={location.pathname === listItem.pathName}
    >
      <ListItemIcon>{listItem.icon}</ListItemIcon>
      <ListItemText primary={listItem.name} />
    </ListItemButton>
  );
});
