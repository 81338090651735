import React from 'react';

import { observer } from 'mobx-react';

import { styled } from '../../styles/theme';
import { useTimedOutSuccessState } from '../../hooks/useTimedOutSuccessState';

import { DoneIcon } from './index';

export const SavedIconText = observer(function SavedIconText({
  visible,
}: {
  visible: boolean;
}) {
  const showingSaved = useTimedOutSuccessState(visible);

  return (
    <SmallIconText
      icon={<DoneIcon />}
      title={'Sparat!'}
      visible={showingSaved}
    />
  );
});

export const SmallIconText = observer(function SmallIconText({
  icon,
  title,
  visible,
}: {
  icon: React.ReactNode;
  title: string;
  visible: boolean;
}) {
  return (
    <StyledSmallIconText className={visible ? 'visible' : ''}>
      {icon}
      <span>{title}</span>
    </StyledSmallIconText>
  );
});

const StyledSmallIconText = styled('div')`
  display: flex;
  align-items: center;

  opacity: 0;
  transform: translate(0, -2px);
  transition: all 0.2s ease-in-out;

  svg {
    width: 12px;
    height: auto;
    margin-right: 4px;
  }

  &.visible {
    opacity: 1;
    transform: translate(0, 0);
  }
`;
