import { Button, TextField } from '@mui/material';

import { styled } from '../../styles/theme';

export const TableTextInput = styled(TextField)`
  width: 100%;
  height: 100%;
  font-size: 10px;
`;

export const InvisibleButton = styled(Button)`
  width: 100%;
  height: 100%;
  border: 0;
  background: 0;
  font-family: inherit;
  font-size: inherit;
  text-align: left;
  margin-left: 2px;
  padding: 6px 2px 7px;
  min-height: 40px;
  max-height: 400px;
  overflow: hidden;
  cursor: pointer;

  overflow-wrap: break-word;
  white-space: pre-wrap;

  text-transform: none;

  &:hover {
    background: none;
  }
`;

export const EditPostButton = styled(Button)``;
export const AddRowButton = styled(Button)`
  margin: 0;
`;
