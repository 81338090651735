import React, { useEffect, useMemo, useState } from 'react';

import { observer } from 'mobx-react';
import { Grid, ModalProps } from '@mui/material';
import clsx from 'clsx';
import { useRouteLoaderData } from 'react-router-dom';

import {
  AppInfoBoxFragment,
  CampQuery,
  ChurchLinkInfoBoxesDocument,
  useAddChurchLinkInfoBoxMutation,
  useChurchLinkInfoBoxesQuery,
  useUpdateChurchLinkInfoBoxMutation,
} from '../../../generated/graphql';
import { useModalRoute } from '../../../hooks';
import { reorder } from '../../../utils/arrayutils';
import { CacheOperation, updateCache } from '../../../utils/updateCache';
import {
  BorderedBox,
  FlexColumn,
  StyledModalView,
} from '../../common/CommonDivs';
import { CustomDialog } from '../../common/CustomDialog';
import { DragList } from '../../drag-list';
import { ImportIcon, SortIcon } from '../../icons';
import {
  AddButton,
  ButtonGroup,
  CloseButton,
  IconButton,
} from '../../icons/IconButton';
import { StyledRoleBox } from '../../item-card';
import { StyledItemListContainer, StyledModalHeader } from '../common';

import { InfoboxForm } from './InfoboxForm';
import { ImportInfobox } from './ImportInfobox';

const NEW_ITEM_NAME = 'Ny infobox';

const TITLE = 'Extra-info boxar';

export const InfoBoxModal = observer(function InfoBoxModal(
  props: Omit<ModalProps, 'children'>,
) {
  const [selectedItem, setSelectedItem] = useState<AppInfoBoxFragment>();

  const [importActive, setImportActive] = useState(false);
  const toggleImport = () => setImportActive((s) => !s);
  const [sortActive, setSortActive] = useState(false);

  const toggleSort = () => {
    if (sortActive) {
      setSortActive(false);
    } else {
      setSortActive(true);
    }
  };

  const camp = useRouteLoaderData('camp') as CampQuery['camp'];
  const [addItem] = useAddChurchLinkInfoBoxMutation();

  const handleAddItem = () => {
    addItem({
      variables: {
        camp_id: camp.camp_id,
        body: {
          title: NEW_ITEM_NAME,
          description: '',
          isHeadLeader: false,
          isLeader: false,
          link: '',
          linkText: '',
        },
      },
      update: (proxy: any, result: { data: { addChurchLinkInfoBox: any } }) => {
        updateCache({
          method: CacheOperation.CREATE,
          query: ChurchLinkInfoBoxesDocument,
          proxy,
          mutationResultObject: result.data.addChurchLinkInfoBox,
          variables: {
            camp_id: camp.camp_id,
          },
        });
      },
    }).then(({ data }: { data: any }) => {
      setSelectedItem(data.addChurchLinkInfoBox);
    });
  };

  const importList = <ImportInfobox />;
  // const importList = <ImportFromCamp collection={collection} />;
  const itemForm = (
    <InfoboxForm
      selectedItem={selectedItem}
      setSelectedItem={setSelectedItem}
    />
  );

  return (
    <CustomDialog {...props} maxWidth={'lg'}>
      <StyledModalHeader>
        <ButtonGroup>
          <IconButton
            title={'Importera'}
            icon={<ImportIcon />}
            active={importActive}
            onClick={toggleImport}
          />
          <IconButton
            title={'Sortera'}
            icon={<SortIcon />}
            active={sortActive}
            onClick={toggleSort}
          />
          <AddButton onClick={handleAddItem} />
        </ButtonGroup>
        <h2>{TITLE}</h2>
        <CloseButton
          onClick={(e) => {
            props.onClose(e, 'backdropClick');
          }}
        />
      </StyledModalHeader>
      <StyledModalView>
        <Grid container spacing={3}>
          <Grid
            item
            xs={4}
            className={clsx('animate', { active: importActive })}
          >
            <BorderedBox>
              <FlexColumn>{importList}</FlexColumn>
            </BorderedBox>
          </Grid>

          <Grid item xs={8}>
            <ItemList
              selectedItem={selectedItem}
              setSelectedItem={setSelectedItem}
              sortActive={sortActive}
            />
          </Grid>

          <Grid
            item
            xs={4}
            className={clsx('animate', { active: !importActive })}
          >
            <BorderedBox>{itemForm}</BorderedBox>
          </Grid>
        </Grid>
      </StyledModalView>
    </CustomDialog>
  );
});

const ItemList = observer(function ItemList({
  selectedItem,
  setSelectedItem,
  sortActive,
}: {
  selectedItem: any;
  setSelectedItem: any;
  sortActive: any;
}) {
  const camp = useRouteLoaderData('camp') as CampQuery['camp'];
  const camp_id = camp.camp_id;

  // get items
  const { data, refetch } = useChurchLinkInfoBoxesQuery({
    variables: {
      camp_id,
    },
    skip: camp_id === -1,
  });

  const items = useMemo(() => {
    return data && data.churchLinkInfoBoxes
      ? data.churchLinkInfoBoxes.sort((a, b) => a.sortId - b.sortId)
      : [];
  }, [data]);

  useEffect(() => {
    if (!selectedItem && items.length) {
      setSelectedItem(items[0]);
    }
  }, [selectedItem, items, setSelectedItem]);

  const [update] = useUpdateChurchLinkInfoBoxMutation();

  const handleReorder = (from: number, to: number) => {
    console.log(from, to);
    const newItems = reorder(items, from, to);
    const promises = newItems.map(async (item: any, idx) => {
      return await update({
        variables: {
          id: item.id,
          body: {
            sortId: idx,
          },
        },
        optimisticResponse: {
          updateChurchLinkInfoBox: {
            __typename: 'ChurchLinkInfoBox',
            ...item,
            sortId: idx,
          },
        },
      });
    });

    Promise.all([promises]).then(() => {
      refetch();
    });
  };

  if (sortActive) {
    const draglistItems = items.map((i) => ({
      id: i.id,
      name: i.title,
    }));

    return (
      // <StyledItemListContainer className="no_scrollbar ">
      <DragList items={draglistItems} reorder={handleReorder} />
      // </StyledItemListContainer>
    );
  }

  return (
    <StyledItemListContainer className="no_scrollbar ">
      <Grid container wrap={'wrap'} spacing={3}>
        {items.map((item) => (
          <Item
            key={item.id}
            item={item}
            setSelectedItem={setSelectedItem}
            isSelected={selectedItem ? item.id === selectedItem.id : false}
          />
        ))}
      </Grid>
    </StyledItemListContainer>
  );
});

const Item = observer(function Item({
  item,
  setSelectedItem,
  isSelected,
}: {
  item: AppInfoBoxFragment;
  setSelectedItem: any;
  isSelected: boolean;
}) {
  return (
    <Grid item xs={12} sm={4} md={4}>
      <StyledRoleBox
        success={isSelected ? true : false}
        onClick={() => {
          setSelectedItem(item);
        }}
      >
        {item.title}
      </StyledRoleBox>
    </Grid>
  );
});
