import { CampColumn } from '../../generated/graphql';
import { ExtendedCampColumn } from '../../types/extendedCampColumn';

export const isDeleteColumn = function isDeleteColumn(
  column?: ExtendedCampColumn,
) {
  return column?.name === 'deleted';
};

export const campColumnsSort = function campColumnsSort(
  colA: CampColumn,
  colB: CampColumn,
) {
  return (colA.adminListOrder as number) - (colB.adminListOrder as number);
};
