import { useRef, useEffect } from 'react';

export const useLatestValue = function useLatestValue<T>(val: T) {
  const latestValueRef = useRef<T>();

  useEffect(() => {
    if (val) {
      latestValueRef.current = val;
    }
  }, [val]);

  return val || latestValueRef.current;
};
