import { useState, useEffect } from 'react';

import { useRouteLoaderData } from 'react-router-dom';

import {
  AppCampCollectionFragment,
  useUpdateCampCollectionMutation,
  CampCollections,
  useDeleteCampCollectionItemMutation,
  CampCollectionDocument,
  CampQuery,
} from '../../generated/graphql';
import { updateCache, CacheOperation } from '../../utils/updateCache';

export const useUpdateCampCollection = function useUpdateCampCollection({
  item,
  collection,
}: {
  item: AppCampCollectionFragment;
  collection?: CampCollections;
}) {
  const [state, setState] = useState<AppCampCollectionFragment>();
  const [hasSaved, setSaved] = useState(false);

  useEffect(() => {
    setState(item);
  }, [item]);

  const [update] = useUpdateCampCollectionMutation();

  const [del] = useDeleteCampCollectionItemMutation();
  const camp = useRouteLoaderData('camp') as CampQuery['camp'];

  const deleteItem = async () => {
    if (!state) throw Error('State not set');

    await del({
      variables: {
        id: state.id,
        collection: state.collection,
      },
      update: (proxy: any) => {
        updateCache({
          id: state.id,
          method: CacheOperation.DELETE,
          query: CampCollectionDocument,
          proxy,
          variables: {
            collection: state.collection,
            camp_id: camp.camp_id,
          },
        });
      },
    });
  };

  const handleChange = (e: any) => {
    const input = e.target as HTMLInputElement;
    let name = input.name;
    let val = name === 'enabled' ? input.checked : input.value;


    // We patch the enabled prop, because in the database, we have stored a deleted flag intead;
    // TODO: migrate the deleted flag to enabled = !deleted
    if (name === 'enabled') {
      name = 'deleted';
      val = !val;
    }
    setSaved(false);
    const newState = { ...(state as AppCampCollectionFragment), [name]: val };
    setState(newState);

    return newState;
  };

  const handleSave = (state: AppCampCollectionFragment) => {
    const { collection, deleted, id, name, description } = state;

    update({
      variables: {
        collection,
        input: {
          deleted,
          id,
          camp_id: camp.camp_id,
          name,
          description,
        },
      },
    })
      .then(() => setSaved(true))
      .catch();
  };

  const handleBlur = () => {
    handleSave(state as AppCampCollectionFragment);
  };

  const handleSwitchChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => void = (e) => {
    handleSave(handleChange(e));
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      (e.target as HTMLInputElement).blur();
    }
  };

  return {
    state,
    hasSaved,
    handleChange,
    handleSwitchChange,
    onKeyDown,
    handleBlur,
    deleteItem,
  };
};
