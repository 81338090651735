import { Button, Input, styled } from '@mui/material';

const grid = 8;
const borderRadius = 0;
const ROW_WIDTH = 240;

export const FlexWrapper = styled('div')`
  display: flex;
  /* height: 500px; */
  min-height: 500px;
  overflow-x: auto;
  /* Outlines look weird without this */
  padding: 4px 0;
`;
export const DropListContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin-right: 16px;
  padding: 1px;
  width: ${ROW_WIDTH}px;
`;
export const HeaderContainer = styled('div')`
  position: relative;
`;
export const StyledPlainInput = styled(Input)`
  background: transparent;
  border: 0;
  flex: 1;
  padding: 6px;
  margin: 0 6px;

  .MuiInputBase-input {
    color: white;
    font-weight: 700;
    font-size: 16px;

    &:focus {
      background: rgba(255, 255, 255, 0.2);
      outline: 0;
    }
    &:disabled {
      color: white;
      -webkit-text-fill-color: white;
    }
  }
`;
export const StyledDeleteButton = styled(Button)`
  border: 0;
  background: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;

  &:focus {
    outline: 1px auto white;
  }
`;
export const DropListHeader = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: ${borderRadius}px;
  border-top-right-radius: ${borderRadius}px;
  border: 1px solid ${(p) => p.theme.palette.primary.main};
  background-color: ${(p) => p.theme.palette.primary.main};
  color: white;
  height: 44px;
  font-size: 0.875rem;
  font-weight: 700;
  z-index: 1;
  overflow: hidden;
  width: ${ROW_WIDTH}px;

  &:focus {
    outline: 1px auto #ce4c3d;
  }
`;

export const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0`,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',

  // change background colour if dragging
  background: 'white',
  border: isDragging ? '2px solid #ce4d3e' : '2px solid #eee',

  // styles we need to apply on draggables
  ...draggableStyle,
});

export const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? '#eee' : 'transparent',
  width: ROW_WIDTH,
  flex: '1 1 auto',
});
