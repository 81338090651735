import React, { useState } from 'react';

import { observer } from 'mobx-react';
import { Box, Button, DialogActions, Typography } from '@mui/material';

import { FullWidthForm } from '../common/CommonDivs';
import { TextFieldWithCharacterCount } from '../textfield';
import { DatePicker } from '../textfield/DateTimePicker';

interface CreateNewCampProps {
  onSubmit: (data) => void;
  onCancel: () => void;
}
export const CreateNewCamp = observer(function CreateNewCamp(
  props: CreateNewCampProps,
) {
  const { onSubmit, onCancel } = props;

  const [state, setState] = useState({
    name: '',
    start_date: new Date().toISOString(),
    end_date: new Date().toISOString(),
    last_signup_date: new Date().toISOString(),
  });

  const handleChange = (e) => {
    const input = e.target as HTMLInputElement;
    const val = input.value;
    const name = input.name;
    const newState = { ...state, [name]: val };
    setState(newState);
  };
  const handleBlur = () => {};

  const submit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // const data = convertFormData(e.target);
    // onSubmit(data);
    onSubmit(state);
  };

  return (
    <Box p={4} paddingBottom={1}>
      <Typography component="h2" variant="h5">
        {'Skapa nytt läger'}
      </Typography>
      <FullWidthForm noValidate onSubmit={submit}>
        <TextFieldWithCharacterCount
          label={'Evenemangets namn'}
          name="name"
          maxLength={50}
          value={state.name}
          onChange={handleChange}
        />
        <DatePicker
          label={'Start datum'}
          value={state.start_date}
          name="start_date"
          handleChange={handleChange}
          handleClose={handleBlur}
        />
        <DatePicker
          label={'Slut datum'}
          value={state.end_date}
          name="end_date"
          handleClose={handleBlur}
          handleChange={handleChange}
        />
        <DatePicker
          label={'Sista anmälningsdag'}
          value={state.last_signup_date}
          name="last_signup_date"
          handleClose={handleBlur}
          handleChange={handleChange}
        />

        <DialogActions>
          <Button color="secondary" type="button" onClick={onCancel}>
            {'Avbryt'}
          </Button>
          <Button color="primary" type="submit" disabled={!state.name}>
            {'Skapa nytt evenemang'}
          </Button>
        </DialogActions>
      </FullWidthForm>
    </Box>
  );
});
