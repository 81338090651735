import React, { ReactNode, useState } from 'react';

import { observer } from 'mobx-react';
import clsx from 'clsx';
import { Breakpoint, Grid, ModalProps } from '@mui/material';

import { CustomDialog } from '../../common/CustomDialog';
import {
  IconButton,
  AddButton,
  CloseButton,
  ButtonGroup,
} from '../../icons/IconButton';
import {
  PaddedModalView,
  BorderedBox,
  FlexColumn,
} from '../../common/CommonDivs';
import { ImportIcon } from '../../icons';

import { StyledModalHeader } from './StyledModalHeader';

interface CampCollectionModalProps extends ModalProps {
  addItem?: () => void;
  importList: ReactNode;
  itemList: ReactNode;
  itemForm: ReactNode;
  maxWidth?: Breakpoint | false;
}

export const CampCollectionModalContainer = observer(
  function CampCollectionModalContainer({
    maxWidth = 'lg',
    open,
    onClose,
    title,
    addItem,
    importList,
    itemList,
    itemForm,
    children,
  }: CampCollectionModalProps) {
    const [importActive, setImportActive] = useState(false);
    const toggleImport = () => setImportActive((s) => !s);

    return (
      <CustomDialog open={open} onClose={onClose} maxWidth={maxWidth}>
        <StyledModalHeader>
          <ButtonGroup>
            <IconButton
              title={'Importera'}
              icon={<ImportIcon />}
              active={importActive}
              onClick={toggleImport}
            />
            <AddButton onClick={addItem} />
          </ButtonGroup>
          <h2>{title}</h2>
          <CloseButton
            onClick={(e) => {
              onClose(e, 'backdropClick');
            }}
          />
        </StyledModalHeader>
        <PaddedModalView>
          <Grid container spacing={3}>
            <Grid
              item
              xs={4}
              className={clsx('animate', { active: importActive })}
            >
              <BorderedBox>
                <FlexColumn>{importList}</FlexColumn>
              </BorderedBox>
            </Grid>

            <Grid item xs={8}>
              {itemList}
            </Grid>

            <Grid
              item
              xs={4}
              className={clsx('animate', { active: !importActive })}
            >
              <BorderedBox>{itemForm}</BorderedBox>
            </Grid>
          </Grid>
          {children}
        </PaddedModalView>
      </CustomDialog>
    );
  },
);
