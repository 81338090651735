import React from 'react';

import { observer } from 'mobx-react';
import { Navigate } from 'react-router';
import { CircularProgress, Typography } from '@mui/material';

import { useStudygroupCollectionQuery } from '../../../../generated/graphql';
import { StudyGroupList } from '../../../../components/studygroup/StudyGroup';
import { FlexRow } from '../../../../components/common/CommonDivs';
import { useStores } from '../../../../stores';

export const ChurchLinkStudygroupsPage = observer(
  function ChurchLinkStudygroupsPage() {
    const stores = useStores();
    const linkStore = stores.linkStore;
    const churchLink = linkStore.activeLink;
    const isHeadleader = linkStore.isHeadleader;
    const isStudygroupActive = linkStore.activeLink?.camp?.studygroups_active;

    const { data, loading } = useStudygroupCollectionQuery({
      variables: {
        id: churchLink && churchLink.group_collection_id,
      },
      skip: !churchLink || !churchLink.group_collection_id,
    });

    if (!isStudygroupActive) {
      return <Navigate to=".." />;
    }

    if (loading) {
      return (
        <FlexRow>
          <CircularProgress />
        </FlexRow>
      );
    }

    if (!data) {
      return null;
    }

    console.log('render group list route');

    return (
      <StudyGroupList
        groupCollection={data.studygroupCollection}
        isHeadleader={isHeadleader}
      />
    );
  },
);
