import { observer } from 'mobx-react';
import { Grid, TextField } from '@mui/material';

import { getLeaderLink } from '../../../config';
import { useChurchForm } from '../../../hooks/camps';
import { DashboardItem } from '../../../components/dashboard/DashboardItem';
import { BellIcon, LinkIcon } from '../../../components/icons';
import { AppCampChurchFragment } from '../../../generated/graphql';
import { churchLinkMenuItems } from '../../../components/shared-links/churchLinkMenuItems';
import { useStores } from '../../../stores';

export const ChurchLinkMenu = observer(function Menu() {
  const stores = useStores();
  const linkStore = stores.linkStore;

  const isHeadleader = linkStore.isHeadleader;

  const infoBoxes = linkStore.activeLink?.infoboxes ?? [];

  const isStudygroupActive = linkStore.activeLink?.camp?.studygroups_active;

  let menuItems = churchLinkMenuItems;

  if (!isStudygroupActive) {
    menuItems = menuItems.filter((i) => i.path !== 'studygroups');
  }

  if (!linkStore.activeLink) return null;

  return (
    <>
      <Grid container spacing={2}>
        {menuItems.map((item) => (
          <DashboardItem key={item.path || item.name} item={item} />
        ))}
        {isHeadleader && (
          <DashboardItem
            item={{
              name: 'Ledarlänk',
              icon: <LinkIcon />,
              DashboardContent: () => <LeaderLink />,
            }}
          />
        )}
        {infoBoxes?.map((infobox) => {
          if (!infobox) return;

          return (
            <DashboardItem
              key={infobox.id}
              item={{
                name: infobox.title!,
                icon: <BellIcon />,
                DashboardContent: () => (
                  <>
                    <p>{infobox.description}</p>
                    {infobox.link && (
                      <a
                        href={infobox.link}
                        target="_blank"
                        rel="noreferrer noopener"
                        className="MuiButton-containedPrimary MuiButton-root mt-2 text-decoration-none d-inline-block"
                      >
                        {infobox.linkText}
                      </a>
                    )}
                  </>
                ),
              }}
            />
          );
        })}
      </Grid>
    </>
  );
});

const LeaderLink = observer(function LeaderLink() {
  const stores = useStores();
  const linkStore = stores.linkStore;
  const churchLink = linkStore.activeLink;

  const { state } = useChurchForm({
    selectedChurch: churchLink as unknown as AppCampChurchFragment,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setChurch: () => {},
  });

  if (!state) {
    return <div>Ingen vald församling</div>;
  }

  const { leader_link } = state;

  return (
    <div>
      <TextField
        margin="normal"
        fullWidth
        label={'Ledarlänk'}
        value={getLeaderLink(leader_link!)}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onChange={() => {}}
      />
      <p>Dela denna länk med dina ledare</p>
    </div>
  );
});
