import { useMemo, useEffect } from 'react';

import { observer } from 'mobx-react';

import { useChurchLinkInfoBoxesQuery } from '../../../generated/graphql';
import { useStores } from '../../../stores';

export const FetchChurchLinkInfoBoxes = observer(
  function FetchChurchLinkInfoBoxes({
    selectedCampId,
  }: {
    selectedCampId: number;
  }) {
    const stores = useStores();
    const importStore = stores.importStore;

    const { data } = useChurchLinkInfoBoxesQuery({
      variables: {
        camp_id: selectedCampId,
      },
      skip: selectedCampId === -1,
    });

    const items = useMemo(() => {
      return data?.churchLinkInfoBoxes ?? [];
    }, [data]);

    useEffect(() => {
      importStore.setAllItems({
        items: items.map((i) => ({
          id: i.id,
          name: i.title,
          meta: i,
        })),
      });
    }, [importStore, items]);

    return null;
  },
);
