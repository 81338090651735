import React from 'react';

import { observer } from 'mobx-react';
import { FormControl, MenuItem, Select } from '@mui/material';
import { useRouteLoaderData } from 'react-router-dom';

import {
  CampChurch,
  CampQuery,
  ChurchLink,
  useCampChurchesWithStudygroupQuery,
} from '../../generated/graphql';

interface ChurchListDropdownProps<T extends ChurchLink | CampChurch> {
  selected: T;
  setSelected: React.Dispatch<React.SetStateAction<T>>;
  showEmpty?: boolean;
}

export const ChurchListDropdown = observer(function ChurchListDropdown<
  T extends ChurchLink | CampChurch,
>({ selected, setSelected, showEmpty = false }: ChurchListDropdownProps<T>) {
  const churches = useChurches(showEmpty);

  const value = !!selected?.id ? String(selected.id) : '';

  if (!churches.length) return null;

  return (
    <>
      <FormControl sx={{ minWidth: 120, maxWidth: 300 }}>
        {/* <InputLabel htmlFor="select-church">--Välj församling--</InputLabel> */}
        <Select
          variant="standard"
          displayEmpty
          value={value}
          onChange={(e) => {
            console.log('onChange', e);

            const churchId = Number(e.target.value);
            const selectedChurch = churches.find((c) => c.id === churchId);
            setSelected(selectedChurch as unknown as T);
          }}
          inputProps={{
            // id: 'select-church',
            'aria-label': 'Välj församling',
          }}
        >
          <MenuItem disabled value="">
            <em>--Välj församling--</em>
          </MenuItem>
          {churches.map((church) => (
            <MenuItem key={church.id} value={String(church.id)}>
              {church.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
});

// Only those that have a groupcollection
function useChurches(showEmpty: boolean) {
  const camp = useRouteLoaderData('camp') as CampQuery['camp'];
  const camp_id = camp.camp_id;
  const { data } = useCampChurchesWithStudygroupQuery({
    variables: {
      camp_id,
    },
  });

  const churches =
    data?.campChurches?.filter((c) => !!c.group_collection_id) ?? [];

  if (!showEmpty) {
    return churches.filter((c) => {
      const studygroups = c.studygroupCollection.studygroups;

      return studygroups.some((studygroup) =>
        Boolean(studygroup.persons.length),
      );
    });
  }

  return churches;
}
