import React from 'react';

import { observer } from 'mobx-react';
import { Dialog, DialogProps } from '@mui/material';

interface CustomDialogProps extends DialogProps {}

export const CustomDialog = observer(function CustomDialog({
  children,
  maxWidth = 'xs',
  ...props
}: CustomDialogProps) {
  return (
    <Dialog
      fullWidth
      aria-labelledby="modal-title"
      {...props}
      maxWidth={maxWidth}
    >
      {children}
    </Dialog>
  );
});
