import React from 'react';

import { observer } from 'mobx-react';
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  Droppable,
  DroppableProvided,
  DropResult,
} from 'react-beautiful-dnd';

import { styled } from '../../styles/theme';
import {
  FlexWrapper,
  DropListContainer,
  DropListHeader,
  HeaderContainer,
} from '../studygroup/styles';

const StyledColumnList = styled('div')`
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  height: 100%;
  /* background: #eee; */
  > div {
    height: 100%;
  }
`;

export type DragListColumn = {
  id: string;
  name: string;
  items: { id: any; name: string }[];
};
export const MultipleDragList = observer(function ColumnList<
  T extends DragListColumn,
>({
  columns,
  reorder,
}: {
  columns: T[];
  reorder: (sourceItem: any, to: string) => void;
}) {
  const handleDragEnd = ({ source, destination }: DropResult) => {
    if (!destination) return;
    const from = source.droppableId;
    const to = destination.droppableId;

    //We skip reorders
    if (to === from) return;

    const sourceArray = columns.find((c) => c.id === from).items;

    const sourceItem = sourceArray[source.index];
    const destArray = columns.find((c) => c.id === to).items;

    reorder(sourceItem, to);
  };

  return (
    <FlexWrapper>
      <DragDropContext onDragEnd={handleDragEnd}>
        {columns.map((col) => {
          return (
            <DropListContainer key={col.id}>
              <HeaderContainer>
                <DropListHeader>{col.name}</DropListHeader>
              </HeaderContainer>
              <StyledColumnList>
                <Droppable droppableId={String(col.id)}>
                  {(droppableProvided: DroppableProvided) => (
                    <div
                      {...droppableProvided.droppableProps}
                      ref={(ref) => {
                        droppableProvided.innerRef(ref);
                      }}
                    >
                      <DraggableList items={col.items} />
                      {droppableProvided.placeholder}
                    </div>
                  )}
                </Droppable>
              </StyledColumnList>
            </DropListContainer>
          );
        })}
      </DragDropContext>
    </FlexWrapper>
  );
});

const DraggableList = observer(function DraggableList<
  T extends { id; name: string },
>({ items }) {
  return (
    <>
      {items.map((item: T, idx) => {
        return (
          <Draggable draggableId={String(item.id)} index={idx} key={item.id}>
            {(provided, snapshot) => {
              const { dragHandleProps, draggableProps, innerRef } = provided;
              const { isDragging } = snapshot;

              return (
                <DraggableColumn
                  {...{
                    item: item,
                    dragHandleProps,
                    draggableProps,
                    innerRef,
                    isDragging,
                  }}
                />
              );
            }}
          </Draggable>
        );
      })}
    </>
  );
});

const DraggableColumn = observer(function DraggableColumn<
  T extends { name: string },
>({
  item,
  isDragging,
  ...draggable
}: {
  item: T;
  isDragging?: boolean;
} & DraggableProvided) {
  const columnTitle = item.name;

  return (
    <div
      ref={draggable.innerRef}
      style={{
        display: 'flex',
        alignItems: 'center',
        background: isDragging ? `background: rgba(0,0,0,.2);` : '',
      }}
      {...draggable.draggableProps}
      {...draggable.dragHandleProps}
    >
      <div className={'drag-item'}>
        <span>{columnTitle}</span>
      </div>
    </div>
  );
});
