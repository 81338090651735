import { action, computed, makeObservable, observable } from 'mobx';

import {
  AddCampCollectionItemsDocument,
  AddCampCollectionItemsMutation,
  AddCampCollectionItemsMutationVariables,
  Camp,
  CampCollectionDocument,
  CampCollectionInput,
  CampCollections,
} from '../generated/graphql';
import { updateCache } from '../utils/updateCache';

import { client } from '.';

/*
TODO: Refactor.
Uses two global stores. We should refactor this by creating this store in the apps entry point
- MainStore
- ActiveCampStore
 */

interface CampCollection {
  id?: number;
  name?: string;

  collection?: CampCollections;
  camp_id?: number;
  description?: string;
  deleted?: boolean;
  sortId?: number;

  // Used in import gdpr
  meta?: any;
}

export class ImportCollectionStore {
  constructor() {
    makeObservable(this, {
      activeCollection: observable,
      allItems: observable,
      setAllItems: action,
      clearItems: action,
      selectedItems: observable,
      newCampCollectionImportItems: action,
      isAllSelected: computed,
      isSomeSelected: computed,
      toggleSelectAllItems: action,
      toggleItem: action,
      addItems: action,
    });
  }

  activeCollection?: CampCollections;
  allItems: CampCollection[] = [];

  setAllItems = ({ items }: { items: CampCollection[] }) => {
    this.allItems = items;
    console.log(
      `replacing import store with items: ${items.length}. Collection: ${this.activeCollection}`,
    );
  };

  readonly selectedItems = observable.array<CampCollection>([]);
  clearItems = () => {
    this.selectedItems.clear();
  };

  /** Only reuses the names of the the collection and sets a new camp_id based on parameter */
  // Used by churches and gdpr
  newCampCollectionImportItems = (camp_id: number) => {
    return this.selectedItems.map((c) => ({
      camp_id,
      name: c.name,
    }));
  };

  get isAllSelected() {
    return this.allItems.length === this.selectedItems.length;
  }

  get isSomeSelected() {
    return !this.isAllSelected && this.selectedItems.length > 0;
  }

  toggleSelectAllItems = (all: boolean) => {
    if (all) {
      this.selectedItems.replace(this.allItems);
    } else {
      this.selectedItems.clear();
    }
  };

  toggleItem = (item: CampCollection) => {
    const existing = this.selectedItems.find((c) => c.id === item.id);

    if (existing) {
      this.selectedItems.remove(existing);
    } else {
      this.selectedItems.push(item);
    }
  };
  addItems = async (input: CampCollectionInput[], selectedCamp: Camp) => {
    const variables: AddCampCollectionItemsMutationVariables = {
      collection: this.activeCollection!,
      input,
    };

    await client.mutate<AddCampCollectionItemsMutation>({
      mutation: AddCampCollectionItemsDocument,
      variables,
      update: (proxy, result) => {
        updateCache({
          query: CampCollectionDocument,
          proxy,
          mutationResultObject: result.data!.addCampCollectionItems,
          variables: {
            camp_id: selectedCamp!.camp_id,
            collection: this.activeCollection,
          },
        });
      },
    });
    this.clearItems();
  };
}
