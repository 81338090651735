import { observer } from 'mobx-react';
import { Grid } from '@mui/material';
import { useRouteLoaderData } from 'react-router-dom';

import { DashboardItem } from '../../../../components/dashboard/DashboardItem';
import { CampQuery } from '../../../../generated/graphql';
import { authorizedCampPages } from '../../../../helpers/camps';

export const CampDashboardPage = observer(function CampDashboardPage() {
  const camp = useRouteLoaderData('camp') as CampQuery['camp'];
  const me = useRouteLoaderData('root');

  if (!camp) {
    return null;
  }

  const displayedMenuItems = authorizedCampPages(me, camp.camp_id!);

  return (
    <>
      <Grid container spacing={2}>
        {displayedMenuItems.map((item) => (
          <DashboardItem key={item.path} item={item!} />
        ))}
      </Grid>
    </>
  );
});
