import React, { useState, useEffect, PropsWithChildren } from 'react';

import { observer } from 'mobx-react';
import { ApolloError } from '@apollo/client';
import { CircularProgress, Container, Typography } from '@mui/material';

import { ErrorIcon } from '../icons';

export const ErrorLoading = observer(function ErrorLoading({
  error,
  loading,
  children,
}: PropsWithChildren<{
  error: ApolloError;
  loading?: boolean;
}>) {
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    if (!loading) return;

    //if loading is active, wait a while until this is rendered.
    const timer = setTimeout(() => {
      setShowLoading(true);
    }, 800);

    return () => clearTimeout(timer);
  }, [loading]);

  if (error) {
    return (
      <Container
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
        }}
      >
        <ErrorIcon />
        <Typography>{error.message}</Typography>
      </Container>
    );
  }

  if (loading) {
    // if it's loading, but before showLoading timeout, we don't want a flash
    // of unpopulated data components
    if (!showLoading) return null;

    return (
      <Container
        sx={{
          width: '100%',
          height: '200px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        <CircularProgress />
      </Container>
    );
  }

  return children;
});
