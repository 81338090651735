import { Fragment, useEffect } from 'react';

import { observer } from 'mobx-react';
import { useRouteLoaderData } from 'react-router-dom';

import { CampQuery, useSharedLinksQuery } from '../../generated/graphql';
import {
  DividerPrimary,
  DividerSecondary,
  StyledDashboardTable,
} from '../common/CommonDivs';
import { formatDateString } from '../../utils/date';

export const SharedLinksDashboard = observer(function SharedLinksDashboard() {
  const camp = useRouteLoaderData('camp') as CampQuery['camp'];

  const camp_id = camp.camp_id;
  const { data, refetch } = useSharedLinksQuery({
    variables: {
      camp_id,
      limit: 3,
      orderBy: 'lastUsed',
    },
    skip: !camp,
  });

  useEffect(() => {
    refetch();
  }, [refetch]);

  if (!data) {
    return null;
  }

  const items =
    data.sharedLinks &&
    data.sharedLinks
      .slice()
      .sort((a, b) => Number(b.lastUsed) - Number(a.lastUsed));

  return (
    <StyledDashboardTable>
      <div className="table-row">
        <div className="text-bold text-overflow-elipsis flex1">Titel</div>
        <div className="text-caption">{'Senast använd'}</div>
      </div>
      <DividerPrimary />
      {items.map((s) => (
        <Fragment key={s.id}>
          <div className="table-row">
            <div className="text-bold text-overflow-elipsis col-1">
              {s.name}
            </div>

            {s.lastUsed ? (
              <div className="text-caption text-right">
                {formatDateString(s.lastUsed, 'yyyy-MM-dd HH:mm')}
              </div>
            ) : (
              <div className="text-caption text-right">{'Oanvänd'}</div>
            )}
          </div>
          <DividerSecondary />
        </Fragment>
      ))}
    </StyledDashboardTable>
  );
});
