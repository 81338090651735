import { observer } from 'mobx-react';
import { useRouteLoaderData } from 'react-router-dom';

import { styled } from '../../../styles/theme';
import { HOME_URL } from '../../../config';
import {
  CampQuery,
  useCreateOneTimeCodeMutation,
} from '../../../generated/graphql';
import {
  AddButton,
  ButtonGroup,
  CSVIcon,
  DeleteIcon,
  FilterIcon,
  IconButton,
} from '../../icons';
import { mediaMobile } from '../../../styles/theme';
import { useStores } from '../../../stores';

export interface TableHeaderOptions {
  isAdmin?: boolean;
}
export const TableHeaderButtonGroup = observer(
  ({ isAdmin }: TableHeaderOptions) => {
    const stores = useStores();
    const store = stores.personStore;

    return (
      <>
        <StyledTableHeader>
          <span />
          <h3>{`Anmälningslista: ${store.dataLength}`}</h3>
          <span>
            <ButtonGroup>
              <>
                {isAdmin && <CreateUserButton />}
                {isAdmin && (
                  <IconButton
                    title={'Export'}
                    icon={<CSVIcon />}
                    onClick={store.exportCSVFile}
                  />
                )}
                <IconButton
                  title={'Visa Avanmälda'}
                  active={store.showingDeleted}
                  icon={<DeleteIcon />}
                  onClick={store.toggleShowingDeleted}
                />

                {isAdmin && (
                  <IconButton
                    title={'Filter'}
                    active={store.filterOverlayActive}
                    icon={<FilterIcon />}
                    onClick={() => {
                      store.setFilterOverlay(true);
                    }}
                  />
                )}
              </>
            </ButtonGroup>
          </span>
        </StyledTableHeader>
      </>
    );
  },
);

const CreateUserButton = observer(function CreateUserButton() {
  const camp = useRouteLoaderData('camp') as CampQuery['camp'];
  const [create] = useCreateOneTimeCodeMutation();
  let signupUrl = `${HOME_URL}/anmalan/${camp.camp_id}`;
  const createUser = async () => {
    if (!camp.signup) {
      const result = await create();
      const code = result.data.createOneTimeCode;
      signupUrl += `?admin=${code}`;
    }
    window.open(signupUrl, '_blank');
  };

  return <AddButton onClick={createUser} />;
});

const StyledTableHeader = styled('div')`
  background-color: ${(p) => p.theme.palette.primary.main};
  color: white;

  height: 44px;

  ${mediaMobile} {
    height: auto;
  }

  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;

  h3,
  a h3 {
    font-weight: 700;
    margin: 0;
    color: inherit;
    text-align: center;
  }

  > span {
    margin-right: 8px;
  }
`;
