import { Person } from '../../generated/graphql';
import { ExtendedPerson } from '../../types/person';

// This allows introducing new local properties on the person (mobx 4 requires this)
export const createExtendedPerson = (p: Person): ExtendedPerson => {
  return {
    ...p,
    editingColumn: -1,
    isHoveredColumn: false,
  };
};
