import React, { memo } from 'react';

import { observer } from 'mobx-react';
import { Grid, styled, useTheme } from '@mui/material';

import { DashboardItemType } from '../../types/dashboard';

import { DashboardItemHeader } from '.';

export const DashboardItem = observer(function DashboardItem({
  item,
}: {
  item: Partial<DashboardItemType> &
    Omit<DashboardItemType, 'DashboardContent' | 'Component'>;
}): JSX.Element {
  const path = item.path?.replace('/*', '/');

  return (
    <Grid item>
      <StyledDashboardItem>
        <DashboardItemHeader
          primaryIcon={item.icon ? item.icon : undefined}
          name={item.name}
          link={path}
        />
        {item.DashboardContent ? (
          <DashboardIconContent>
            <item.DashboardContent />
          </DashboardIconContent>
        ) : null}
      </StyledDashboardItem>
    </Grid>
  );
});

const StyledDashboardItem = styled('div')`
  background: white;
  border-radius: 0;
  border: 1px solid ${(p) => p.theme.palette.primary.main};
  width: 320px;
  height: 240px;

  display: flex;
  flex-direction: column;
`;

const DashboardIconContent = styled('div')`
  padding: 20px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  color: #555555;
`;
