import React, { useEffect, useState } from 'react';

import { observer } from 'mobx-react';
import { TextField } from '@mui/material';

import {
  AppCampRoleFragment,
  useUpdateCampRoleMutation,
} from '../../../generated/graphql';
import { FlexColumn } from '../../common/CommonDivs';
import { SavedIconText } from '../../icons';
import { LabelSwitch } from '../../switch';

export const RoleForm = observer(function RoleForm({
  campRole,
}: {
  campRole: AppCampRoleFragment;
}) {
  const {
    state,
    saved,
    error,
    handleChange,
    handleSwitchChange,
    handleBlur,
    onKeyDown,
  } = useUpdateRole({ campRole });

  if (!state) {
    return <div>Ingen vald roll</div>;
  }

  const {
    description,
    extraMailInformation,
    receivesExtraMailInformation,
    receivesAppCode,
    enabled,
  } = state;

  return (
    <FlexColumn>
      <h2>{campRole.name}</h2>
      <TextField
        margin="normal"
        fullWidth
        id={'description'}
        name={'description'}
        label={`Extra info i anmälningsformuläret`}
        value={description || ''}
        onChange={handleChange}
        onKeyDown={onKeyDown}
        onBlur={handleBlur}
      />
      <TextField
        multiline
        rows={8}
        margin="normal"
        required={true}
        fullWidth
        disabled={!enabled}
        name={'extraMailInformation'}
        label={'Extra Mail-information'}
        value={extraMailInformation || ''}
        onChange={handleChange}
        onKeyDown={onKeyDown}
        onBlur={handleBlur}
      />
      <LabelSwitch
        name="enabled"
        label={'Är aktiv i Anmälningsformuläret'}
        value={enabled}
        onChange={handleSwitchChange}
      />
      <LabelSwitch
        name="receivesExtraMailInformation"
        label={'Får extra Mail-information'}
        value={receivesExtraMailInformation}
        onChange={handleSwitchChange}
      />
      <LabelSwitch
        name="receivesAppCode"
        label={'Får koden till lägret i Appen'}
        value={receivesAppCode}
        onChange={handleSwitchChange}
      />

      <br />
      <SavedIconText visible={saved} />
      {/* <span>{saved ? 'Sparat!' : ''}</span> */}
      <span>{error ? error.message : ''}</span>
    </FlexColumn>
  );
});

function useUpdateRole({ campRole }: { campRole: any }) {
  // @ts-ignore
  const [state, setState] = useState<AppCampRoleFragment>(null);
  const [saved, setSaved] = useState(false);

  useEffect(() => {
    setState(campRole);
  }, [campRole]);

  const [update, { error }] = useUpdateCampRoleMutation();

  const handleChange = (e: any) => {
    const input = e.target as HTMLInputElement;
    const name = input.name;
    const val = (name == 'enabled' ||
      name == 'receivesExtraMailInformation' ||
      name == 'receivesAppCode'
    ) ? input.checked : input.value;
  

    console.log('name', name);
    console.log('input', input.checked);
    

    setSaved(false);
    const newState = { ...state, [name]: val };
    setState(newState);

    return newState;
  };

  const handleSave = (state: AppCampRoleFragment) => {
    const {
      receivesExtraMailInformation,
      extraMailInformation,
      receivesAppCode,
      description,
      enabled,
    } = state;



    console.log('updating:', state);

    update({
      variables: {
        id: state.id,
        input: {
          extraMailInformation,
          receivesExtraMailInformation,
          receivesAppCode,
          enabled,
          description,
        },
      },
    })
      .then(() => setSaved(true))
      .catch();
  };

  const handleBlur = () => {
    handleSave(state);
  };

  const onKeyDown = (_e: React.KeyboardEvent<HTMLInputElement>) => {
    // if (e.key === 'Enter') {
    //   (e.target as HTMLInputElement).blur();
    // }
  };

  const handleSwitchChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => void = (e) => {
    handleSave(handleChange(e));
  };

  return {
    state,
    saved,
    error,
    handleChange,
    handleSwitchChange,
    handleBlur,
    onKeyDown,
  };
}
