import React, { useMemo } from 'react';

import { observer } from 'mobx-react';
import { Paper } from '@mui/material';
import { useRouteLoaderData } from 'react-router-dom';

import {
  Camp,
  CampQuery,
  PushNotificationInput,
  PushNotificationsDocument,
  PushNotificationUpdate,
  useCreatePushNotificationMutation,
  useDeletePushNotificationMutation,
  usePushNotificationsQuery,
  useUpdatePushNotificationMutation,
} from '../../../../../../generated/graphql';
import {
  CacheOperation,
  updateCacheWithCampId,
} from '../../../../../../utils/updateCache';
import { CustomDialog } from '../../../../../../components/common/CustomDialog';
import { ErrorLoading } from '../../../../../../components/common/ErrorLoading';
import { CreatePushNotification } from '../../../../../../components/Modals/CreatePushNotification';
import {
  TableComponent,
  TableHeader,
} from '../../../../../../components/Table/Table';
import { useStores } from '../../../../../../stores';
import { InputType } from '../../../../../../types/inputTypes';
import { sortByCreatedAtDescending } from '../../../../../../utils/date';

const headers = [
  {
    title: 'Rubrik',
    accessor: 'title',
    inputType: InputType.Text,
  },
  {
    title: 'Meddelande',
    accessor: 'description',
    inputType: InputType.Textarea,
  },
  {
    title: 'Likes',
    accessor: 'hearts',
    displayer: ({ value, id }: { value: string; id: number }) => (
      <HeartDisplayer value={value} id={id} />
    ),
  }
];

export const AppNotificationsPage = observer(function AppNotificationsPage() {
  const stores = useStores();
  const modalStore = stores.modalStore;
  const camp = useRouteLoaderData('camp') as CampQuery['camp'];
  const camp_id = camp.camp_id;
  const { data, error, loading } = usePushNotificationsQuery({
    variables: { camp_id },
  });
  const [deleteItem] = useDeletePushNotificationMutation();
  const [updateItem] = useUpdatePushNotificationMutation();

  const rows = useMemo(() => {
    const arrayCopy = (data?.pushNotifications ?? []).slice();

    return sortByCreatedAtDescending(arrayCopy);
  }, [data?.pushNotifications]);

  if (!data) {
    return <ErrorLoading {...{ loading, error }} />;
  }

  return (
    <Paper>
      {!!rows && (
        <>
          <TableHeader
            title={'Notiser'}
            onAddItem={() => {
              modalStore.addItem();
            }}
          />
          <TableComponent
            headers={headers}
            // @ts-ignore
            rows={rows}
            showEdit
            onEdit={(row) => modalStore.updateItem(row)}
            onUpdateRow={(id, accessor, newValue) => {
              return updateItem({
                variables: {
                  body: {
                    id: id as number,
                    [accessor]: newValue,
                  },
                },
              });
            }}
            onDelete={(row) => {
              //todo: delete row.
              // row.id
              deleteItem({
                variables: {
                  pushNotificationId: Number(row.id),
                },
                update: (proxy: any) =>
                  updateCacheWithCampId({
                    query: PushNotificationsDocument,
                    proxy,
                    method: CacheOperation.DELETE,
                    camp_id: camp_id!,
                    mutationResultObject: null,
                    id: row.id,
                  }),
              });
            }}
          />
        </>
      )}

      <AddItem camp={camp} />
      <EditItem camp={camp} />
    </Paper>
  );
});

const AddItem = observer(({ camp }: { camp: Camp }) => {
  const stores = useStores();
  const modalStore = stores.modalStore;

  const [createItem] = useCreatePushNotificationMutation();
  const close = modalStore.closeAddItemModal;

  return (
    <CustomDialog open={modalStore.showingAddItemModal} onClose={close}>
      <CreatePushNotification
        camp={camp}
        onCancel={close}
        onSubmit={(data) => {
          data.camp_id = camp.camp_id;
          createItem({
            variables: {
              pushNotificationBody: data as PushNotificationInput,
            },
            update: (
              proxy: any,
              mutationResult: { data: { createPushNotification: any } },
            ) => {
              updateCacheWithCampId({
                query: PushNotificationsDocument,
                proxy,
                method: CacheOperation.CREATE,
                camp_id: camp.camp_id!,
                mutationResultObject:
                  mutationResult.data.createPushNotification,
              });
            },
          });
          close();
        }}
      />
    </CustomDialog>
  );
});

const EditItem = observer(({ camp }: { camp: Camp }) => {
  const stores = useStores();
  const modalStore = stores.modalStore;
  const [updateItem] = useUpdatePushNotificationMutation();

  const close = modalStore.closeUpdateItemModal;

  return (
    <CustomDialog open={modalStore.showingUpdateItemModal} onClose={close}>
      <CreatePushNotification
        camp={camp}
        existingItem={modalStore.existingItem}
        onCancel={close}
        onSubmit={(data) => {
          data.id = modalStore.existingItem.id;
          updateItem({
            variables: {
              body: data as PushNotificationUpdate,
            },
          });
          close();
        }}
      />
    </CustomDialog>
  );
});

const HeartDisplayer = observer(({ value, id }: { value: any; id: any }) => {
  const stores = useStores();
  const mainStore = stores.mainStore;
  const latestValue = mainStore.latestHeartedPushNotificationCount.find(
    (i) => i.id === id,
  );

  return <>{latestValue ? latestValue.count : value}</>;
});
