import React, { useCallback } from 'react';

import { observer } from 'mobx-react';
import {
  DateTimePickerProps as MuiDateTimePickerProps,
  DateTimePicker as MuiDateTimePicker,
  PickerChangeHandlerContext,
  DateTimeValidationError,
} from '@mui/x-date-pickers';
import { parseISO } from 'date-fns/esm';

interface DateTimePickerProps
  extends Partial<Omit<MuiDateTimePickerProps<Date>, 'value'>> {
  label: string;
  name: string;
  value: string;
  handleChange: ({ target }: { target: { name: string; value: any } }) => void;
  handleClose: ({ target }: { target: { name: string; value: any } }) => void;
}

export const DateTimePicker = observer(function DateTimePicker({
  label,
  name,
  value,
  handleChange,
  handleClose,
  ...props
}: DateTimePickerProps) {
  const onChange: (
    value: Date | null,
    context: PickerChangeHandlerContext<DateTimeValidationError>,
  ) => void = useCallback(
    (date, str) => {
      handleChange({
        target: {
          name: name,
          value: date,
        },
      });
    },
    [name, handleChange],
  );

  const parsed = parseISO(value);

  return (
    <>
      <MuiDateTimePicker
        // variant="inline"
        // minDate={new Date('1900-01-01')}
        ampm={false}
        label={label}
        name={name}
        value={parsed}
        onChange={onChange}
        onClose={() => {
          handleClose({
            target: {
              name: name,
              value: value,
            },
          });
        }}
        // disablePast
        format="yyyy-MM-dd HH:mm"
        {...props}
      />
    </>
  );
});

export const DatePicker = observer(function DatePicker({
  label,
  name,
  value,
  handleChange,
  handleClose,
  ...props
}: DateTimePickerProps) {
  const onChange: (
    value: Date | null,
    context: PickerChangeHandlerContext<DateTimeValidationError>,
  ) => void = useCallback(
    (date, str) => {
      handleChange({
        target: {
          name: name,
          value: date,
        },
      });
    },
    [name, handleChange],
  );

  const parsed = parseISO(value);

  return (
    <>
      <MuiDateTimePicker
        // variant="inline"
        // minDate={new Date('1900-01-01')}
        ampm={false}
        label={label}
        name={name}
        value={parsed}
        onChange={onChange}
        onClose={() => {
          handleClose({
            target: {
              name: name,
              value: value,
            },
          });
        }}
        // disablePast
        format="yyyy-MM-dd"
        {...props}
      />
    </>
  );
});
