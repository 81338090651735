import { tss } from 'tss-react/mui';

import { styled } from '../../styles/theme';
import { mediaMobile } from '../../styles/theme';

export const drawerWidth = 240;

// @ts-expect-error
export const useLayoutStyles = tss.withName('Layout').create(({ theme }) => ({
  root: {
    display: 'flex',
  },
  listItem: {},
  toolbar: {
    ...theme.mixins.toolbar,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  drawerPaper: {
    width: drawerWidth,
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    minHeight: '100vh',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export const StyledLogoLink = styled('div')`
  display: flex;
  width: 100%;
  height: 64px;
  padding: 16px;

  a {
    margin: 0 auto;
  }

  img {
    height: 40px;
  }
`;

export const StyledMainContent = styled('main')((p) => ({
  padding: '84px 24px 24px',
  flexGrow: 1,
  paddingLeft: '256px',

  [mediaMobile(p)]: {
    paddingLeft: '24px',
  },
}));

export const StyledAppBar = styled('header')((p) => ({
  boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 4px',
  color: '#fff',
  backgroundColor: '#ab3224',
  top: '0',
  left: 'auto',
  right: '0',
  position: 'fixed',
  zIndex: 1100,
  width: '100%',
  height: '64px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  paddingRight: '24px',
  paddingLeft: `${drawerWidth + 24}px`,

  [mediaMobile(p)]: {
    paddingLeft: '24px',
  },

  ['.menuButton']: {
    marginRight: '16px',
    display: 'none',

    [mediaMobile(p)]: {
      display: 'block',
    },
  },
}));

export const StyledNav = styled('nav')`
  width: ${drawerWidth}px;
  flex-shrink: 0;
`;
