import { styled } from '../../../styles/theme';

export interface StyledDraggableColumnProps {
  isDragging?: boolean;
}
export const StyledDraggableColumn = styled('div')<StyledDraggableColumnProps>`
  display: flex;
  align-items: center;
  ${(p) => (p.isDragging ? `background: rgba(0,0,0,.2);` : '')};
  .handle {
    margin-right: 10px;
    display: flex;
    align-items: center;
  }
`;
export const FlexLabel = styled('span')`
  flex: 1 1 auto;
`;

export const StyledFilterMaster = styled('div')`
  display: flex;
  border-bottom: 1px solid #d3d3d3;
  span {
    font-weight: 700;
    display: flex;
    align-items: center;
  }
`;
