import { runInAction } from 'mobx';

import { Camp, usePersonsQuery } from '../../generated/graphql';
import { useStores } from '../../stores';

export const useCampPersonsQuery = function useCampPersonsQuery(camp: Camp) {
  const stores = useStores();
  const store = stores.personStore;

  return usePersonsQuery({
    variables: { camp_id: camp.camp_id },
    onCompleted: (data) => {
      runInAction(() => {
        store.setCamp(camp);
        store.setPersons(data.persons);
        store.setCampColumns(data.campColumns);
        store.setCampCollections(data.campCollections);
      });
    },
    skip: !camp?.camp_id,
  });
};
