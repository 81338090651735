import React from 'react';

import {
  FormControl,
  InputLabel,
  InputProps,
  OutlinedInput,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { observer } from 'mobx-react';

interface ExtendedTextFieldProps extends InputProps {
  label: string;
  maxLength: number;
  preventEnter?: boolean;
}

export const TextFieldWithCharacterCount = observer(
  function TextFieldWithCharacterCount({
    maxLength,
    preventEnter = false,
    ...props
  }: ExtendedTextFieldProps) {
    const { classes, cx } = useStyles({
      length: (props.value as string)?.length,
      maxLength: maxLength,
    });

    return (
      <FormControl>
        <InputLabel
          htmlFor="textfield-charactercounter"
          className={cx(classes.label)}
          shrink
        >
          <Typography component="span" className={cx(classes.title)}>
            {props.label}
          </Typography>
          <span className={cx(classes.counterWrapper)}>
            <Typography component="span" className={cx(classes.counter)}>
              {(props.value ? `${(props.value as string)?.length}` : '0') +
                ' / ' +
                maxLength}
            </Typography>
          </span>
        </InputLabel>
        <OutlinedInput
          onKeyDown={(e) => {
            e.key === 'Enter' && preventEnter === true && e.preventDefault();
          }}
          id="textfield-charactercounter"
          {...props}
          sx={{
            minWidth: '250px',
            ['&::before']: {
              borderBottomColor: 'rgba(255, 255, 255, 0.5)',
              ['&:hover']: {
                borderBottomColor: '#ffffff',
              },
            },
            ...props.sx,
          }}
          inputProps={{ maxLength: maxLength, ...props.inputProps }}
        />
      </FormControl>
    );
  },
);

const useStyles = makeStyles<
  { length?: number; maxLength: number },
  'label' | 'title' | 'counter'
>({
  name: 'TextFieldWithCharacterCount',
})((theme, { length, maxLength }, classes) => ({
  label: {
    display: 'flex',
    width: 'calc(133%)',
    justifyContent: 'space-between',
    [`&.Mui-focused .${classes.title}`]: {
      opacity: 1,
    },
    [`&.Mui-focused .${classes.counter}`]: {
      opacity: 1,
    },
  },
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '16px',
    opacity: 0.8,
  },
  counterWrapper: {
    backgroundColor: 'white',
    paddingLeft: 5,
    paddingRight: 5,
  },
  counter: {
    fontSize: '16px',
    opacity: 1,
    userSelect: 'none',
  },
}));
