import { useMemo } from 'react';

import { observer } from 'mobx-react';
import {
  Button,
  Typography,
  Box,
  TextField,
  DialogActions,
  Tooltip,
  IconButton,
  Paper,
} from '@mui/material';
import { DeleteOutline } from '@mui/icons-material';
import { useLoaderData } from 'react-router';

import { TableColumn } from '../../../types/tableColumn';
import { InputType } from '../../../types/inputTypes';
import {
  useUpdateUserMutation,
  useCreateUserMutation,
  UserInput,
  User,
  UsersDocument,
  useResetPasswordMutation,
  useDeleteUserMutation,
  UsersQuery,
} from '../../../generated/graphql';
import { TableComponent, TableHeader } from '../../../components/Table/Table';
import { AuthorizedCellMenu } from '../../../components/users/AuthorizedAreasCell';
import { CustomDialog } from '../../../components/common/CustomDialog';
import { UpdateUserModal } from '../../../components/Modals/CreateUser';
import { FullWidthForm } from '../../../components/common/CommonDivs';
import { StarIcon } from '../../../components/icons';
import { updateCache } from '../../../utils/updateCache';
import { convertFormData } from '../../../utils/form';
import { useStores } from '../../../stores';
import { AuthorizedAreasCell } from '../../../components/users/AuthorizedAreasCell';

export const UsersPage = observer(function UsersPage() {
  const stores = useStores();
  const modalStore = stores.modalStore;
  const loaderData = useLoaderData() as UsersQuery['users'];
  const [updateUser] = useUpdateUserMutation();
  const [deleteUser] = useDeleteUserMutation();

  const users = useMemo(() => {
    return loaderData ? loaderData.filter((u) => u.active) : [];
  }, [loaderData]);

  const onDelete = async (row: { [x: string]: any; id: any }) => {
    const confirm = await modalStore.confirm(
      'Är du säker på att du vill ta bort denna användare?',
    );

    if (confirm) {
      // delete
      deleteUser({
        variables: {
          userId: row.id,
        },
        update: (proxy: any) => {
          updateCache({
            proxy,
            query: UsersDocument,
            id: row.id,
          });
        },
      });

      window.location.reload();

    }
  };

  return (
    <Paper className="page">
      <TableHeader
        title={'Användare'}
        onAddItem={() => {
          modalStore.addItem();
        }}
      />
      <TableComponent
        headers={headers}
        rows={users}
        onEdit={(item) => {
          // Displays reset password modal below. We just reuse modal store for convenience
          modalStore.updateItem(item);
        }}
        EditCellRenderer={(row) =>
          row.superAdmin ? (
            <Tooltip title={'Systemadministratör'}>
              <IconButton>
                <StarIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <IconButton onClick={() => onDelete(row)}>
              <DeleteOutline color="error" />
            </IconButton>
          )
        }
        onUpdateRow={async (id, accessor, newVal) => {

          let newValue: any = newVal;

          if (accessor === 'siteAdmin') {
            newValue = newVal == 'true';
          }

          updateUser({
            variables: {
              userId: id as number,
              body: {
                [accessor]: newValue,
              },
            },
          });

          window.location.reload();

        }}
      />
      <AuthorizedCellMenu />
      <AddItem />
      <ResetPasswordModal />
    </Paper>
  );
});

const AddItem = observer(function AddItem() {
  const stores = useStores();
  const modalStore = stores.modalStore;
  const [createItem] = useCreateUserMutation();
  const close = modalStore.closeAddItemModal;

  return (
    <CustomDialog open={modalStore.showingAddItemModal} onClose={close}>
      <UpdateUserModal
        title="användare"
        onCancel={close}
        onSubmit={async (data: UserInput) => {
          await createItem({
            variables: {
              body: data,
            },
            update: (
              proxy: any,
              mutationResult: { data: { createUser: any } },
            ) => {
              updateCache({
                proxy,
                query: UsersDocument,
                mutationResultObject: mutationResult.data.createUser,
              });
            },
          });
          close();
          window.location.reload();
        }}
      />
    </CustomDialog>
  );
});

const ResetPasswordModal = observer(function ResetPasswordModal() {
  const stores = useStores();
  const { mainStore, modalStore } = stores;
  const [resetPassword] = useResetPasswordMutation();

  const close = modalStore.closeUpdateItemModal;

  const user = modalStore.existingItem || {
    first_name: '',
    last_name: '',
  };

  return (
    <CustomDialog open={modalStore.showingUpdateItemModal} onClose={close}>
      <Box pl={3} pr={3} pt={3} pb={1}>
        <Typography component="h2" variant="h5" id="modal-title">
          {'Återställ lösenord'}
        </Typography>
        <Typography className="mt-2">
          {`Du håller på att ändra lösenord för ${user.first_name} ${user.last_name}.
          Observera att avändaren då inte längre kan använda det tidigare lösenordet.`}
        </Typography>
        <FullWidthForm
          noValidate
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            const data = convertFormData<any>(e.currentTarget);

            resetPassword({
              variables: {
                newPassword: data.password,
                userId: modalStore.existingItem.id,
              },
            }).then(() => {
              close();
              mainStore.displayNotification({
                message: 'Lösenord återställt!',
              });
            });
          }}
        >
          <TextField
            name="password"
            autoComplete="other-user password"
            variant="outlined"
            margin="normal"
            required
            label={'Nytt lösenord'}
            fullWidth
          />
          <DialogActions>
            <Button color="secondary" type="button" onClick={close}>
              {'Avbryt'}
            </Button>
            <Button color="primary" type="submit">
              Återställ
            </Button>
          </DialogActions>
        </FullWidthForm>
      </Box>
    </CustomDialog>
  );
});

const headers: TableColumn[] = [
  {
    title: 'Användarnamn',
    accessor: 'username',
    inputType: InputType.Text,
    width: 120,
    isDisabled: (row: User) => true,
  },
  {
    title: 'Epost',
    accessor: 'email',
    inputType: InputType.Text,
    isDisabled: (row: User) => Boolean(row.superAdmin),
  },
  {
    title: 'Förnamn',
    accessor: 'first_name',
    inputType: InputType.Text,
    width: 120,
    isDisabled: (row: User) => Boolean(row.superAdmin),
  },
  {
    title: 'Efternamn',
    accessor: 'last_name',
    inputType: InputType.Text,
    width: 120,
    isDisabled: (row: User) => Boolean(row.superAdmin),
  },
  {
    title: 'Telefon',
    accessor: 'phone',
    inputType: InputType.Text,
    width: 120,
    isDisabled: (row: User) => Boolean(row.superAdmin),
  },
  {
    title: 'Lösenord',
    accessor: 'password',
    buttonTitle: 'Återställ',
    inputType: InputType.Button,
    width: 120,
    isDisabled: (row: User) => Boolean(row.superAdmin),
  },
  {
    title: 'Lägerchef',
    accessor: 'siteAdmin',
    inputType: InputType.Boolean,
    width: 100,
    isDisabled: (row: User) => Boolean(row.superAdmin),
  },
   {
    title: '',
    accessor: 'userAuthorizedAreas',
    CellRenderer: (row: any) => {
      return (!row.row?.siteAdmin) ? <AuthorizedAreasCell row={row.row} /> : null;
    }
   }
 //  {
//   title: '',
  //   accessor: 'userAuthorizedAreas',
  //   // cellRenderer: AuthorizedAreasCell,
  // },
];
