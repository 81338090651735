import React, { useState } from 'react';

import { observer } from 'mobx-react';
import { Grid, ModalProps } from '@mui/material';
import clsx from 'clsx';
import { useRouteLoaderData } from 'react-router-dom';

import {
  AppCampCollectionFragment,
  CampCollectionDocument,
  CampCollections,
  CampColumnsDocument,
  CampQuery,
  useAddCampCollectionItemsMutation,
  useCampCollectionQuery,
  useUpdateCampMutation,
} from '../../../generated/graphql';
import { useModalRoute } from '../../../hooks';
import { CacheOperation, updateCache } from '../../../utils/updateCache';
import {
  BorderedBox,
  FlexColumn,
  StyledModalView,
} from '../../common/CommonDivs';
import { CustomDialog } from '../../common/CustomDialog';
import { ImportIcon } from '../../icons';
import {
  AddButton,
  ButtonGroup,
  CloseButton,
  IconButton,
} from '../../icons/IconButton';
import { StyledRoleBox } from '../../item-card';
import {
  ActiveOnCampSwitch,
  CampCollectionForm,
  ImportFromCamp,
  StyledItemListContainer,
  StyledModalHeader,
} from '../common';

const NEW_ITEM_NAME = 'Ny intressegrupp';
const collection = CampCollections.Interests;
const TITLE = 'Intressegrupper';
const ITEM_SINGULAR = 'Intressegrupp';

export const CampInterestsModal = observer(function CampInterestsModal(
  props: Omit<ModalProps, 'children'>,
) {
  const [selectedItem, setSelectedItem] = useState<AppCampCollectionFragment>();

  const [importActive, setImportActive] = useState(false);
  const toggleImport = () => setImportActive((s) => !s);
  const [sortActive, setSortActive] = useState(false);

  const toggleSort = () => setSortActive((s) => !s);

  const camp = useRouteLoaderData('camp') as CampQuery['camp'];

  const [activeValue, setActiveValue] = useState(camp.interests_active!);

  const [addItems] = useAddCampCollectionItemsMutation();
  const [updateCamp] = useUpdateCampMutation();

  const addItem = () => {
    addItems({
      variables: {
        collection,
        input: [
          {
            name: NEW_ITEM_NAME,
            camp_id: camp.camp_id,
          },
        ],
      },
      update: (
        proxy: any,
        result: { data: { addCampCollectionItems: any } },
      ) => {
        updateCache({
          method: CacheOperation.CREATE,
          query: CampCollectionDocument,
          proxy,
          mutationResultObject: result.data.addCampCollectionItems,
          variables: {
            camp_id: camp.camp_id,
            collection,
          },
        });
      },
    }).then(({ data }: { data: any }) => {
      setSelectedItem(data.addCampCollectionItems[0]);
    });
  };

  const importList = <ImportFromCamp collection={collection} />;
  const itemForm = (
    <CampCollectionForm
      descriptionEnabled
      itemSingular={ITEM_SINGULAR}
      selectedItem={selectedItem!}
      setSelectedItem={setSelectedItem}
    />
  );

  return (
    <CustomDialog {...props} maxWidth={'lg'}>
      <StyledModalHeader>
        <ButtonGroup>
          <IconButton
            title={'Importera'}
            icon={<ImportIcon />}
            active={importActive}
            onClick={toggleImport}
          />
          {/* <IconButton
            title={'Sortera'}
            icon={<SortIcon />}
            active={sortActive}
            onClick={toggleSort}
          /> */}
          <AddButton onClick={addItem} />
        </ButtonGroup>
        <h2>{TITLE}</h2>
        <CloseButton
          onClick={(e) => {
            props.onClose(e, 'backdropClick');
          }}
        />
      </StyledModalHeader>
      <StyledModalView>
        <Grid container spacing={3}>
          <Grid
            item
            xs={4}
            className={clsx('animate', { active: importActive })}
          >
            <BorderedBox>
              <FlexColumn>{importList}</FlexColumn>
            </BorderedBox>
          </Grid>

          <Grid item xs={8}>
            <ItemList
              setSelectedItem={setSelectedItem}
              sortActive={sortActive}
            />
          </Grid>

          <Grid
            item
            xs={4}
            className={clsx('animate', { active: !importActive })}
          >
            <BorderedBox>{itemForm}</BorderedBox>
          </Grid>
        </Grid>
        <ActiveOnCampSwitch
          checked={activeValue}
          onChange={(e, checked) => {
            setActiveValue(checked);
            updateCamp({
              variables: {
                body: {
                  camp_id: camp.camp_id,
                  interests_active: checked,
                },
              },
              refetchQueries: [
                {
                  query: CampColumnsDocument,
                  variables: { camp_id: camp.camp_id },
                },
              ],
            });
          }}
        />
      </StyledModalView>
    </CustomDialog>
  );
});

const ItemList = observer(function ItemList({
  setSelectedItem,
  sortActive,
}: {
  setSelectedItem: any;
  sortActive: any;
}) {
  const camp = useRouteLoaderData('camp') as CampQuery['camp'];
  const camp_id = camp.camp_id;

  const { data } = useCampCollectionQuery({
    variables: {
      collection,
      camp_id,
    },
  });
  const items = data && data.campCollection ? data.campCollection : [];

  return (
    <StyledItemListContainer className="no_scrollbar ">
      <Grid container wrap={'wrap'} spacing={3}>
        {items.map((item: { id: React.Key | null | undefined }) => (
          <Item key={item.id} item={item} setSelectedItem={setSelectedItem} />
        ))}
      </Grid>
    </StyledItemListContainer>
  );
});

const Item = observer(function Item({
  item,
  setSelectedItem,
}: {
  item: any;
  setSelectedItem: any;
}) {
  return (
    <Grid item xs={12} sm={4} md={4}>
      <StyledRoleBox
        onClick={() => {
          setSelectedItem(item);
        }}
        success={item.deleted ? false : true}
        error={item.deleted ? true : false}
      >
        {item.name}
      </StyledRoleBox>
    </Grid>
  );
});
