import React, { useCallback, useMemo, useState } from 'react';

import { observer } from 'mobx-react';
import { FormControl, FormHelperText, Paper, Typography } from '@mui/material';
import {
  addDays,
  differenceInDays,
  isAfter,
  isBefore,
  isSameDay,
  isValid,
} from 'date-fns';
import { BarChart, PieChart } from '@mui/x-charts';
import { DatePicker } from '@mui/x-date-pickers';
import { useRouteLoaderData } from 'react-router-dom';

import { formatDate } from '../../../../../utils/date';
import {
  BorderedBox,
  FlexRow,
} from '../../../../../components/common/CommonDivs';
import { DashboardItemHeader } from '../../../../../components/dashboard';
import { PersonUpdater } from '../../../../../components/persons/PersonUpdater';
import { ErrorIcon } from '../../../../../components/icons';
import { CampQuery } from '../../../../../generated/graphql';
import { useStores } from '../../../../../stores';
import { useCampPersonsQuery } from '../../../../../hooks/camps';
import { ErrorLoading } from '../../../../../components/common';

export const CampStatisticsPage = observer(function CampStatisticsPage() {
  const camp = useRouteLoaderData('camp') as CampQuery['camp'];
  const { data, error, loading } = useCampPersonsQuery(camp);

  return (
    <>
      <Paper>
        <DashboardItemHeader name="Statistik" />
        <ErrorLoading {...{ error, loading }}>
          <BorderedBox>
            {data && (
              <>
                <WeekLineChart />
                <LeaderParticipantPieChart />
              </>
            )}
          </BorderedBox>
        </ErrorLoading>
      </Paper>
      <PersonUpdater />
    </>
  );
});

type DateType = { date: Date; name: string; value: number };

const createRange = (from: number | Date, to: number | Date): DateType[] => {
  const data: DateType[] = [];
  const fromDate = new Date(from);
  const toDate = new Date(to);

  if (!fromDate || !toDate) {
    console.warn('Invalid dates detected');

    return null;
  }

  const amountOfDays = differenceInDays(to, from);

  if (amountOfDays < 0) {
    console.warn('Negative date range detected');

    return null;
  }

  for (let i = 0; i < amountOfDays; i++) {
    const date = addDays(from, i);
    data.push({ date: date, name: formatDate(date, 'dd MMM'), value: 0 });
  }

  return data;
};

const WeekLineChart = observer(function WeekLineChart() {
  const stores = useStores();
  const store = stores.personStore;
  const camp = useRouteLoaderData('camp') as CampQuery['camp'];
  const today = new Date();
  const lastSignupDate = new Date(camp.last_signup_date);

  const [state, setState] = useState({
    from: new Date(camp.created),
    to: isBefore(today, lastSignupDate) ? today : lastSignupDate,
  });

  const initialData = useMemo(() => {
    return createRange(state.from, state.to);
  }, [state.from, state.to]);

  const data = useMemo(() => {
    return store.activePersons.reduce((acc, curr) => {
      const createdAt = new Date(curr.createdAt);

      const activeSet = acc.find((a) => isSameDay(a.date, createdAt));

      if (activeSet) {
        activeSet.value += 1;
      }

      return acc;
    }, initialData);
  }, [initialData, store.activePersons]);

  return (
    <>
      <InputDateRange state={state} setState={setState} />
      <BarChart
        width={600}
        height={300}
        dataset={data}
        series={[
          { dataKey: 'value', label: 'Antal anmälda', color: '#8884d8' },
        ]}
        xAxis={[{ dataKey: 'name', scaleType: 'band' }]}
        tooltip={{ trigger: 'none' }}
      />
    </>
  );
});

// const data = [
//   { name: 'Ledare', value: 30 },
//   { name: 'Deltagare', value: 150 },
// ];

const COLORS = [
  '#0088FE',
  '#00C49F',
  '#FFBB28',
  '#FF8042',
  '#F44336',
  '#9C27B0',
  '#00BCD4',
];

const LeaderParticipantPieChart = observer(
  function LeaderParticipantPieChart() {
    const stores = useStores();
    const store = stores.personStore;

    const data = useMemo(() => {
      return store.piechartData.map((item, idx) => ({
        id: String(idx),
        value: item.value,
        label: item.name,
        color: COLORS[idx % COLORS.length],
      }));
    }, [store.piechartData]);

    // console.log('store.activePersons', store.activePersons[0]);
    console.log(
      'store.persons',
      store.persons.map((item) => {
        return item.role?.role_slug;
      }),
    );
    console.log('store.piechartData', store.piechartData);
    console.log('pie', data);

    return (
      <PieChart
        width={600}
        height={300}
        margin={{ top: 20, right: 300, bottom: 20, left: 0 }}
        dataset={data}
        series={[{ data }]}
      />
    );
  },
);

const InputDateRange = observer(function InputDateRange({
  state,
  setState,
}: {
  state: { from: Date; to: Date };
  setState: React.Dispatch<React.SetStateAction<{ from: Date; to: Date }>>;
}) {
  const [localFrom, setLocalFrom] = useState<Date>(state.from);
  const [localTo, setLocalTo] = useState<Date>(state.to);
  const [status, setStatus] = useState<JSX.Element>(null);

  const validateDateInput = useCallback(
    (type: 'from' | 'to', date: Date) => {
      const checkDate = new Date(date);
      const valid = isValid(checkDate);

      if (!valid) return false;

      const fromDate = type === 'from' ? date : state.from;
      const toDate = type === 'to' ? date : state.to;

      const amountOfDays = differenceInDays(toDate, fromDate);
      const isInOrder =
        type === 'from'
          ? isAfter(fromDate, state.to)
          : isBefore(toDate, state.from);
      // console.log('amountOfDays', amountOfDays);
      // console.log('isInOrder', isInOrder);

      if (amountOfDays < 0 || amountOfDays > 300 || isInOrder) {
        setStatus(
          <>
            <ErrorIcon />
            <Typography>Felaktig datuminställning</Typography>
          </>,
        );
      } else {
        setStatus(null);
      }

      return !(amountOfDays < 0 || amountOfDays > 300 || isInOrder);
    },
    [state.from, state.to],
  );

  return (
    <FormControl
      component="fieldset"
      fullWidth
      style={{
        padding: '20px 60px',
      }}
    >
      <FlexRow>
        <DatePicker
          label={'Från'}
          value={localFrom}
          maxDate={state.to}
          name="from"
          onChange={(e) => {
            console.log('onChange from');
            setLocalFrom(state.from);

            const isValid = validateDateInput('from', e);

            console.log(e.toLocaleString() + ' is valid: ' + isValid);

            if (!isValid) {
              // console.log(e.toLocaleString() + ' is incorrect');

              return;
            }
            setState((s) => {
              const shouldUpdateTo =
                localTo !== s.to && validateDateInput('to', localTo);

              if (shouldUpdateTo) {
                console.log('updating to');
              }

              return { from: e, to: shouldUpdateTo ? localTo : s.to };
            });
          }}
        />
        <div style={{ width: 10 }}></div>
        <DatePicker
          label={'Till'}
          value={localTo}
          minDate={state.from}
          name="to"
          onChange={(e) => {
            console.log('onChange to');
            setLocalTo(state.to);

            const isValid = validateDateInput('to', e);

            console.log(e.toLocaleString() + ' is valid: ' + isValid);

            if (!isValid) {
              // console.log(e.toLocaleString() + ' is incorrect');

              return;
            }

            setState((s) => {
              // const amountOfDays = differenceInDays(e, s.from);
              // if (amountOfDays < 0) return s;
              const shouldUpdateFrom =
                localFrom !== s.from && validateDateInput('from', localFrom);

              if (shouldUpdateFrom) {
                console.log('updating from');
              }

              return { from: shouldUpdateFrom ? localFrom : s.from, to: e };
            });
          }}
        />
      </FlexRow>
      <FormHelperText error sx={{ display: 'flex' }}>
        {status}
      </FormHelperText>
    </FormControl>
  );
});
