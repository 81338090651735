import React from 'react';

import { observer } from 'mobx-react';
import { AddCircle } from '@mui/icons-material';
import { indigo } from '@mui/material/colors';
import { Form } from 'react-router-dom';

import { mediaMobile, styled } from '../../styles/theme';

const styles = {
  blue: { color: indigo[500] },
};

export const FlexColumn = styled('div')`
  display: flex;
  flex-direction: column;
`;
export const FlexColumnAlignCenter = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const FlexRow = styled('div')`
  display: flex;
`;
export const FlexRowAlignCenter = styled('div')`
  display: flex;
  align-items: center;
`;
export const FlexEnd = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
export const FlexBetween = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const Spacer = styled('div')`
  flex: 1 1 100%;
`;

export const FullWidthForm = styled(Form)`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 8px;

  > div {
    width: 100%;
    margin-bottom: 10px;
  }
`;
export const PaddedView = styled('div')`
  padding: 20px;
`;
export const PaddedHorizontalView = styled('div')`
  padding: 0 20px;
`;

// Used on camp collection items
export const PaddedModalView = styled('div')`
  padding: 20px;
  min-height: 500px;

  display: flex;
  flex-direction: column;
  > div:first-of-type {
    flex: 1 1 auto;
  }
  @media screen and (min-width: 601px) {
    .animate {
      position: relative;
      /* transition: all 20s ease-in-out; */
      transition: all 0.3s ease-in-out;
      flex-basis: 0;

      overflow: hidden;

      &:not(.active) {
        padding-left: 0;
        padding-right: 0;
        opacity: 0;
      }
      &.active {
        flex-basis: 33.333333%;
        opacity: 1;
      }

      > div {
        min-width: 240px;
      }
      ${mediaMobile} {
        > div {
          min-width: 0;
        }
      }

      &.large {
        &:not(.active) {
          opacity: 1;
          flex-basis: 100%;
          max-width: 100%;

          padding: 0 12px;
        }
        &.active {
          flex-basis: 66.666667%;
          opacity: 1;
          padding: 0 12px;
        }
      }
    }
  }
  @media screen and (max-width: 600px) {
    .animate {
      display: none;
    }
    .animate.active {
      display: block;
    }
  }
`;

export const StyledModalView = styled(PaddedModalView)`
  min-height: 560px;
`;

export const BorderedBox = styled('div')`
  border: 1px solid ${(p) => p.theme.palette.primary.main};
  padding: ${(p) => p.theme.spacing(2)};
`;

export const DimmedText = styled('span')`
  color: #868686;
  font-weight: 500;
`;

export const BluePlusIcon = observer(() => <AddCircle style={styles.blue} />);

export const DividerPrimary = styled('hr')`
  border-bottom: 1px solid ${(p) => p.theme.palette.primary.main};
  margin-bottom: 10px;
`;
export const DividerSecondary = styled('hr')`
  border-bottom: 1px solid #eee;
  margin-bottom: 4px;
`;
export const StyledDashboardTable = styled('div')`
  .table-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .flex1 {
    flex: 1 1 auto;
  }
  .col-1 {
    flex: 1 1 auto;
    max-width: 170px;
  }
  .col-2,
  .col-3 {
    width: 45px;
    text-align: center;
  }
`;
