import React from 'react';

import { observer } from 'mobx-react';
import { Button, Switch } from '@mui/material';

import { styled } from '../../../styles/theme';
import { CloseButton } from '../../icons';
import { useStores } from '../../../stores';

import { ColumnList } from './ColumnList';
import { FlexLabel, StyledFilterMaster } from './StyledDraggableColumn';

const OVERLAY_WIDTH = 320;
const PILL_WIDTH = 0;

export const UserOverlay = observer(function UserOverlay() {
  const stores = useStores();
  const store = stores.personStore;

  return (
    <Overlay
      style={{
        right: store.filterOverlayActive
          ? -2
          : -(OVERLAY_WIDTH - PILL_WIDTH + 1),
      }}
    >
      <InnerOverlay>
        <OverlayHeader>
          <CloseButton
            style={{ width: '100%' }}
            onClick={() => {
              store.setFilterOverlay(false);
            }}
          />
        </OverlayHeader>
        {/* <ColumnListHeader>{'Visa dessa kolumner:'}</ColumnListHeader> */}
        <StyledFilterMaster>
          <FlexLabel>{'Göm/visa alla'}</FlexLabel>
          <Switch
            color="primary"
            title="Visa alla kolumner"
            checked={store.showingAllColumns}
            onChange={() => {
              store.toggleAllColumns();
            }}
          />
        </StyledFilterMaster>
        <ColumnList />
        <Button
          variant="contained"
          style={{ width: '100%', marginBottom: 10 }}
          onClick={() => {
            store.setDisplayingSharedLinkModal(true);
          }}
        >
          {'Skapa delad länk'}
        </Button>
      </InnerOverlay>
    </Overlay>
  );
});

const Overlay = styled('div')`
  width: ${OVERLAY_WIDTH}px;
  display: flex;
  position: fixed;
  right: 0;
  top: 70px;
  height: 90%;
  z-index: 20;

  color: #111;

  /* sine */
  transition: right 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);

  pointer-events: none;
`;

const InnerOverlay = styled('div')`
  pointer-events: auto;

  display: flex;
  flex-direction: column;

  padding: 12px 20px;
  background: white;

  border: 1px solid ${(p) => p.theme.palette.primary.main};
  border-radius: 4px;
  height: 100%;
  flex: 1 1 auto;
`;

const OverlayHeader = styled('div')`
  /* padding: 20px 0; */
  margin-bottom: 32px;
  margin-top: 6px;
  font-size: 1rem;
`;
