import React, { useEffect, useState } from 'react';

import { observer } from 'mobx-react';
import { Button, Grid, TextField } from '@mui/material';
import { tss } from 'tss-react/mui';
import { useRouteLoaderData } from 'react-router-dom';

import {
  CampQuery,
  useUpdateCampMutation,
} from '../../../../../generated/graphql';
import { ImageLibrarySelect } from '../../../media/MediaPage';
import { cancelSubmit } from '../../../../../utils/form';
import { CampCollectionItems } from '../../../../../components/camp-collections';
import {
  DimmedText,
  FullWidthForm,
  PaddedView,
} from '../../../../../components/common/CommonDivs';
import { DashboardItemHeader } from '../../../../../components/dashboard';
import { SavedIconText } from '../../../../../components/icons';
import { LabelSwitch } from '../../../../../components/switch';
import { TextFieldWithCharacterCount } from '../../../../../components/textfield';
import { DatePicker } from '../../../../../components/textfield/DateTimePicker';
import {
  FlexSwitchColumn,
  FlexSwitchRow,
} from '../../../../../components/camp/styles';
import { StyledSettingsBox } from '../../../../../components/camp/settings/StyledSettingsBox';
import { useUpdateCamp } from '../../../../../components/camp/settings/useUpdateCamp';

export const CampSettingsPage = observer(function CampSettingsPage() {
  return (
    <Grid container spacing={2}>
      <MainCampSettings />
      <CampDescriptions />
      <CampDetailOptions />
    </Grid>
  );
});

const MainCampSettings = observer(function MainCampSettings() {
  const {
    state,
    onKeyDown,
    handleChange,
    handleBlur,
    handleSwitchChange,
    hasSaved,
  } = useUpdateCamp();

  if (!state) return null;

  return (
    <Grid item xs={12} md={6} lg={4}>
      <StyledSettingsBox>
        <DashboardItemHeader
          name={'Huvudinställningar'}
          secondaryIcon={<SavedIconText visible={hasSaved} />}
        />
        <PaddedView>
          <FullWidthForm onSubmit={cancelSubmit}>
            <TextFieldWithCharacterCount
              label={'Evenemangets namn'}
              name="name"
              maxLength={50}
              value={state.name}
              onChange={handleChange}
              onBlur={handleBlur}
              onKeyDown={onKeyDown}
            />
            <DatePicker
              label={'Start-datum'}
              value={state.start_date}
              name="start_date"
              handleChange={handleChange}
              handleClose={handleBlur}
              slotProps={{
                popper: {
                  onBlur: handleBlur,
                },
              }}
            />
            <DatePicker
              label={'Slut-datum'}
              value={state.end_date}
              name="end_date"
              handleClose={handleBlur}
              handleChange={handleChange}
              slotProps={{
                popper: {
                  onBlur: handleBlur,
                },
              }}
            />
            <DatePicker
              label={'Sista anmälningsdag'}
              value={state.last_signup_date}
              name="last_signup_date"
              handleClose={handleBlur}
              handleChange={handleChange}
              slotProps={{
                popper: {
                  onBlur: handleBlur,
                },
              }}
            />
            <FlexSwitchRow>
              <LabelSwitch
                name="active"
                value={state.active}
                onChange={handleSwitchChange}
                label={'Aktivt'}
                labelPlacement={'start'}
              />
              <LabelSwitch
                name="signup"
                value={state.signup}
                onChange={handleSwitchChange}
                label={'Anmälan öppen'}
                labelPlacement={'start'}
              />
            </FlexSwitchRow>
            <hr />
            <FlexSwitchColumn>
              <div>
                <LabelSwitch
                  name="panicMode"
                  value={state.panicMode}
                  onChange={handleSwitchChange}
                  label={'Kritiskt läge'}
                  labelPlacement={'start'}
                />
              </div>
              <DimmedText>
                {'Aktivera om evenemanget skulle behöva utrymmas.'}
              </DimmedText>
            </FlexSwitchColumn>
            {state.panicMode && (
              <TextField
                label={'Kritiskt läge - meddelande'}
                name="panicModeText"
                value={state.panicModeText || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                onKeyDown={onKeyDown}
              />
            )}
            <TextField
              label={'Lägerchefs epost'}
              name="camp_leader_email"
              autoComplete="camp_leader_email"
              value={state.camp_leader_email || ''}
              onChange={handleChange}
              onBlur={handleBlur}
              onKeyDown={onKeyDown}
            />
          </FullWidthForm>
        </PaddedView>
      </StyledSettingsBox>
    </Grid>
  );
});

const CampDescriptions = observer(function CampDescriptions() {
  const { state, onKeyDown, handleChange, handleBlur, hasSaved } =
    useUpdateCamp();

  if (!state) return null;

  return (
    <Grid item xs={12} md={6} lg={4}>
      <StyledSettingsBox>
        <DashboardItemHeader
          name="Beskrivningar"
          secondaryIcon={<SavedIconText visible={hasSaved} />}
        />
        <PaddedView>
          <FullWidthForm onSubmit={cancelSubmit}>
            <h4>Beskrivning till startsidan</h4>
            <TextField
              name="description"
              value={state.description || ''}
              onChange={handleChange}
              onBlur={handleBlur}
              // onKeyDown={onKeyDown}
              multiline
              rows={3}
            />
            <hr />
            <h4>Beskrivning till anmälningsformuläret</h4>
            <TextField
              name="extraDescription"
              value={state.extraDescription || ''}
              onChange={handleChange}
              onBlur={handleBlur}
              // onKeyDown={onKeyDown}
              multiline
              rows={4}
            />
          </FullWidthForm>
        </PaddedView>
        <CampImage />
      </StyledSettingsBox>
    </Grid>
  );
});

const CampDetailOptions = observer(function CampDetailOptions() {
  const camp = useRouteLoaderData('camp') as CampQuery['camp'];

  if (!camp) return null;

  return (
    <Grid item xs={12} md={6} lg={4}>
      <StyledSettingsBox>
        <DashboardItemHeader name={'Valmöjligheter'} />
        <PaddedView>
          <CampCollectionItems />
        </PaddedView>
      </StyledSettingsBox>
    </Grid>
  );
});

const CampImage = observer(function CampImage() {
  const camp = useRouteLoaderData('camp') as CampQuery['camp'];
  const [selectedImageId, setSelectedImageId] = useState(-1);
  const [hasSaved, setHasSaved] = useState(false);
  const { classes, cx } = useStyles();

  useEffect(() => {
    if (camp.image?.id) {
      setSelectedImageId(camp.image.id);
    }
  }, [camp]);

  const [update] = useUpdateCampMutation();

  if (!camp) return null;

  const saveImage: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setHasSaved(false);
    update({
      variables: {
        body: {
          camp_id: camp.camp_id,
          imageId: selectedImageId,
        },
      },
    }).then(() => {
      setHasSaved(true);
    });

    return false;
  };

  return (
    <>
      <div className={cx(classes.imageHeaderPadding)}></div>
      <DashboardItemHeader
        name={'Evenemangsbild'}
        secondaryIcon={<SavedIconText visible={hasSaved} />}
      />
      <PaddedView>
        <ImageLibrarySelect
          selected={selectedImageId}
          onSelect={(imageId) => {
            setSelectedImageId((i) => (imageId === i ? -1 : imageId));
          }}
        />
        <FullWidthForm onSubmit={saveImage}>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            size={'medium'}
          >
            Spara
          </Button>
        </FullWidthForm>
      </PaddedView>
    </>
  );
});

const useStyles = tss.withName('CampSettings').create((p) => ({
  imageHeaderPadding: {
    height: 20,
    background: '#fafafa',
    borderTop: `1px solid ${p.theme.palette.primary.main}`,
    margin: -1,
  },
}));
