import React, { useEffect } from 'react';

import { observer } from 'mobx-react';
import { Paper } from '@mui/material';
import { runInAction } from 'mobx';
import { useRouteLoaderData } from 'react-router';

import { getExternalSharedLink } from '../../../../../config';
import { CampQuery, usePersonsQuery } from '../../../../../generated/graphql';
import { useStores } from '../../../../../stores/Store';
import { copyTextToClipboard } from '../../../../../utils/clipboard';
import { BorderedBox } from '../../../../../components/common/CommonDivs';
import { CustomDialog } from '../../../../../components/common/CustomDialog';
import { ErrorLoading } from '../../../../../components/common/ErrorLoading';
import { CreateSharedLinkModal } from '../../../../../components/Modals/CreateSharedLink';
import { TableHeaderButtonGroup } from '../../../../../components/persons/header/TableHeaderButtonGroup';
import { PersonsCardView } from '../../../../../components/persons/PersonsCardView';
import { PersonUpdater } from '../../../../../components/persons/PersonUpdater';
import { PersonsTable } from '../../../../../components/persons/VirtualizedPersonsTable';
import { UserOverlay } from '../../../../../components/persons/user-columns-overlay';

export const CampPersonsPage = observer(function CampPersonsPage() {
  const camp = useRouteLoaderData('camp') as CampQuery['camp'];
  const camp_id = camp.camp_id;
  const stores = useStores();
  const personStore = stores.personStore;

  useEffect(() => {
    runInAction(() => {
      personStore.filters.clear();
      personStore.setShowingDeleted(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [camp_id]);

  const { data, error, loading } = usePersonsQuery({
    variables: { camp_id },
    onCompleted: (data) => {
      runInAction(() => {
        personStore.setCamp(camp);
        personStore.setPersons(data.persons);
        personStore.setCampColumns(data.campColumns);
        personStore.setCampCollections(data.campCollections);
      });
    },
  });

  return (
    <>
      <ErrorLoading {...{ loading, error }}>
        <Paper>
          <TableHeaderButtonGroup isAdmin />
          <BorderedBox>
            {personStore.cardView ? <PersonsCardView /> : <PersonsTable />}
          </BorderedBox>
          <UserOverlay />
          <PersonUpdater />
          <SharedLinkModal />
        </Paper>
      </ErrorLoading>
    </>
  );
});

const SharedLinkModal = observer(() => {
  const stores = useStores();
  const { mainStore, personStore } = stores;

  const close = () => {
    personStore.setDisplayingSharedLinkModal(false);
  };

  return (
    <CustomDialog open={personStore.displayingSharedLinkModal} onClose={close}>
      <CreateSharedLinkModal
        title="länk"
        onCancel={close}
        onSubmit={(input) => {
          personStore
            .createSharedLink(input)
            .then((sharedLink) => {
              personStore.setDisplayingSharedLinkModal(false);
              mainStore.displayNotification({
                message: 'Skapade ny länk',
                options: {
                  variant: 'success',
                  buttonTitle: 'Kopiera',
                  handlePrimaryAction: () => {
                    copyTextToClipboard(
                      getExternalSharedLink(sharedLink!.link),
                    ).catch(console.error);
                  },
                },
              });
            })
            .catch();
        }}
      />
    </CustomDialog>
  );
});
