import React, { useState } from 'react';

import { observer } from 'mobx-react';
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';

import {
  CampChurch,
  StudygroupCollectionDocument,
  useUpdatePersonStudygroupMutation,
} from '../../generated/graphql';
import { getPersonDisplayName } from '../../helpers/persons/getPersonDetails';
import { PaddedHorizontalView } from '../common/CommonDivs';
import { useLatestValue } from '../../hooks/useLatestValue';
import { useStores } from '../../stores';

import { ChurchListDropdown } from './ChurchListDropdown';

export const MovePersonModal = observer(function MovePersonModal() {
  const stores = useStores();
  const store = stores.movePersonStore;
  const activePerson = store.activePerson;
  const latestPerson = useLatestValue(activePerson);
  const [selectedChurch, setSelectedChurch] = useState<CampChurch>(null);

  // selectedChurch.studygroupCollection.studygroups.
  const handleCancel = () => store.clear();
  const [updatePerson] = useUpdatePersonStudygroupMutation();

  const handleMovePerson = () => {
    if (!selectedChurch.startStudyGroup) {
      throw new Error('Ingen startgrupp hittades');
    }
    updatePerson({
      variables: {
        id: activePerson.id,
        studygroupId: selectedChurch.startStudyGroup.id,
      },
      refetchQueries: [
        {
          query: StudygroupCollectionDocument,
          variables: {
            id: store.activeChurch.group_collection_id,
          },
        },
        // {
        //   query: CampChurchesWithStudygroupDocument,
        //   variables: {
        //     camp_id: store.activeChurch.camp_id,
        //   },
        // },
      ],
    }).then(() => {
      store.clear();
    });
  };

  return (
    <Dialog
      open={!!activePerson}
      maxWidth="xs"
      fullWidth
      // disableBackdropClick
      onClose={handleCancel}
      aria-labelledby="modal-title"
    >
      <>
        <DialogTitle id="modal-title">
          {getPersonDisplayName(latestPerson)}
        </DialogTitle>
        <PaddedHorizontalView>
          <div>
            <p>Flytta person till en annan församlings studiegrupper:</p>
            <ChurchListDropdown
              selected={selectedChurch}
              setSelected={setSelectedChurch}
              showEmpty
            />
            <Button
              disabled={!selectedChurch}
              onClick={handleMovePerson}
              color="primary"
              type="button"
              size="medium"
            >
              Flytta
            </Button>
          </div>
        </PaddedHorizontalView>
        <DialogActions>
          <Button onClick={handleCancel} color="secondary" type="button">
            Stäng
          </Button>
        </DialogActions>
      </>
    </Dialog>
  );
});
