import React, { useState, useEffect } from 'react';

import { observer } from 'mobx-react';
import {
  TextField,
  Typography,
  Box,
  Button,
  DialogActions,
  FormControlLabel,
  Switch,
} from '@mui/material';

import { convertFormData } from '../../utils/form';
import { ImageLibrarySelect } from '../../pages/dashboard/media/MediaPage';
import { NewsPost } from '../../generated/graphql';
import { FullWidthForm } from '../common/CommonDivs';
import { useRouteLoaderData } from 'react-router-dom';
import { CampQuery } from '../../generated/graphql';


interface CreateNewsPostProps {
  onSubmit: (data) => void;
  onCancel: () => void;
  existingItem?: NewsPost;
}
export const CreateNewsPost = observer(function CreateNewsPost(
  props: CreateNewsPostProps,
) {
  let { onSubmit, onCancel, existingItem } = props;

  const [new_passwords, setPasswords] = useState('');

  const camp = useRouteLoaderData('camp') as CampQuery['camp'];
  const p_str = camp.app_passwords;
  let passwords = existingItem ? existingItem.passwords : '';

  let roles = [];

  if (p_str)
  {
    roles = p_str.split(',');
  }

  console.log('existingItem', existingItem);
  console.log('camp', camp);

  const getRoleTitle = (role) => {
    return role.split('_')[1];
  }

  const getChecked = (existingItem, role) => {
    if (!existingItem) return false;
    if (!existingItem.passwords) return false;
    return existingItem.passwords.includes(role.split('_')[0]);
  };

  const getChecked2 = (pw, role) => {
    return pw.includes(role.split('_')[0]);
  };

  const setPassword = (role, set) => {

    let p = role.split('_')[0];

    if (!existingItem)
      {
        passwords = new_passwords;
      }

    console.log('p', p);
    console.log(passwords);
    console.log('set', set);

    if (set)
      {
        passwords = passwords + p + ',';
      }
    if (!set)
      {
        passwords = passwords.replace(p, '');
      }

      passwords = passwords.replace(',,', ',');

      console.log('passwords', passwords);

      if (existingItem)
        {
          existingItem.passwords = passwords;
        }
        else
        {
          setPasswords(passwords);
        }

  }



  const isEdit = !!existingItem;

  const [restrictedAudience, setRestrictedAudience] = useState(false);
  const [selectedImage, setSelectedImage] = useState(-1);

  useEffect(() => {
    if (!existingItem) return;
    setRestrictedAudience(existingItem.restrictedAudience);
    if (existingItem.image) {
      setSelectedImage(existingItem.image.id);
    }
  }, [existingItem]);

  const submit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const data = convertFormData(e.target);
    data.restrictedAudience = restrictedAudience;
    data.imageId = selectedImage;
    if (existingItem) {
      data.passwords = passwords;
    }
    else
    {
      data.passwords = new_passwords;
    }
    onSubmit(data);
  };

  return (
    <Box p={4} paddingBottom={1}>
      <Typography component="h2" variant="h5">
        {isEdit ? 'Uppdatera inlägg' : 'Skapa nytt inlägg'}
      </Typography>
      <FullWidthForm noValidate onSubmit={submit}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="title"
          name="title"
          label="Rubrik"
          autoFocus
          defaultValue={existingItem ? existingItem.title : ''}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="description"
          name="description"
          label="Brödtext"
          rows={4}
          multiline
          defaultValue={existingItem ? existingItem.description : ''}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="linkText"
          name="linkText"
          label="Knapptext"
          defaultValue={existingItem ? existingItem.linkText : ''}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="link"
          name="link"
          label="Länk (URL)"
          type="url"
          defaultValue={existingItem ? existingItem.link : ''}
        />

    {

      roles.map((role) => (
        <FormControlLabel
          control={
            <Switch
              checked={existingItem ? getChecked(existingItem, role) : getChecked2(new_passwords, role) }
              onChange={(event) => {
                setPassword(role, event.target.checked);
                console.log('event.target.checked', event.target.checked);
              }}
            />
          }
          label={getRoleTitle(role)}
        />
      ))

    }

        <ImageLibrarySelect
          selected={selectedImage}
          onSelect={(imageId) => {
            setSelectedImage((i) => (imageId === i ? -1 : imageId));
          }}
        />

        <DialogActions>
          <Button
            color="secondary"
            type="button"
            onClick={onCancel}
            id="cancel"
          >
            {'Avbryt'}
          </Button>
          <Button color="primary" type="submit" id="submit">
            {isEdit ? 'Spara inlägg' : 'Skapa nytt inlägg'}
          </Button>
        </DialogActions>
      </FullWidthForm>
    </Box>
  );
});
