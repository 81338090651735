import { observer } from 'mobx-react';
import { Outlet, useLoaderData } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { CampQuery } from '../../../../generated/graphql';

export const CampDetailsPage = observer(function CampDetailsPage() {
  const camp = useLoaderData() as CampQuery['camp'];

  if (!camp) {
    return null;
  }

  return (
    <>
      <Helmet title={camp.name} />
      <Outlet />
    </>
  );
});
