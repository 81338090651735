import { ChangeEvent, useEffect, useState } from 'react';

import { useRouteLoaderData } from 'react-router-dom';

import {
  Camp,
  CampQuery,
  useUpdateCampMutation,
} from '../../generated/graphql';

export const useUpdateCamp = function useUpdateCamp() {
  const camp = useRouteLoaderData('camp') as CampQuery['camp'];
  const [state, setState] = useState<Partial<Camp>>(camp);
  const [hasSaved, setSaved] = useState(false);

  useEffect(() => {
    setState(camp);
  }, [camp]);

  const [update] = useUpdateCampMutation();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    let newValue: any = value;

    if (name === 'leader_meet' || name === 'leader_day' || name === 'studygroups_active') {
      newValue = value == 'true';
    }

    setSaved(false);
    const newState = { ...state, [name]: newValue };
    setState(newState);

    return newState;
  };

  const handleSave = (newState: typeof state) => {
    const {
      camp_id,
      leader_day,
      leader_meet,
      leader_day_info,
      leader_meet_info,
      shared_link_password,
      app_passwords,
      studygroups_active,
    } = newState;

    update({
      variables: {
        body: {
          camp_id,
          leader_day,
          leader_meet,
          leader_day_info,
          leader_meet_info,
          shared_link_password,
          app_passwords,
          studygroups_active,
        },
      },
    })
      .then(() => setSaved(true))
      .catch();
  };

  const handleBlur = () => {
    handleSave(state);
  };

  const handleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleSave(handleChange(e));
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      (e.target as HTMLInputElement).blur();
    }
  };

  return {
    state,
    hasSaved,
    handleChange,
    handleSwitchChange,
    onKeyDown,
    handleBlur,
  };
};
