import React, { useEffect, useRef, useState } from 'react';

import { observer } from 'mobx-react';
import { StandardTextFieldProps, TextField } from '@mui/material';

import { styled } from '../../styles/theme';

interface AutoresizingInputField extends StandardTextFieldProps {
  onChange: (e: any) => void;
  value: string;
}
export const AutoresizingInputField = observer(function AutoresizingInputField(
  props: AutoresizingInputField,
) {
  const { value, onChange, ...other } = props;
  const [rows, setRows] = useState(1);

  const ref = useRef<HTMLDivElement>(null);

  const updateRows = (target: HTMLTextAreaElement) => {
    const textareaLineHeight = 17; //or 20 /19
    // const previousRows = target.rows;
    target.rows = 4; // reset number of rows in textarea
    const currentRows = ~~((target.scrollHeight * 0.9) / textareaLineHeight); //~~ = math floor
    target.rows = Math.max(1, currentRows);
    setRows(currentRows);
  };

  const handleChange: React.ChangeEventHandler<HTMLTextAreaElement> = (
    event,
  ) => {
    updateRows(event.target);
    onChange(event);
  };

  useEffect(() => {
    const textarea = ref.current?.firstChild?.firstChild as HTMLTextAreaElement;
    if (!textarea) return;
    updateRows(textarea);
    textarea.setSelectionRange(textarea.value.length, textarea.value.length);
  }, []);

  return (
    <TableTextInput
      ref={ref}
      multiline
      rows={rows}
      value={value}
      onChange={handleChange}
      {...other}
    />
  );
});

export const TableTextInput = styled(TextField)`
  width: 100%;
  height: 100%;
  > div {
    color: inherit;
    padding: 0;
  }
  /* input,
  textarea {
    color: inherit;
  } */
` as typeof TextField;
