import { useEffect } from 'react';

import { observer } from 'mobx-react';
import {
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
  useRouteLoaderData,
} from 'react-router';

import { Layout } from '../../components/layout';

export const DashboardPage = observer(function DashboardPage() {
  const location = useLocation();
  const nav = useNavigate();
  const me = useRouteLoaderData('root');

  useEffect(() => {
    if (location.pathname === '/dashboard' && me) {
      nav('/dashboard/camps');
    }
  }, [location.pathname, me, nav]);

  return (
    <Layout>
      <Outlet />
    </Layout>
  );
});
