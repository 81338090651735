import { useMemo } from 'react';

import { observer } from 'mobx-react';

import { StudyGroupCollection } from '../../generated/graphql';
import { convertToMap } from '../../utils/convertToMap';
import { useSyncState } from '../useSyncState';
import { move } from '../../utils/arrayutils';

import { useUpdatePersonsStudygroup } from './useUpdatePersonsStudygroup';

export const useDragAndDrop = function useDragAndDrop(
  groupCollection: StudyGroupCollection,
) {
  const studygroups = groupCollection.studygroups;
  const stateMap = useMemo(() => {
    return convertToMap(studygroups);
  }, [studygroups]);
  const update = useUpdatePersonsStudygroup();
  const [state, setState] = useSyncState(stateMap);
  const getStudyGroupList = (id: string) => state[id];

  const onDragEnd = (result: { source?: any; destination?: any }) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }
    if (source.droppableId === destination.droppableId) {
      //We skip reorders
      return;
    }
    const studygroup1 = { ...getStudyGroupList(source.droppableId) };
    const studygroup2 = { ...getStudyGroupList(destination.droppableId) };
    // Set sourcePerson.studygroup_id = resultStudygroupId
    const sourcePerson = studygroup1.persons[source.index];
    const firstPersons = studygroup1.persons;
    const secondPersons = studygroup2.persons;
    const [persons1, persons2] = move(
      firstPersons,
      secondPersons,
      source,
      destination,
    );
    studygroup1.persons = persons1;
    studygroup2.persons = persons2;
    // Optimistic ui
    setState((s) => ({
      ...s,
      [studygroup1.id]: studygroup1,
      [studygroup2.id]: studygroup2,
    }));
    update(sourcePerson, studygroup1, studygroup2).catch(() => {
      // Errored, we restore the previous
      const studygroup1Copy = { ...studygroup1 };
      const studygroup2Copy = { ...studygroup2 };
      studygroup1Copy.persons = firstPersons;
      studygroup2Copy.persons = secondPersons;
      setState((s) => ({
        ...s,
        [studygroup1.id]: studygroup1Copy,
        [studygroup2.id]: studygroup2Copy,
      }));
    });

    return;
    // If its the same list, we reorder
    if (source.droppableId === destination.droppableId) {
      // const persons = reorder(
      //   studygroup.persons,
      //   source.index,
      //   destination.index,
      // );
      // studygroup.persons = persons;
      // setState(s => ({ ...s, [studygroup.id]: studygroup }));
    }
  };

  return {
    state,
    onDragEnd,
  };
};
