import { observer } from 'mobx-react';
import { Button, Paper, Typography } from '@mui/material';
import { Form, useRouteLoaderData } from 'react-router-dom';

import { MeQuery } from '../../../generated/graphql';

export const SettingsPage = observer(function SettingsPage() {
  const me = useRouteLoaderData('root') as MeQuery['me'];

  return (
    <Paper className="page">
      <Typography variant={'h6'}>Inloggad som: {me.username} </Typography>
      <Form method="post" action="/login">
        <Button
          variant="contained"
          size="medium"
          color="primary"
          type="submit"
          name="intent"
          value="logout"
        >
          Logga ut
        </Button>
      </Form>
    </Paper>
  );
});
