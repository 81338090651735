import { InputType } from '../../generated/graphql';
import { ExtendedCampColumn } from '../../types/extendedCampColumn';
import { ExtendedPerson } from '../../types/person';
import { formatDateString } from '../../utils/date';

export const getPersonValueFromColumn = (
  person: ExtendedPerson,
  column: ExtendedCampColumn,
) => {
  // The displayed value
  let stringValue = String(person.details[column.name!] || '');
  // The underlaying data-value
  let idValue: number | string = -1;

  switch (column.inputType) {
    case InputType.Id:
      // @ts-ignore
      stringValue = String(person[column.name!]);
      break;
    case InputType.Values:
      idValue = stringValue;
      break;
    case InputType.Boolean:
      // Clarification InputType.Boolean are stored in the server as '1' and '0'
      // This is just to handle empty strings as 0
      idValue = Number(stringValue) ? '1' : '0';
      stringValue = Number(stringValue) ? 'Ja' : 'Nej';
      break;
    case InputType.Collection:
      // Not from details - collections are directly on person;
      // @ts-ignore
      const relation: CampCollection = person[column.name!];

      if (relation) {
        stringValue = relation.name;
        idValue = relation.id;
      } else {
        idValue = -1;
        stringValue = '–';
      }

      break;
    default:
      // // Not really in use
      // if (column.relationalId) {
      //   const relation = person[column.name]
      //   stringValue = relation? relation.name? relation.name: relation: '';
      //   idValue = relation ? relation.id : -1;
      //   stringValue = String(stringValue);
      // }
      break;
  }
  if (column.name === 'signup_date') {
    stringValue = formatDateString(stringValue, 'yyyy-MM-dd HH:mm:ss');
  }

  return { idValue, stringValue };
};
