import React, { useRef, useState } from 'react';

import { observer } from 'mobx-react';
import { GridCellProps, MultiGrid } from 'react-virtualized';
import { Grid } from '@mui/material';

import { styled } from '../../styles/theme';
import { StyledChurchBox } from '../item-card';
import { getPersonDisplayName } from '../../helpers/persons/getPersonDetails';
import { ExtendedPerson } from '../../types/person';
import { useStores } from '../../stores';

const HEADER_HEIGHT = 100;
const CELL_HEIGHT = 60;
const CELL_WIDTH = 120;
const MIN_ROWS = 5;

const RowHeightFn = observer(({ index }) =>
  index === 0 ? HEADER_HEIGHT : CELL_HEIGHT,
);

const CellRendererFn = observer(function CellRendererFn(props: GridCellProps) {
  return (
    <div>
      cell {props.rowIndex}, {props.columnIndex}
    </div>
  );
});

export const PersonsCardView = observer(function PersonsCardView() {
  const tableRef = useRef<MultiGrid>();
  const stores = useStores();
  const store = stores.personStore;
  const columns = store.columns;
  const churches = store.sortedPersonsByChurch;
  const [selectedItem, setSelectedItem] = useState<ExtendedPerson>();

  const loading = !columns?.length;
  // TODO: Loading timeout
  if (loading) return <>{'Laddar...'}</>;

  return (
    <>
      {/* <h2>
        Antal: {persons.length}/{store.persons.length}
      </h2> */}
      <Grid container>
        <Grid item xs={12} sm={6} md={6}>
          <PersonList>
            {churches.map((church) => (
              <div key={church.churchId}>
                <StyledChurchName>{church.name}</StyledChurchName>
                <Grid container wrap={'wrap'} spacing={3}>
                  {church.persons.map((item) => {
                    const isSelected =
                      selectedItem && selectedItem.id === item.id;

                    return (
                      <Item
                        key={item.id}
                        item={item}
                        setSelectedItem={setSelectedItem}
                        isSelected={isSelected}
                      />
                    );
                  })}
                </Grid>
                <br />
              </div>
            ))}
          </PersonList>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          {selectedItem ? selectedItem.details.first_name : 'None selected!'}
        </Grid>
      </Grid>
    </>
  );
});

interface ItemProps {
  item: ExtendedPerson;
  setSelectedItem: React.Dispatch<React.SetStateAction<ExtendedPerson>>;
  isSelected: boolean;
}

const Item = observer(function Item({
  item,
  setSelectedItem,
  isSelected,
}: ItemProps) {
  return (
    <Grid item xs={12} sm={4} md={4}>
      <StyledChurchBox
        active={isSelected}
        onClick={() => {
          if (!isSelected) {
            setSelectedItem(item);
          }
        }}
      >
        {getPersonDisplayName(item)}
      </StyledChurchBox>
    </Grid>
  );
});

const StyledChurchName = styled('div')`
  color: #424242;
  position: relative;
  margin-bottom: 10px;
  &:after {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 70px;
    border-bottom: 1px solid #424242;
  }
`;

const PersonList = styled('div')`
  height: calc(100vh - 270px);
  overflow-y: auto;
`;
