import React, { useCallback, useEffect } from 'react';

import { observer } from 'mobx-react';
import {
  Button,
  Checkbox,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  TableCell,
  MenuItem,
  Typography,
  ListItemButton,
} from '@mui/material';
import { makeObservable } from 'mobx';
import { ArrowDropDownIcon } from '@mui/x-date-pickers';

import {
  User,
  UpdateUserAuthorizedAreasDocument,
  UpdateUserAuthorizedAreasMutation as MuationResult,
  UpdateUserAuthorizedAreasMutationVariables as MuationVars,
  Camp,
  UserAuthorization,
} from '../../generated/graphql';
import { useLatestValue } from '../../hooks/useLatestValue';
import { Spacer } from '../common/CommonDivs';
import { useStores } from '../../stores';

class DialogStore {
  constructor() {
    makeObservable(this, { anchorEl: true, activeRow: true, setAnchor: true });
  }
  anchorEl?: EventTarget & HTMLButtonElement;
  activeRow?: User;

  setAnchor(
    anchorEl?: (EventTarget & HTMLButtonElement) | undefined,
    row?: User | undefined,
  ) {
    this.anchorEl = anchorEl;
    this.activeRow = row;
  }
}
const dialogStore = new DialogStore();

export const AuthorizedAreasCell = observer(function AuthorizedAreasCell({
  row,
}: {
  row: User;
}) {
  // Update the active row if that user has new changes
  useEffect(() => {
    if (dialogStore.activeRow && row.id === dialogStore.activeRow.id) {
      dialogStore.activeRow = row;
    }
  }, [row]);
  if (row.siteAdmin) {
    return <TableCell></TableCell>;
  }

  return (
    <TableCell>
      <Button
        aria-controls="AuthorizedCellMenu"
        aria-haspopup="true"
        onClick={(event) => {
          dialogStore.setAnchor(event.currentTarget, row);
        }}
      >
        <Typography variant="inherit" className="mr-1">
          {'Behörigheter'}
        </Typography>
        <ArrowDropDownIcon fontSize="small" />
      </Button>
    </TableCell>
  );
});

const availableAreas: { title: string; slug: string }[] = [
  {
    title: 'Anmälningsystem',
    slug: 'persons',
  },
  {
    title: 'Appen',
    slug: 'app',
  },
  {
    title: 'Bildagrupper',
    slug: 'bilda',
  },
];

export const AuthorizedCellMenu = observer(function AuthorizedCellMenu() {
  const stores = useStores();
  const activeCampStore = stores.activeCampStore;

  const camps = activeCampStore.camps;

  const isOpen = !!dialogStore.activeRow;

  const handleClose = () => {
    dialogStore.setAnchor(undefined);
  };

  return (
    <Menu
      id="AuthorizedCellMenu"
      anchorEl={dialogStore.anchorEl}
      keepMounted
      className="menu-dialog"
      open={isOpen}
      onClose={handleClose}
    >
      <div className="inner-menu-dialog">
        <MenuItem className="bg-gray p-3" onClick={handleClose}>
          <Typography variant="inherit">Behörigheter</Typography>
          <Spacer />
          <ArrowDropDownIcon fontSize="small" />
        </MenuItem>
        {camps.map((camp) => (
          <CampPermissionsRow key={camp.camp_id} camp={camp} />
        ))}
      </div>
    </Menu>
  );
});

const CampPermissionsRow = observer(function CampPermissionsRow({
  camp,
}: {
  camp: Camp;
}) {
  const stores = useStores();
  const mainStore = stores.mainStore;
  const activeUser = useLatestValue(dialogStore.activeRow);

  const updateUserArea = useCallback(
    (userId: number, campId: number, areas: string, isCampEditor: boolean) => {
      console.log(`userId: ${userId}, campId: ${campId}, areas: ${areas}`);

      console.log("areas", areas);


      mainStore.client
        .mutate<MuationResult, MuationVars>({
          mutation: UpdateUserAuthorizedAreasDocument,
          variables: {
            userId,
            campId,
            areas: areas,
            isCampEditor,
          },
        })
        .catch();
    },
    [mainStore.client],
  );

  if (!activeUser) {
    return null;
  }
  let campArea =
    activeUser &&
    activeUser.userAuthorizedAreas?.find(
      (a) => a && a.camp_id === camp.camp_id,
    );
  // If we dont have a UserAuthorization for this user and this camp, we create one temporarily
  // It will be created on the server if it doesnt exist
  if (!campArea) {
    campArea = {
      // @ts-ignore
      authorizedAreas: '[]',
      camp_id: camp.camp_id!,
      user_id: activeUser.id,
      isCampEditor: false,
    };
  }
  const isCampEditor = campArea.isCampEditor;

  const onChange = (event: any, checked: boolean) => {
    updateUserArea(
      activeUser.id,
      camp.camp_id!,
      JSON.stringify(campArea!.authorizedAreas),
      checked,
    );
  };

  return (
    <div>
      <List>
        <ListItem style={styles.headerItem}>
          <ListItemText primary={camp.name} />
        </ListItem>

        <ListItem style={styles.campEditorItem}>
          <div style={styles.campEditorItemText}>{'Lägeradmin'}</div>
          <ListItemSecondaryAction>
            <Checkbox edge="end" onChange={onChange} checked={isCampEditor} />
          </ListItemSecondaryAction>
        </ListItem>

        <List style={styles.nested}>
          {availableAreas.map((area) => {
            return (
              <CampAuthArea
                key={area.slug}
                area={area}
                campArea={campArea!}
                disabled={isCampEditor}
                updateUserArea={updateUserArea}
              />
            );
          })}
        </List>
      </List>
    </div>
  );
});

const CampAuthArea = observer(function CampAuthArea({
  area,
  campArea,
  disabled,
  updateUserArea,
}: {
  area: { title: string; slug: string };
  campArea: UserAuthorization;
  disabled: boolean;
  updateUserArea: (
    userId: number,
    campId: number,
    areas: string,
    isCampEditor: boolean,
  ) => void;
}) {
  const authorizedAreas = campArea.authorizedAreas;
  const checked = authorizedAreas.includes(area.slug);

  const onChange = (event: any, checked: any) => {

    console.log("authorizedAreas", authorizedAreas);

    let newAreas = authorizedAreas.slice() as unknown as string[];
    if (checked) {
      newAreas.push(area.slug);
    } else {
      newAreas = newAreas.filter((a) => a !== area.slug);
    }
    updateUserArea(
      campArea.user_id,
      campArea.camp_id,
      JSON.stringify(newAreas),
      campArea.isCampEditor,
    );
  };

  return (
    <ListItemButton dense>
      <ListItemText primary={area.title} />
      <ListItemSecondaryAction>
        <Checkbox
          edge="end"
          onChange={onChange}
          checked={checked}
          disabled={disabled}
        />
      </ListItemSecondaryAction>
    </ListItemButton>
  );
});

const styles = {
  headerItem: {
    padding: '0px 20px',
  },
  campEditorItemText: {
    fontSize: '0.875rem',
  },
  campEditorItem: {
    paddingLeft: 36,
  },
  nested: {
    paddingLeft: 20,
  },
};
