import { useRouteLoaderData } from 'react-router-dom';

import { CampQuery, useCampColumnsQuery } from '../../generated/graphql';

export const useGdprColumns = function useGdprColumns() {
  const camp = useRouteLoaderData('camp') as CampQuery['camp'];

  const { data } = useCampColumnsQuery({
    variables: {
      camp_id: camp.camp_id,
    },
    skip: !camp || camp.camp_id === -1,
  });

  const columns = data
    ? data.campColumns.filter((col) => !col.nonDeletable)
    : [];

  return columns;
};
