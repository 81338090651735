import { GraphQLError } from 'graphql';
import { ServerParseError, ServerError, ApolloError } from '@apollo/client';

import {
  isAtUnauthenticatedRoute,
  NETWORK_ERROR,
  NETWORK_ERROR_SERVER,
} from '../../stores/apollo';
import { MainStore } from '../../stores/Store';

export const handleGraphqlErrors = function handleGraphqlErrors(
  graphQLErrors: GraphQLError[],
  store: MainStore,
) {
  for (const err of graphQLErrors) {
    console.log(
      `[GraphQL error]: Message: ${err.message}, Location: ${JSON.stringify(err.locations)}, Path: ${err.path}`,
    );
    if (err.extensions) {
      switch (err.extensions.code) {
        case 'UNAUTHENTICATED':
          if (!isAtUnauthenticatedRoute()) {
            // store.previousPathBeforeLogin = window.location.href;
            // navigate('/login');

            return;
          }
          if (window.location.pathname !== '/login') {
            store.displayError(err.message);
          }

          return;
          break;

        case 'FORBIDDEN':
          console.log('Forbidden!', err.message);
          store.displayError(err.message);
          // if (ActiveCampStore.store.selectedCamp) {
          // navigate(
          //   `/dashboard/camps/${ActiveCampStore.store.selectedCamp.camp_id}`,
          // );
          // }

          //User isnt allowed to view the requested data, redirect to dashboard
          // if (window.location.pathname !== '/dashboard') {
          //   navigate('/dashboard');
          // }
          return;
          break;
        case 'InvalidLinkPassword':
        case 'UserInputError':
          return;
      }
      store.displayError(err.message);
    }
  }
};

export const handleNetworkError = function handleNetworkError(
  networkError: Error | ServerParseError | ServerError,
  store: MainStore,
) {
  console.log(`[Network error]: ${networkError}`);
  if ((networkError as ServerError).statusCode === 400) {
    store.displayError('Invalid request!');
  } else {
    if (!navigator.onLine) {
      store.displayError(NETWORK_ERROR);

      return;
    }
    store.displayError(NETWORK_ERROR_SERVER);
  }
};

export const isErrorBadInput = function isErrorBadInput(error: ApolloError) {
  return isErrorOfType(error, 'BAD_USER_INPUT');
};

export const isErrorOfType = function isErrorOfType(
  error: ApolloError,
  type: string,
) {
  if (!error?.graphQLErrors?.length) {
    return false;
  }
  for (const err of error.graphQLErrors) {
    if (err.extensions?.code === type) {
      return true;
    }
  }

  return false;
};
