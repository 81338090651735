import React, { useState, useEffect } from 'react';

import { observer } from 'mobx-react';
import {
  TextField,
  Typography,
  Box,
  Button,
  DialogActions,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { DateField } from '@mui/x-date-pickers';

import { convertFormData } from '../../utils/form';
import { FullWidthForm } from '../common/CommonDivs';
import { TableColumn } from '../../types/tableColumn';
import { InputType } from '../../types/inputTypes';

export interface AutoTableModalProps {
  title: string;
  headers: TableColumn[];
  existingItem?: Record<string, any>;
  onSubmit: (data: Record<string, any>) => void;
  onCancel: () => void;
}

export const AutoTableModal = observer(
  ({
    title,
    headers,
    existingItem,

    onSubmit,
    onCancel,
  }: AutoTableModalProps) => {
    const [state, setState] = useState({});

    const isEdit = !!existingItem;
    const headersWithInputs = headers.filter((h) => !!h.inputType);

    const submit: React.FormEventHandler<HTMLFormElement> = (e) => {
      e.preventDefault();
      e.stopPropagation();

      const data = {
        ...convertFormData<any>(e.target as HTMLFormElement),
        ...state,
      };

      headersWithInputs.forEach((h) => {
        const val = data[h.accessor];

        if (h.inputType === InputType.Number) {
          data[h.accessor] = Number(val);
        } else if (h.inputType === InputType.Boolean) {
          data[h.accessor] = Boolean(val);
        }
      });
      onSubmit(data);
    };

    return (
      <Box pl={3} pr={3} pt={3} pb={1}>
        <Typography component="h2" variant="h5" id="modal-title">
          {isEdit ? `Uppdatera ${title}` : `Skapa ny ${title}`}
        </Typography>
        <FullWidthForm noValidate onSubmit={submit}>
          {headersWithInputs.map((header, idx) => {
            const defaultValue = isEdit
              ? // @ts-ignore
                existingItem[header.accessor]
              : header.defaultValue || '';

            if (
              header.inputType === InputType.Text ||
              header.inputType === InputType.Number
            ) {
              return (
                <TextField
                  key={header.accessor}
                  variant="outlined"
                  margin="normal"
                  required={header.required}
                  fullWidth
                  id={header.accessor}
                  name={header.accessor}
                  label={header.title}
                  autoFocus={idx === 0}
                  type={
                    header.inputType === InputType.Number ? 'number' : 'text'
                  }
                  defaultValue={defaultValue}
                />
              );
            } else if (header.inputType === InputType.Textarea) {
              return (
                <TextField
                  key={header.accessor}
                  variant="outlined"
                  margin="normal"
                  required={header.required}
                  fullWidth
                  id={header.accessor}
                  name={header.accessor}
                  label={header.title}
                  rows={2}
                  multiline
                  defaultValue={defaultValue}
                />
              );
            } else if (header.inputType === InputType.Date) {
              return (
                <DateField
                  key={header.accessor}
                  variant="outlined"
                  margin="normal"
                  required={header.required}
                  fullWidth
                  id={header.accessor}
                  name={header.accessor}
                  label={header.title}
                  defaultValue={defaultValue}
                />
              );
            } else if (header.inputType === InputType.Boolean) {
              return (
                <FormControlLabel
                  key={header.accessor}
                  control={
                    <Switch
                      // @ts-ignore
                      checked={!!state[header.accessor]}
                      onChange={(event) => {
                        setState((s) => ({
                          ...s,
                          [header.accessor]: event.target.checked,
                        }));
                      }}
                    />
                  }
                  label={header.title}
                />
              );
            }

            return null;
          })}
          <DialogActions>
            <Button color="secondary" type="button" onClick={onCancel}>
              {'Avbryt'}
            </Button>
            <Button color="primary" type="submit">
              {isEdit ? `Spara ${title}` : `Skapa ny ${title}`}
            </Button>
          </DialogActions>
        </FullWidthForm>
      </Box>
    );
  },
);
