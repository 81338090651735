import React, { useEffect, useMemo } from 'react';

import { observer } from 'mobx-react';
import { Tooltip } from '@mui/material';
import { useRouteLoaderData } from 'react-router-dom';

import { styled } from '../../styles/theme';
import {
  CampQuery,
  useStudygroupStatisticsQuery,
} from '../../generated/graphql';

export const StudygroupDashboard = observer(function StudygroupDashboard() {
  const camp = useRouteLoaderData('camp') as CampQuery['camp'];
  const { data, refetch } = useStudygroupStatisticsQuery({
    variables: {
      camp_id: camp.camp_id,
    },
  });

  useEffect(() => {
    refetch();
  }, [refetch]);

  const studygroupCount = data?.studygroupStatistics.studygroupCount;
  const unfinishedStudygroups = useMemo(() => {
    return (
      data?.studygroupStatistics.studygroupsFromPersonsInStartgroups
        .slice()
        .sort((a, b) => a.name.localeCompare(b.name)) ?? []
    );
  }, [data?.studygroupStatistics.studygroupsFromPersonsInStartgroups]);

  const tooltipText = useMemo(
    () => unfinishedStudygroups.map((s) => <div key={s.id}>{s.name}</div>),
    [unfinishedStudygroups],
  );

  if (!data) {
    return null;
  }

  return (
    <StatisticsDiv>
      <div>
        <div className="circle">
          <span>{studygroupCount}</span>
        </div>
        <span>Antal Bildagrupper</span>
      </div>
      <div>
        <Tooltip title={tooltipText} placement="right">
          <div className="circle">
            <span>{unfinishedStudygroups.length}</span>
          </div>
        </Tooltip>
        <span>Församlingar inte färdiga</span>
      </div>
    </StatisticsDiv>
  );
});

export const StatisticsDiv = styled('div')`
  margin-top: 14px;
  display: flex;
  /* align-items: center; */

  text-align: center;
  font-size: 16px;
  font-weight: 700;
  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    padding: 10px;
  }

  .circle {
    font-size: 18px;
    color: white;
    background: ${(p) => p.theme.palette.primary.main};
    position: relative;
    border-radius: 25px;
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
    > span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;
