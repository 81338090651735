import React from 'react';

import { observer } from 'mobx-react';
import { Button, TextField } from '@mui/material';
import { CheckCircle } from '@mui/icons-material';
import { useRouteLoaderData } from 'react-router-dom';

import { styled } from '../../../styles/theme';
import { Camp, CampQuery } from '../../../generated/graphql';
import { useUpdateCampChanger } from '../../../hooks/camps/useUpdateCampChanger';

const PaddedView = styled('div')``;

export const AppDashboard = observer(function AppDashboard() {
  const camp = useRouteLoaderData('camp') as CampQuery['camp'];

  if (!camp) return null;

  return (
    <PaddedView>
      <CodeChanger camp={camp} />
    </PaddedView>
  );
});

const CodeChanger = observer(({ camp }: { camp: Camp }) => {
  const {
    val,
    onKeyDownHandler,
    onChangeHandler,
    isValid,
    handleUpdate,
    success,
    errorMessage,
  } = useUpdateCampChanger(camp, 'campCode', (val) => val.length === 5);

  return (
    <>
      <h3>Lägerkod</h3>
      <FlexCenter>
        <TextField
          value={val}
          onChange={onChangeHandler}
          onKeyDown={onKeyDownHandler}
        />
        <Button disabled={!isValid} onClick={handleUpdate}>
          Spara
        </Button>
        {success && <CheckCircle />}
      </FlexCenter>
      {errorMessage && <div>{errorMessage}</div>}
    </>
  );
});

const FlexCenter = styled('div')`
  display: flex;
  align-items: center;
  svg {
    color: ${(p) => p.theme.palette.success.dark};
  }
`;
