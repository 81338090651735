import React, { useState } from 'react';

import { observer } from 'mobx-react';
import clsx from 'clsx';
import { Grid, ModalProps } from '@mui/material';
import { useRouteLoaderData } from 'react-router-dom';

import {
  AppCampChurchFragment,
  CampChurch,
  CampChurchesDocument,
  CampQuery,
  useAddCampChurchMutation,
} from '../../../generated/graphql';
import { useModalRoute } from '../../../hooks';
import { CacheOperation, updateCache } from '../../../utils/updateCache';
import {
  BorderedBox,
  FlexColumn,
  StyledModalView,
} from '../../common/CommonDivs';
import { CustomDialog } from '../../common/CustomDialog';
import {
  AddButton,
  ButtonGroup,
  CloseButton,
  IconButton,
} from '../../icons/IconButton';
import { StyledModalHeader } from '../common';
import { ImportIcon } from '../../icons/Icons';

import { ChurchForm } from './ChurchForm';
import { ChurchList } from './ChurchList';
import { ImportChurches } from './ImportChurches';

export const CampChurchModal = observer(function CampChurchModal(
  props: Omit<ModalProps, 'children'>,
) {
  const [importActive, setImportActive] = useState(false);
  const [selectedChurch, setChurch] = useState<AppCampChurchFragment | null>(
    null,
  );

  const setSelectedChurch = (church: CampChurch) => {
    setImportActive(false);
    setChurch(church);
  };

  const toggleImport = () => {
    setChurch(null);
    setImportActive((s) => !s);
  };

  const [addChurchMutation] = useAddCampChurchMutation();
  const camp = useRouteLoaderData('camp') as CampQuery['camp'];

  const addChurch = () => {
    addChurchMutation({
      variables: {
        input: {
          name: 'Namnlös församling',
          camp_id: camp.camp_id,
        },
      },
      update: (proxy: any, result: any) => {
        updateCache({
          method: CacheOperation.CREATE,
          query: CampChurchesDocument,
          proxy,
          mutationResultObject: result.data.addCampChurch,
          variables: {
            camp_id: camp.camp_id,
          },
        });
      },
    }).then(({ data }) => {
      setSelectedChurch(data.addCampChurch);
    });
  };

  return (
    <CustomDialog {...props} maxWidth={'lg'}>
      <StyledModalHeader>
        <ButtonGroup>
          <IconButton
            title={'Importera'}
            icon={<ImportIcon />}
            active={importActive}
            onClick={toggleImport}
          />
          <AddButton onClick={addChurch} />
        </ButtonGroup>
        <h2>Församlingar</h2>
        <CloseButton
          onClick={(e) => {
            props.onClose(e, 'backdropClick');
          }}
        />
      </StyledModalHeader>
      <StyledModalView>
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            sm={4}
            className={clsx('animate', { active: importActive })}
          >
            <BorderedBox>
              <FlexColumn>
                <ImportChurches />
              </FlexColumn>
            </BorderedBox>
          </Grid>
          <Grid item xs={12} sm={8}>
            <ChurchList
              selectedChurch={selectedChurch!}
              setChurch={setSelectedChurch}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            className={clsx('animate', {
              active: !importActive,
            })}
          >
            <BorderedBox>
              <ChurchForm
                selectedChurch={selectedChurch!}
                setChurch={setSelectedChurch}
              />
            </BorderedBox>
          </Grid>
        </Grid>
      </StyledModalView>
    </CustomDialog>
  );
});
