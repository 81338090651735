import React, { useEffect } from 'react';

import { observer } from 'mobx-react';
import { runInAction } from 'mobx';
import { useReactiveVar } from '@apollo/client';

import { useSharedLinkQuery } from '../../../generated/graphql';
import { isErrorOfType } from '../../../helpers/apollo';
import { Layout } from '../../../components/layout';
import { PersonsTable } from '../../../components/persons';
import { linkPasswordVar, useStores } from '../../../stores';
import { ErrorLoading } from '../../../components/common';
import { useLoaderData } from 'react-router';

export const ExternalLinkPage = observer(function ExternalLinkPage() {

  let link = useLoaderData().toString();

  const { data, error, loading } = useLinkFetcher({ link });

  return (
    <Layout>
      <ErrorLoading {...{ error, loading }}>
        <PersonsTable />
      </ErrorLoading>
    </Layout>
  );
});

const useLinkFetcher = function useLinkFetcher({ link }: { link: string }) {
  const stores = useStores();
  const { linkStore, modalStore, personStore } = stores;
  const pwd = useReactiveVar(linkPasswordVar);

  console.log('useLinkFetcher', link, pwd);

  const { data, error, loading, refetch } = useSharedLinkQuery({
    variables: { link, password: pwd },
  });

  console.log('replacing sharedLink', data);
  console.log('replacing sharedLink', error);
  console.log('replacing sharedLink', loading);

  // useEffect(() => {
  //   LinkStore.store.setError(result.error);
  // }, [result.error]);

  const sharedLink = data && data.sharedLink;

  useEffect(() => {
    const prompt = async () => {
      if (isErrorOfType(error, 'InvalidLinkPassword')) {
        try {
          const pwd = await modalStore.promptPassword();
          linkPasswordVar(pwd);

          refetch();
        } catch (e) {
          console.error('LinkFetcher error', e);
        }

        return;
      }

      if (sharedLink) {
        runInAction(() => {
          console.log('set sharedLink props');
          personStore.setCamp(sharedLink.camp);
          personStore.setPersons(sharedLink.persons);
          personStore.setCampColumns(sharedLink.campColumns);
          personStore.setCampCollections(sharedLink.camp.campCollections);
          personStore.setEditingEnabled(false);

          linkStore.setActiveLink(sharedLink as any);
          linkStore.linkUrl = window.location.pathname;
          // Used in side bar navigation
          linkStore.setCamp(sharedLink.camp);
        });
      }

      if (!error) {
        runInAction(() => {
          modalStore.setShowingPromptDialog(false);
        });
      }
    };
    prompt();
  }, [sharedLink, personStore, error, refetch, linkStore, modalStore]);

  return { data, error, loading };
};
