import React from 'react';

import { observer } from 'mobx-react';
import { Drawer, DrawerProps, Hidden } from '@mui/material';

import { StyledNav, useLayoutStyles } from './styles';

export const SideDrawer = observer(function SideDrawer({
  children,
  open,
  onClose,
}: DrawerProps) {
    // @ts-expect-error
  const { classes, cx } = useLayoutStyles();

  return (
    <StyledNav>
      <Hidden smUp implementation="css">
        <Drawer
          anchor={'left'}
          open={open}
          onClose={onClose}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            className: cx(classes.drawerPaper),
          }}
        >
          {children}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          PaperProps={{
            className: cx(classes.drawerPaper),
          }}
          variant="permanent"
          open
        >
          {children}
        </Drawer>
      </Hidden>
    </StyledNav>
  );
});
