import { sv } from 'date-fns/locale';
import { compareDesc, format, parseISO } from 'date-fns';

import { Camp } from '../generated/graphql';

type DataObject = {
  createdAt: string;
};

export const svLocale = sv;

export const formatDateString = function formatDateString(
  date: string,
  formatStr = 'yyyy-MM-dd',
) {
  const parsed = parseISO(date);

  return format(parsed, formatStr, {
    locale: sv,
  });
};

export const formatDate = function formatDate(date: Date, formatStr: string) {
  return format(date, formatStr, {
    locale: sv,
  });
};

export const toISOString = function toISOString(date: Date) {
  return format(date, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx", {
    locale: sv,
  });
};

export const compareDescString = function compareDescString(
  date1: string,
  date2: string,
) {
  return compareDesc(parseISO(date1), parseISO(date2));
};

export const campSorter = function campSorter(a: Camp, b: Camp) {
  return compareDescString(a.start_date, b.start_date);
};

export const sortByCreatedAtDescending = function sortByCreatedAtDescending<
  T extends DataObject,
>(data: T[]) {
  if (data) {
    return data.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));
  }

  return null;
};
