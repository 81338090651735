import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { observer } from 'mobx-react';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';

import { styled } from '../../../styles/theme';
import { SERVER_URL } from '../../../config';
import {
  AppCampFragment,
  useCampsFromYearLazyQuery,
  useGenerateReportMutation,
} from '../../../generated/graphql';
import { useCampsQueryWithAuth } from '../../../hooks/camps';
import { FlexColumn } from '../../../components/common';

export const ReportsPage = observer(function ReportsPage() {
  const [year, setYear] = useState('');
  const [password, setPassword] = useState('');
  const [selectedCamps, setSelectedCamps] = useState<number[]>([]);

  const [getCamps, { data }] = useCampsFromYearLazyQuery();
  const camps = useMemo(() => data?.campsFromYear ?? [], [data]);

  const [generate] = useGenerateReportMutation({ fetchPolicy: 'no-cache' });

  const handleYearChange: (
    event: SelectChangeEvent<string>,
    child: React.ReactNode,
  ) => void = useCallback(
    (event) => {
      const newYear = event.target.value;

      setYear(newYear);

      getCamps({
        variables: {
          year: parseInt(newYear, 10),
        },
      });
    },
    [getCamps],
  );

  useEffect(() => {
    if (camps.length) {
      setSelectedCamps(camps.map((c) => c.camp_id));
    }
  }, [camps]);

  return (
    <Paper className="page">
      <Typography variant="h6">Generera årsrapport</Typography>
      <FlexColumn
        sx={(theme) => ({
          width: 300,
          px: theme.spacing(2),
          gap: theme.spacing(1),
        })}
      >
        <YearSelect year={year} handleYearChange={handleYearChange} />
        {camps.length > 0 && (
          <Camps
            camps={camps}
            selectedCamps={selectedCamps}
            setSelectedCamps={setSelectedCamps}
          />
        )}
        {selectedCamps.length > 0 && (
          <TextField
            label="Lösenord till filen"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
        )}
        {selectedCamps.length > 0 && password && (
          <Button
            variant="contained"
            color="primary"
            size={'medium'}
            onClick={async () => {
              generate({
                variables: {
                  campIds: selectedCamps,
                  password,
                },
              }).then(({ data }) => {
                const file = SERVER_URL + data.generateReport;
                window.open(file, '_blank');

                // exportCSVFile(persons);
              });
            }}
          >
            Generera
          </Button>
        )}
      </FlexColumn>
    </Paper>
  );
});

const YearSelect = observer(function YearSelect({
  year,
  handleYearChange,
}: {
  year: string;
  handleYearChange: (
    event: SelectChangeEvent<string>,
    child: React.ReactNode,
  ) => void;
}) {
  const campYears = useCampYears();

  return (
    <FormControl>
      <InputLabel id="year-select-label">Välj år</InputLabel>
      <Select
        labelId="year-select-label"
        label="Välj år"
        id="year-select"
        value={year}
        onChange={handleYearChange}
      >
        {campYears.map((y) => (
          <MenuItem key={y} value={y}>
            {y}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
});

const useCampYears = function useCampYears() {
  const { data } = useCampsQueryWithAuth();
  const campYears = useMemo(() => {
    if (!data?.camps?.length) {
      return [];
    }

    const campYears = data.camps.reduce<number[]>((acc, curr) => {
      const campYear = new Date(curr.start_date).getFullYear();

      if (!acc.includes(campYear)) {
        acc.push(campYear);
      }

      return acc;
    }, []);

    return campYears.sort().reverse();
  }, [data]);

  return campYears;
};

const Camps = observer(function Camps({
  camps,
  setSelectedCamps,
  selectedCamps,
}: {
  camps: AppCampFragment[];
  setSelectedCamps: (s: number[]) => void;
  selectedCamps: number[];
}) {
  const handleSelect = (e, val: boolean) => {
    const camp_id = parseInt(e.target.value, 10);
    const campSet = new Set(selectedCamps);

    if (val) {
      campSet.add(camp_id);
    } else {
      campSet.delete(camp_id);
    }
    setSelectedCamps([...campSet]);
  };

  return (
    <FormControl component="fieldset" margin="dense">
      <FormLabel component="legend">
        Välj vilka läger som du vill ha med
      </FormLabel>
      <FormGroup>
        {camps.map((c) => {
          const checked = selectedCamps.includes(c.camp_id);

          return (
            <FormControlLabel
              key={c.camp_id}
              control={
                <Checkbox
                  checked={checked}
                  onChange={handleSelect}
                  value={c.camp_id}
                />
              }
              label={c.name}
            />
          );
        })}
      </FormGroup>
    </FormControl>
  );
});
