import React from 'react';

import { observer } from 'mobx-react';
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  Droppable,
  DroppableProvided,
  DropResult,
} from 'react-beautiful-dnd';

import { styled } from '../../styles/theme';

const StyledColumnList = styled('div')`
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  height: 100%;
`;

export const DragList = observer(function ColumnList<
  T extends { id: number; name: string },
>({
  items,
  reorder,
}: {
  items: T[];
  reorder: (from: number, to: number) => void;
}) {
  const handleDragEnd = (result: DropResult) => {
    // setDragging(false);
    // dropped outside the list or same pos
    if (!result.destination) return;
    const from = result.source.index;
    const to = result.destination.index;
    if (to === from) return;
    reorder(from, to);
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="userColumnList">
        {(droppableProvided: DroppableProvided) => (
          <StyledColumnList
            className="scrollbar"
            {...droppableProvided.droppableProps}
            ref={(ref) => {
              droppableProvided.innerRef(ref);
            }}
          >
            <DraggableList items={items} />
            {droppableProvided.placeholder}
          </StyledColumnList>
        )}
      </Droppable>
    </DragDropContext>
  );
});

const DraggableList = observer(function DraggableList<
  T extends { id: number; name: string },
>({ items }: { items: any[] }) {
  return (
    <>
      {items.map((item: T, idx) => {
        return (
          <Draggable draggableId={String(item.id)} index={idx} key={item.id}>
            {(provided, snapshot) => {
              const { dragHandleProps, draggableProps, innerRef } = provided;
              const { isDragging } = snapshot;

              return (
                <DraggableColumn
                  {...{
                    item: item,
                    dragHandleProps,
                    draggableProps,
                    innerRef,
                    isDragging,
                  }}
                />
              );
            }}
          </Draggable>
        );
      })}
    </>
  );
});

const DraggableColumn = observer(function DraggableColumn<
  T extends { name: string },
>({
  item,
  isDragging,
  ...draggable
}: {
  item: T;
  isDragging?: boolean;
} & DraggableProvided) {
  const columnTitle = item.name;

  return (
    <div
      ref={draggable.innerRef}
      style={{
        display: 'flex',
        alignItems: 'center',
        background: isDragging ? `background: rgba(0,0,0,.2);` : '',
      }}
      {...draggable.draggableProps}
      {...draggable.dragHandleProps}
    >
      <div className={'drag-item'}>
        <span>{columnTitle}</span>
      </div>
    </div>
  );
});
