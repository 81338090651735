import React, { Fragment } from 'react';

import { observer } from 'mobx-react';

import { styled } from '../../styles/theme';
import { usePersonsStatisticsQuery } from '../../generated/graphql';
import {
  DividerPrimary,
  DividerSecondary,
  StyledDashboardTable,
} from '../common/CommonDivs';
import { useStores } from '../../stores';

export const ChurchLinkDashboard = observer(function ChurchLinkDashboard() {
  const stores = useStores();
  const { linkStore, personStore } = stores;
  const churchLink = linkStore.activeLink;

  const { data, loading, error } = usePersonsStatisticsQuery({
    variables: {
      camp_id: churchLink!.camp_id,
    },
    skip: !churchLink,
  });

  if (!churchLink) {
    return null;
  }

  if (loading) {
    return <span>{'Laddar...'}</span>;
  }
  if (error && !data) {
    return <span>{`${error.message}`}</span>;
  }
  if (!data) {
    return <span>{'No data'}</span>;
  }
  const { total } = data.personsStatistics;
  const current = personStore.persons.filter((p) => p.deleted === false).length;

  return (
    <StatisticsDiv>
      <div>
        <div className="circle">
          <span>{total}</span>
        </div>
        <span>Totalt på lägret</span>
      </div>
      <div>
        <div className="circle">
          <span>{current}</span>
        </div>
        <span>Från {churchLink.name}</span>
      </div>
    </StatisticsDiv>
  );
});
export const ChurchLinkDashboardGroupsContent = observer(() => {
  const stores = useStores();
  const linkStore = stores.linkStore;
  const churchLink = linkStore.activeLink;

  if (!churchLink || !churchLink.studygroupCollection) {
    return null;
  }

  const items = churchLink.studygroupCollection.studygroups;

  if (!items) return null;

  return (
    <StyledDashboardTable>
      <div className="table-row">
        <div className="text-bold text-overflow-elipsis col-1">Titel</div>
        <div className="text-caption col-2">{'Ledare'}</div>
        <div className="text-caption col-3">{'Deltagare'}</div>
      </div>
      <DividerPrimary />
      {items.map((item) => {
        if (!item?.persons) return null;

        const leaderCount = item.persons.filter(
          (person) => person?.role && person.role.isLeader,
        ).length;
        const participantCount = item.persons.length - leaderCount;

        return (
          <Fragment key={item.id}>
            <div className="table-row">
              <div className="text-bold text-overflow-elipsis col-1">
                {item.church_id ? 'Startgrupp' : item.name}
              </div>
              <div className="text-caption col-2">{leaderCount}</div>
              <div className="text-caption col-3">{participantCount}</div>
            </div>
            <DividerSecondary />
          </Fragment>
        );
      })}
    </StyledDashboardTable>
  );
});

const StatisticsDiv = styled('div')`
  margin-top: 14px;
  display: flex;
  /* align-items: center; */

  text-align: center;
  font-size: 16px;
  font-weight: 700;
  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    padding: 10px;
  }

  .circle {
    font-size: 18px;
    color: white;
    background: ${(p) => p.theme.palette.primary.main};
    position: relative;
    border-radius: 25px;
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
    > span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;
