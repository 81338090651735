import React from 'react';

import { DashboardItemType } from '../../types/dashboard';
import { CardListIcon, PersonsIcon } from '../icons';

import {
  ChurchLinkDashboard,
  ChurchLinkDashboardGroupsContent,
} from './ChurchLinkDashboard';

export const churchLinkMenuItems: DashboardItemType[] = [
  {
    name: 'Anmälningslista',
    path: 'persons',
    icon: <PersonsIcon />,
    // Component: () => <PersonTableWrapper />,
    DashboardContent: () => <ChurchLinkDashboard />,
  },
  {
    name: 'Bilda listor',
    path: 'studygroups',
    icon: <CardListIcon />,
    // Component: () => <StudyGroupListRoute />,
    DashboardContent: () => <ChurchLinkDashboardGroupsContent />,
  },
];
