import React, { Fragment, useCallback, useEffect } from 'react';

import { observer } from 'mobx-react';
import { Paper } from '@mui/material';
import { useRouteLoaderData } from 'react-router-dom';

import {
  useSharedLinksQuery,
  useDeleteSharedLinkMutation,
  useUpdateSharedLinkMutation,
  SharedLinksDocument,
  useRegenerateSharedLinkMutation,
  CampQuery,
} from '../../../../../generated/graphql';
import { TableColumn, Row } from '../../../../../types/tableColumn';
import { InputType } from '../../../../../types/inputTypes';
import {
  TableHeader,
  TableComponent,
} from '../../../../../components/Table/Table';
import {
  updateCacheWithCampId,
  CacheOperation,
} from '../../../../../utils/updateCache';
import { getExternalSharedLink } from '../../../../../config';

const headers: TableColumn[] = [
  {
    title: 'Namn på listan',
    accessor: 'name',
    inputType: InputType.Text,
    width: 140,
  },

  {
    title: 'Länk',
    accessor: 'link',
    inputType: InputType.Copy,

    // isDisabled: () => true,
    displayer: ({ value }: { value: string }) => getExternalSharedLink(value),
    width: 320,
  },
  {
    title: 'Startdatum',
    accessor: 'startDate',
    inputType: InputType.Date,
    width: 100,
  },
  {
    title: 'Slutdatum',
    accessor: 'endDate',
    inputType: InputType.Date,
    width: 100,
  },
  {
    title: 'Aktiv',
    accessor: 'active',
    inputType: InputType.Boolean,
    width: 100,
  },
  {
    title: 'Regenera länk',
    accessor: 'regenerate',
    inputType: InputType.Button,
    width: 120,
    buttonTitle: 'Regenera länk',
  },
];

export const CampSharedLinksPage = observer(function CampSharedLinksPage() {
  const camp = useRouteLoaderData('camp') as CampQuery['camp'];
  const camp_id = camp.camp_id;
  const { data } = useSharedLinksQuery({
    variables: {
      camp_id,
    },
    skip: !camp,
  });

  const [deleteItem] = useDeleteSharedLinkMutation();
  const [updateItem] = useUpdateSharedLinkMutation();
  const [regenerateItem] = useRegenerateSharedLinkMutation();

  const handleDelete = useCallback(
    (row: Row) => {
      deleteItem({
        variables: {
          id: Number(row.id),
        },
        update: (proxy: any) =>
          updateCacheWithCampId({
            query: SharedLinksDocument,
            proxy,
            camp_id: camp_id!,
            id: row.id,
            method: CacheOperation.DELETE,
          }),
      });
    },
    [camp_id, deleteItem],
  );

  const handleUpdate = useCallback(
    (id: string | number, accessor: string, newValue: string | number) => {
      console.log(id, accessor, newValue);

      return updateItem({
        variables: {
          id: id as number,
          body: {
            [accessor]: newValue,
          },
        },
      });
    },

    [updateItem],
  );

  const regenerateLink = useCallback(
    (row: Row) => {
      return regenerateItem({
        variables: {
          id: Number(row.id),
        },
      });
    },
    [regenerateItem],
  );

  if (!data) {
    return null;
  }

  return (
    <>
      <Paper>
        <TableHeader title={'Kolumner'} />
        <TableComponent
          headers={headers}
          rows={data.sharedLinks}
          onUpdateRow={handleUpdate}
          onEdit={regenerateLink}
          onDelete={handleDelete}
        />
      </Paper>
    </>
  );
});
