import { styled } from '../../../styles/theme';

export const StyledImportList = styled('div')`
  max-height: 500px;
  overflow: auto;
`;

export const StyledItemListContainer = styled('div')`
  height: 100%;
  max-height: 618px;
  overflow: auto;
`;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export const DropDownMenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 5.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
